import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Button, Divider, StructuredList, TextInput } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import {
  getEditPanel,
  getHoldStatus,
  getRequesterApprovals,
  getYardToPDCState,
} from '../../../../../store/features/HoldTypeDetails/selectors';
import { setRequesterApprovals } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import './RequesterApprovals.scss';

const StyledGrid = styled(Grid)({
  display: 'block',
  padding: '16px 16px 16px 16px',
  //   display: 'flex',
  // width: '100%',
  // padding: '12px 16px',
  // flexDirection: 'column',
  // alignItems: 'flex-start',
  // gap: '8px',
  // justifyContent:'space-between'
});
const StyledGrid2 = styled(Grid)({
  display: 'flex',
  padding: 'var(--spacing-xs, 8px) var(--spacing-sm, 16px) var(--spacing-sm, 16px) var(--spacing-sm, 16px)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 'var(--spacing-half-xs, 4px)',
  flex: '1 0 0',
});
const TypoGraphy = styled(Typography)({
  color: 'var(--sys-light-Text-variant, #58595B)',
  /* Labels/Regular */
  fontFamily: 'ToyotaType-Regular',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px',
});
export const RequesterApprovals = ({ setFooterDisable }) => {
  const [test, setIndex] = useState<number>(0);
  const [childError, setChildError] = useState<boolean>(false);
  const editPanel = useAppSelector(getEditPanel);
  const holdStatus = useAppSelector(getHoldStatus);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const requesterApprovals = useAppSelector(getRequesterApprovals);
  const dispatch = useAppDispatch();
  const [updatedData, setUpdatedData] = useState<any>(requesterApprovals);

  useEffect(() => {
    setUpdatedData({ ...requesterApprovals });
  }, [requesterApprovals]);
  const handleBlur = (e) => {
    dispatch(setRequesterApprovals(updatedData));
  };

  const columns = [
    {
      field: 'firstName',
      name: 'First Name',
      type: 'string',
    },
    {
      field: 'lastName',
      name: 'Last Name',
      type: 'string',
    },
    {
      field: 'jobtitle',
      name: 'Job Role',
      type: 'string',
    },
    {
      field: 'email',
      name: 'Email',
      type: 'string',
    },
  ];
  let rows = requesterApprovals.approvalUsers.map((req, i) => {
    return {
      id: i,
      data: {
        firstName: req.firstName,
        lastName: req.lastName,
        jobtitle: req.jobTitle,
        email: req.email,
      },
      isActive: false,
    };
  });

  const inputField = ({ label, placeholder, onChange, value, error, onBlur }) => {
    return (
      <>
        <TypoGraphy>{label}</TypoGraphy>
        <TextInput
          label=""
          maxWidth="22vw"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          helperText={error && 'Please enter a valid Email address'}
          onBlur={onBlur}
        />
      </>
    );
  };

  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const data = event.target.value;
    setFooterDisable(false);
    setUpdatedData({
      approvalUsers: updatedData.approvalUsers.map((user, i) => (i === index ? { ...user, firstName: data } : user)),
    });
  };
  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const data = event.target.value;
    setFooterDisable(false);
    setUpdatedData({
      approvalUsers: updatedData.approvalUsers.map((user, i) => (i === index ? { ...user, lastName: data } : user)),
    });
  };

  const handleJobTitle = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setFooterDisable(false);
    setUpdatedData({
      approvalUsers: updatedData.approvalUsers.map((user, i) =>
        i === index ? { ...user, jobTitle: event.target.value } : user,
      ),
    });
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setIndex(index);
    const emailEvent = event.target.value;
    setFooterDisable(false);
    setUpdatedData({
      approvalUsers: updatedData.approvalUsers.map((user, i) => (i === index ? { ...user, email: emailEvent } : user)),
    });
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(emailEvent) && emailEvent.trim()) {
      if (index === test) {
        setChildError(true);
      }
    } else {
      setChildError(false);
    }
  };
  const handleDeleteUser = (index: number) => {
    const updatedUsers = [...updatedData.approvalUsers];
    updatedUsers.splice(index, 1);
    setFooterDisable(false);
    dispatch(setRequesterApprovals({
      ...updatedData,
      approvalUsers: updatedUsers,
    }));
  };

  const handleAddMoreApproval = () => {
    setFooterDisable(false);
    dispatch(setRequesterApprovals({
      approvalUsers: [...updatedData.approvalUsers, { firstName: '', lastName: '', jobTitle: '', email: '' }],
    }));
  };

  const isEdit = holdStatus === 'Draft' || editPanel === '7' || isyardToPDC;

  return (
    <>
      {!isEdit ? (
        <StyledGrid>
          <StructuredList
            variant="columnlist"
            padding="10px"
            listHeader=""
            rows={rows}
            columns={columns}
            alignment="hanging"
            listVariant="default"
            fullBorder={true}
            sx={{
              fontFamily: 'ToyotaType-Regular',
              fontSize: '12px',
              '& .MuiTableHead-root': {
                width: '200px',
                bgcolor: 'whitesmoke',
              },
              '&.MuiTableRow-root': {
                bgcolor: 'whitesmoke',
              },
            }}
            columnHeaderStyles={{
              width: '1600px',
              fontFamily: 'Toyota Type Semibold',
              fontSize: '14px',
              bgcolor: '#f1f2f4',
              '& .MuiTableHead-root': {
                width: '200px',
                bgcolor: 'whitesmoke',
              },
              '&.MuiTableRow-root': {
                bgcolor: 'whitesmoke',
              },
            }}
          />
        </StyledGrid>
      ) : (
        updatedData?.approvalUsers?.map((user, index) => (
          <div className="mainDiv">
            <Grid container spacing={0} sx={{ marginBottom: '1rem' }}>
              <StyledGrid2 item lg={4} md={4}>
                {inputField({
                  label: (
                    <>
                      First Name <span style={{ color: 'var(--sys-light-Text-error, #C40818)' }}>*</span>
                    </>
                  ),
                  onChange: (e) => handleFirstName(e, index),
                  placeholder: 'Enter First Name',
                  value: user?.firstName,
                  error: false,
                  onBlur: (e) => handleBlur(e),
                })}
              </StyledGrid2>
              <StyledGrid2 item lg={4} md={4}>
                {inputField({
                  label: (
                    <>
                      Last Name <span style={{ color: 'var(--sys-light-Text-error, #C40818)' }}>*</span>{' '}
                    </>
                  ),
                  placeholder: 'Enter Last Name',
                  onChange: (e) => handleLastName(e, index),
                  value: user?.lastName,
                  error: false,
                  onBlur: (e) => handleBlur(e),
                })}
              </StyledGrid2>
              <StyledGrid2 item lg={3.7} md={3.7}>
                {inputField({
                  label: 'Job Role',
                  placeholder: 'Enter Job Role',
                  onChange: (e) => handleJobTitle(e, index),
                  value: user?.jobTitle,
                  error: false,
                  onBlur: (e) => handleBlur(e),
                })}
              </StyledGrid2>
              {updatedData.approvalUsers.length > 1 && (
                <Grid item lg={0.1} md={0.1}>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '.25rem',
                      alignSelf: 'stretch',
                      marginTop: '6.875rem',
                      marginLeft: '.3125rem',
                    }}
                    onClick={() => handleDeleteUser(index)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </Grid>
              )}
              <StyledGrid2 item lg={4} md={4}>
                {inputField({
                  label: 'Email',
                  placeholder: ' Enter Email',
                  onChange: (e) => handleEmail(e, index),
                  value: user?.email,
                  error: index === test && childError,
                  onBlur: (e) => handleBlur(e),
                })}
              </StyledGrid2>
            </Grid>
            <Divider
              sx={{
                display: 'flex',
                padding: '.625rem 1rem',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                width: '100%',
                marginBottom: '.625rem',
              }}
            />
          </div>
        ))
      )}
      {isEdit && (
        <StyledGrid2 item sx={{ margin: '0px 24px' }} xs={12} lg={12} md={12}>
          <Button variant={'secondary'} label={'Add Another Member +'} onClick={handleAddMoreApproval} />
        </StyledGrid2>
      )}
    </>
  );
};
