// @ts-nocheck
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { Button, IconButton, LoadingSpinner, Modal, useNotificationContext } from 'cubee2e-cube-global-components';
import dayjs from 'dayjs';
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import {
  setHoldDetails,
  setHoldReqNumber,
  setHoldStatus,
  setIssueResolution,
  setModelSpecification,
  setParts,
  setRequesterApprovals,
  setToyotaInternalDetails as setToyotaInternalDetailsData,
  setyardToPDCState,
} from '../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Activity } from './Activity';
import Comment from './Comment';
import './HoldRequestDetails.scss';
import Vehicles from './Vehicles/Vehicles';

import { useNavigate, useParams } from 'react-router-dom';
import { getUserDetails } from '../../../App';
import { apiHandler } from '../../../assets/api';
import { endpoint } from '../../../assets/api/endpoint';
import { getFetchingDetailsData, getHoldStatus, getSubmissionDate, getVehicleDetailsMetricsApi } from '../../../store';
// import { getHoldStatusCountsApi } from '../../../store/features/HoldRequest/api';
import { setViewUpdated as setViewUpdate } from '../../../store/features/HoldRequest/slice';
import {
  getHmStorageValuesApi,
  getHoldRequestDetailsApi,
  getPDCAssigneeDropdownOptionsApi,
  getVehicleModelsDropdownApi,
} from '../../../store/features/HoldTypeDetails/api';
import {
  getEditPanel,
  getHoldReqNumber,
  getHoldType,
  getIssueDetails,
  getPDCAssigneeDropdownOptions,
} from '../../../store/features/HoldTypeDetails/selectors';
import { HoldRequest } from '../../../store/features/HoldTypeDetails/types';
import { RootState } from '../../../store/store';
import { IssueDetailsData } from '../../../types';
import { getCurrentDateAndTime } from '../../../utils';
import HoldRequestActions from '../../HoldRequestActions/HoldRequestActions';
import HoldReqAtionsServices from '../../HoldRequestActions/HoldRequestService';
import Attachments from './Attachments';
import Details from './Details';
import { Footer } from './Footer';
import { HoldRequestDetailsHeader } from './HoldRequestDetailsHeader';

const gridName = 'Vehicle List';

const SaveAsModal = lazy(() => import('../../SavePageView/SaveAsModal/SaveAsModal'));
const ManagePageView = lazy(() => import('../../SavePageView/ManagePageViewModal/ManagePageView'));

const HoldRequestDetails = (props) => {
  const [isrendered, setisrendered] = useState(false);
  const [isnotactive, setisnotactive] = useState(true);
  const [isLoaderHRdetails, setIsLoaderHRdetails] = useState<boolean>(true);
  const [lastrefreshedDT, setLastrefreshedDT] = useState<String>(getCurrentDateAndTime());
  const [edit, setEdit] = useState<boolean>(true);
  const holdTypeDetails: HoldRequest = useAppSelector((state: RootState) => state.holdTypeDetails);
  const metricsData = useAppSelector((state) => state.vehicleMetrics);
  const [selectedKPIFilter, setSelectedKPIFilter] = useState<Object | null>(null);
  const [holdReqNo, setHoldReqNo] = useState();
  const [footerApproveDisable, setFooterApproveDisable] = useState<boolean>(true);
  const currentYear = new Date().getFullYear();
  const [holdReqActions, setHoldReqActions] = useState({
    isCompleteEnable: false,
    isCancelEnable: false,
    showModal: false,
    modalCall: '',
    disabled: false,
  });
  let [notification, setNotification] = useState<{ type: 'success' | 'error' | 'none'; message: string }>({
    type: 'none',
    message: '',
  });

  useEffect(() => {
    props.ShowSidebar(false);
  }, []);
  //state for handling data
  let [toyotaInternalDetails, setToyotaInternalDetails] = useState({
    anticipatedDelayTiming: null,
    updateDelay: false,
  });
  const [riskRank, setRiskRank] = useState([{ optionLabel: '', id: '' }]);
  const [PDCAssignee, setPDCAssignee] = useState<string>(holdTypeDetails?.PDCPEAssignee ?? '');
  const [employeeId, setEmployeeId] = useState<string>(holdTypeDetails?.employeeId ?? '');
  const [convertToPDC, setConvertToPdc] = useState(false);

  //# SAVE PAGE VIEW - STATES[]
  const [viewUpdated, setViewUpdated] = useState<boolean>(false);
  const [openSaveAsModal, setOpenSaveAsModal] = useState<boolean>(false);
  const [openManagePageViewModal, setOpenManagePageViewModal] = useState<boolean>(false);
  const [savePageViewList, setSavePageViewList] = useState<{}>({});
  const [selectedPageView, setSelectedPageView] = useState<string>('');
  const [savePageLoader, setSavePageLoader] = useState<boolean>(false);
  const [dataTableData, setDataTableData] = useState({});
  const [newTableData, setNewTableData] = useState({});
  const [updatedData, setUpdatedData] = useState<any>();
  const [delaytiming, setdelaytiming] = useState(null);
  const [value, setValue] = React.useState('1');
  const [parentState, setParentState] = useState<string>('');
  const [isActionbtndisabled, setisActionbtndisabled] = useState<boolean>(false);
  //Redux hooks
  const HoldReqNum = useAppSelector(getHoldReqNumber);
  const holdType = useAppSelector(getHoldType);
  const holdStatus = useAppSelector(getHoldStatus);
  const submissionDate = useAppSelector(getSubmissionDate);
  const fetchingDetailsData = useAppSelector(getFetchingDetailsData);
  const pdcAssigneeDropdownOptions = useAppSelector(getPDCAssigneeDropdownOptions);
  const editPanel = useAppSelector(getEditPanel);
  const auth = useAppSelector((state) => state.auth);
  const issueDetails = useAppSelector(getIssueDetails);
  const issueResolution = holdTypeDetails?.issueResolution;
  const toyotaInternalDetailsData = holdTypeDetails?.toyotaInternalDetails;
  const modelSpecifications = holdTypeDetails?.modelSpecification;
  const requesterApprovals = holdTypeDetails?.requesterApprovals;
  const parts = holdTypeDetails?.parts;
  const { dispatchNotification } = useNotificationContext();
  const dispatch = useAppDispatch();
  const authToken = auth.idToken;
  const authAccessToken = auth.accessToken;
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo') || '{}');
  const [holdRequestDetails, setHoldRequestDetails] = useState<IssueDetailsData>(issueDetails);

  const { qcHoldId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoaderHRdetails(true);
    if (qcHoldId) {
      // Dispatch it to the Redux store
      setHoldReqNo(qcHoldId);
      dispatch(setHoldReqNumber(qcHoldId));
      dispatch(getHoldRequestDetailsApi(qcHoldId));
      dispatch(getVehicleDetailsMetricsApi(qcHoldId));
    }
  }, [qcHoldId]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    console.log(
      'totalVehiclesSubmitted',
      metricsData?.totalVehiclesSubmitted,
      ' userData?.employeeId ',
      userData?.employeeId,
    );

    let updatedUserId = holdTypeDetails?.updatedUserId;
    let loginUserId = userData?.employeeId;
    if (updatedUserId === null || updatedUserId === undefined) {
      updatedUserId = "";
    }
    if (loginUserId === null || loginUserId === undefined) {
      loginUserId = "";
    }
    if (
      metricsData?.totalVehiclesSubmitted > 0 &&
      ((updatedUserId.toString() !== loginUserId.toString() &&
        holdTypeDetails?.holdStatus === 'Pending Approval' &&
        holdTypeDetails?.pendingApprovalType !== 'HoldCancel' &&
        holdTypeDetails?.pendingApprovalType !== 'YardToPDC') ||
        (holdTypeDetails?.pendingApprovalType === 'HoldCancel' &&
          holdTypeDetails?.pendingApprovalType !== 'YardToPDC' &&
          holdTypeDetails?.pendingApprovalType !== 'HoldInitiation' &&
          updatedUserId.toString() !== loginUserId.toString()) ||
        (holdTypeDetails?.holdStatus === 'Pending Approval' &&
          holdTypeDetails?.pendingApprovalType === 'YardToPDC' &&
          holdTypeDetails?.pendingApprovalType !== 'HoldCancel' &&
          holdTypeDetails?.pendingApprovalType !== 'HoldInitiation' &&
          updatedUserId.toString() !== loginUserId.toString()))
    ) {
      setFooterApproveDisable(false);
    } else {
      setFooterApproveDisable(true);
    }

  }, [holdTypeDetails, metricsData?.totalVehiclesSubmitted > 0]);

  useEffect(() => {
    setHoldReqNo(HoldReqNum);
    dispatch(setHoldReqNumber(HoldReqNum));
    getPageViewList();
    if (holdTypeDetails?.pendingApprovalType !== 'HoldCancel' && HoldReqNum !== '') {
      getActions();
    }
    setLastrefreshedDT(getCurrentDateAndTime());
  }, [HoldReqNum]);

  useEffect(() => {
    if (holdStatus == 'Active Hold' || holdStatus == 'Active') {
      setisnotactive(false);
    }
  }, [holdStatus]);

  const ConvertToPDCUpdate = (value) => {
    setConvertToPdc(value);
    dispatch(setyardToPDCState(false));
  };
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };

  const onRefresh = useCallback(async () => {
    dispatch(getVehicleDetailsMetricsApi(qcHoldId));
  }, [qcHoldId]);

  const savePageCallback = (data, init = false) => {
    if (!init) {
      setViewUpdated(!!selectedPageView && true);
      dispatch(setViewUpdate(!!selectedPageView && true));
    }
    setNewTableData({ ...data });
  };

  const sendRefreshCall = (val) => {
    // the callback. Use a better namesetsolventstate(solventstate);
    if (val === '1') {
      setLastrefreshedDT(getCurrentDateAndTime());
    }
  };

  const sendApproveDenyCall = (val) => {
    if (val === 'Approve') {
      setHoldReqActions({
        ...holdReqActions,
        showModal: true,
        modalCall: 'cancelApprove',
      });
    } else if (val === 'Deny') {
      setHoldReqActions({
        ...holdReqActions,
        showModal: true,
        modalCall: 'cancelDeny',
      });
    }
  };

  const updateStatusCall = (val) => {
    // the callback. Use a better namesetsolventstate(solventstate);
    if (val === 'success') {
      setisActionbtndisabled(true);
      let successMsg = {
        fieldName: 'success',
        field: `Hold Request #${HoldReqNum} has been cancelled successfully`,
      };

      if (holdReqActions.modalCall === 'cancel' && holdStatus !== 'Pending Approval') {
        dispatch(setHoldStatus('Pending Approval'));
        successMsg['field'] = `Hold Request #${HoldReqNum} has been updated successfully`;
      } else if (holdReqActions.modalCall === 'cancel' && holdStatus === 'Pending Approval') {
        dispatch(setHoldStatus('Pending Approval'));
        successMsg['field'] = `Hold Request #${HoldReqNum} has been cancelled successfully`;
      } else if (holdReqActions.modalCall === 'cancelApprove') {
        dispatch(setHoldStatus('Cancellation In Progress'));
        successMsg['field'] = `Hold #${HoldReqNum} has been approved and hold Cancellation will complete shortly`;
      } else if (holdReqActions.modalCall === 'cancelDeny') {
        dispatch(setHoldStatus('Pending Approval'));
        successMsg['field'] = `Hold Request #${HoldReqNum} is moved to previous state successfully`;
      } else {
        dispatch(setHoldStatus('Completed'));
        successMsg['field'] = `PDC Hold Request #${HoldReqNum} marked as Completed`;
      }
      localStorage.setItem('actionRequest', JSON.stringify({ status: 'success', message: successMsg }));
      navigate(`/HoldManagement`);
      setHoldReqActions({
        ...holdReqActions,
        showModal: false,
      });
    } else {
      setHoldReqActions({
        ...holdReqActions,
        showModal: false,
      });
      const errorMsg = {
        fieldName: 'error',
        field: '',
      };

      if (holdReqActions.modalCall === 'cancel') {
        errorMsg['field'] = `Failed to cancel the hold request #${HoldReqNum}`;
      } else {
        errorMsg['field'] = `Failed to mark the PDC Hold Request #${HoldReqNum} as Completed`;
      }

      handleNotification(errorMsg);
    }
  };

  //# SAVE PAGE VIE

  const handlePDCPEAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = event.id;
    setPDCAssignee(event);
    setEmployeeId(pdcAssigneeDropdownOptions[id]?.employeeId);
  };

  const getActions = async () => {
    const fetchActionDetails = await HoldReqAtionsServices.getApplicableActions(
      HoldReqNum,
      authToken,
      userData?.employeeId,
    );
    setHoldReqActions({
      ...holdReqActions,
      isCompleteEnable: fetchActionDetails?.data?.isCompleteEnable,
      isCancelEnable: fetchActionDetails?.data?.isCancelEnable,
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue !== '2') {
      setSelectedKPIFilter(null);
      setSelectedPageView('');
      setViewUpdated(false);
      dispatch(setViewUpdate(false));
    }
    else if(newValue === '2') 
    {
      dispatch(getVehicleDetailsMetricsApi(HoldReqNum));
    }
    else {
      let defaultView = savePageViewList?.pinnedViews?.find((x) => x.isDefault);
      if (defaultView) {
        onClickViewName(defaultView);
      }
    }
    setValue(newValue);
  };

  const handleConvertYardToPDC = () => {
    setValue('1');
    setConvertToPdc(true);
    dispatch(getVehicleModelsDropdownApi());
    dispatch(getHmStorageValuesApi());
    dispatch(getPDCAssigneeDropdownOptionsApi());
    dispatch(setyardToPDCState(true));

    // dispatch(setIsDraft(true));
    dispatch(
      setIssueResolution({
        pdcActionDirectionAtYardPort: '',
        processContainmentMethod: '',
        flowOutPreventionMethod: '',
      }),
    );
    dispatch(
      setToyotaInternalDetailsData({
        icf: { id: '', optionLabel: '' },
        companyResponsible: { id: '', optionLabel: '' },
        supplierResponsible: '',
      }),
    );
    // dispatch(setToyotaInternalDetails({icf: {id: '', optionLabel: ''}, companyResponsible: {id: '', optionLabel: ''}, supplierResponsible: ''}))
    dispatch(
      setParts({
        partDtls: [{ partNumber: '', partName: '', partSupplier: '', icfForPartIssue: '', supplierCode: '' }],
      }),
    );
    dispatch(
      setHoldDetails({
        riskRank: { id: '', optionLabel: '' },
        pdcPEAssignee: '',
        employeeId: '',
        anticipatedDelayTiming: '',
      }),
    );
    // dispatch(
    //   setModelSpecification({
    //     endDateType: { id: '', optionLabel: '' },
    //     startDateType: { id: '', optionLabel: '' },
    //     vehicleDtl: [],
    //     vinEndDateTime: '',
    //     vinEndLogicReasoning: '',
    //     vinStartDateTime: '',
    //     vinStartLogicReasoning: '',
    //   }),
    // );

    dispatch(
      setModelSpecification({
        vehicleDtl: [
          {
            vehicleTypeSpecification: '',
            modelYear: {
              open: false,
              value: {},
              options: [
                { optionLabel: (currentYear + 1).toString(), id: (currentYear + 1).toString() },
                { optionLabel: currentYear.toString(), id: currentYear.toString() },
                { optionLabel: (currentYear - 1).toString(), id: (currentYear - 1).toString() },
              ],
            },
            series: { open: false, value: { optionLabel: '', id: '' }, options: [] },
            model: { open: false, value: [], options: [] },
          },
        ],
        endDateType: { id: '', optionLabel: '' },
        startDateType: { id: '', optionLabel: '' },
        vinEndDateTime: dayjs(new Date()),
        vinEndLogicReasoning: '',
        vinStartDateTime: dayjs(new Date()),
        vinStartLogicReasoning: '',
      }),
    );

    dispatch(
      setRequesterApprovals({
        approvalUsers: [{ fullName: '', firstName: '', lastName: '', jobTitle: '', email: '' }],
      }),
    );
    // dispatch(setHoldType('PDC'));
  };

  const handleKPIFilter = (data) => {
    if (value !== '2') {
      setValue('2');
    }
    setSelectedKPIFilter(data);
  };

  const HoldReqDtl = useMemo(() => {
    let result = {
      riskRankID: holdTypeDetails?.riskRank_Id,
      riskRank_label: holdTypeDetails?.riskRank,
      PDCPEAssigneeD: holdTypeDetails?.PDCPEAssignee,
      anticipatedTime: holdTypeDetails?.anticipatedDelayTiming === '-' ? null : holdTypeDetails?.anticipatedDelayTiming,
    };
    if (updatedData && updatedData?.hasOwnProperty('toyotaInternalDetails')) {
      if (updatedData?.toyotaInternalDetails?.anticipatedDelayTiming !== '') {
        result = {
          ...result,
          anticipatedTime: updatedData?.toyotaInternalDetails?.anticipatedDelayTiming,
        };
      }
      if (updatedData?.toyotaInternalDetails?.riskRank !== '') {
        result = {
          ...result,
          riskRankID: updatedData?.toyotaInternalDetails?.riskRank,
          riskRank_label: updatedData?.toyotaInternalDetails?.riskRank_optionLabel,
        };
      }
      if (updatedData?.toyotaInternalDetails?.pdcPEAssignee !== '') {
        result = {
          ...result,
          PDCPEAssigneeD: updatedData?.toyotaInternalDetails?.pdcPEAssignee,
        };
      }
    }
    return result;
  }, [holdTypeDetails, updatedData]);
  let riskRankID = HoldReqDtl?.riskRankID;

  riskRankID =
    riskRankID === 'NOTRANKED'
      ? 'Not Rank'
      : riskRankID === ''
        ? 'Not Rank'
        : riskRankID === 'undefined'
          ? 'Not Rank'
          : riskRankID === undefined
            ? 'Not Rank'
            : riskRankID + ' RANK';
  useEffect(() => {
    dispatch(getHmStorageValuesApi());
    dispatch(getPDCAssigneeDropdownOptionsApi());
    dispatch(getVehicleModelsDropdownApi());
    let seriesD = '';
    for (let i = 0; i < holdTypeDetails?.vehicleDetailsList?.length; i++) {
      if (i + 1 === holdTypeDetails?.vehicleDetailsList?.length) {
        seriesD = seriesD + holdTypeDetails?.vehicleDetailsList?.[i]?.['series'];
      } else {
        seriesD = seriesD + holdTypeDetails?.vehicleDetailsList[i]?.series + ', ';
      }
    }
  }, []);

  useEffect(() => {
    if (editPanel === '0') {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, [editPanel]);

  //fetching data from api to text boxes

  const getActionsModal = (action: string) => {
    setHoldReqActions({
      ...holdReqActions,
      showModal: true,
      modalCall: action,
    });
  };

  const CreatedQCAction = (action: string) => {
    console.log('action', action);
    console.log('1111111111111 issueDetails', issueDetails);
    console.log('1111111111111 issueResolution', issueResolution);
    console.log('1111111111111 toyotaInternalDetailsData', toyotaInternalDetailsData);
  };

  useEffect(() => {
    if (HoldReqDtl?.riskRankID) {
      const updatedRiskRank = {
        optionLabel: HoldReqDtl?.riskRank_label,
        id: HoldReqDtl?.riskRankID,
      };
      setRiskRank(updatedRiskRank);
    }
    if (HoldReqDtl?.PDCPEAssigneeD) {
      setPDCAssignee({ optionLabel: HoldReqDtl?.PDCPEAssigneeD, id: HoldReqDtl?.PDCPEAssigneeD });
    }
    if (HoldReqDtl?.anticipatedTime) {
      let delaydate = null;
      if (delaytiming === null) {
        delaydate = HoldReqDtl?.anticipatedTime;
      } else {
        delaydate = delaytiming;
      }
      setToyotaInternalDetails({
        ...toyotaInternalDetails,
        anticipatedDelayTiming: dayjs(delaydate),
        updateDelay: false,
      });
    }
  }, [edit]);
  useEffect(() => {
    if (notification.type !== 'none') {
      setTimeout(() => {
        setNotification({ type: 'none', message: '' });
      }, 5000);
    }
  }, [notification]);

  // make wrapper function to give child
  const wrapperSetParentState = useCallback(
    (val) => {
      setParentState(val);

      if (val == 'success') {
        setisrendered(false);
        setisnotactive(true);
        dispatch(setHoldStatus('Pending Update'));
        const filter = {
          fieldName: 'success',
          field: `Hold Request #${holdReqNo} successfully converted to Pending Update`,
        };
        handleNotification(filter);
      }
      if (val == 'error') {
        setisrendered(false);
      }
      // setisrendered(false);
    },
    [setParentState],
  );

  useEffect(() => { }, []);
  const getPageViewList = async (noDefault = false) => {
    let userDetails = userData;
    setIsLoaderHRdetails(true);
    if (!userData || Object.keys(userDetails)?.length <= 0) {
      let details = await getUserDetails(authAccessToken);
      userDetails = details;
    }

    const result = await apiHandler({
      url: endpoint.PAGE_VIEW_LIST + `?userId=${userDetails?.employeeId}&gridName=${gridName}`,
      method: 'GET',
      authToken,
    });

    if (result) {
      setSavePageViewList(result.data?.pageViews);
      let defaultView = result?.data?.pageViews?.pinnedViews?.find((x) => x.isDefault);
      if (defaultView && !noDefault) {
        onClickViewName(defaultView);
      } else {
        unsetView(result?.data?.pageViews?.unPinnedViews);
      }
    }
    setIsLoaderHRdetails(false);
  };

  const onClickViewName = (view, setByClick = false) => {
    if (view && Object.keys(view)?.length <= 0) {
      return;
    }
    setSelectedPageView(view.viewName);
    setDataTableData({ ...view.viewData, setByClick: setByClick });
  };

  const unsetView = (list: []) => {
    let defaultView = [...(savePageViewList?.unPinnedViews || list || [])].find((x) => x.userId === 0);
    if (!defaultView) {
      return;
    }
    setViewUpdated(false);
    dispatch(setViewUpdate(false));
    onClickViewName(defaultView, true);
  };

  const getAndSetPageViews = (pageViews, oldName, newName) => {
    setSavePageViewList(pageViews);
    if (selectedPageView === oldName) {
      setSelectedPageView(newName);
    }
    if ([...(pageViews?.pinnedViews || []), ...(pageViews?.unPinnedViews || [])]?.length <= 1) {
      unsetView();
    }
  };

  const handleMenuItemClick = (item: any) => {
    if (item === 'saveAs') {
      setOpenSaveAsModal(true);
    } else if (item === 'save') {
      updatePageView();
    } else if (item === 'managePageView') {
      setOpenManagePageViewModal(true);
    } else if (item === 'default') {
      unsetView();
    } else {
      onClickViewName(
        savePageViewList['pinnedViews'].find((x) => x.viewName === item),
        true,
      );
    }
  };

  const updatePageView = async () => {
    setSavePageLoader(true);

    let view = [...(savePageViewList?.pinnedViews || []), ...(savePageViewList?.unPinnedViews || [])].find(
      (x) => x.viewName === selectedPageView,
    );
    if (!view) {
      return;
    }

    const payload = {
      userId: userData?.employeeId,
      gridName: gridName,
      isUpdateViewData: true,
      pageViews: [
        {
          viewName: selectedPageView,
          isDefault: view.isDefault,
          isPinned: view.isPinned,
          viewOrder: view.viewOrder,
          seqNo: view.seqNo,
          viewData: {
            ...newTableData,
          },
        },
      ],
    };

    try {
      const result = await apiHandler({
        url: endpoint.PAGE_VIEW_UPDATE,
        method: 'PUT',
        authToken,
        data: payload,
      });
      if (['200', '201'].includes((result?.status || 0).toString())) {
        setViewUpdated(false);
        dispatch(setViewUpdate(false));
        getPageViewList(true);
        setNotification({ type: 'success', message: 'View details updated.' });
      }
      setSavePageLoader(false);
    } catch (error) {
      setSavePageLoader(false);
      setNotification({ type: 'error', message: 'View update failed.' });
    }
  };

  const closeActionModal = async () => {
    setHoldReqActions({
      ...holdReqActions,
      showModal: false,
    });
  };

  if (fetchingDetailsData) {
    return <LoadingSpinner variant="loading-spinner" />;
  }
  console.log('isLoaderHRdetails', isLoaderHRdetails);
  const BackToAllQC = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          position: 'sticky',
          top: '6.4rem',
          background: '#f8f8f9',
          zIndex: 1000, // High zIndex to stay above other elements
        }}
      >
        <IconButton
          name="ArrowBack"
          sx={{ cursor: 'pointer' }}
          fontSize="medium"
          onClick={() => navigate(`/HoldManagement`)}
        />
        <Typography variant="body1">Back to All QC Hold </Typography>
      </Grid>
    );
  };

  return isLoaderHRdetails ? (
    <div className="loading-hold">
      <LoadingSpinner variant="loading-spinner" />
    </div>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        top: '7.5rem',
      }}
    >
      <BackToAllQC />
      <Grid container xs={12}>
        <HoldRequestDetailsHeader
          holdReqNo={holdReqNo}
          isActionbtndisabled={isActionbtndisabled}
          getActionsModal={getActionsModal}
          holdReqActions={holdReqActions}
          value={value}
          savePageViewList={savePageViewList}
          handleConvertYardToPDC={handleConvertYardToPDC}
          lastrefreshedDT={lastrefreshedDT}
          convertToPDC={convertToPDC}
          ConvertToPDCUpdate={ConvertToPDCUpdate}
          handleMenuItemClick={handleMenuItemClick}
        />
        <Box sx={{ width: '100%', typography: 'body1', flexGrow: 1, ml: '1rem', mr: '1rem', marginBottom: '60px' }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                position: 'sticky',
                top: '13rem',
                zIndex: 998,
              }}
            >
              <TabList
                className="TabView"
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={{ background: '#fff', width: '100%' }}
              >
                <Tab
                  label="Details"
                  value="1"
                  TabIndicatorProps={{ style: { backgroundColor: '#000' } }}
                  sx={{
                    width: '175px',
                    color: '#000000',
                    fontFamily: 'ToyotaType-Regular',
                    fontSize: '14px',
                    padding: '16px',
                    textTransform: 'capitalize',
                  }}
                />
                <Tab
                  label="Vehicles"
                  value="2"
                  sx={{
                    width: '175px',
                    color: '#000000',
                    fontFamily: 'ToyotaType-Regular',
                    fontSize: '14px',
                    padding: '16px',
                    textTransform: 'capitalize',
                  }}
                />
                <Tab
                  label="Attachments"
                  value="3"
                  TabIndicatorProps={{ style: { backgroundColor: '#000' } }}
                  sx={{
                    width: '175px',
                    color: '#000000',
                    fontFamily: 'ToyotaType-Regular',
                    fontSize: '14px',
                    padding: '16px',
                    textTransform: 'capitalize',
                  }}
                />
                <Tab
                  label="Activity"
                  value="4"
                  disabled={true}
                  sx={{
                    width: '175px',
                    color: '#000000',
                    fontFamily: 'ToyotaType-Regular',
                    fontSize: '14px',
                    padding: '16px',
                    textTransform: 'capitalize',
                  }}
                />
                <Tab
                  label="Comments"
                  value="5"
                  // disabled={true}
                  sx={{
                    width: '175px',
                    color: '#000000',
                    fontFamily: 'ToyotaType-Regular',
                    fontSize: '14px',
                    padding: '16px',
                    textTransform: 'capitalize',
                  }}
                />
              </TabList>
            </Box>
            <Details convertToPDC={convertToPDC} />
            <TabPanel value="2" className="tabpanelcss">
              <Vehicles
                holdId={holdReqNo}
                submissionDate={submissionDate}
                onRefresh={onRefresh}
                selectedKPIFilter={selectedKPIFilter}
                setSelectedKPIFilter={handleKPIFilter}
                tableData={dataTableData}
                handleTableUpdate={savePageCallback}
                sendRefreshCall={sendRefreshCall}
                holdType={holdType}
                holdStatus={holdStatus}
                lastrefreshedDT={lastrefreshedDT}
              />
            </TabPanel>
            <TabPanel value="3" className="tabpanelcss">
              <Attachments text={''} height={0} DetailsP={'true'} sendRefreshCall={sendRefreshCall} />
            </TabPanel>
            <TabPanel value="4" className="tabpanelcss" disabled={true}>
              <Activity />
            </TabPanel>
            <TabPanel value="5" className="tabpanelcss" disabled={true}>
              <Comment />
            </TabPanel>
          </TabContext>
        </Box>
        <Footer
          holRequest={holdReqNo}
          holdStatus={holdStatus}
          setFooterApproveDisable={setFooterApproveDisable}
          footerApproveDisable={footerApproveDisable}
          sendApproveDenyCall={sendApproveDenyCall}
          userId={userData?.employeeId}
        />
        {holdReqActions.showModal && (
          <HoldRequestActions
            modalCall={holdReqActions.modalCall}
            HoldReqNum={HoldReqNum}
            holdStatus={holdStatus}
            authToken={authToken}
            employeeId={userData?.employeeId}
            updateStatusCall={updateStatusCall}
            closeModal={closeActionModal}
            totalVINs={metricsData?.totalVehiclesSubmitted}
          />
        )}
      </Grid>

      {openSaveAsModal && (
        <Suspense fallback={<></>}>
          <SaveAsModal
            openSaveAsModal
            setOpenSaveAsModal={setOpenSaveAsModal}
            setSelectedValue={(viewData) => {
              setSelectedPageView(viewData);
              setViewUpdated(false);
              dispatch(setViewUpdate(false));
              getPageViewList(true);
              setNotification({ type: 'success', message: 'New view saved.' });
            }}
            savePageViewList={savePageViewList}
            gridViewName={gridName}
            saveData={{
              ...newTableData,
            }}
          />
        </Suspense>
      )}
      {openManagePageViewModal && (
        <Suspense fallback={<></>}>
          <ManagePageView
            viewsList={savePageViewList}
            openManagePageViewModal
            setOpenManagePageViewModal={setOpenManagePageViewModal}
            getAndSetPageViews={(e, oldName, newName) => getAndSetPageViews(e, oldName, newName)}
            gridViewName={gridName}
          />
        </Suspense>
      )}
      <Modal
        modalButton={<Button></Button>}
        openModal={savePageLoader}
        label={'Saving Changes'}
        closeIcon={''}
        variant="Passive modal"
        bodyText={`You made modifications to your ${selectedPageView}.Please wait while changes are being saved.`}
        slotComponent={
          <div
            style={{
              padding: '68px',
              textAlign: 'center',
            }}
          >
            <LoadingSpinner variant="loading-spinner" />
          </div>
        }
      />
    </Box>
    //</>
  );
};

export default HoldRequestDetails;
