import React, { createContext, useEffect, useState } from 'react';
import { getEnv } from '../../env/env';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getApiData } from '../../services';
import { RoleManager } from './RoleManager';

interface Access {
  feature: any[];
  appRole: string;
}

interface RoleManagementPolicy {
  Access: Access[];
  AppId: string;
  IdPRole: string;
  userId: string;
  version: string;
}

interface AccessContextType {
  policy: RoleManagementPolicy;
  roleManager: RoleManager | null;
  accessPolicy: any
}

export const AccessContext = createContext<AccessContextType | null>(null);

const AccessProvider: React.FC<any> = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenExpiresAt] = useSessionStorage('Root-IdTokenExpiresAt', '0');
  const [policy, setPolicy] = useState<RoleManagementPolicy>({
    Access: [
      {
        feature: [],
        appRole: '',
      },
    ],
    AppId: '',
    IdPRole: '',
    userId: '',
    version: '',
  });

  const [roleManager, setRoleManager] = useState<RoleManager | null>(null);
  const [accessPolicy, setAccessPolicy] = useState<any>({});

  useEffect(() => {
    const rootLoginState = JSON.parse(localStorage.getItem('Root-LoginSate') ?? '{}');
    const accessToken = rootLoginState?.accessToken;
    const idToken = rootLoginState?.idToken;
    const HMIdToken = localStorage.getItem('HM-IdToken');

    if (!accessToken || !idToken) {
      console.error('Tokens are missing from Root-LoginSate');
      return;
    }

    // Initialize RoleManager instance
    const newRoleManager = new RoleManager(
      accessToken,
      HMIdToken,
      getEnv().POLICY_URL,
      // 'https://api.hm.cubedev.toyota.com/v1/roleconsumption/policy',
    );
    setRoleManager(newRoleManager);

    const fetchPolicyAndRoles = async () => {
      try {
        // Initialize authentication
        await newRoleManager.initializeAuth();
        const PDCHoldView = await newRoleManager.checkPermission('PDC Hold', 'View');
        setAccessPolicy({
          ...accessPolicy,
          'PolicyEnabled': true,
          'PDCHoldView': PDCHoldView
        });

        // Fetch the access policy from your API
        const url = 'https://hnkw7xowyk.execute-api.us-west-2.amazonaws.com/dev/roles/getUserRolePolicy';
        const config = {
          headers: {
            Authorization: idToken,
          },
        };
        const response = await getApiData<RoleManagementPolicy>(url, config);
        console.log('Fetched roles:', response, accessPolicy);

        setPolicy(response);
      } catch (error) {
        console.error('Error fetching policy or roles:', error);
      }
    };

    fetchPolicyAndRoles();
  }, []);

  useEffect(() => {
    const timeRemaining: number = parseInt(tokenExpiresAt) * 1000 - Math.floor(Date.now() + 5 * 60000);
    if (timeRemaining && timeRemaining > 0) {
      setIsTokenValid(true);
    } else {
      setIsTokenValid(false);
    }
  }, [tokenExpiresAt]);

  return <AccessContext.Provider value={{ policy, roleManager, accessPolicy }}>{isTokenValid && children}</AccessContext.Provider>;
};

export default AccessProvider;
