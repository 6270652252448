import CheckIcon from '@mui/icons-material/Check';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { Button, Modal, TextInput, useNotificationContext } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import { getEnv } from '../../../env/env';
import { getPendingApprovalType, getUpdatedUserID, getHoldReqNumber} from '../../../store';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { BrowserStorage } from '../../../utils/browserStorage';
import { BrowserStorageConst } from '../../../utils/constants';
import {
  getHoldRequestDetailsApi,
} from '../../../store/features/HoldTypeDetails/api';

const Star = styled('span')({
  fontSize: '.75rem',
  fontWeight: 600,
  lineHeight: 'var(--spacingsm)',
  textAlign: 'left',
  color: '#C40818',
});
const Grid1 = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  color: '#58595B',
  fontSize: '.75rem',
  weight: 600,
});

const styles = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 736,
  bgcolor: 'background.paper',
  boxShadow: 15,
  borderRadius: '.25rem',
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '.625rem 1.5rem',
  justifyContent: 'end',
  position: 'fixed',
  bottom: 0,
  zIndex: 10,
  gap: '0rem',
  border: '.0625rem 0rem 0rem 0rem',
  marginLeft: '1.125rem',
  left: 0,
  width: 'calc(100% - 55px)',
  marginRight: '16px',
}));
interface FooterProps {
  holRequest?: any;
  setFooterApproveDisable?: (value: boolean) => void;
  footerApproveDisable?: boolean;
  userId?: string;
  sendApproveDenyCall?: Function;
  setRefresh?: Function;
  holdStatus?: string;
}

export const Footer: React.FC<FooterProps> = ({
  holRequest,
  setFooterApproveDisable,
  setRefresh,
  footerApproveDisable,
  sendApproveDenyCall,
  userId,
  holdStatus,
}) => {
  const [modelOpen, setModelOpen] = useState<boolean>();
  const [approveModal, setApproveModal] = useState<boolean>();
  const [denyButton, setDenyButton] = useState<boolean>(true);
  const [denyReason, setDenyReason] = useState<string>('');
  const [letterCounts, setLetterCounts] = useState<number>(0);
  const [isYardToPDCApproval, setIsYardToPDCApproval] = useState<boolean>(true);
  const [approve, setApprove] = useState<boolean>(true);
  const [authToken] = useState<string>(BrowserStorage.getItem(BrowserStorageConst.HMIdToken));
  const sessionId = BrowserStorage.getSessionId();
  const pendingApprovaltype = useAppSelector(getPendingApprovalType);
  const updatedUserId = useAppSelector(getUpdatedUserID);
  const dispatch = useAppDispatch();
  const qcNumber =  useAppSelector(getHoldReqNumber);

  const { dispatchNotification } = useNotificationContext();
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };
  const handlerDenyReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    const length = data.length;
    if (length <= 300) {
      setLetterCounts(length);
      if (letterCounts <= 300) {
        if (data.trim()) {
          setDenyButton(false);
          setDenyReason(data);
          setApprove(false);
        } else {
          setLetterCounts(0);
          setDenyReason('');
          setDenyButton(true);
          setApprove(true);
        }
      }
    }
  };

  const submitApprove = async () => {
    
    const url = `${getEnv().API_BASE_URL}/hold/QChold/approveYardToPDC`;

    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    const reqData = {
      userId: userId,
      qcNumber: holRequest,
      isApprove: isYardToPDCApproval
    };
    if(!isYardToPDCApproval)
    {
      reqData["reasong"]= denyReason;
    }
    try {
      const response = await axios.post(url, reqData, config);
      if (response?.status === 202 || response?.data?.data?.status === 202) {

        const filter = {
          fieldName: 'success',
          field: '',
        };

        if(isYardToPDCApproval)
        {
          filter["field"] = "Yard to PDC convertion is Successful";
        }
        else
        {
          filter["field"] = "Yard to PDC conversion is Denied";
        }
        
        dispatch(getHoldRequestDetailsApi(qcNumber));
        handleNotification(filter);
        setApproveModal(false);
        setModelOpen(false);
        setDenyReason('');
        setFooterApproveDisable(true);
        // setRefresh();
        // setRefresh();
      } else {
        setApproveModal(false);
        const filter = {
          fieldName: 'error',
          field: 'Something Went Wrong',
        };
        handleNotification(filter);
        setModelOpen(false);
        setDenyReason('');
      }
    } catch (error) {
      setApproveModal(false);
      setModelOpen(false);
      setDenyReason('');
      setDenyButton(true);
      setLetterCounts(0);
      const filter = {
        fieldName: 'error',
        field: 'Something Went Wrong',
      };
      handleNotification(filter);
    } finally {
      setTimeout(() => {
        setDenyButton(true);
        setDenyReason('');
        setLetterCounts(0);
      }, 5000);
    }
  };

  const handleApprove = async () => {
    if (pendingApprovaltype == 'YardToPDC') {
      submitApprove();
    } else {
      const url = `${getEnv().API_BASE_URL}/hold/QChold/initiateHold`;
      const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
      const config = {
        headers: {
          Authorization: `${authToken}`,
          'x-api-key': apikey,
          'Content-Type': 'application/json',
        },
      };
      const reqData = {
        sessionId: sessionId || '',
        userId: userId,
        qcNumber: holRequest,
        isApprove: approve,
        remarks: denyReason,
        isSelectedAll: true,
        vin: [],
      };
      try {
        const response = await axios.post(url, reqData, config);
        if (response?.status === 202 || response?.data?.data?.status === 202) {
          setApproveModal(false);
          setModelOpen(false);
          setDenyReason('');
          const filter = {
            fieldName: 'success',
            field: response?.data?.data?.message,
          };
          handleNotification(filter);
          dispatch(getHoldRequestDetailsApi(qcNumber));
          setFooterApproveDisable(true);
          // setRefresh();
        } else {
          setApproveModal(false);
          setModelOpen(false);
          setDenyReason('');
          const filter = {
            fieldName: 'error',
            field: 'Something Went Wrong',
          };
          handleNotification(filter);
        }
      } catch (error) {
        setApproveModal(false);
        setModelOpen(false);
        setDenyReason('');
        setDenyButton(true);
        setLetterCounts(0);
        let filter = {
          fieldName: 'error',
        };
        if (
          error?.response?.data?.status === 400 &&
          error?.response?.data?.errorMessage === 'The delay date is in the past.'
        ) {
          filter['field'] = 'The delay date is in the past, please change the delay date before approval';
        } else {
          filter['field'] = 'Something Went Wrong';
        }
        handleNotification(filter);
      } finally {
        setTimeout(() => {
          setDenyButton(true);
          setDenyReason('');
          setLetterCounts(0);
        }, 5000);
      }
    }
  };

  const handleCancel = () => {
    setDenyButton(true);
    setDenyReason('');
    setLetterCounts(0);
    setModelOpen(false);
  };

  const yardToPDCFunction = (actionType: string) => {
    if(actionType === "Deny")
    {
      setModelOpen(true);
      setIsYardToPDCApproval(false);
    }
    else
    {
      setApproveModal(true);
      setIsYardToPDCApproval(true);
    }

  }
  

  return (
    <Box
      sx={{
        position: 'sticky', // keeps it at the bottom of the viewport when scrolling
        bottom: '10px',
        background: '#fff',
        zIndex: 999,
        borderTop: '1px solid #ddd',
        padding: '16px',
        width: '100vw',
        display: 'flex',
        justifyContent: 'flex-end', // aligns content to the right
      }}
    >
      {!footerApproveDisable && (
        <Stack gap={2} direction="row" sx={{ paddingBottom: '1rem' }}>
          <Typography variant="body1" className='PendingAppType'>{pendingApprovaltype === 'HoldCancel' ? "Cancel Hold Requested:": pendingApprovaltype === 'YardToPDC' ? "Convert To PDC Requested:": "Hold Initiation: "}</Typography>
          <Modal
            modalStyles={styles}
            modalButton={
              <Button
                variant="tertiary"
                size="small"
                onClick={() => {
                  pendingApprovaltype === 'HoldCancel' ? sendApproveDenyCall('Deny') : pendingApprovaltype === 'YardToPDC' ? yardToPDCFunction("Deny") : setModelOpen(true);
                }}
                label="Deny"
                icon="DoDisturb"
                disabled={footerApproveDisable}
              />
            }
            openModal={modelOpen}
            variant="Danger modal"
            label="Deny Request"
            bodyText="Please provide a short reason for denying the request in the field below."
            slotComponent={
              <TextInput
                multiline
                maxLength={300}
                label={
                  <Typography>
                    Reason for Denying <Star>*</Star>
                  </Typography>
                }
                placeholder="Input text"
                value={denyReason}
                onChange={handlerDenyReason}
                sx={{
                  width: '665px',
                  '& .MuiOutlinedInput-root': {
                    height: '5.75rem',
                  },
                }}
              />
            }
            actionButtonProps={{
              variant: 'danger',
              onClick: () => void handleApprove(),
              disabled: denyButton,
              label: 'Deny',
            }}
            handleClose={() => setModelOpen(false)}
            handleCancel={handleCancel}
            closeIcon=""
          />
          <Modal
            closeIcon=""
            modalButton={
              <Button
                variant="primary"
                size="small"
                onClick={() => {
                  pendingApprovaltype === 'HoldCancel' ? sendApproveDenyCall('Approve') : pendingApprovaltype === 'YardToPDC' ? yardToPDCFunction("Approve") :  setApproveModal(true);;
                }}
                label= {pendingApprovaltype === 'HoldCancel' ? "Cancel Hold" : "Approve"}
                icon={<CheckIcon />}
                disabled={footerApproveDisable}
              />
            }
            openModal={approveModal}
            variant="Transactional modal"
            label="Approve Request?"
            bodyText="By approving this request, holds will be initiated for its vehicle(s) momentarily."
            slotComponent="Are you sure you want to approve?"
            actionButtonProps={{
              variant: 'primary',
              onClick: () => void handleApprove(),
              label: 'Approve Request',
            }}
            handleClose={() => setApproveModal(false)}
            handleCancel={() => setApproveModal(false)}
          />
        </Stack>
      )}
    </Box>
  );
};
