import { Grid, Typography } from '@mui/material';
import { Button, LoadingSpinner, Modal, useNotificationContext } from 'cubee2e-cube-global-components';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { uploadParseApi } from '../../../../../services';
import { getDocumentDetails, getFileAttachments, useAppDispatch, useAppSelector } from '../../../../../store';
import { getParsedDataApi, uploadDocumentApi } from '../../../../../store/features/HoldRequest/api';
import { setFileAttachments } from '../../../../../store/features/HoldTypeDetails/slice';
import theme from '../../../../../theme';
import { ParseStatusEnum, UploadDocumentTypes } from '../../../../../types';
import { CheckStatusAPIs } from '../../../../CreateHoldRequest/CreateHoldRequestModal';
import { maxAttempts, parseTimeout } from '../../../../CreateHoldRequest/CreateHoldRequestModalConstant';

const AppendixA = () => {
  const [fileUploading, setFileUploading] = useState(false);
  const [importFile, setImportFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const documentDetails = useAppSelector(getDocumentDetails);
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
  const fileAttachments = useAppSelector(getFileAttachments);
  const { dispatchNotification } = useNotificationContext();
  const navigate = useNavigate();
  const authToken = useAppSelector((state) => state.auth).idToken;
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };
  const handleSelectFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]?.type === 'application/pdf') {
      const file = event.target.files?.[0];
      setImportFile(file as File);
      if (file) {
        setFileUploading(true);
        dispatch(
          uploadDocumentApi(file, UploadDocumentTypes.APPENDIX_A, handleNotification, (docId: string, path: string) => {
            uploadCallback(docId, path, file[0]);
          }),
        );
      }
    }
  };
  const uploadCallback = (documentId: string, documentPath: string, documentDetails: File) => {
    checkParseStatus({ documentId, documentPath, documentDetails });
  };

  const checkParseStatus = async ({ attempts, documentId, documentPath, documentDetails }: CheckStatusAPIs) => {
    if (!attempts) {
      attempts = 0;
    }
    const response = await uploadParseApi(documentId, authToken, UploadDocumentTypes.APPENDIX_A);

    if (response.status === 200) {
      const parseStatus = response.data.data.body.parseStatus;
      const parseError = response.data.data.body.parseError;
      if (parseStatus === ParseStatusEnum.Failed) {
        dispatchNotification({
          open: true,
          variant: 'toast',
          type: 'error',
          iconEnabled: true,
          text: `Upload ${UploadDocumentTypes.APPENDIX_A} ${parseStatus}`,
        });
      } else {
        if (
          [
            ParseStatusEnum.NoStatus,
            ParseStatusEnum.Started,
            ParseStatusEnum.Parsing,
            ParseStatusEnum.Validating,
          ].includes(parseStatus) &&
          (!parseError || parseError === 'No Error')
        ) {
          if (attempts < maxAttempts) {
            setTimeout(() => {
              checkParseStatus({
                attempts: attempts + 1,
                documentId,
                documentPath,
                documentDetails,
              });
            }, parseTimeout);
          }
        } else if (
          [ParseStatusEnum.PartiallyParsed, ParseStatusEnum.Parsed, ParseStatusEnum.ParsedSuccessfully].includes(
            parseStatus,
          )
        ) {
          let fileList: any[] = [...fileAttachments.fileMetadata];
          fileList.push({
            documentId: documentId,
            uploadFilePath: documentPath,
            fileTitle: documentDetails?.name,
            fileDescription: '',
            documentType: UploadDocumentTypes.APPENDIX_A,
            isdeletable: false,
            uploadedBy: userData?.employeeId,
            uploadedUserName: userData?.userName,
            uploadedDate: '',
            name: documentDetails?.name,
          });
          dispatch(
            setFileAttachments({
              fileMetadata: fileList,
            }),
          );
          setFileUploading(false);
          dispatch(getParsedDataApi({ authToken, documentId, documentPath }, 'Details', navigate));
        }
      }
    }
  };

  return (
    <Grid container sx={{ flexDirection: 'column', background: theme.palette.common.white, padding: '24px' }} gap={1}>
      <Grid item>
        <Typography variant="bodyShort" color={theme.palette.text.primary}>
          Import data from an existing PDC <Typography variant="bodyShortSemibold">Appendix A</Typography> document to
          auto-fill portions of this form. This document is required in order to Submit for Approval.{' '}
        </Typography>
      </Grid>
      <Grid item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            whiteSpace: 'nowrap',
          }}
        >
          <Button variant="secondary" size="medium" label="Upload Appendix A" onClick={handleSelectFileClick} />

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".pdf"
            style={{ display: 'none' }}
            className="input"
          />
        </div>
      </Grid>
      {/* when user upload Appendix A loader trigger from her */}
      <Modal
        handleCancel={() => {
          setFileUploading(false);
        }}
        handleClose={() => {
          setFileUploading(false);
        }}
        modalButton={<></>}
        openModal={fileUploading}
        label={'Importing Appendix A'}
        variant="Passive modal"
        bodyText={
          'File import in progress, please wait for a moment. The file will be attached to this request and import its data to auto-fill your PDC Request form.Please note, not all data may be populated. Please check your form to ensure accuracy.'
        }
        slotComponent={
          <div
            style={{
              padding: '68px',
              textAlign: 'center',
            }}
          >
            <LoadingSpinner variant="loading-spinner" />
          </div>
        }
        closeIcon={''}
      />
    </Grid>
  );
};

export default AppendixA;
