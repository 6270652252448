export const getKPICardTooltipText = (status: string) => {
  switch (status) {
    case 'Drafts':
      return 'Holds that have not been submitted and are in drafts.';
    case 'Pending':
      return 'Holds that are pending initial approval.';
    case 'Active Holds':
      return 'Holds that have been approved with remaining vehicles that have yet to be released. This includes VINs that are in progress to becoming active.';
    case 'Completed':
      return 'Holds that are resolved with no remaining vehicles to be released. Includes number of completed holds for the last 5 years.';
    case 'Canceled':
      return 'Holds that have been created but canceled.';
    case 'Awaiting Approval':
      return 'Vehicles awaiting addition to current hold.';
    case 'On Hold':
      return 'Vehicles that have been approved and are actively on hold.';
    case 'In Evaluation':
      return 'Vehicles that are pending or awaiting action review.';

    case 'Released':
      return 'Vehicles that have been released and are no longer on hold.';
    case 'SAN':
      return 'Vehicles that have been shipped after notification of hold. Vehicles are shipped within a day of being on hold.';
    case 'Shipped':
      return 'Vehicles that have been shipped before notification of hold. Vehicles are not actively on hold.';
    case 'Invalid':
      return 'VINs that are invalid or duplicate. These VINs are not actively on hold.';
  }
};
