import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { NotificationProvider } from 'cubee2e-cube-global-components';
import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import AccessProvider from './Components/AccessProvider/AccessProvider';
import Authenticator from './Components/Authenticator';
import { msalConfig } from './config/authConfig';
import './index.css';
import store from './store/store';
import theme from './theme';

const pca = new PublicClientApplication(msalConfig);

export default function Root(props) {
  return (
    <>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Authenticator>
              <AuthenticatedTemplate>
                <AccessProvider>
                  <NotificationProvider>
                    <App />
                  </NotificationProvider>
                </AccessProvider>
              </AuthenticatedTemplate>
            </Authenticator>
          </ThemeProvider>
        </Provider>
      </MsalProvider>
    </>
  );
}
