export const deleteModalStyles = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 15,
    borderRadius: '4px',
    padding: '0px',
  };
  
  export const deleteModalLabelStyles = {
    fontFamily: 'ToyotaType-Regular',
    fontSize: '24px',
  };
  
  export const deleteModalBodyTextStyles = {
    fontFamily: 'ToyotaType-Regular',
    fontSize: '14px',
  };
  