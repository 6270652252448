import { Grid } from '@mui/material';
import { Chip, DatePicker, Dropdown } from 'cubee2e-cube-global-components';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  getEditPanel,
  getHoldDetails,
  getHoldStatus,
  getHoldType,
  getPDCAssigneeDropdownOptions,
  getRiskRankDropdownOptions,
  getToyotaInternalDetails,
  getYardToPDCState,
} from '../../../../../store';
import { setHoldDetails } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { RenderItem } from '../RenderItem';
import './HoldDetails.scss';
const chipComHeight = '1.5rem';
const chipComFW = '600';

const HoldDetails = ({ setFooterDisable }) => {
  const [riskRankDropdown, setRiskRankDropdown] = useState<boolean>(false);
  const [delaytimingDropdownOpen, setDelaytimingDropdownOpen] = useState<boolean>(false);
  const [disableDelayDate, setDisableDelayDate] = useState<boolean>(false);
  const [PDCAssigneeDropdownOpen, setPDCAssigneeDropdownOpen] = useState<boolean>(false);
  const holdStatus = useAppSelector(getHoldStatus);
  const toyotaInternalDetails = useAppSelector(getToyotaInternalDetails);
  const holdDetails = useAppSelector(getHoldDetails);
  const holdType = useAppSelector(getHoldType);
  const editPanel = useAppSelector(getEditPanel);
  const riskRankOptionList = useAppSelector(getRiskRankDropdownOptions);
  const pdcAssigneeDropdownOptions = useAppSelector(getPDCAssigneeDropdownOptions);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchDelatDateStatus = async () => {
      if (['Active Hold', 'Pending Update'].includes(holdStatus) && holdType === 'PDC') {
        setDisableDelayDate(false);
      } else if (['Pending Approval'].includes(holdStatus) && holdType === 'PDC') {
        const today = new Date();
        const delaydate = new Date();

        today.setHours(0, 0, 0, 0);
        delaydate.setHours(0, 0, 0, 0);

        if (delaydate < today) {
          setDisableDelayDate(false);
        } else if (delaydate > today) {
          setDisableDelayDate(true);
        } else {
          setDisableDelayDate(true);
        }
      }
    };

    fetchDelatDateStatus();
  }, []);

  const handleRiskRankOpen = () => {
    setRiskRankDropdown(true);
  };
  const handleRiskRankClose = () => {
    setRiskRankDropdown(false);
  };

  const handleRiskRank = (option: any) => {
    dispatch(setHoldDetails({ ...holdDetails, riskRank: option }));
    setFooterDisable(false);
  };

  const handleAnticipatedDelayTiming = (date: Dayjs | null) => {
    console.log('werdfghj', date, date.toString());
    if (date) {
      dispatch(
        setHoldDetails({
          ...holdDetails,
          anticipatedDelayTiming: moment(date.toString()).format('MM/DD/YYYY'),
          updateDelay: true,
        }),
      );
      setFooterDisable(false);
    }
  };
  const handlePDCPEAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHoldDetails({ ...holdDetails, pdcPEAssignee: event, updateDelay: true }));
    setFooterDisable(false);
  };

  let IntRiskRankCustomize = (riskRankID) => {
    return (
      <Chip
        label={riskRankID}
        colorScheme={
          riskRankID === 'S RANK'
            ? 'error'
            : riskRankID === 'A RANK'
            ? 'warning'
            : riskRankID === 'B RANK'
            ? 'purple'
            : riskRankID === 'C RANK'
            ? 'primary'
            : 'primaryContainer'
        }
        variant={'filled'}
        sx={{
          height: chipComHeight,
          fontWeight: chipComFW,
          paddingLeft: '8px',
          marginRight: '0.5rem',
          fontFamily: 'ToyotaType-Regular',
        }}
      />
    );
  };
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const formatDate = (delayDate: string) => {

    if (!delayDate || delayDate === 'undefined' || delayDate === null) {
      return null;
    }

    // Parse the ISO date string
    const date = new Date(delayDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    // Extract month, day, and year, and format as MM/DD/YYYY
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };
  const isEdit = holdStatus === 'Draft' || editPanel === '1' || isyardToPDC;
  return (
    <Grid container spacing={2} sx={{ alignItems: 'center', padding: '1rem' }}>
      <Grid item xs={12} sm={6} md={4}>
        <RenderItem
          isRequired={false}
          label="Risk Rank"
          value={
            !isEdit ? (
              holdDetails?.riskRank?.id ? (
                IntRiskRankCustomize(holdDetails?.riskRank?.optionLabel)
              ) : (
                '-'
              )
            ) : (
              <Dropdown
                variant="singleSelect"
                sx={{ width: '440px' }}
                onOpen={handleRiskRankOpen}
                onClose={handleRiskRankClose}
                onChange={handleRiskRank}
                placeholderText="Select Risk Rank"
                optionsList={riskRankOptionList}
                open={riskRankDropdown}
                selectedOptions={holdDetails.riskRank}
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RenderItem
          isRequired={false}
          label="Anticipated Delay Date"
          value={
            !isEdit ? (
              holdDetails?.anticipatedDelayTiming ? (
                formatDate(holdDetails?.anticipatedDelayTiming)
              ) : (
                '-'
              )
            ) : (
              <DatePicker
                open={delaytimingDropdownOpen}
                width={'456px'}
                value={holdDetails.anticipatedDelayTiming ? dayjs(holdDetails.anticipatedDelayTiming) : null}
                disablePastDays={true}
                handleChange={handleAnticipatedDelayTiming}
                handleOpen={(value: boolean) => setDelaytimingDropdownOpen(value)}
                //disabled={disableDelayDate}
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RenderItem
          isRequired={false}
          label="PDC PE Lead"
          value={
            !isEdit ? (
              holdDetails.pdcPEAssignee?.optionLabel ? (
                holdDetails.pdcPEAssignee?.optionLabel
              ) : (
                '-'
              )
            ) : (
              <Dropdown
                variant="singleSelect"
                sx={{ width: '440px' }}
                optionsList={pdcAssigneeDropdownOptions}
                onOpen={() => {
                  setPDCAssigneeDropdownOpen(true);
                }}
                onClose={() => {
                  setPDCAssigneeDropdownOpen(false);
                }}
                open={PDCAssigneeDropdownOpen}
                selectedOptions={{
                  id: holdDetails?.pdcPEAssignee?.id,
                  optionLabel: holdDetails?.pdcPEAssignee?.optionLabel,
                }}
                onChange={handlePDCPEAssignee}
              />
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default HoldDetails;
