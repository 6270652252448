import { Icon } from 'cubee2e-cube-global-components';
import React, { useEffect } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import theme from '../../theme';

interface UploadSectionProps {
  //   onOpenFile: () => void;
  supportedDocFormats: string[];
  handleAcceptedFiles: (files: readonly FileWithPath[]) => void;
  acceptFormats: {};
}

const FileUploadDragAndDrop: React.FC<UploadSectionProps> = ({
  supportedDocFormats,
  handleAcceptedFiles,
  acceptFormats,
}) => {
  const {
    getRootProps,
    getInputProps,
    open: openFile,
    acceptedFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: acceptFormats,
    // Remove the validator function temporarily
  });
  console.log('dxcfgvhbjn', 1, acceptedFiles);
  useEffect(() => {
    handleAcceptedFiles(acceptedFiles);
  }, [acceptedFiles]);
  return (
    <div {...getRootProps()} className="container">
      <input {...getInputProps()} />
      <div
        className="dropzone-section"
        style={{
          height: '164px',
          border: '1px dashed #909295',
          borderRadius: '4px',
          backgroundColor: theme.palette.secondary[100],
        }}
      >
        <div
          style={{
            width: '52px',
            height: '52px',
            backgroundColor: theme.palette.common.white,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name="FileUploadOutlined" fontSize="medium" />
        </div>
        <div style={{ fontSize: '14px' }}>
          Drag & Drop or{' '}
          <span className="dropzone-link" onClick={openFile}>
            Choose File
          </span>
        </div>
        <div style={{ fontSize: '14px' }}>
          (
          {supportedDocFormats.length > 0
            ? supportedDocFormats
                .map((x) => x.replace('.', ''))
                .join(', ')
                .toUpperCase()
            : '(No supported formats available)'}
          )
        </div>
      </div>
    </div>
  );
};

export default FileUploadDragAndDrop;
