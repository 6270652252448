export const sampleTableData = [
  {
    Series: '4Runner',
    VIN: "7MUCAAAGXNV009018",
    DestCountry: "USA",
    HoldStatus: "Example",
    Group: "A",
    Location: "MTMUS",
    Region: "150",
    CurrentLocation:'Rail Location', 
    ActualLineOffDate: "Feb 4 2024" ,
    ProductionDate: "Mar 4 2024 at 7:34",
    SoldDate: "Mar 4 2024 at 7:34",
    LifecycleStatus: "OTHER VPC STOCK",
    Katashiki: "MXGA10L AHXNHA",
    MYSpec: "N",
    AssemblySequence: "635",
    SoldSequence: "188"
},
{
    Series: '4Runner',
    VIN: "7MUCAAAGXNV009017",
    DestCountry: "Canada",
    HoldStatus:  "Example1",
    Group: "A",
    Location: "NY",
    Region: "150",
    CurrentLocation:'Truck Location', 
    ActualLineOffDate: "Feb 4 2024" ,
    ProductionDate: "Mar 4 2024 at 7:34",
    SoldDate: "Mar 4 2024 at 7:34",
    LifecycleStatus: "IN TRANSIT TO OTHER VPC",
    Katashiki: "MXGA10L AHXNHA",
    MYSpec: "N",
    AssemblySequence: "608",
    SoldSequence: "40"
},
{
    Series: '4Runner',
    VIN: "7MUCAAAGXNV009016",
    DestCountry: "USA",
    HoldStatus:  "Example",
    Group: "A",
    Location: "PT",
    Region: "600",
    CurrentLocation:'Dealership', 
    ActualLineOffDate: "Feb 4 2024" ,
    ProductionDate: "Mar 4 2024 at 7:34",
    SoldDate: "Mar 4 2024 at 7:34",
    LifecycleStatus: "UNBUILT",
    Katashiki: "MXGA15L AHXNHA",
    MYSpec: "N",
    AssemblySequence: "573",
    SoldSequence: "189"
}
];

export const baseUrl = "sample";

export const supportedDocFormats = ['.csv', '.xls', '.xlsx'];

export const maxAttempts = 25;

export const parseTimeout = 250;

export const uploadStages = ['Uploading file', 'Parsing data', 'Validating Data'];

export const modalStages = [
  'Start Import',
  ...uploadStages,
  'Upload Failed',
  'Parsing Failed',
  'Validation Failed',
  'Preview',
  'SelectAction',
  'TableView',
];

export const importActions = [{label:"Use to Search QC Vehicles", value:"filter"},{label:"Request Add to Hold", value:"add"},{label:"Update Status", value:"update"}]

export const uploadDocStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '500px',
  maxWidth: '1300px',
  minHeight: '300px',
  bgcolor: 'background.paper',
  boxShadow: 15,
  borderRadius: '4px',
};
