import { Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Button, DatePicker, Divider, Dropdown, TextInput } from 'cubee2e-cube-global-components';
import dayjs from 'dayjs';
import { ChangeEvent, default as React, ReactNode, useCallback, useEffect, useState } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  getEditPanel,
  getHoldStatus,
  getModelDropdownOptions,
  getModelSpecifications,
  getSeriesDropdownOptions,
  getStartDateTypeOptions,
  getYardToPDCState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../store';
import { setModelSpecification, setSeriesDropdownOptions } from '../../../../../store/features/HoldTypeDetails/slice';
import { ModelSpecification } from '../../../../../store/features/HoldTypeDetails/types';
import theme from '../../../../../theme';
import { DropDownOption, Vehicle } from '../../../../../types';
import { modelModelDropdownValues, seriesDropdownValues } from '../../../../../utils';
import { RenderItem } from '../RenderItem';
import './ModelSpecifications.scss';
import TextBox from './TextBox';

//styles
const StyledGrid2 = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignIems: 'flex-start',
  gap: '.25rem',
  flex: '1 0 0',
  marginBottom: '1rem',
});

const StyledColumn = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0rem 0rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

export const ModelSpecifications = ({ setFooterDisable }) => {
  const currentYear = new Date().getFullYear();

  const [startDateTypeOpen, setStartDateTypeOpen] = useState(false);
  const [endDateTypeOpen, setEndDateTypeOpen] = useState(false);
  const [startDateTimeOpen, setStartDateTimeOpen] = useState(false);
  const [endDateTimeOpen, setEndDateTimeOpen] = useState(false);

  const editPanel = useAppSelector(getEditPanel);
  const startDateDropownOptions = useAppSelector(getStartDateTypeOptions);
  const vehicleSpecification = useAppSelector(getModelSpecifications);
  const models = useAppSelector(getModelDropdownOptions);
  const seriesDropdownOptions = useAppSelector(getSeriesDropdownOptions);
  const auth = useAppSelector((state) => state.auth);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const dispatch = useAppDispatch();
  const [updatedData, setUpdatedData] = useState<ModelSpecification>(vehicleSpecification);

  useEffect(() => {
    setUpdatedData({ ...vehicleSpecification, 
      vehicleDtl: [
        ...vehicleSpecification.vehicleDtl.map(model=>({
          ...model,
          model: {
            ...model.model,
            options: modelModelDropdownValues(models,model.modelYear.value?.id||'',model.series.value?.id||''),
          },
          series: {
            ...model.series,
            options: seriesDropdownValues(models,model.modelYear.value?.id||''),
          }
        }))
      ]
    });
  }, [vehicleSpecification]);

  const handleBlur = () => {
    dispatch(setModelSpecification(updatedData));
  };

  const holdStatus = useAppSelector(getHoldStatus);
  const isEdit = holdStatus === 'Draft' || editPanel === '5' || isyardToPDC;

  const getOptionObject = (value) => {
    return value ? { optionLabel: value, id: value } : { optionLabel: '', id: '' };
  };
  const getOptionModel = (value) => {
    return value ? [{ optionLabel: value, id: value }] : [];
  };

  function convertToOptions<T>(
    items: Set<T> | T[], // Accept both Set<T> and T[]
    getOptionLabel: (item: T) => string,
    getId: (item: T) => string,
  ): DropDownOption[] {
    // Convert to array if it’s a Set
    const itemArray = Array.isArray(items) ? items : Array.from(items);

    return itemArray.map((item) => ({
      optionLabel: getOptionLabel(item),
      id: getId(item),
    }));
  }

  const handleAddVehicle = () => {
    dispatch(
      setModelSpecification({
        ...vehicleSpecification,
        vehicleDtl: [
          ...updatedData.vehicleDtl,
          {
            vehicleTypeSpecification: '',
            modelYear: {
              open: false,
              value: { optionLabel: currentYear.toString(), id: currentYear.toString() },
              options: [
                { optionLabel: (currentYear + 1).toString(), id: (currentYear + 1).toString() },
                { optionLabel: currentYear.toString(), id: currentYear.toString() },
                { optionLabel: (currentYear - 1).toString(), id: (currentYear - 1).toString() },
              ],
            },
            series: { open: false, value: { optionLabel: '', id: '' }, options: [] },
            model: { open: false, value: [], options: [] },
          },
        ],
      }),
    );
    setFooterDisable(false);
  };

  const handleDeleteVehicle = (index: number) => {
    if (index > 0) {
      let vehicleArray = [...updatedData.vehicleDtl]
      vehicleArray.splice(index, 1);
      dispatch(setModelSpecification({ ...vehicleSpecification, vehicleDtl: [...vehicleArray] }));
    }
    setFooterDisable(false);
  };

  const handleDropdownOpen = useCallback(
    (index: number, dropdownType) => {
      dispatch(
        setModelSpecification({
          ...vehicleSpecification,
          vehicleDtl: updatedData.vehicleDtl.map((vehicle, i) =>
            i === index ? { 
              ...vehicle, 
              modelYear: { ...vehicle.modelYear, open: dropdownType==="modelYear" } ,
              model: { ...vehicle.model, open: dropdownType==="model" } ,
              series: { ...vehicle.series, open: dropdownType==="series" } 
            } : { 
              ...vehicle, 
              modelYear: { ...vehicle.modelYear, open: false } ,
              model: { ...vehicle.model, open: false } ,
              series: { ...vehicle.series, open: false } 
            },
          ),
        }),
      );
    },
    [updatedData.vehicleDtl, updatedData.vehicleDtl],
  );

  const handlestartDateType = (value: DropDownOption) => {
    dispatch(
      setModelSpecification({
        ...vehicleSpecification,
        startDateType: value,
        endDateType: value,
      }),
    );
    setFooterDisable(false);
  };

  const handleEndDateType = (value: DropDownOption) => {
    dispatch(
      setModelSpecification({
        ...vehicleSpecification,
        endDateType: value,
      }),
    );
    setFooterDisable(false);
  };

  const handleVINStartDateTime = (date: dayjs.Dayjs) => {
    dispatch(setModelSpecification({ ...vehicleSpecification, vinStartDateTime: dayjs(date).format('MM/DD/YYYY') }));
    setFooterDisable(false);
  };

  const handleVINEndDateTime = (date: dayjs.Dayjs) => {
    dispatch(setModelSpecification({ ...vehicleSpecification, vinEndDateTime: dayjs(date).format('MM/DD/YYYY') }));
    setFooterDisable(false);
  };

  const handleVINStartLogicReasoning = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedData({ ...vehicleSpecification, vinStartLogicReasoning: event?.target?.value });
  };

  const handleVINEndLogicReasoning = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedData({ ...vehicleSpecification, vinEndLogicReasoning: event?.target.value });
  };

  const handleModelYearChange = (value: { optionLabel: string; id: string }, index: number) => {
    const filteredModels = models.filter((model) => model.modelYear.toString() === value['optionLabel'].toString());
    const seriesNames = new Set(
      filteredModels.map((model) => model.orderingSeriesName).filter((name) => name !== null),
    );
    const sortedSeriesNames = Array.from(seriesNames).sort((a: any, b: any) => a.localeCompare(b));
    const sortedSeriesNamesSet = new Set(sortedSeriesNames);

    dispatch(setSeriesDropdownOptions(sortedSeriesNamesSet as any));

    dispatch(
      setModelSpecification({
        ...vehicleSpecification,
        vehicleDtl: updatedData.vehicleDtl.map((vehicle, i) =>
          i === index
            ? {
                ...vehicle,
                modelYear: {
                  ...vehicle.modelYear,
                  value: value,
                  open: false,
                },
                series: {
                  open: false,
                  value: null,
                  options: convertToOptions(
                    sortedSeriesNamesSet,
                    (name: string) => name,
                    (name: string) => name,
                  ),
                },
                model: {
                  ...vehicle.model,
                  open: false,
                  value: [],
                },
              }
            : vehicle,
        ),
      }),
    );
    setFooterDisable(false);
  };

  const handleSeriesNameChange = (value: { optionLabel: string; id: string }, index: number) => {
    const vehicle = updatedData.vehicleDtl[index];
    const filteredModels = models
      .filter((model) => model.modelYear.toString() === vehicle?.modelYear?.value?.optionLabel)
      .filter((model) => model.orderingSeriesName === value['optionLabel'].toString());
    const codes = new Set(filteredModels.map((model) => model.modelCode).filter((code) => code !== null));
    dispatch(
      setModelSpecification({
        ...vehicleSpecification,
        vehicleDtl: updatedData.vehicleDtl.map((vehicle, i) =>
          i === index
            ? {
                ...vehicle,
                series: {
                  ...vehicle.series,
                  open: false,
                  value: value,
                },
                model: {
                  open: false,
                  value: [],
                  options: convertToOptions(
                    codes,
                    (code: any) => code,
                    (code) => code,
                  ),
                },
              }
            : vehicle,
        ),
      }),
    );
    setFooterDisable(false);
  };

  const handleModelCodeChange = (value: { optionLabel: ''; id: '' }[], index: number) => {
    const validEntries = value.filter((item) => item.optionLabel && item.id);
    if (validEntries.length === 0) {
      setUpdatedData({
        ...vehicleSpecification,
        vehicleDtl: updatedData.vehicleDtl.map((vehicle, i) =>
          i === index ? { ...vehicle, model: { ...vehicle.model, value: []} } : vehicle,
        ),
      });
      return;
    }

    setUpdatedData({
      ...vehicleSpecification,
      vehicleDtl: updatedData.vehicleDtl.map((vehicle, i) =>
        i === index ? { ...vehicle, model: { ...vehicle.model, value: value } } : vehicle,
      ),
    });
    setFooterDisable(false);
  };

  const handleDropdownChange = (option, index, dropdownType) => {
    if (dropdownType === 'modelYear') {
      handleModelYearChange(option, index);
    } else if (dropdownType === 'series') {
      handleSeriesNameChange(option, index);
    } else if (dropdownType === 'model') {
      handleModelCodeChange(option, index);
    }
  };

  const renderItem = (label: string, value: ReactNode, isRequired: boolean = false) => {
    return <RenderItem label={label} value={value} isRequired={isRequired} />;
  };

  const RenderModelYear = ({ vehicle, index }: { vehicle: Vehicle; index: number }) => {
    return (
      <StyledColumn item xs={12} sm={6} md={4} lg={4}>
        {renderItem(
          'Model Year',
          !isEdit ? (
            vehicle.modelYear?.value?.optionLabel === '' ? (
              '-'
            ) : (
              vehicle.modelYear?.value?.optionLabel
            )
          ) : (
            <Grid item xs={4}>
              <Dropdown
                label=""
                required
                variant="singleSelect"
                placeholderText="Select options"
                sx={{ width: '22.78vw' }}
                optionsList={vehicle.modelYear?.options}
                onOpen={() => handleDropdownOpen(index, 'modelYear')}
                open={vehicle.modelYear?.open}
                selectedOptions={vehicle.modelYear?.value}
                onChange={(option: any[]) => handleDropdownChange(option, index, 'modelYear')}
              />
            </Grid>
          ),
          isEdit ? true : false,
        )}
      </StyledColumn>
    );
  };

  const RenderVehicleDetails = ({ vehicle, index }: { vehicle: Vehicle; index: number }) => {
    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'flex-start',
          flex: '1 0 0',
          alignSelf: 'stretch',
        }}
      >
        {/* Model Year */}
        <RenderModelYear vehicle={vehicle} index={index} />

        {/* Sales Series */}
        <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
          {renderItem(
            'Sales Series',
            !isEdit ? (
              vehicle.series?.value?.optionLabel == '' ? (
                '-'
              ) : (
                vehicle.series?.value?.optionLabel
              )
            ) : (
              <Dropdown
                label=""
                variant="singleSelect"
                placeholderText="Select options"
                sx={{ width: '22.78vw' }}
                optionsList={vehicle.series?.options}
                onOpen={() => handleDropdownOpen(index, 'series')}
                open={vehicle.series?.open}
                selectedOptions={vehicle.series?.value}
                onChange={(option: any[]) => handleDropdownChange(option, index, 'series')}
              />
            ),
            isEdit ? true : false,
          )}
        </StyledGrid2>

        {/* Model */}
        <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
          {renderItem(
            'Model',
            !isEdit ? (
              vehicle?.model?.value.length > 0 ? (
                vehicle?.model?.value.map(model=>model.id).join(',')
              ) : (
                '-'
              )
            ) : (
              <Dropdown
                label=""
                variant="multiSelect"
                placeholderText="Select options"
                sx={{ width: '22.78vw' }}
                optionsList={vehicle?.model?.options}
                onOpen={() => handleDropdownOpen(index, 'model')}
                open={vehicle?.model?.open}
                onClose={()=>{
                  dispatch(setModelSpecification({
                    ...vehicleSpecification,
                    vehicleDtl: updatedData.vehicleDtl.map((vehicle, i) =>
                      i === index
                        ? {
                            ...vehicle,
                            model: {
                              ...vehicle.model,
                              open: false,
                            },
                          }
                        : vehicle,
                    ),
                  }),
                );
                }}
                selectedOptions={vehicle?.model?.value ?? []}
                onChange={(option) => handleDropdownChange(option, index, 'model')}
              />
            ),
            isEdit ? true : false,
          )}
        </StyledGrid2>
        {/* Remove Icon, positioned to avoid grid shifting */}
        {isEdit && index !== 0 && (
          <Grid item xs={0.1}>
            <Box display="flex" alignItems="center" sx={{ marginTop: '1.875rem', marginLeft: '-0.5rem' }}>
              <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteVehicle(index)} />
            </Box>
          </Grid>
        )}
        {/* Vehicle Type Specification */}
        <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
          {renderItem(
            'Vehicle Type Specifications',
            !isEdit ? (
              updatedData.vehicleDtl[index]?.vehicleTypeSpecification == '' ? (
                '-'
              ) : (
                updatedData.vehicleDtl[index]?.vehicleTypeSpecification
              )
            ) : (
              <TextBox index={index} setFooterDisable={setFooterDisable} />
            ),
          )}
        </StyledGrid2>
      </Grid>
    );
  };

  const formatDate = (delayDate: string) => {

    if (!delayDate || delayDate === 'undefined' || delayDate === null) {
      return null;
    }

    // Parse the ISO date string
    const date = new Date(delayDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    // Extract month, day, and year, and format as MM/DD/YYYY
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <Grid container spacing={0} sx={{ padding: '1rem 1rem' }}>
      {updatedData.vehicleDtl?.map((vehicle: Vehicle, index) => (
        <Grid container key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
          <RenderVehicleDetails vehicle={vehicle} index={index} />

          {index != 0 && (
            <Divider
              sx={{
                display: 'flex',
                padding: '.3125rem 1rem',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                width: '100%',
                margin: '.625rem 0rem',
              }}
            />
          )}
        </Grid>
      ))}

      <Divider
        sx={{
          display: 'flex',
          padding: '.3125rem 1rem',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          width: '100%',
          margin: '.625rem 0rem',
        }}
      />

      {/* Part-2 */}
      <Grid container spacing={1}>
        <StyledGrid2 item xs={4}>
          {renderItem(
            'VIN Start Date Type',
            !isEdit ? (
              vehicleSpecification?.startDateType?.optionLabel === '' ? (
                '-'
              ) : (
                vehicleSpecification?.startDateType?.optionLabel
              )
            ) : (
              <Dropdown
                label=""
                variant="singleSelect"
                placeholderText="Select options"
                sx={{ width: '22.78vw' }}
                optionsList={startDateDropownOptions}
                onOpen={() => {
                  setStartDateTypeOpen(true);
                }}
                onClose={() => {
                  setStartDateTypeOpen(false);
                }}
                open={startDateTypeOpen}
                selectedOptions={vehicleSpecification?.startDateType}
                onChange={(e) => {
                  handlestartDateType(e);
                }}
              />
            ),
            isEdit ? true : false,
          )}
        </StyledGrid2>
        <StyledGrid2 item xs={4}>
          {renderItem(
            'VIN Start Date & Time',
            !isEdit ? (
              vehicleSpecification?.vinStartDateTime === '' ? (
                '-'
              ) : (
                formatDate(vehicleSpecification?.vinStartDateTime)
              )
            ) : (
              <DatePicker
                value={vehicleSpecification?.vinStartDateTime ? dayjs(vehicleSpecification?.vinStartDateTime) : null}
                handleChange={handleVINStartDateTime}
                open={startDateTimeOpen}
                handleOpen={(value: boolean) => setStartDateTimeOpen(value)}
                disablePastDays={false}
                width={'22.78vw'}
              />
            ),
            isEdit ? true : false,
          )}
        </StyledGrid2>
        <StyledGrid2 item xs={4}>
          {renderItem(
            'VIN Start Logic/Reasoning',
            !isEdit ? (
              updatedData.vinStartLogicReasoning === '' ? (
                '-'
              ) : (
                updatedData.vinStartLogicReasoning
              )
            ) : (
              <TextInput
                placeholder="Input Text"
                onChange={handleVINStartLogicReasoning}
                // label={'VIN Start Logic/Reasoning'}
                label=""
                value={updatedData.vinStartLogicReasoning}
                onBlur={handleBlur}
                sx={{
                  width: '22.78vw',
                  backgroundColor: 'white',
                  '& .MuiInputBase-input': {
                    padding: '10px 12px',
                  },
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: '24.44vw',
                  },
                  [theme.breakpoints.between('sm', 'md')]: {
                    maxWidth: '24.44vw',
                  },
                  [theme.breakpoints.between('md', 'lg')]: {
                    maxWidth: '50vw',
                  },
                }}
              />
            ),
          )}
        </StyledGrid2>
        <StyledGrid2 item xs={4}>
          {renderItem(
            'VIN End Date Type',
            !isEdit ? (
              vehicleSpecification.endDateType?.optionLabel === '' ? (
                '-'
              ) : (
                vehicleSpecification.endDateType?.optionLabel
              )
            ) : (
              <Dropdown
                label=""
                variant="singleSelect"
                placeholderText="Select options"
                sx={{ width: '22.78vw' }}
                optionsList={startDateDropownOptions}
                selectedOptions={vehicleSpecification.endDateType}
                onOpen={(e) => {
                  setEndDateTypeOpen(true);
                }}
                onClose={(e) => {
                  setEndDateTypeOpen(false);
                }}
                open={endDateTypeOpen}
                onChange={(e) => {
                  handleEndDateType(e);
                }}
              />
            ),
          )}
        </StyledGrid2>
        <StyledGrid2 item xs={4}>
          {renderItem(
            'VIN End Date & Time',
            !isEdit ? (
              vehicleSpecification?.vinEndDateTime === '' ? (
                '-'
              ) : (
                formatDate(vehicleSpecification?.vinEndDateTime)
              )
            ) : (
              <DatePicker
                value={vehicleSpecification?.vinEndDateTime ? dayjs(vehicleSpecification?.vinEndDateTime) : null}
                handleChange={handleVINEndDateTime}
                open={endDateTimeOpen}
                handleOpen={(value: boolean) => setEndDateTimeOpen(value)}
                disablePastDays={false}
                width={'22.78vw'}
              />
            ),
            isEdit ? true : false,
          )}
        </StyledGrid2>
        <StyledGrid2 item xs={4}>
          {renderItem(
            'End Date Logic/Reasoning',
            !isEdit ? (
              updatedData.vinEndLogicReasoning === '' ? (
                '-'
              ) : (
                updatedData.vinEndLogicReasoning
              )
            ) : (
              <TextInput
                placeholder="Input text"
                onChange={handleVINEndLogicReasoning}
                value={updatedData.vinEndLogicReasoning}
                //label={'VIN End Logic/Reasoning'}
                label=""
                onBlur={handleBlur}
                sx={{
                  width: '22.78vw',
                  backgroundColor: 'white',
                  '& .MuiInputBase-input': {
                    padding: '10px 12px',
                  },
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: '24.44vw',
                  },
                  [theme.breakpoints.between('sm', 'md')]: {
                    maxWidth: '24.44vw',
                  },
                  [theme.breakpoints.between('md', 'lg')]: {
                    maxWidth: '50vw',
                  },
                }}
              />
            ),
          )}
        </StyledGrid2>
      </Grid>
      {isEdit && (
        <Grid item sm={12} md={12} lg={12}>
          <Button
            variant={'secondary'}
            label={'Add Vehicle +'}
            onClick={handleAddVehicle}
            sx={{ width: '12.1875rem', marginTop: '24px' }}
          />
        </Grid>
      )}
    </Grid>
  );
};
