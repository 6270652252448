export const predefinedFilters = [
  {
    id: 1,
    key: 'Earmark Eligible',
    label: `Earmark Eligible`,
  },
  {
    id: 2,
    key: 'Earmarked Vehicles',
    label: `Earmarked Vehicles`,
  },
];
