import axios from "axios";
import { getEnv } from "../../../../env/env";

export namespace VehiclesServices {

  export const UpdateDelayDate = async (auth, reqData) => {
    const updateDelayDate = `${getEnv().API_BASE_URL}/hold/QChold/updateDelayDate`;
    const config = {
      headers: {
        'x-api-key': "cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70",
        "Authorization": auth,
      }
    }
    try {
      const response = await axios.put(updateDelayDate, reqData, config);
      const updateStatus = response?.data?.data;
      const resData = {
        status: "success",
        data: []
      }
      return resData;
    } catch (error: any) {
      let resData = {
        status: "error",
        data: []
      }
      return resData;
    }
  }
}