import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
    pendingApproval: string,
    approved: string,
    onHold: string,
    readyForRelease: string,
    vehiclesReleased: string,
    vehiclesInvalid: string,
    vehiclesIneligible: string,
    totalVehiclesHeld: string,
    totalVehiclesSubmitted: string,
    shippedAfterNotice: string,
}

const initialState: State = {
  pendingApproval: "0",
  approved: "0",
  onHold: "0",
  readyForRelease: "0",
  vehiclesReleased: "0",
  vehiclesInvalid: "0",
  vehiclesIneligible: "0",
  totalVehiclesHeld: "0",
  totalVehiclesSubmitted: "0",
  shippedAfterNotice: "0"
};

export const vehicleMetricsSlice = createSlice({
  name: 'vehicleMetrics',
  initialState,
  reducers: {
    setVehicleMetrics: (state, action: PayloadAction<Partial<State>>) => {
      return {
        ...state,
        ...action.payload
      };
    },
  },
});

export const {
  setVehicleMetrics,
} = vehicleMetricsSlice.actions;

export default vehicleMetricsSlice.reducer;