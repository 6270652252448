import { Box, Grid, Stack, Typography } from '@mui/material';
import { Button, Divider, Dropdown, Icon, Link, Panel } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import { DataTableShape } from '../../../../types';
const Status = ['Complete', 'In-progress', 'Not Applicable'];
export const ActivityPanel = ({
  open,
  handleClose,
  pdcEventDetail,
  columns,
}: {
  open: boolean;
  handleClose: () => void;
  columns: DataTableShape.ColumnData[];
  pdcEventDetail: object;
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const dropdownOptionsList = [
    {
      optionLabel: 'Complete',
      id: 'complete',
    },
    {
      optionLabel: 'In-progress',
      id: 'inprogress',
    },
    {
      optionLabel: 'Not Applicable',
      id: 'notapplicable',
    },
  ];
  const renderTitle = () => {
    return (
      <Box sx={{ flexGrow: 1, padding: '2rem' }}>
        <Grid container flexDirection={'column'}>
          <Grid item xs={12}>
            <Typography>PDC Request Date</Typography>
          </Grid>
          <Grid container sx={{ flexDirection: 'row' }} spacing={1}>
            <Grid item>Status</Grid>
            <Grid item>
              {isEdit ? (
                <Dropdown
                  optionsList={dropdownOptionsList}
                  variant="singleSelect"
                  selectedOptions={pdcEventDetail['status']}
                  onChange={() => {}}
                />
              ) : (
                <Typography>{pdcEventDetail['status']}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderCardBody = () => {
    return (
      <Grid container flexDirection={'column'}>
        <Grid item xs={12} sx={{ padding: '12px' }}>
          PDC Event Details
        </Grid>
        <Divider />
        <Grid item xs={12} sx={{ padding: '12px' }}>
          {columns.map((column, index) => {
            return (
              <Grid container flexDirection={'row'} spacing={2} key={index}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    width: '140px',
                    wordWrap: 'normal',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginRight: '8px',
                  }}
                >
                  {column.name}
                </Grid>
                <Grid item xs={8}>
                  {pdcEventDetail[column.field]}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const renderAttachedFileBody = () => {
    return (
      <Grid container flexDirection={'column'}>
        <Grid item xs={12} sx={{ padding: '12px' }}>
          PDC Event Details
        </Grid>
        <Divider />
        <Grid item direction={'row'} sx={{ padding: '12px' }}>
          <Typography>{'Attachment 1'}</Typography>
          <Link
            variant={'inline'}
            label={'asdfcgvbhnj'}
            handleClick={() => {}}
            isVisited={false}
            isActive={false}
            disabled={false}
          />
        </Grid>
      </Grid>
    );
  };

  const getFooterModalComponent = () => {
    return (
      <Stack height="2.5rem" margin={'24px'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} ml={'auto'}>
          <Button
            label="Edit"
            size="small"
            icon={<Icon name={'Edit'} />}
            sx={{ marginLeft: 'auto' }}
            variant="secondary"
            onClick={() => setIsEdit(true)}
          />
        </Stack>
      </Stack>
    );
  };

  const renderBody = () => {
    return (
      <Grid container direction={'column'} sx={{ minHeight: '100vh' }}>
        <Grid item xs sx={{ padding: '2rem' }}>
          <Grid container direction={'column'} spacing={2}>
            <Grid item xs={12} sx={{ backgroundColor: '#F8F8F9' }}>
              {renderCardBody()}
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: '#F8F8F9', marginTop: '28px' }}>
              {renderAttachedFileBody()}
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ mt: 'auto' }}>
          <Divider variant="fullWidth" />
          {getFooterModalComponent()}
        </Grid>
      </Grid>
    );
  };
  return (
    <Panel
      isOpen={open}
      alignment={'right'}
      togglePanel={handleClose}
      titleText={renderTitle()}
      childComponents={renderBody()}
      sx={{ width: '800px' }}
    />
  );
};
