import axios, { AxiosRequestConfig } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { getUserDetails } from '../../../App';
import { getKey } from '../../../Constants';
import { endpoint } from '../../../assets/api/endpoint';
import { getEnv } from '../../../env/env';
import { downloadFileServiceApi } from '../../../services';
import { UploadDocumentTypes } from '../../../types';
import { ParsedDataAPI } from '../../../types/Shapes/PDCRequestDetails';
import { currentDateAndTime, formatRowData, getCurrentDateAndTime } from '../../../utils';
import { AppDispatch, RootState } from '../../store'; // Adjust this import based on your store file
import { parseAndSetData } from '../HoldTypeDetails/parseAppendixA';
import {
  setCanvasVehicleList,
  setCanvasVehicleListColumns,
  setCanvasVehicleListLoading,
  setCanvasVehicleListingCounts,
  setCurrentUploadStage,
  setDocumentDetails,
  setDownloadStatus,
  setHoldRequestPageViewList,
  setHoldRequestStatus,
  setHoldRequestStatusCounts,
  setLastRefreshDate,
  setLoading,
  setPreviewData,
  setRefresh,
  setReload,
  setTableCount,
  setUploadStageError,
  setUploadedDate,
  setVehicleList,
} from './slice'; // Import your setHoldRequest action
const controller = new AbortController();
const signal = controller.signal;
export const parseTimeout = 250;
export const maxAttempts = 25;

export const getHoldStatusCountsApi = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;
    const holdType = state.holdTypeDetails.holdType;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    try {
      let url = `${getEnv().API_BASE_URL}/hold/QChold/getHoldStatusCount`;
      const queryParam = holdType === '1' ? 'PDC' : holdType === '2' ? 'YARD' : '';
      if (queryParam.length > 0) {
        url = `${url}?holdType=${queryParam}`;
      }
      const response = await axios.get(url, config);

      // Dispatch the setHoldRequest action with the response data
      if (Object.keys(response.data.data).length) {
        dispatch(setHoldRequestStatus(response.data.data)); // Assuming response.data.data contains pdcStatus and yardStatus
        // Helper function to safely parse integers with a fallback
        const safeParseInt = (value) => parseInt(value, 10) || 0;

        let pdcCount = 0;
        let yardCount = 0;

        if (response.data.data.pdcStatus.length > 0) {
          for (const statusObj of response.data.data.pdcStatus) {
            // Add only the value of pdcrequest to pdcCount
            pdcCount += safeParseInt(statusObj.pdcrequest);
          }
        }
        if (response.data.data.yardStatus.length > 0) {
          // Iterate over the array of yardStatus
          for (const statusObj of response.data.data.yardStatus) {
            // Loop through each key-value pair in the yardStatus object
            for (const [key, value] of Object.entries(statusObj)) {
              yardCount += safeParseInt(value);
            }
          }
        }
        // Update counts in the state
        dispatch(
          setHoldRequestStatusCounts({
            pdcCount: pdcCount,
            yardCount: yardCount,
            all: pdcCount + yardCount,
          }),
        );
      } else {
        // dispatchNotification({
        //   open: true,
        //   variant: 'toast',
        //   type: 'error',
        //   iconEnabled: true,
        //   text: `Unable to display Hold metrics. Please try later.`,
        // });
      }
    } catch (error) {
      console.error('Error fetching hold status counts:', error);
      // Handle error if necessary
      // dispatchNotification({
      //   open: true,
      //   variant: 'toast',
      //   type: 'error',
      //   iconEnabled: true,
      //   text: `Unable to display Hold metrics. Please try later.`,
      // });
    }
  };
};

export const getHoldRequestsPageViewListApi = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const auth = state.auth;
    const authToken = auth.idToken;
    const authAccessToken = auth.accessToken;
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'Content-Type': 'application/json',
      },
    };
    let userData = JSON.parse(localStorage.getItem('HM-UserInfo') || '{}');
    if (!userData || Object.keys(userData).length <= 0) {
      userData = await getUserDetails(authAccessToken);
    }
    try {
      const response = await axios.get(
        endpoint.PAGE_VIEW_LIST + `?userId=${userData?.employeeId}&gridName=Hold List`,
        config,
      );
      dispatch(setHoldRequestPageViewList(response.data));
    } catch (error) {}
  };
};

export const getVehicleListApi = (notification?: (data: Object) => void) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setReload(true));
    const state = getState();
    const auth = state.auth;
    const holdRequestState = state.holdRequest;
    const authToken = auth.idToken;
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
      },
    };

    const { sorts, filters, pagination } = holdRequestState.dataTable;

    try {
      const response = await axios.post(
        `${getEnv().API_BASE_URL}/hold/QCHold/listing`,
        {
          filter:
            Array.isArray(filters) && filters.length > 0
              ? filters.map((x: any) => {
                  const key = getKey(x.field);
                  return {
                    columnName: key,
                    value:
                      x.value && Array.isArray(x.value)
                        ? key === 'HoldType' || key === 'HoldStatus'
                          ? x.query
                          : x.value[0].toString()
                        : x.value,
                  };
                })
              : [],
          page: { offset: (pagination.page - 1) * pagination.size, limit: pagination.size },
          sort: sorts.filter((x) => Object.keys(x).length > 0),
        },
        config,
      );
      if (response.status === 200) {
        dispatch(setLoading(false));
        dispatch(setReload(false));
        dispatch(setLastRefreshDate(getCurrentDateAndTime()));
      }

      if (response.data?.data?.getListOfHolds?.listOfHolds.length > 0) {
        dispatch(setTableCount(response.data?.data?.getListOfHolds?.totalRecordsCount || 0));
        dispatch(setVehicleList(formatRowData(response.data?.data?.getListOfHolds?.listOfHolds)));
      } else {
        dispatch(setTableCount(0));
        dispatch(setVehicleList([]));
        notification && notification({ field: 'No Hold Requests found', fieldName: 'success' });
      }
    } catch (error) {
      console.error('Error fetching hold status counts:', error);
      // Handle error if necessary
    }
  };
};

export const getDownloadApi = (handleNotification: (filter: any) => void) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const download = state.holdRequest.downloadStatus;
    const { filters, sorts, totalCount, columns } = state.holdRequest.dataTable;
    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/QCHold/listing/download`;
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
      },
    };
    try {
      dispatch(setDownloadStatus({ downloadSuccess: false, isError: false, downloading: true }));
      let response = await axios.post(
        url,
        {
          signal,
          filter: filters.map((x) => ({ columnName: x.name.replace(/\s+/g, ''), value: x.query })),
          sort: !!sorts[0]?.order
            ? [{ columnName: sorts[0].columnName, order: sorts[0].order.toLocaleLowerCase() }]
            : [],
          page: { offset: 0, limit: totalCount },
          columns: [...columns.map((x) => x.name)],
        },
        config,
      );
      const link = response.data.data;

      const downloadURL = `${getEnv().DOCUMENT_URL}/${link}`;
      const configs: AxiosRequestConfig = {
        signal,
        headers: {
          Authorization: `${authToken}`,
        },
        responseType: 'blob',
      };
      if (response.status === 200) {
        downloadFileServiceApi(link, authToken, handleNotification, () => {
          dispatch(setDownloadStatus({ downloadSuccess: true, isError: false, downloading: false }));
        });
      }
    } catch (error) {
      let filter = {
        fieldName: 'error',
        field: `Something went wrong. Please try again later`,
      };
      if (error.code == 'ERR_CANCELED') {
        filter['field'] = `The request to download has been canceled`;
        dispatch(setRefresh());
      }
      dispatch(setDownloadStatus({ downloadSuccess: false, isError: true, downloading: false }));
    } finally {
      setTimeout(() => {
        // controller.abort();
        if (download.loading || !download.downloadSuccess || !download.isError) {
          dispatch(setDownloadStatus({ downloadSuccess: false, isError: false, downloading: false }));
        }
      }, 8000);
    }
  };
};

export const uploadDocumentApi = (
  file: File,
  uploadType: string,
  handleNotification: (filter: any) => void,
  callback?: (documentId: string, documentPath: string) => void,
) => {
  return async (dispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/uploadDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    let fileExt = file.name.split('.').pop();
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    const sessionId = localStorage.getItem(`HM_sessionId`);
    const qcNumber = state.holdTypeDetails.requestState.holdRequestNumber;

    let reqData = {
      name: file.name,
      holdType: 'QUALITY_HOLD',
      userName: userData?.userName,
      userId: userData?.employeeId,
      documentType: uploadType,
      documentFormat: fileExt,
      sessionId: sessionId || '',
      qcNumber: qcNumber ?? '',
      sessionInfo: {
        systemContextFunction: 'testfn',
        sessionDominantRole: 'admin',
      },
    };

    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(url, reqData, config);
      if (response?.status?.toString() !== '201' || response?.data?.data?.responseData?.status?.toString() !== '201') {
        throw new Error(response?.data?.data?.errorMessage || 'Error Uploading File');
      }
      //response from meta api
      const documentId = response?.data?.data?.responseData?.response?.documentId;
      const documentPath = response?.data?.data?.responseData?.response?.documentPath;
      const sessionId = response?.data?.data?.responseData?.response?.sessionId;

      localStorage.setItem('HM_sessionId', sessionId);

      // dispatch(setSessionId(sessionId));
      dispatch(setUploadedDate(currentDateAndTime(response?.data?.data?.responseData?.response?.uploadedDate || '')));

      const urlUpload = `${getEnv().DOCUMENT_URL}/${documentPath}`;
      const uploadConfig = {
        headers: {
          Authorization: `${authToken}`,
          'Content-Type': 'application/json',
        },
      };
      const responseUpload = await axios.put(urlUpload, file, { ...uploadConfig, timeout: 10000 });
      if (responseUpload.status === 200) {
        setTimeout(() => {
          dispatch(setDocumentDetails({ documentId, documentPath, name: file.name }));
          if (file.name !== 'data.csv') {
            if (uploadType === UploadDocumentTypes.INITIALVEHICLESIMPORT) {
              dispatch(getDocumentPreviewApi(documentId));
              if (callback) callback(documentId, documentPath);
            }
            if (uploadType === UploadDocumentTypes.APPENDIX_A && callback) {
              callback(documentId, documentPath);
            }
            if (uploadType === UploadDocumentTypes.MISCELLANEOUS) {
              if (callback) callback(documentId, documentPath);
            }
          }
        }, parseTimeout);
      } else {
        handleNotification({
          fieldName: 'error',
          field: `Unable to attach file. Please try again later.`,
        });
      }
      return responseUpload;
    } catch (error: any) {
      console.error('Error uploading document', error);
      dispatch(setUploadStageError(error.message || 'Missing document path'));
      handleNotification({
        fieldName: 'error',
        field: `Unable to attach file. Please try again later.`,
      });
    }
  };
};

export const getDocumentPreviewApi = (documentId: string) => {
  return async (dispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;

    const url = `${getEnv().API_BASE_URL}/hold/QChold/getDocumentPreview/${
      UploadDocumentTypes.INITIALVEHICLESIMPORT
    }/${documentId}`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.get(url, config);
      if (response.status === 200) {
        dispatch(
          setPreviewData({
            headers: response?.data?.data?.body?.headers,
            data: response?.data?.data?.body?.parsed,
            count: response?.data?.data?.body?.totalCount,
            parsedBy: response?.data?.data?.body?.keyColumns,
            isGoCanvas: response?.data?.data?.body?.isGoCanvas,
            isVehicleFilter: response?.data?.data?.body?.isVehicleFilter,
          }),
        );
        dispatch(setCurrentUploadStage(3));
      }
    } catch (error: any) {
      dispatch(
        setPreviewData({
          headers: [],
          data: [{}],
          count: 0,
          parsedBy: [],
          isGoCanvas: 0,
          isVehicleFilter: 0,
        }),
      );
      dispatch(setUploadStageError(error.message || 'Failed to preview document'));
    }
  };
};

export const getCanvasVehicleListingCountsApi = (documentId: string) => {
  return async (dispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/QChold/getParsedCount/${
      UploadDocumentTypes.INITIALVEHICLESIMPORT
    }/${documentId}`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
      },
    };
    try {
      const response = await axios.get(url, config);
      dispatch(setCanvasVehicleListingCounts(response.data.data));
    } catch (error) {}
  };
};

export const getCanvasVehicleListingApi = (documentId: string, reqData: any) => {
  return async (dispatch, getState: () => RootState) => {
    dispatch(setCanvasVehicleListLoading(true));
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;

    const apiKey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/getParsedListing/${
      UploadDocumentTypes.INITIALVEHICLESIMPORT
    }/${documentId}`;
    try {
      const response = await axios.post(url, reqData, config);
      if (response.status === 201) {
        dispatch(setCanvasVehicleListLoading(false));
        dispatch(setCanvasVehicleList(response.data.data.vehicles));
        const columns = response.data.data.columns;
        dispatch(
          setCanvasVehicleListColumns(
            columns.map((x, i) => ({
              name: x,
              field: x,
              width: 120,
              id: i,
              sortable: false,
              pinnable: false,
              hidable: false,
              resizable: true,
              fixed: false,
              type: 'string',
              pinned: false,
            })),
          ),
        );
        // setFilters(filter);
      }
    } catch (error) {
      // if (error.status === 404) {
      //   handleNotification({ fieldName: 'success', field: 'No vehicles found.' });
      // } else {
      //   handleNotification({ fieldName: 'error', field: 'Something went wrong.' });
      // }
    } finally {
      // setLoading(false);
      // setReload(false);
    }
  };
};

export const getParsedDataApi = (
  { authToken, documentId }: ParsedDataAPI,
  type: 'Details' | 'Create',
  navigate?: NavigateFunction,
) => {
  return async (dispatch, getState: () => RootState) => {
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/getParsedData/APPENDIX_A/${documentId}`;
    try {
      const response = await axios.get(url, config);
      let parsedData = response.data.data;
      dispatch(parseAndSetData(parsedData, type, navigate));
    } catch (error) {}
  };
};
