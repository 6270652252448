import { Box } from '@mui/material';
import { CustomizedModal, DownloadModal, IconButton, useNotificationContext } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import { HoldRequestColumns } from '../../../Constants';

import { getDataTable, getDownloadApi, getDownloadStatus, getTotalCount } from '../../../store';
import { setColumns, setRefresh, setViewUpdated } from '../../../store/features/HoldRequest/slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const ToolbarIcons = () => {
  const dispatch = useAppDispatch();
  const totalCount = useAppSelector(getTotalCount);
  const { filters, sorts, columns } = useAppSelector(getDataTable);
  const download = useAppSelector(getDownloadStatus);
  const [downloadOpen, setDownloadModal] = useState(false);
  const { dispatchNotification } = useNotificationContext();

  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };
  let displayItems = [
    { title: 'Sorted by', value: sorts[0]?.fieldName || '-' },
    { title: 'Filtered by', value: filters.map((x) => x.field).join(', ') || '-' },
  ];

  useEffect(() => {
    if (download.downloadSuccess === true) {
      setTimeout(() => {
        setDownloadModal(false);
      }, 2000);
    }
  }, [download]);
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      <IconButton
        name="Cached"
        onClick={() => {
          dispatch(setRefresh());
        }}
      />
      <DownloadModal
        displayItems={displayItems}
        open={downloadOpen}
        downloadSuccess={download.downloadSuccess}
        isError={download.isError}
        downloading={download.downloading}
        handleClose={() => {
          setDownloadModal(false);
        }}
        targetElement={
          <IconButton
            name="FileDownloadOutlined"
            size="medium"
            disabled={false}
            onClick={() => setDownloadModal(true)}
          />
        }
        count={totalCount}
        headerText={'Download Data'}
        footer={{
          footerLabel: download.isError
            ? `Your download failed to prepare`
            : download.downloadSuccess
            ? `Your download has been prepared`
            : `Report contains ${totalCount || 0} items`,
        }}
        handleDownload={() => {
          dispatch(getDownloadApi(handleNotification));
        }}
        sx={{
          fontFamily: 'ToyotaType-Regular',
          padding: '24px',
          '& .MuiTypography-h3': {
            fontFamily: 'ToyotaType-Regular',
          },
        }}
      />

      <CustomizedModal
        targetElement={
          <IconButton
            size="medium"
            name="ViewWeekOutlined"
            sx={{
              color: 'black',
            }}
          />
        }
        modalPlacement={'bottom-end'}
        tables={[]}
        selectedTable={{ id: '', label: '', name: '', pinnedColumns: [] }}
        viewByModalTitle={'Table views allow you to group the data by different high-level categories'}
        columns={HoldRequestColumns}
        currentColumns={[...columns].filter((x) => !x.hide)}
        handleApplyColumns={(columns) => {
          dispatch(setColumns(columns));
          dispatch(setViewUpdated(true));
        }}
        handleApplyTable={() => {}}
        featureFlags={{
          isViewbyEnabled: false,
          isColumnsViewEnabled: true,
        }}
      />
    </Box>
  );
};

export default ToolbarIcons;
