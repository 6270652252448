import { Theme, createTheme } from '@mui/material/styles';

import { customTypography } from './customTypography';
import { palette } from './palette';
import { typography } from './typography';
import { ThemeShape } from '../types/Shapes';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
  interface TypographyVariantsOptions {
    h1: ThemeShape.FontStyle;
    h1Semibold: ThemeShape.FontStyle;
    h2: ThemeShape.FontStyle;
    h2Semibold: ThemeShape.FontStyle;
    h3: ThemeShape.FontStyle;
    h3Semibold: ThemeShape.FontStyle;
    bodyShort: ThemeShape.FontStyle;
    bodyShortSemibold: ThemeShape.FontStyle;
    bodyLong: ThemeShape.FontStyle;
    bodyLongSemibold: ThemeShape.FontStyle;
    caption: ThemeShape.FontStyle;
    boldCaption: ThemeShape.FontStyle;
    label: ThemeShape.FontStyle;
    labelRegular: ThemeShape.FontStyle;
    labelSemibold: ThemeShape.FontStyle;
    labelUppercase: ThemeShape.FontStyle;
    labelUppercaseSemibold: ThemeShape.FontStyle;
    button: ThemeShape.FontStyle;
    buttonSemibold: ThemeShape.FontStyle;
    displayRegular: ThemeShape.FontStyle;
    displaySemibold: ThemeShape.FontStyle;
  }

  interface Theme {
    palette: ThemeShape.PaletteShape;
    typography: TypographyVariantsOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Semibold: true;
    h2Semibold: true;
    h3Semibold: true;
    bodyShort: true;
    bodyShortSemibold: true;
    bodyLong: true;
    bodyLongSemibold: true;
    boldCaption: true;
    label: true;
    labelRegular: true;
    labelSemibold: true;
    labelUppercase: true;
    labelUppercaseSemibold: true;
    buttonSemibold: true;
    displayRegular: true;
    displaySemibold: true;
  }
}

const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  typography: customTypography,
  palette: palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: typography,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme: MuiTheme }) => ({
          paddingLeft: '2rem',
          paddingRight: '2rem',
          [MuiTheme.breakpoints.values.laptop]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        }),
        disableGutters: {
          padding: 0,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});

export default theme;
