// @ts-nocheck
import { AccessTimeOutlined } from '@mui/icons-material';
import BackHandIcon from '@mui/icons-material/BackHand';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsRailwayFilledSharpIcon from '@mui/icons-material/DirectionsRailwayFilledSharp';
import FactoryIcon from '@mui/icons-material/Factory';
import GarageIcon from '@mui/icons-material/Garage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import {
  Button,
  Chip,
  CustomizedModal,
  DataTableView,
  DatePicker,
  IconButton,
  LoadingSpinner,
  Menu,
  Modal,
  TextInput,
  useNotificationContext,
} from 'cubee2e-cube-global-components';
import { DataTableShape } from 'cubee2e-cube-global-components/dist/src/types/Shapes/DataTable.shape';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ImportFileDetails } from '../../../../types';
import { BrowserStorage } from '../../../../utils/browserStorage';
import GoCanvasModal from './GoCanvasModal/GoCanvas';
import ImportModal from './ImportModal/ImportModal';
import './Vehicles.scss';

import dayjs, { Dayjs } from 'dayjs';
import { getEnv } from '../../../../env/env';
import { setPageFilterData } from '../../../../store/features/CascadingFilter/cascadingFilterSlice';
import { VehiclesServices } from './Vehicles.Services';

//Styles
const StyledGridRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '21px 0px 16px 0px',
});
const StyledGridColumn = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 0rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});
const TypoGraphy = styled(Typography)({
  color: 'var(--sys-light-Text-variant, #58595B)',
  fontFamily: "'ToyotaType-Regular', sans-serif", //"Toyota Type"
  fontSize: '.75rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1rem',
});

const TypoGraphy2 = styled(Typography)({
  color: 'var(--sys-light-Text-default, #000)',
  fontFamily: 'ToyotaType-Regular',
  fontSize: '.75rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1rem',
  paddingRight: '0px',
  paddingTop: '1px',
  paddingBottom: '1px',
});

function VehicleDataTable(props: {
  holdId: string;
  submissionDate: string;
  selectedKPIFilter: Object;
  refresh?: number;
  handleTableUpdate: Function;
  tableData: Object;
  tableUpdated: Function;
  vehicleAction: Function;
  holdType: string;
  holdStatus: string;
}) {
  const [limitCount, setLimitCount] = useState<Number>(25);
  const [offsetCount, setOffsetCount] = useState<Number>(0);
  const [showModal, setShowModal] = useState<Number>(false);
  const [itemSelected, setItemSelected] = useState<Number>(false);
  const [showGoCanvasModal, setShowGoCanvasModal] = useState<Number>(false);
  const [showVehicleDelayModal, setShowVehicleDelayModal] = useState<boolean>(false);
  const [delayDateCall, setDelayDateCall] = useState<string>('1');
  const [confirmButtonDisable, setConfirmButtonDisable] = useState<boolean>(true);
  const [uploadDetails, setUploadDetails] = useState<ImportFileDetails | null>(null);
  const [actionAnchor, setActionAnchor] = useState<HTMLElement | null>(null);
  const [selectAllAnchorEl, setSelectAllAnchorEl] = useState<HTMLElement | null>(null);
  const [pageCount, setPageCount] = useState<Number>(1);
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
  const [filterArray, setFilterArray] = useState<Object[]>([]);
  const [tableNotification, setTableNotification] = useState<{ type: 'filter' | 'sort' | 'none'; column: string }>({
    type: 'none',
    column: '',
  });
  const [opendatecalendar, setopendatecalendar] = useState<boolean>(false);
  const [dateChangesNotes, setDateChangesNotes] = useState<string>('');
  const [letterCounts, setLetterCounts] = useState<number>(0);
  // const [anticipatedDelayTiming, setAnticipatedDelayTiming] = useState<string>(dayjs(new Date()).format('MM/DD/YYYY'));
  const [anticipatedDelayTiming, setAnticipatedDelayTiming] = useState(null);
  // const [updateDelayDate, setUpdateDelayDate] = useState<boolean>(false);
  const [uploadGoCanvasfile, setUploadGoCanvasfile] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth).idToken;
  const dealerData = useAppSelector((state) => state.cascadingFilter?.dealer);
  const cascadingFilters = useAppSelector((state) => state.cascadingFilter.cfilter);
  const holdType = useAppSelector((state) => state.holdTypeDetails.holdType);
  const holdStatus = useAppSelector((state) => state.holdTypeDetails.holdStatus);
  //cascading filters values for save page view
  const cfSelectedPredefined = useAppSelector((state) => state.cascadingFilter.selectedPredefined);
  const cfDistributor = useAppSelector((state) => state.cascadingFilter.distributor);
  const cfBrand = useAppSelector((state) => state.cascadingFilter.selectedBrands);
  const cfFleet = useAppSelector((state) => state.cascadingFilter.fleet);
  const cfRegion = useAppSelector((state) => state.cascadingFilter.region);
  const cfVechileAssignment = useAppSelector((state) => state.cascadingFilter.vechileAssignment);
  const cfDealer = useAppSelector((state) => state.cascadingFilter.dealer);
  const cfModelYear = useAppSelector((state) => state.cascadingFilter.modelYear);
  const cfSeries = useAppSelector((state) => state.cascadingFilter.selectedSeries);
  const cfFilterButtonChipValue = useAppSelector((state) => state.cascadingFilter.filterButtonChipValue);
  const cfIsCascadingFilterSwitchChecked = useAppSelector(
    (state) => state.cascadingFilter.isCascadingFilterSwitchChecked,
  );
  const metricsData = useAppSelector((state) => state.vehicleMetrics);
  const sessionId = BrowserStorage.getSessionId() || { id: '20240722T055243_0532282f-64f4-4c75-a1e4-7b7fdfaf2ff9' };
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
  const [sortLabel, setSortLabel] = useState<any>(<span className="setTableFontFamily">Sorted By VIN</span>);
  const [totalCount, setTotalCount] = useState<Number>(0);
  const [selectedRecs, setSelectedRecs] = useState<Object>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [currentDelayTiming, setCurrentDelayTiming] = useState<string>('');
  const [totalVehicleSelected, setTotalVehicleSelected] = useState<string>('');
  const [dataTableData, setDataTableData] = useState<Object>({});
  const [upDateDelayPaylod, setUpDateDelayPaylod] = useState<Object>({});
  const [isvalidVIN, setIsvalidVIN] = useState<boolean>(false);
  // const [delayDatenotification, setDelayDateNotification] = useState<NotificationInfo | null>(null);
  const [filterID, setFilterID] = useState<string>('');
  const [VinList, setVinList] = useState<string[]>(['']);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { dispatchNotification } = useNotificationContext();
  const handleNotification = (notification: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: notification.fieldName,
      iconEnabled: true,
      text: notification.field,
    });
  };
  const uploadDocStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    // maxWidth: '700px',
    // minHeight: '300px',
    bgcolor: 'background.paper',
    boxShadow: 15,
    fontFamily: 'ToyotaType-Regular',
    borderRadius: '4px',
    '& .MuiTypography-bodyLong': { padding: '0px 0px 0px 0px' },
    '& .MuiGrid-container': { marginBottom: '0px', paddingBottom: '0px' },
    '& .MuiGrid-root.MuiGrid-item': { marginBottom: '0px' },
  };
  //loader with progress bar
  const [progressCount, setProgressCount] = useState(0);
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const handleCount = () => {
    if (progressCount < 100) {
      const time = setTimeout(() => {
        setProgressCount((prevCount) => prevCount + 10);
      }, 1000);
    } else if (progressCount == 100) {
      setNotificationSuccess(true);
    }
    return () => clearTimeout(time);
  };

  let LifecycleStatusChip = (label: string | number | JSX.Element) => {
    return <Chip label={label} colorScheme="primaryContainer" variant="filled"></Chip>;
  };

  let CurrentLocationIcon = (label: string) => {
    return label === 'Rail Location'
      ? { icon: <DirectionsRailwayFilledSharpIcon />, label }
      : label === 'Truck Location'
      ? { icon: <LocalShippingIcon />, label }
      : label === 'Dealership'
      ? { icon: <GarageIcon />, label }
      : label === 'Plant Location'
      ? { icon: <FactoryIcon />, label }
      : label === 'Vessel Location'
      ? { icon: <DirectionsBoatIcon />, label }
      : { icon: null, label };
  };

  const CurrentLocationComponent = (label: string) => {
    const { icon, label: locationLabel } = CurrentLocationIcon(label);
    return (
      <div style={{ display: 'flex' }}>
        {icon && (
          <div
            style={{
              padding: '4px',
              gap: '8px',
              borderRadius: '4px',
              height: '30px',
              width: '32px',
              backgroundColor: '#143A8C',
              color: '#FFFFFF',
            }}
          >
            {icon}
          </div>
        )}
        <div style={{ margin: '6px 6px', fontFamily: 'ToyotaType-Regular' }}>{locationLabel}</div>
      </div>
    );
  };

  const HoldStatusIcon = (label: string) => {
    return label === '1'
      ? { icon: <PanoramaFishEyeIcon />, label: 'Pending' }
      : label === '2'
      ? { icon: <BackHandIcon />, label: 'On Hold' }
      : label === '3'
      ? { icon: <CheckIcon />, label: 'In Evaluation' }
      : label === '4'
      ? { icon: <CheckIcon />, label: 'Completed' }
      : label === '5'
      ? { icon: <ClearIcon />, label: 'Invalid' }
      : label === '6'
      ? { icon: <PanoramaFishEyeIcon />, label: 'Notify CQS' }
      : label === '7'
      ? { icon: <AccessTimeOutlined />, label: 'In Progress' }
      : label === '8'
      ? { icon: <AccessTimeOutlined />, label: 'Complete in progress' }
      : label === '9'
      ? { icon: <AccessTimeOutlined />, label: 'Delay Date Update in progress' }
      : label === '11'
      ? { icon: <AccessTimeOutlined />, label: 'SAN' }
      : { icon: null, label: null };
  };

  const HoldStatusComp = (label: string) => {
    const { icon, label: iconLabel } = HoldStatusIcon(label);
    return (
      <div style={{ display: 'flex' }}>
        {icon && (
          <div
            style={{
              padding: '4px',
              gap: '8px',
              borderRadius: '4px',
              height: '30px',
              width: '32px',
              backgroundColor: '#E66C02',
              color: '#FFFFFF',
            }}
          >
            {icon}
          </div>
        )}
        <div style={{ margin: '6px 6px', fontFamily: 'ToyotaType-Regular', fontWeight: 400, fontSize: '14px' }}>
          {iconLabel ? iconLabel : '-'}
        </div>
      </div>
    );
  };

  const columns = [
    {
      field: 'Series',
      name: 'Sales Series',
      width: 90,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'VIN',
      name: 'VIN',
      width: 209,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
      copyIcon: true,
    },
    {
      field: 'URN',
      name: 'URN',
      width: 180,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
      copyIcon: true,
    },
    {
      field: 'Location',
      name: 'Plant',
      width: 115,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'HoldStatus',
      name: 'Hold Status',
      width: 149,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'lastTransactionStatus',
      name: 'Current Transaction Status',
      width: 184,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'LifecycleStatus',
      name: 'Lifecycle Status',
      width: 184,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'LifecycleSubStatus',
      name: 'Lifecycle Substatus',
      width: 184,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'submittedOn',
      name: 'Submitted On',
      width: 150,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'AnticipatedDelayDate',
      name: 'Anticipated Delay Date',
      width: 184,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'Age',
      name: 'Age',
      width: 150,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
      valueAlign: 'end',
    },
    {
      field: 'Region',
      name: 'Region',
      width: 87,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'ModelYear',
      name: 'Model Year',
      width: 180,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
      valueAlign: 'end',
    },
    {
      field: 'CurrentLocation',
      name: 'Current Location',
      width: 205,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'Group',
      name: 'Group',
      width: 150,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'DestCountry',
      name: 'Destination Country',
      width: 167,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    // {
    //     field: "Notes",
    //     name: "Notes",
    //     width: 180,
    //     resizable: true,
    //     sortable: false,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // },
    {
      field: 'ActualLineOffDate',
      name: 'Production Date',
      width: 184,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'SoldDate',
      name: 'Sold Date',
      width: 160,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'Katashiki',
      name: 'Katashiki',
      width: 170,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'MYSpec',
      name: 'MY Spec',
      width: 90,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'AssemblySequence',
      name: 'Assembly Sequence',
      width: 160,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'SoldSequence',
      name: 'Sold Sequence',
      width: 120,
      resizable: true,
      sortable: false,
      fixed: false,
      type: 'string',
      pinned: false,
    },
  ];

  let [currentColumns, setCurrentColumns] = useState<[]>(columns.slice(0, 11));

  const selectedRowBarIcons = () => {
    return <Button variant="primary" label="test" onClick={() => {}} />;
  };

  // ? Selection logic start section --------------------------------------------------------------------

  const selectAllMenu = [
    {
      optionLabel: `Select All - All Pages (${totalCount} items)`,
      id: 'all',
      section: 'top',
      disabled: totalCount <= limitCount,
    },
    {
      optionLabel: `Select All - Current Page (${
        totalCount - limitCount * pageCount >= 0 ? limitCount : totalCount % limitCount
      } items)`,
      id: 'page',
      section: 'top',
      disabled: false,
    },
  ];
  useEffect(() => {
    if (holdType == 'PDC') {
      if (holdStatus == 'Active Hold' || holdStatus == 'Active') {
        let vinListArray = [];
        let newList = [];

        Object.keys(selectedRecs)?.map((key) => {
          if (selectedRecs?.[key]?.length > 0) {
            if (isvalidVIN === false) {
              setDataTableData((data) => ({
                ...data,
                tableData: {
                  ...data.tableData,
                  reloading: true,
                },
              }));
            }
            // vinListArray = SelectedRecList?.filter(vehicleInfo => !vehicleInfo?.VIN)?.map(vehicleInfo => vehicleInfo?.VIN);
            vinListArray = [...vinListArray, ...(selectedRecs?.[key]?.map((vehicleInfo) => vehicleInfo?.VIN) || [])];
            newList = selectedRecs?.[key]?.filter((vehicleInfo) => {
              if (
                vehicleInfo?.HoldStatus?.props?.children?.[1]?.props?.children === 'On Hold' ||
                vehicleInfo?.HoldStatus?.props?.children?.[1]?.props?.children === 'In Evaluation'
              ) {
                return vehicleInfo;
              }
            });
            if (newList.length > 0 && isvalidVIN === false) {
              setIsvalidVIN(true);
            } else if (newList.length === 0) {
              setDataTableData((data) => ({
                ...data,
                tableData: {
                  ...data.tableData,
                  reloading: false,
                },
              }));
            }
          } else {
            setDataTableData((data) => ({
              ...data,
              tableData: {
                ...data.tableData,
                reloading: false,
              },
            }));
          }
        });

        let condition = '';

        if (selectAll === true) {
          condition = 'ALL';
          setIsvalidVIN(true);

          // vinListArray = []
        } else {
          condition = 'NONE';
          if (newList?.length > 0) {
            if (isvalidVIN === false) {
              setIsvalidVIN(true);
            }
          } else {
            setIsvalidVIN(false);
          }
        }
        // setUpDateDelayPaylod(["filterId"] = filterID)
        const delayDatePayload = {
          qcNumber: `${props.holdId}`, //M
          notes: dateChangesNotes, //OP
          delayDate: anticipatedDelayTiming === null ? '' : formatDateApi(anticipatedDelayTiming), //M
          vehicles: vinListArray,
          condition: condition, //M
        };
        if (uploadDetails?.action === 'filter') {
          delayDatePayload['documentId'] = uploadDetails?.documentId;
        }
        if (!filterID) {
        } else {
          delayDatePayload['filterId'] = filterID;
        }
        setUpDateDelayPaylod(delayDatePayload);
        // setDataTableData({
        //   ...dataTableData,
        //   tableHeader: {
        //     ...dataTableData?.tableHeader,
        //     toolbarIcons: <ToolbarIcons selectionCount={getSelectedItemsCount()} curColumns={currentColumns} selectedRecords={selectedRecs} />,
        //   },
        // });
      } else {
        setIsvalidVIN(false);
      }
    } else {
      setIsvalidVIN(false);
    }
  }, [selectAll, selectedRecs, dateChangesNotes, anticipatedDelayTiming]);

  useEffect(() => {
    setDataTableData((data) => ({
      ...data,
      tableData: {
        ...data.tableData,
        reloading: false,
      },
      tableHeader: {
        ...data.tableHeader,
        toolbarIcons: (
          <ToolbarIcons
            selectionCount={getSelectedItemsCount()}
            curColumns={currentColumns}
            selectedRecords={selectedRecs}
          />
        ),
      },
    }));
  }, [isvalidVIN]);

  const getSelectedItemsCount = () => {
    let paginatedRecords = Object.values({ ...selectedRecs });
    if ([].concat(...paginatedRecords).length === 0 && selectAll) {
      return totalCount;
    }
    let totalLen = 0;
    let offset = 0;
    paginatedRecords.forEach((x) => {
      totalLen =
        totalLen + (x.length > 0 ? (selectAll ? limitCount - x.length : x.length) : selectAll ? limitCount : 0);
    });
    if (selectAll) {
      let countS = 0;
      let unvisitedPages = Math.ceil(totalCount / limitCount) - paginatedRecords.length;
      if (!paginatedRecords[unvisitedPages - 1]) {
        offset = limitCount * (unvisitedPages - 1);

        let rem = totalCount % limitCount;
        offset = offset + (rem > 0 ? rem : limitCount);
      } else {
        offset = limitCount * unvisitedPages;
        countS = offset + 50 - totalCount;
      }
      totalLen = totalLen + offset - countS;
    }
    return totalLen;
  };

  const handleSelectInversion = (selectedItems: Vehicle[], rowData?: Vehicle[]) => {
    if (!rowData) {
      rowData = [...(dataTableData?.tableData?.rowsData || [])];
    }
    if (!selectAll) {
      return selectedItems;
    }
    let returnArray = rowData?.filter((x) => !selectedItems.some((y) => y.id === x.id));
    return returnArray;
  };

  const handleSelectedItemUpdate = async (selectedItems: Vehicle[]) => {
    let paginatedRecords = { ...selectedRecs };
    let newlySelected = selectedItems.length;
    let currentSelected = (paginatedRecords[pageCount] || []).length;
    currentSelected = selectAll ? limitCount - currentSelected : currentSelected;
    let totalSelected = getSelectedItemsCount();
    if (Math.abs(newlySelected - currentSelected) <= 1) {
      setSelectedRecs((recs) => ({ ...recs, [pageCount]: [...handleSelectInversion(selectedItems)] }));
      const delatDate = selectedItems?.[0]?.AnticipatedDelayDate + ' (In ' + selectedItems?.[0]?.Age + ' days)';
      setCurrentDelayTiming(delatDate);
      let count = totalSelected + (newlySelected - currentSelected);
      if (count > 1) {
        setCurrentDelayTiming('Vehicles may have varying delay timing');
      }
      setTotalVehicleSelected(count);
      setDataTableData({
        ...(dataTableData || {}),
        tableHeader: {
          ...(dataTableData?.tableHeader || {}),
          viewlabel: <span style={{ fontFamily: 'ToyotaType-Regular' }}>{count} Selected</span>,
          toolbarIcons: (
            <ToolbarIcons
              selectionCount={count}
              curColumns={currentColumns}
              selectedRecords={{ ...selectedRecs, [pageCount]: [...handleSelectInversion(selectedItems)] }}
            />
          ),
        },
        selectedItems: selectedItems,
      });
    } else {
      let el = document.getElementsByClassName('PrivateSwitchBase-input').item(0);
      setCurrentDelayTiming('Vehicles may have varying delay timing');
      handleSelectAllSelection(el, currentSelected, totalSelected);
    }
  };

  const handleSelectAllSelection = (element: HTMLElement, currentSelected: Number, totalSelected: Number) => {
    let selectedAllRecords = totalSelected.toString() === totalCount.toString() && selectAll;
    if (selectedAllRecords || currentSelected === limitCount || currentSelected === totalCount) {
      resetSelection(selectedAllRecords);
    } else {
      setSelectAllAnchorEl(element);
    }
  };

  const resetSelection = (selectedAll: boolean, filterReset?: boolean) => {
    if (filterReset) {
      setSelectedRecs([]);
      return;
    }

    const count = selectedAll ? 0 : getSelectedItemsCount() - limitCount;
    let selectedList;

    if (selectAll) {
      setSelectAll(false);
      setSelectedRecs([]);
      selectedList = {};
    } else {
      let selected = { ...selectedRecs, [pageCount]: handleSelectInversion([]) };
      setSelectedRecs(selected);
      selectedList = selected;
    }
    setTotalVehicleSelected(count);
    setDataTableData({
      ...(dataTableData || {}),
      tableHeader: {
        ...(dataTableData?.tableHeader || {}),
        viewlabel: <span style={{ fontFamily: 'ToyotaType-Regular' }}>{formatNumber(count)} Selected</span>,
        toolbarIcons: (
          <ToolbarIcons selectionCount={count} curColumns={currentColumns} selectedRecords={selectedList} />
        ),
      },
      selectedItems: [],
    });
  };

  const selectAllActionHandler = (e: any) => {
    setSelectAllAnchorEl(null);
    if (e[0] === 'page') {
      let selectionLength =
        getSelectedItemsCount() -
        handleSelectInversion(selectedRecs[pageCount] || []).length +
        Math.min(limitCount, totalCount, totalCount - (pageCount - 1) * limitCount);
      setSelectedRecs({
        ...selectedRecs,
        [pageCount]: [...handleSelectInversion(dataTableData?.tableData?.rowsData || [])],
      });
      // handleSelectedItemUpdate();
      if (selectionLength === totalCount) {
        // setSelectAll(true);
      }

      //     pagination: {
      //         page: dataTableData.pagination.page,
      //         size: dataTableData.pagination.size,
      //     },
      //     // selection:{
      //     //     data: {
      //     //         ...selectedRecs,
      //     //         [pageCount]: [...handleSelectInversion(dataTableData?.tableData?.rowsData || [])],
      //     //     },
      //     //     selectAll: selectAll,
      //     // },
      //     // sorts: [{
      //     //     field: 'Submitted Date',
      //     //     order: 'DESC',
      //     // }],
      //     columns:[...dataTableData.columns],
      //     filters: [...dataTableData.filters.appliedFilters]
      // });
      setTotalVehicleSelected(selectionLength);
      setDataTableData({
        ...(dataTableData || {}),
        tableHeader: {
          ...(dataTableData?.tableHeader || {}),
          viewlabel: <span style={{ fontFamily: 'ToyotaType-Regular' }}>{formatNumber(selectionLength)} Selected</span>,
          toolbarIcons: (
            <ToolbarIcons
              selectionCount={selectionLength}
              curColumns={currentColumns}
              selectedRecords={{
                ...selectedRecs,
                [pageCount]: [...handleSelectInversion(dataTableData?.tableData?.rowsData || [])],
              }}
            />
          ),
        },
        selectedItems: [...(dataTableData?.tableData?.rowsData || [])],
      });
    } else if (e[0] === 'all') {
      setSelectAll(true);
      setSelectedRecs({ [pageCount]: [] });
      setTotalVehicleSelected(totalCount);
      setDataTableData({
        ...(dataTableData || {}),
        tableHeader: {
          ...(dataTableData?.tableHeader || {}),
          viewlabel: <span style={{ fontFamily: 'ToyotaType-Regular' }}>{formatNumber(totalCount)} Selected</span>,
          toolbarIcons: (
            <ToolbarIcons
              selectionCount={totalCount}
              currentColumns={currentColumns}
              selectedRecords={{ ...selectedRecs, [pageCount]: dataTableData?.tableData?.rowsData }}
            />
          ),
        },
        selectedItems: [...(dataTableData?.tableData?.rowsData || [])],
      });
    }
  };

  const handleRowCellClick = (data: any) => {
    const vehicle = vehicleList[data?.row?.id ?? 0];
    const auditHistoryPanel: any = {
      PanelVin: vehicle?.vin,
      PanelModelYear: vehicle?.modelYear,
      PanelSalesSeries: vehicle?.salesSeriesName,
      PanelModelNo: vehicle?.modelCode,
      PanelBrand: vehicle?.modelDescription,
    };
    // AppEventService?.sendEvent("HOLD_VEHICLE_TAB_DATATABLE_CLICK", "HOLD", "VMGT", auditHistoryPanel);
  };

  const getKey = (key) => {
    switch (key) {
      case 'salesSeriesName':
      case 'Series':
        key = 'salesSeriesName';
        break;
      case 'VIN':
        key = 'vin';
        break;
      case 'DestCountry':
        key = 'DestinationCountry';
        break;
      // case "AnticipatedDelayDate" : return  0
      // break;
      // case "Age" : return 0
      // break;
      case 'Group':
        key = 'GroupName';
        break;
      case 'CurrentLocation':
        key = 'currentLocation';
        break;
      case 'Location':
        key = 'plantShortDescription';
        break;
      case 'Region':
        key = 'currentRegionCode';
        break;
      case 'ActualLineOffDate':
        key = 'actualLineOffDate';
        break;
      case 'SoldDate':
        key = 'rdrDate';
        break;
      case 'LifecycleStatus':
        key = 'vehicleLifeCycleStatus';
        break;
      case 'Katashiki':
        key = 'katashikiDescription';
        break;
      case 'AnticipatedDelayDate':
        key = 'DelayDate';
        break;
      default:
        break;
    }
    return key;
  };

  const handleCurrentSortUpdate = async (newSort: DataTableShape.SortData[]) => {
    let sortUpdate = [
      ...newSort.map((x) => {
        let columnName = getKey(x.field);
        let fieldName = columns.find((y) => y.field === x.field).name;
        return {
          ...x,
          field: x.field,
          columnName: columnName,
          fieldName: fieldName,
        };
      }),
    ];
    handleNotification({
      fieldName: 'success',
      field: `Sort applied to ${sortUpdate[0].fieldName} in ${
        sortUpdate[0].order === 'ASC' ? 'ascending' : 'descending'
      } order.`,
    });
    setSorts(sortUpdate);
    props.handleTableUpdate({
      ...props.tableData,
      // sorts: [{
      //     field: 'Submitted Date',
      //     order: 'DESC',
      // }],
    });
    setDataTableData((prevData) => ({
      ...prevData,
      tableData: {
        ...prevData.tableData,
        loading: true,
      },
    }));
  };

  const applyFontFamily = (text) => <div style={{ fontFamily: 'ToyotaType-Regular' }}>{text}</div>;

  const formatDate = (dateString: string, submissionDate: boolean = false) => {
    if (!!!dateString) {
      // return submissionDate ? props.submissionDate : '-';
      return 'None';
    } else return dateString.substring(0, 11).replace('T', '\t');
  };

  const formatDateApi = (unformatDate: string | number | Date) => {
    if (unformatDate === '' || unformatDate === 'undefined' || unformatDate === undefined || unformatDate === null) {
      return '';
    } else {
      const newDate = new Date(unformatDate);
      let formatDate = '';
      if (isNaN(newDate.getMonth()) || isNaN(newDate.getDate()) || isNaN(newDate.getFullYear())) {
        formatDate = '';
      } else {
        formatDate =
          ('0' + (newDate.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + newDate.getDate()).slice(-2) +
          '/' +
          newDate.getFullYear();
      }
      return formatDate;
    }
  };

  const formatNumber = (number: Number) => {
    return (number || 0).toLocaleString('en-US');
  };
  const formatRowData: Vehicle[] = (rows: Vehicle[]) => {
    console.log('rows', rows);
    return (
      rows?.map((vehicle, index) => ({
        id: index,
        Series: applyFontFamily(vehicle.salesSeriesName),
        VIN: vehicle.vin,
        URN: vehicle.urn || '-',
        DestCountry: applyFontFamily(vehicle.DestinationCountry || '-'),
        HoldStatus: HoldStatusComp(vehicle.HoldStatus.toString()),
        submittedOn: formatDate(vehicle.SubmittedOn, true),
        ModelYear: vehicle.modelYear || '-',
        AnticipatedDelayDate:
          vehicle?.DelayDate === 'N/A'
            ? 'None'
            : vehicle?.DelayDate === null
            ? '-'
            : vehicle?.DelayDate.substring(5, 7) +
              '/' +
              vehicle?.DelayDate.substring(8, 10) +
              '/' +
              vehicle?.DelayDate.substring(0, 4),
        Age: vehicle.Age || '0',
        Group: applyFontFamily(vehicle.GroupName),
        // Notes: getNotesDropdown(vehicle.notes || null, index),
        Location: applyFontFamily(vehicle.manufacturingLocation?.plantShortDescription || '-'),
        Region: applyFontFamily(vehicle.relatedDealers?.currentDealer?.currentRegionCode || '-'),
        CurrentLocation: CurrentLocationComponent(vehicle.currentLocation || '-'),
        ActualLineOffDate:
          vehicle.vehicleDates?.actualLineOffDate === 'N/A'
            ? 'N/A'
            : vehicle.vehicleDates?.actualLineOffDate === null
            ? '-'
            : vehicle.vehicleDates?.actualLineOffDate.substring(5, 7) +
              '/' +
              vehicle.vehicleDates?.actualLineOffDate.substring(8, 10) +
              '/' +
              vehicle.vehicleDates?.actualLineOffDate.substring(0, 4),
        SoldDate:
          vehicle.retailSales?.rdrDate === 'N/A'
            ? 'N/A'
            : vehicle.retailSales?.rdrDate === null
            ? '-'
            : vehicle.retailSales?.rdrDate.substring(5, 7) +
              '/' +
              vehicle.retailSales?.rdrDate.substring(8, 10) +
              '/' +
              vehicle.retailSales?.rdrDate.substring(0, 4),
        LifecycleStatus: LifecycleStatusChip(vehicle.vehicleEvent?.vehicleLifeCycleStatus || '-'),
        LifecycleSubStatus: vehicle.vehicleEvent?.lifecycleSubstatusDescription || '-',
        lastTransactionStatus: applyFontFamily(vehicle?.lastTransactionStatus || '-'),
        Katashiki: applyFontFamily(vehicle.order?.katashikiDescription || '-'),
        MYSpec: applyFontFamily(vehicle.MYSpec),
        AssemblySequence: applyFontFamily(vehicle.AssemblySequence),
        SoldSequence: applyFontFamily(vehicle.SoldSequence),
      })) || []
    );
  };

  const rows: Vehicle[] = formatRowData(vehicleList);
  const options = [
    { label: 'Y', value: 'yes' },
    { label: 'N', value: 'no' },
  ];

  const SortOrder = {
    ASC: 'ASC',
    DESC: 'DESC',
  };

  const [sorts, setSorts] = useState([{ field: 'id', order: SortOrder.ASC }]);

  const tablesMockData = [
    {
      id: 'byVin',
      name: 'vehicledetails',
      label: 'Option1',
      pinnedColumns: ['vin'],
      defaultSorts: [
        {
          field: 'firstName',
          order: 'DESC',
        },
      ],
      default: true,
    },
    {
      id: 'byRegion',
      name: 'area',
      label: 'Option2',
      pinnedColumns: ['currentLocation'],
      defaultSorts: [],
    },
    {
      id: 'bySeries',
      name: 'series',
      label: 'Option3',
      pinnedColumns: ['brand'],
      defaultSorts: [
        {
          field: 'firstName',
          order: 'DESC',
        },
      ],
    },
    {
      id: 'byRetailed',
      name: 'retailed',
      label: 'Option4',
      pinnedColumns: ['vin'],
      defaultSorts: [],
    },
  ];

  const ToolbarIcons = ({
    selectionCount = getSelectedItemsCount(),
    curColumns = columns.slice(0, 9),
    selectedRecords = selectedRecs,
  }: {
    selectionCount: number;
    curColumns?: any[];
    selectedRecords: any[];
  }) => {
    const showBannerTooltips = uploadDetails?.action === 'filter';
    const getselectedVehicles = Object.values({ ...selectedRecords }).flat();
    const releaseCount = getselectedVehicles.filter(
      (x) => x.HoldStatus.props.children[1].props.children === 'In Evaluation',
    ).length;
    return selectionCount > 0 ? (
      <Stack direction={'row'}>
        {isvalidVIN && (
          <Button
            disabled={false}
            variant="tertiary"
            label={'Update Delay Timing'}
            onClick={() => {
              updateAticipatedDelayDate();
            }}
            sx={{ height: '40px', padding: '12px 16px', color: '#000', borderColor: '#000', marginRight: '16px' }}
          />
        )}
        {(releaseCount > 0 || (selectAll && metricsData?.readyForRelease && metricsData?.readyForRelease > 0)) && (
          <Button
            disabled={false}
            variant="tertiary"
            label={'Complete'}
            onClick={() => {
              releaseVehiclesCallback(getselectedVehicles);
            }}
            sx={{ height: '40px', padding: '12px 16px', color: '#000', borderColor: '#000' }}
          />
        )}{' '}
      </Stack>
    ) : (
      <Stack direction={'row'}>
        <ImportModal
          vinList={VinList}
          tableRows={tableRows}
          setNotification={handleNotification}
          loadUploadedFile={(data: ImportFileDetails) => {
            setUploadDetails({ ...data });
            setSelectedRecs([]);
            setSelectAll(false);
            if (data.action === 'filter') {
              getVehicleList(data.action, { documentId: data.documentId, documentName: data.fileName });
            } else {
              setShowGoCanvasModal(true);
            }
          }}
        />
        {/* <DownloadModal
          targetElement={
            <IconButton
              size="medium"
              name="FileDownloadOutlined"
              sx={{
                color: 'black',
              }}
            />
          }
          count={13541}
          filter={'applied filters'} // example:filters.appliedFilters
          viewBy={'View by'} //example: tableHeader.table.label
          sortBy={' Sort by'} // example:sortLabel
          handleDownload={() => {}} //handler function
        /> */}
        <CustomizedModal
          targetElement={
            <IconButton
              size="medium"
              name="ViewWeekOutlined"
              sx={{
                color: 'black',
              }}
            />
          }
          modalPlacement={'bottom-end'}
          tables={[]}
          selectedTable={{}}
          viewByModalTitle={'Table views allow you to group the data by different high-level categories'}
          columns={columns}
          currentColumns={curColumns?.filter((x) => !x.hide)}
          handleApplyColumns={handleCurrentColumnUpdate}
          handleApplyTable={() => {}}
          featureFlags={{
            isViewbyEnabled: false,
            isColumnsViewEnabled: true,
          }}
        />
      </Stack>
    );
  };

  const [isLoader, setIsLoader] = useState<boolean>(false);

  const handlePaginationUpdatedRows = (pagination: any) => {
    setDataTableData((prevData) => ({
      ...prevData,
      tableData: {
        ...prevData.tableData,
        loading: true,
      },
      pagination: { page: pagination.page, size: pagination.size },
    }));
  };

  const formatInlineFilters = (inlineFilters: any): any => {
    if (inlineFilters.length <= 0) {
      return [];
    }
    const result = inlineFilters.reduce((acc, { field, value, type, name }) => {
      acc[field] ??= {
        field,
        value: [],
        type,
        name,
      };
      if (Array.isArray(value)) {
        acc[field].value = acc[field].value.concat(value);
      } else {
        acc[field].value?.push(value);
      }
      return acc;
    }, {});

    return Object.values(result);
  };
  const handleReset = () => {
    setUploadDetails(null);
    setFilterArray([]);
    setSelectedRecs([]);
    setPageCount(1);

    props.handleTableUpdate({
      ...props.tableData,
      pagination: {
        page: 1,
        size: limitCount,
      },
      filters: [],
    });
    setDataTableData({
      ...dataTableData,
      tableHeader: {
        ...(dataTableData?.tableHeader || {}),
        viewlabel: <span style={{ fontFamily: 'ToyotaType-Regular' }}>0 Selected</span>,
        toolbarIcons: <ToolbarIcons selectionCount={0} curColumns={currentColumns} selectedRecords={{}} />,
      },
      selectedItems: [],
      filters: {
        appliedFilters: [],
        resetButton: getResetButton([]),
      },

      // selectedItems: [],
    });
  };

  useEffect(() => {
    let viewData = props.tableData;
    setSelectAll(viewData.selection?.selectAll || false);
    // let sortObj = viewData.orderBy||sorts[0];
    // sortObj = {...sortObj,
    // columnName: getKey(sortObj.field),
    // fieldName: columns.find(x=>x.field === sortObj.field)?.name||sorts[0].fieldName,
    // }
    // setSorts([sortObj]);
    setCurrentColumns([...(viewData.columns || currentColumns)].map((x) => ({ ...x, isPinned: x.pinned })));
    setDataTableData((data) => ({
      ...data,
      pagination: { ...(viewData.pagination || { page: 1, size: 25 }) },
      // sorts:[sortObj],
      columns: [...(viewData.columns || currentColumns)].map((x) => ({ ...x, isPinned: x.pinned })),
      filters: {
        appliedFilters: [...(viewData.filters || [])],
        resetButton: getResetButton([...(viewData.filters || [])]),
      },
      tableHeader: {
        ...data.tableHeader,
        // viewlabel: `Sorted by ${sortObj.fieldName}`,
        toolbarIcons: (
          <ToolbarIcons
            selectionCount={getSelectedItemsCount()}
            curColumns={[...(viewData.columns || currentColumns)]}
            selectedRecords={selectedRecs}
          />
        ),
      },
    }));
    setFilterArray(
      viewData.filters?.map((x) => ({
        ...x,
        columnName: getKey(x.field),
        fieldName: columns.find((y) => y.field === x.field)?.name || '',
      })) || [],
    );
    setPageCount(viewData.pagination?.page || 1);
    setLimitCount(viewData.pagination?.size || 25);
    setSelectedRecs(viewData.selection?.data || {});
  }, [props.tableData]);

  useEffect(() => {
    const rows = dataTableData?.tableData?.rowsData;
    if (rows?.length > 0) {
      const vins = rows.map((row, i) => {
        return row.VIN;
      });
      setVinList((prevVins) => {
        prevVins.push(...vins);
        return prevVins;
      });
      setTableRows((prevRows) => {
        prevRows.push(...rows);
        return prevRows;
      });
    }
  }, [dataTableData]);

  const getResetButton = (filterArray) => {
    return (
      <Button
        variant="tertiary"
        sx={{ padding: '0.375rem 1rem', alignItems: 'end', right: 0 }}
        onClick={handleReset}
        size="small"
        label="Clear Table Filters"
        disabled={filterArray.length > 0 ? false : true}
      />
    );
  };

  const DataTableSkeleton = {
    tableData: {
      rowsData: rows,
      dropDownOptions: options,
      loading: isLoader,
      reloading: false,
      count: `${totalCount}`,
    },
    columns: [...currentColumns].map((col, ind) => ({ ...col, index: ind })),
    tableHeader: {
      title: 'All Vehicles',
      sortLabel: sortLabel,
      viewlabel: (
        <span style={{ fontFamily: 'ToyotaType-Regular' }}>{formatNumber(getSelectedItemsCount())} Selected</span>
      ),
      tableCount: <span className="setTableFontFamily">{formatNumber(totalCount)} items</span>,
      toolbarIcons: <ToolbarIcons />,
      enableSelectedItemsBanner: false,
      table: {
        label: tablesMockData.find((i) => i.default === true)?.label || tablesMockData[0].label,
      },
    },
    filters: {
      appliedFilters: formatInlineFilters([
        ...filterArray,
        ...(uploadDetails?.action === 'filter'
          ? [
              {
                field: 'imported_file',
                name: uploadDetails?.fileName || 'File',
                value: ['IMPORTED LIST:' + (uploadDetails?.fileName || 'importedfile').toUpperCase()],
                type: String,
              },
            ]
          : []),
      ]),
      resetButton: getResetButton([
        ...filterArray.map((x) => x.field),
        ...(uploadDetails?.action === 'filter' ? ['import'] : []),
      ]),
    },
    inlineFilterColumns: columns,
    inlineFilterValues: {},
    sorts: sorts,
    selectedItems: [...handleSelectInversion(selectedRecs[pageCount] || [])],
    pagination: { page: pageCount, size: limitCount },
    checkboxEnabled: true,
    getSelectedRowBarIcons: selectedRowBarIcons(),
    viewByModalTitle: 'Table views allow you to group the data by different high-level categories',
    viewByModalData: tablesMockData,
    toastNotification: {},
  };

  const VHColumnList = [
    'Series',
    'Location',
    'Region',
    'CurrentLocation',
    'DestCountry',
    'MYSpec',
    'AssemblySequence',
    'SoldSequence',
  ];

  let opDetails = (input: string) => {
    let sym = '';
    if (
      input.match('<=') !== null ||
      input.match('>=') !== null ||
      input.match('!=') !== null ||
      input.match('=') !== null
    ) {
      sym = '=';
    } else if (input.match('>') !== null) {
      sym = '>';
    } else if (input.match('<') !== null) {
      sym = '<';
    }
    let res = sym === '' ? [input] : input.split(sym);

    return {
      op: {
        lt: res.length === 2 && res[0].length < 1 && sym === '<',
        gt: res.length === 2 && res[0].length < 1 && sym === '>',
        eq: res.length === 2 && res[0].length < 1 && sym === '=',
        ne: res.length === 2 && res[0] === '!',
        gte: res.length === 2 && res[0] === '>',
        lte: res.length === 2 && res[0] === '<',
      },
      input: res.length > 1 ? res[1] : res[0],
    };
  };
  const getHoldStatus = (matchValue: string) => {
    let resp = [];
    const holdStatuses = {
      pending: 1,
      'pending approval': 1,
      'awaiting approval': 1,
      san: 11,
      'on hold': [2, 7],
      'in evaluation': 3,
      completed: [4, 8],
      invalid: 5,
      'notify cqs': 6,
      shipped: 6,
      'in progress': 7,
      'complete in progress': [4, 8],
      'delay date update in progress': 9,
    };
    Object.keys(holdStatuses).forEach((x) => {
      let str1 = x.replaceAll(' ', '');
      matchValue.forEach((y) => {
        let str2 = y.toLowerCase().replaceAll(' ', '');
        if (str1.match(str2) && !resp.find((z) => z === holdStatuses[x])) {
          resp.push(holdStatuses[x]);
        }
      });
    });
    return resp;
  };

  const getFilterId = async (docId) => {
    let _cascadingFilters = cascadingFilters;

    // Object.entries(cascadingFilters)?.forEach(([key, value]) => {
    //     let formattedKey = getKey(key);
    //     if (formattedKey !== ""){
    //         _cascadingFilters[key] = value;
    //     }
    // })

    if (filterArray.length <= 0 && Object.keys(_cascadingFilters)?.length === 0) {
      return 'NA';
    }
    const url = `${getEnv().API_BASE_URL}/hold/QChold/vehicles/filter?qcnumber=${props.holdId}${
      !!docId ? `&documentId=${docId}` : ''
    }`;
    const config = {
      headers: {
        'x-api-key': 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
        Authorization: auth,
      },
    };

    let filterObject = {};
    if (Object.keys(_cascadingFilters).length > 0) {
      filterObject = { ...filterObject, ..._cascadingFilters };
    }
    filterArray?.forEach((x) => {
      if (x.field === 'HoldStatus') {
        let opDetail = opDetails(x.value[0]);
        let op = opDetail.op.ne ? 'notIn' : 'in';
        let queryValue = getHoldStatus(
          (
            (opDetail.op.gt ? '>' : opDetail.op.lt ? '<' : opDetail.op.gte ? '>=' : opDetail.op.lte ? '<=' : '') +
            opDetail.input
          ).split(','),
        );
        filterObject = { ...filterObject, [x.columnName]: { [op]: [...queryValue].flat() } };
      } else {
        let op = [...VHColumnList.map((x) => getKey(x)), '', 'AnticipatedDelayDate', 'submittedOn', 'Age'].includes(
          x.columnName,
        )
          ? 'in'
          : 'like';
        let opDetail = opDetails(x.value[0]);
        let query =
          opDetail.input.split(',').length > 1
            ? { in: [...opDetail.input.split(',').map((x) => x.trim())] }
            : { [op]: [opDetail.input] };
        if (opDetail.op.gt) {
          query = { gt: [opDetail.input] };
        } else if (opDetail.op.lt) {
          query = { lt: [opDetail.input] };
        } else if (opDetail.op.gte) {
          query = { gte: [opDetail.input] };
        } else if (opDetail.op.lte) {
          query = { lte: [opDetail.input] };
        } else if (opDetail.op.eq) {
          query = { eq: [opDetail.input] };
        } else if (opDetail.op.ne) {
          query = { notIn: [opDetail.input] };
        }
        filterObject = { ...filterObject, [x.columnName]: query };
      }
    });

    const requestBody = {
      sortingFilter: {
        filter: {
          ...filterObject,
        },
      },
    };

    try {
      const response = await axios.post(url, requestBody, config);
      setFilterID(response?.data?.data?.filterId);
      // setSelectedRecs([]);

      // setDataTableData({
      //     ...dataTableData,
      //     tableHeader: {
      //         ...(dataTableData?.tableHeader || {}),
      //         viewlabel: <span style={{ fontFamily: "ToyotaType-Regular" }}>0 Selected</span>,
      //         toolbarIcons: () => { return toolbarIcons(0, currentColumns) },
      //     },
      //     selectedItems: [],
      //     // selectedItems: [],
      // });
      // setDataTableData(prevData => ({
      //     ...prevData,
      //     tableData: {
      //         ...prevData.tableData,
      //         // loading: true
      //     },
      //     selectedItems: [],
      //     tableHeader: {
      //         ...prevData.tableHeader,
      //         enableSelectedItemsBanner: false,
      //     }
      // }));
      return response.data.data.filterId;
    } catch (error) {
      return 'ER';
    }
  };

  const getVehicleList = async (
    action?: string = '',
    actionObject?: { documentId: string; documentName: string } | Object = {},
  ) => {
    setIsLoader(true);

    setDataTableData((prevData) => ({
      ...prevData,
      tableData: {
        ...prevData.tableData,
        reloading: true,
      },
    }));

    let filterId = await getFilterId(actionObject?.documentId || uploadDetails?.documentId || '');

    // no filter id case
    if (!!!filterId) {
      handleNotification({ fieldName: 'success', field: 'No vehicles found.' });
      setIsLoader(false);
      return;
    }
    //error case
    else if (filterId === 'ER') {
      handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
      setIsLoader(false);
      return;
    }
    // Not applicable case
    else if (filterId === 'NA') {
      filterId = '';
    } else if (filterId === 'unsupported') {
      handleNotification({ fieldName: 'error', field: 'Filter condition/operator not supported for column.' });
      setIsLoader(false);
      return;
    }

    const url = `${getEnv().GRAPHQL_BASE_URL}`;
    const config = {
      headers: {
        'x-api-key': 'da2-cbu55ahpjnhd5as47wv3dctcaa',
        Authorization: auth,
      },
    };

    const queryFilter =
      (((action === 'filter' && !!actionObject?.documentId) ||
        (uploadDetails?.action === 'filter' && !!uploadDetails?.fileName)) &&
      !!!filterId
        ? ' documentId:' + (actionObject.documentId || uploadDetails?.documentId)
        : '') + (!!filterId ? ' filterId:' + filterId : '');

    const requestBody = {
      query: `query MyQuery {
    getVehiclesWithHoldRequest(limit: ${limitCount}, offset: ${offsetCount}, where: {qcNumber: \"${props.holdId}\" ${queryFilter}}) {
        finalCount
        finalDetails {
        AssemblySequence
        DestinationCountry
        GroupName
        HoldStatus
        MySpec
        SoldSequence
        DelayDate
        Age
        lastTransactionStatus
        manufacturingLocation {
            plantDescription
            plantShortDescription
        }
        SubmittedOn
        modelCode
        modelDescription
        modelYear
        order {
            katashikiDescription
        }
        relatedDealers {
            currentDealer {
            currentRegionCode
            currentRegionName
            }
        }
        retailSales {
            rdrDate
        }
        salesSeriesName
        vehicleDates {
            actualBuildDate
            actualLineOffDate
        }
        vehicleEvent {
            lifecycleSubstatusDescription
            vehicleLifeCycleStatus
        }
        vin,
        urn
        }
    }
    }`,
    };

    try {
      const response = await axios.post(url, requestBody, config);
      if (!response.data?.data?.getVehiclesWithHoldRequest?.finalDetails) {
        throw new Error('Something went wrong. Please try again later.');
      }
      setTotalCount(response.data?.data?.getVehiclesWithHoldRequest?.finalCount);
      setDataTableData((prev) => ({
        ...prev,
        tableData: {
          ...prev.tableData,
          loading: false,
          reloading: false,
          rowsData: formatRowData([...(response?.data?.data?.getVehiclesWithHoldRequest?.finalDetails || [])]),
          count: `${response.data?.data?.getVehiclesWithHoldRequest?.finalCount}`,
        },
        tableHeader: {
          ...prev.tableHeader,
          viewlabel: (
            <span style={{ fontFamily: 'ToyotaType-Regular' }}>{formatNumber(getSelectedItemsCount())} Selected</span>
          ),
          // sortLabel: sortLabel,
          tableCount: (
            <span className="setTableFontFamily">
              {formatNumber(response.data?.data?.getVehiclesWithHoldRequest?.finalCount)} items
            </span>
          ),
        },
        inlineFilterValues: {},
        filters: {
          appliedFilters: formatInlineFilters([
            ...(action === 'filter' || uploadDetails?.action === 'filter'
              ? [
                  {
                    field: 'imported_file',
                    name: 'File',
                    value: [
                      'IMPORTED LIST:' +
                        (actionObject?.documentName || uploadDetails?.fileName || 'importedfile').toUpperCase(),
                    ],
                    type: String,
                  },
                ]
              : []),
            ...filterArray.map((x) => ({ ...x, type: String })),
          ]),
          resetButton: getResetButton([
            ...filterArray.map((x) => x.field),
            ...(action === 'filter' || uploadDetails?.action === 'filter' ? ['imported_file'] : []),
          ]),
        },
        // sorts: sorts,
        selectedItems: [
          ...handleSelectInversion(
            selectedRecs[pageCount] || [],
            formatRowData([...(response.data?.data?.getVehiclesWithHoldRequest?.finalDetails || [])]),
          ),
        ],
        pagination: { page: pageCount, size: limitCount },
      }));
      props.tableUpdated('1');
    } catch (error) {
      handleNotification({ fieldName: 'error', field: 'Please try again after sometime.' });
      setIsLoader(false);
    }
  };

  const releaseVehicles = async (documentId?: string = '', vehicles?: Object[] = []) => {
    setIsLoader(true);

    const url = `${getEnv().API_BASE_URL}/hold/complete/vehicle`;

    const config = {
      headers: {
        'x-api-key': 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
        Authorization: auth,
      },
    };

    let vinList = [];
    vehicles.forEach((vehicleInfo) => {
      vinList = [...vinList, vehicleInfo?.VIN];
    });

    const requestBody = {
      sessionId: sessionId || '',
      documentID: documentId,
      qcNumber: props.holdId,
      userId: userData?.employeeId,
      vehicles: vinList,
      condition: selectAll === true ? 'ALL' : 'NONE',
    };

    try {
      const response = await axios.post(url, requestBody, config);
      if (response?.data?.data?.statusCode?.toString() === '201') {
        handleNotification({ fieldName: 'success', field: 'Vehicle complete in progress.' });
        setUploadDetails(null);
        setSelectedRecs([]);
        setSelectAll(false);
        reloadData();
      } else {
        throw new Error('Failed to release vehicles.');
      }
    } catch (error) {
      handleNotification({ fieldName: 'error', field: 'Failed to release vehicles.' });
    }
  };

  const releaseVehiclesCallback = (vehicles: Object[]) => {
    releaseVehicles(uploadDetails?.documentId, vehicles);
  };

  const handleGoCanvasResponse = (data: any) => {
    setShowGoCanvasModal(false);
    setUploadGoCanvasfile(true);
    reloadData();
  };

  //To resize ,pin , hide, unhide and re-arrange columns
  const handleCurrentColumnUpdate = (newData: any, pinOrHide?: boolean, reArrange?: boolean = false) => {
    if (pinOrHide && !reArrange) {
      newData = [...newData].sort((a, b) => {
        return !b.pinned ? a.index - b.index : 0;
      });
    } else {
      let index = 0;
      let pinnedColumnIndexes = [...newData].filter((x) => x.pinned).map((x) => x.index);
      while (pinnedColumnIndexes.includes(index)) {
        index = index + 1;
      }
      newData = [...newData].map((col) => {
        let ind = col.index;
        if (!pinnedColumnIndexes.includes(ind)) {
          ind = index;
          index = index + 1;
          while (pinnedColumnIndexes.includes(index)) {
            index = index + 1;
          }
        }
        return { ...col, index: ind };
      });
    }
    props.handleTableUpdate({
      ...props.tableData,
      columns: [...newData.map((x) => ({ ...x, isPinned: x.pinned }))],
    });
    setDataTableData((prev) => ({
      ...prev,
      tableHeader: {
        ...prev.tableHeader,
        toolbarIcons: <ToolbarIcons curColumns={newData.map((x) => ({ ...x, isPinned: x.pinned }))} />,
      },
      columns: [...newData.map((x) => ({ ...x, isPinned: x.pinned }))],
    }));
  };
  const handleDataTableUpdate = (newData: any) => {
    setDataTableData({
      ...dataTableData,
    });
  };

  const setPagination = (data: any) => {
    const pageSize = data.size;
    const newPage = data.page;
    const currentOffset = offsetCount as number;

    let newOffsetCount = currentOffset;

    if (newPage > pageCount) {
      newOffsetCount = pageSize * (newPage - 1);
    } else if (newPage === 1) {
      newOffsetCount = 0;
    } else if (newPage < pageCount) {
      newOffsetCount = Math.max(currentOffset - pageSize, 0);
    }
    props.handleTableUpdate({
      ...props.tableData,
      pagination: {
        page: newPage,
        size: pageSize,
      },
    });
    setOffsetCount(newOffsetCount);
    setLimitCount(pageSize);
    setPageCount(newPage);
  };

  const handleApplyTable = () => {
    setDataTableData({
      ...dataTableData,
    });
  };

  const handleDeleteFilter = (field: any) => {
    if (field === 'imported_file') {
      setUploadDetails(null);
    }

    let newFilterObj = [...filterArray]?.filter((x) => x.field !== field);
    setFilterArray([...newFilterObj]);
    props.handleTableUpdate({
      ...props.tableData,
      pagination: {
        page: 1,
        size: limitCount,
      },
      filters: [...newFilterObj],
    });
    setPageCount(1);
  };

  function handleApplyFilter(filtersobject: Filter): any {
    let opDetail = opDetails(filtersobject.value[0]);
    if (
      [
        'LifecycleStatus',
        'ActualLineOffDate',
        'SoldDate',
        'Katashiki',
        'LifecycleSubStatus',
        'URN',
        'lastTransactionStatus',
        'ModelYear',
      ].includes(filtersobject.field)
    ) {
      handleNotification({ fieldName: 'error', field: 'This column does not support filtering.' });
      return;
    } else if (
      VHColumnList.includes(filtersobject.field) &&
      (opDetail.op.gt || opDetail.op.lt || opDetail.op.gte || opDetail.op.lte || opDetail.op.ne)
    ) {
      handleNotification({
        fieldName: 'error',
        field: 'Column filters/operations not supported by column ' + filtersobject.field + '.',
      });
      return;
    }
    let fieldName = columns.find((x) => x.field === filtersobject.field)?.name || '';
    let tableFilterArray = dataTableData.filters.appliedFilters.map((x) => x.field);
    handleNotification({
      fieldName: 'success',
      field: `Filter applied to ${fieldName} .`,
    });
    let newArray = [
      ...filterArray.filter((x) => tableFilterArray.includes(x.field)).filter((x) => x.field !== filtersobject.field),
      {
        ...filtersobject,
        columnName: getKey(filtersobject.field),
        fieldName: fieldName,
        value: [filtersobject.value[0].toUpperCase()],
      },
    ];
    props.handleTableUpdate({
      ...props.tableData,
      pagination: {
        page: 1,
        size: limitCount,
      },
      filters: [...newArray],
    });
    setPageCount(1);
    setOffsetCount(0);
    setSelectedRecs([]);
    setSelectAll(false);
    setFilterArray([...newArray]);
  }

  function search(rowData: any[], searchItem: any) {
    return rowData?.filter((row: any) => {
      // Check each searchItem
      return (
        searchItem.appliedFilters &&
        searchItem?.appliedFilters?.every((item: any) => {
          // Get the value to search for
          const value = item.value[0];
          // Check if the value exists in the row for the specified field
          return row[item.field].includes(value);
        })
      );
    });
  }

  const handleFilteredRows = (data: any) => {
    const searchResult = search(rows, data);
    setDataTableData({
      ...dataTableData,
      tableData: {
        ...dataTableData.tableData,
        rowsData: searchResult,
      },
      tableHeader: {
        ...dataTableData.tableHeader,
        tableCount: <span className="setTableFontFamily">{formatNumber(totalCount)} items</span>,
      },
    });
  };

  const handleApplyCustomizeColumns = (customizedColumns: any[]) => {
    setDataTableData({
      ...dataTableData,
      currentColumns: customizedColumns,
    });
  };

  useEffect(() => {
    if (props?.selectedKPIFilter?.value) {
      setUploadDetails(null);
      resetSelection(false, true);
      props.handleTableUpdate({
        ...props.tableData,
        filters: [
          ...(filterArray?.filter((x) => x.field !== 'HoldStatus') || []),
          {
            field: 'HoldStatus',
            fieldName: 'Hold Status',
            value: [props.selectedKPIFilter.label],
            type: String,
            columnName: getKey('HoldStatus'),
          },
        ],
      });
      setFilterArray((filterArr) => {
        return [
          ...(filterArr?.filter((x) => x.field !== 'HoldStatus') || []),
          {
            field: 'HoldStatus',
            fieldName: 'Hold Status',
            value: [props.selectedKPIFilter.label],
            type: String,
            columnName: getKey('HoldStatus'),
          },
        ];
      });
      setPageCount(1);
    }
  }, [props.selectedKPIFilter]);

  useEffect(() => {
    if (!!props.holdId) {
      if (uploadDetails && uploadDetails.action === 'filter') {
        getVehicleList(uploadDetails.action, {
          documentId: uploadDetails.documentId,
          documentName: uploadDetails.fileName,
        });
      } else {
        getVehicleList();
      }
    }
  }, [limitCount, pageCount, filterArray]);

  useEffect(() => {
    setDataTableData((data) => ({
      ...data,
      tableData: {
        ...data.tableData,
        reloading: isLoader,
      },
    }));
  }, [isLoader]);

  const reloadData = () => {
    props.vehicleAction();
  };

  const tableReload = (initLoad: boolean = false) => {
    setSelectedRecs([]);
    setUploadDetails(null);
    resetSelection(false, true);
    setDataTableData((prevData) => ({
      ...prevData,
      tableData: {
        ...prevData.tableData,
        loading: initLoad,
        reloading: !initLoad,
      },
      selectedItems: [],
      tableHeader: {
        ...prevData.tableHeader,
        enableSelectedItemsBanner: false,
      },
    }));
    getVehicleList();
  };

  useEffect(() => {
    tableReload(props.refresh <= 0);
  }, [props.refresh]);

  const getGlobalFilterData = async () => {
    try {
      const url = 'https://hnkw7xowyk.execute-api.us-west-2.amazonaws.com/dev/pagefilter';
      const auth = JSON.parse(localStorage.getItem('Root-LoginSate'))?.idToken;
      const config = {
        headers: {
          Authorization: auth,
        },
      };

      const result = await axios.get(url, config);
      if (result?.status === 200) dispatch(setPageFilterData(result?.data));
    } catch (error) {}
  };

  useEffect(() => {
    setDataTableData({
      ...DataTableSkeleton,
      pagination: { ...(props.tableData.pagination || { page: 1, size: 25 }) },
      // sorts:[sortObj],
      columns: [...(props.tableData.columns || currentColumns)].map((x) => ({ ...x, isPinned: x.pinned })),
      filters: {
        appliedFilters: [...(props.tableData.filters || [])],
        resetButton: getResetButton([...(props.tableData.filters || [])]),
      },
      // selection???
    });
    getGlobalFilterData();
  }, []);

  useEffect(() => {
    if (cascadingFilters && Object.keys(cascadingFilters)?.length > 0) {
      getVehicleList();
    }
  }, [cascadingFilters]);

  const handleModalCancel = () => {
    setShowVehicleDelayModal(false);
  };

  const updateAticipatedDelayDate = () => {
    setShowVehicleDelayModal(true);
  };

  const updateAticipatedDelayDateEvent = async () => {
    setConfirmButtonDisable(true);
    setDelayDateCall('2');
    if (upDateDelayPaylod?.vehicles.length == 0 && upDateDelayPaylod?.condition === 'NONE') {
      handleNotification({
        fieldName: 'error',
        field: 'Vehicles delay timing could not be updated. Please try again.',
      });
      setConfirmButtonDisable(false);
      setDelayDateCall('1');
    } else {
      const UpdateDelayDateRes = await VehiclesServices.UpdateDelayDate(auth, upDateDelayPaylod);
      if (UpdateDelayDateRes.status === 'success') {
        setShowVehicleDelayModal(false);
        //setUpdateDelayDate(true);
        setDateChangesNotes('');
        setLetterCounts(0);
        setAnticipatedDelayTiming(null);
        handleNotification({ fieldName: 'success', field: 'Vehicles delay timing updated.' });
        reloadData();
      } else {
        setShowVehicleDelayModal(false);
        //setUpdateDelayDate(true);
        setDateChangesNotes('');
        setLetterCounts(0);
        setAnticipatedDelayTiming(null);
        handleNotification({
          fieldName: 'error',
          field: 'Vehicles delay timing could not be updated. Please try again.',
        });
      }
      setConfirmButtonDisable(false);
      setDelayDateCall('1');
    }
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let notes = event.target.value;
    if (notes.length <= 150) {
      setLetterCounts(notes.length);
      setDateChangesNotes(notes);
    }
  };

  const handleupdatemodalOpen = (value: boolean) => {
    setopendatecalendar(value);
  };

  const handleAnticipatedDelayTiming = (date: Dayjs | null) => {
    const updatedDate = dayjs(dayjs(date).format('MM/DD/YYYY'));
    const todaysDate = dayjs(dayjs().format('MM/DD/YYYY'));
    if (updatedDate.isAfter(todaysDate) || updatedDate.isSame(todaysDate)) {
      setConfirmButtonDisable(false);
    } else {
      setConfirmButtonDisable(true);
    }
    if (date) {
      setAnticipatedDelayTiming(date);
    }
  };

  return (
    <>
      <Modal
        handleCancel={handleModalCancel}
        label={
          <div>
            Update Delay Timing{' '}
            <p
              style={{
                marginTop: '8px',
                marginBottom: '0px',
                fontSize: '12px',
                color: '#58595b',
                lineHeight: '16px',
                fontFamily: 'ToyotaType-Regular',
              }}
            >
              {' '}
              {totalVehicleSelected} Vehicles Selected
            </p>
          </div>
        }
        openModal={showVehicleDelayModal}
        modalStyles={uploadDocStyle}
        optionalLabel={''}
        footerOptionalLabel={''}
        variant={'Transactional modal'}
        bodyText={
          // <><div style={{ paddingTop: "35px", paddingBottom: "35px" }}><LoadingSpinner variant={'loading-spinner'} /></div></>
          <>
            {delayDateCall === '1' ? (
              <StyledGridRow container sx={{ paddingBottom: '0px' }}>
                <StyledGridColumn
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ width: 'inherit', paddingTop: '0px', paddingBottom: '28px' }}
                >
                  <TypoGraphy style={{ fontSize: '14px', color: '#000' }}>
                    Anticipated delay date can be updated for vehicles on hold and pending review.
                  </TypoGraphy>
                </StyledGridColumn>

                <StyledGridColumn
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ width: 'inherit', paddingTop: '0px', paddingBottom: '28px' }}
                >
                  <TypoGraphy style={{ fontSize: '14px' }}>
                    Current Delay Timing: <span style={{ color: '#000' }}>{currentDelayTiming}</span>
                  </TypoGraphy>
                </StyledGridColumn>

                <StyledGridColumn
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ width: 'inherit', paddingTop: '0px', paddingBottom: '4px' }}
                >
                  {/* <TypoGraphy>Revised Delay Date </TypoGraphy> */}
                  <TypoGraphy2>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <StyledDatePicker >
                                        <DatePicker
                                            className="datepicker"
                                            onChange={handleAnticipatedDelayTiming}
                                            value={anticipatedDelayTiming}
                                            disablePastDays={true}
                                            slots={{
                                                textField: (params) => <TextField {...params} size="small" />,
                                            }}
                                            sx={{ height: "40px" }}
                                        />
                                    </StyledDatePicker>
                                </LocalizationProvider> */}
                    <DatePicker
                      open={opendatecalendar}
                      minWidth="320px"
                      datePickerLabel={
                        <span style={{ fontFamily: 'ToyotaType-Regular', lineHeight: '16px', marginBottom: '8px' }}>
                          Revised Delay Date
                        </span>
                      }
                      value={anticipatedDelayTiming}
                      disablePastDays={true}
                      handleOpen={handleupdatemodalOpen}
                      handleChange={handleAnticipatedDelayTiming}
                      placeHolderText="Enter Revised Delay Date"
                      sx={{
                        '& .MuiPickersCalendarHeader-labelContainer': {
                          justifyContent: 'center',
                        },
                        '& .MuiOutlinedInput-root.MuiInputBase-colorPrimary': {
                          fontFamily: 'ToyotaType-Regular',
                          fontSize: '14px',
                        },
                      }}
                    />
                  </TypoGraphy2>
                </StyledGridColumn>

                <StyledGridColumn
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ width: 'inherit', paddingTop: '28px', gap: '0rem', paddingBottom: '32px' }}
                >
                  <TypoGraphy style={{ width: '100%' }}>
                    Notes <span style={{ float: 'inline-end' }}>{letterCounts}/150</span>
                  </TypoGraphy>
                  <TypoGraphy2>
                    <TextInput
                      multiline={true}
                      label=""
                      sx={{
                        width: '652px',
                        '.MuiInputBase-formControl.MuiInputBase-multiline': { paddingTop: '0px', paddingBottom: '0px' },
                        '& .MuiInputBase-input': { padding: '12px 12px' },
                        '& .MuiOutlinedInput-root.MuiInputBase-colorPrimary': {
                          fontFamily: 'ToyotaType-Regular',
                          fontSize: '14px',
                          color: '#58595B',
                        },
                      }}
                      value={dateChangesNotes}
                      placeholder="Enter Notes"
                      onChange={handleNotesChange}
                    />
                  </TypoGraphy2>
                </StyledGridColumn>
              </StyledGridRow>
            ) : (
              <div style={{ paddingTop: '35px', paddingBottom: '35px' }}>
                <LoadingSpinner variant={'loading-spinner'} />
              </div>
            )}
          </>
        }
        actionButtons={
          <>
            <Button
              sx={{ marginRight: '16px', height: '40px', fontFamily: "'ToyotaType-Regular', sans-serif" }}
              variant="tertiary"
              label={'Cancel'}
              onClick={() => {
                handleModalCancel();
              }}
            />
            <Button
              disabled={confirmButtonDisable}
              variant="primary"
              label={'Confirm'}
              onClick={() => {
                updateAticipatedDelayDateEvent();
              }}
            />
          </>
        }
        slotComponent={''}
        modalButton={undefined}
        handleClose={handleModalCancel}
        sx={{ '& .MuiTypography-h1': { marginTop: '0px' } }}
      ></Modal>

      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        sx={{ marginTop: '16px', fontFamily: 'ToyotaType-Regular' }}
        className="dataTableGrid"
      >
        {Object.keys(dataTableData).length > 0 && (
          <DataTableView
            {...dataTableData}
            handleCurrentColumnUpdate={(columns) => {
              let currentPinCount = columns.filter((x) => x.isPinned).length;
              let incomingPinCount = columns.filter((x) => x.pinned).length;
              let pinning = currentPinCount !== incomingPinCount;
              handleCurrentColumnUpdate(columns, pinning, !pinning);
            }}
            handleDataTableUpdate={handleDataTableUpdate}
            handlePaginationUpdatedRows={handlePaginationUpdatedRows}
            handleApplyTable={handleApplyTable}
            handleReset={handleReset}
            handleFilteredRows={handleFilteredRows}
            handleApplyFilter={handleApplyFilter}
            handleDeleteFilter={handleDeleteFilter}
            handlePagination={setPagination}
            handleRowCellClick={handleRowCellClick}
            handleSelectedItemUpdate={handleSelectedItemUpdate}
            handleCurrentSortUpdate={() => {}}
            // handleCurrentSortUpdate={handleCurrentSortUpdate}
            handleApplyCustomizeColumns={handleApplyCustomizeColumns}
          />
        )}
      </Grid>
      {showGoCanvasModal && (
        <GoCanvasModal
          setNotification={handleNotification}
          qcNumber={props.holdId}
          documentId={uploadDetails.documentId}
          setShowImport={setShowGoCanvasModal}
          loadUploadedFile={handleGoCanvasResponse}
          action={uploadDetails.action}
          fileName={uploadDetails.fileName}
        />
      )}

      <Menu
        anchorEl={selectAllAnchorEl}
        variant="singleSelect"
        targetElement={<></>}
        optionsList={selectAllMenu}
        handleClose={() => {
          setSelectAllAnchorEl(null);
        }}
        onActionSelect={selectAllActionHandler}
      />
    </>
  );
}

export default VehicleDataTable;
