import React, { useEffect } from 'react';
// import { useMsal } from '@azure/msal-react'
// import { getMSToken } from '../services/auth-actions'
import '../App.scss';
import AllHoldRequest from '../Components/AllHoldRequest/AllHoldRequest';

export const HoldManagement = (props) => {
  useEffect(() => {
    props.ShowSidebar(true);
  }, []);

  return <AllHoldRequest />;
};

export default HoldManagement;
