import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface CustomDrawerState {
  open: boolean;
}

// Define the initial state using that type
const initialState: CustomDrawerState = {
  open: false,
};

// `createSlice` will infer the state type from the `initialState` argument
export const customDrawerSlice = createSlice({
  name: 'customDrawer',
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setDrawerOpen } = customDrawerSlice.actions;

export default customDrawerSlice.reducer;
