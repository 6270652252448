import { DataTableView } from 'cubee2e-cube-global-components';
import React, { useEffect } from 'react';
import { getDataTable, getLoading, getReloading, getTotalCount, useAppSelector } from '../../../store';
import { DataTableShape } from '../../../types';
import ToolbarIcons from './Toolbar';

export const AllHoldRequestDataTable = (props: DataTableShape.DataTableViewProps) => {
  const {
    handleRowCellClick,
    handlePagination,
    handleReset,
    handleSelectedItemUpdate,
    handleCurrentColumnUpdate,
    handleApplyFilter,
    handleCurrentSortUpdate,
    handleDeleteFilter,
  } = props;
  const totalCount = useAppSelector(getTotalCount);
  const loading = useAppSelector(getLoading);
  const reload = useAppSelector(getReloading);

  const { columns, filters, pagination, sorts, tableData, selectedItems, inlineFilterValues, tableHeader } =
    useAppSelector(getDataTable);
  const currentColumns = columns?.map((column) => ({ ...column })); // Deep copy each column object
  useEffect(() => {}, [filters]);
  return (
    <DataTableView
      emptyDataPlaceholder={'No rows found'}
      handlePagination={handlePagination}
      handleReset={handleReset}
      filters={{ appliedFilters: filters }}
      columns={currentColumns}
      sorts={sorts}
      pagination={pagination}
      tableData={{ ...tableData, loading: loading, reloading: reload }}
      selectedItems={selectedItems}
      inlineFilterValues={inlineFilterValues}
      handleRowCellClick={handleRowCellClick}
      handleSelectedItemUpdate={handleSelectedItemUpdate}
      handleCurrentColumnUpdate={handleCurrentColumnUpdate}
      handleCurrentSortUpdate={handleCurrentSortUpdate}
      handleApplyFilter={handleApplyFilter}
      handleDeleteFilter={handleDeleteFilter}
      tableHeader={{
        ...tableHeader,
        tableCount: `${totalCount} items`,
        toolbarIcons: <ToolbarIcons />,
      }}
      sx={{
        position: 'sticky',
        top: '12rem', // Adjust based on header height
        zIndex: 998,
        background: '#fff', // Background for sticky effect
        padding: '0 !important',
        marginRight: '24px',
        minWidth: 0,
        '@media (min-width: 1200px)': {
          minWidth: 0,
          maxWidth: 'none !important',
        },
      }}
    />
  );
};
