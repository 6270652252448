import { RootState } from '../../store';

export const getHoldReqNumber = (state: RootState) => state.holdTypeDetails.requestState.holdRequestNumber;
export const getHoldStatus = (state: RootState) => state.holdTypeDetails.holdStatus;
export const getSubmissionDate = (state: RootState) => state.holdTypeDetails.createdDateTime;
export const getCreatedUserData = (state: RootState) => state.holdTypeDetails.createdUserData;
export const getFetchingDetailsData = (state: RootState) => state.holdTypeDetails.requestState.loading;
export const getDraft = (state: RootState) => state.holdTypeDetails.requestState.isDraft;
export const getEditPanel = (state: RootState) => state.holdTypeDetails.requestState.editPanel;
export const getEdit = (state: RootState) => state.holdTypeDetails.requestState.edit;
export const getPendingApprovalType = (state: RootState) => state.holdTypeDetails.pendingApprovalType;
export const getUpdatedUserID  = (state: RootState) => state.holdTypeDetails.updatedUserId;
export const getCommentsList = (state: RootState) => state.holdTypeDetails.requestState.commentList;
export const getDocumentId = (state: RootState) => state.holdTypeDetails.requestState.documentId;
export const getHoldType = (state: RootState) => state.holdTypeDetails.holdType;
export const getCreateHoldType = (state: RootState) => state.holdTypeDetails.requestState.createHoldType;
export const getIssueDetails = (state: RootState) => state.holdTypeDetails.issueDetails;
export const getIssueResolution = (state: RootState) => state.holdTypeDetails.issueResolution;
export const getToyotaInternalDetails = (state: RootState) => state.holdTypeDetails.toyotaInternalDetails;
export const getModelSpecifications = (state: RootState) => state.holdTypeDetails.modelSpecification;
export const getModelSpecVehicles = (state: RootState) => state.holdTypeDetails.modelSpecification.vehicleDtl;
export const getFileAttachments = (state: RootState) => state.holdTypeDetails.fileAttachments;
export const getRequesterApprovals = (state: RootState) => state.holdTypeDetails.requesterApprovals;
export const getParts = (state: RootState) => state.holdTypeDetails.parts;
export const  getHoldDetails= (state: RootState) => state.holdTypeDetails.holdDetails;
export const getHoldRequestDetails = (state: RootState) => state.holdTypeDetails;

//dropdown selectors
export const getPDCAssigneeDropdownOptions = (state: RootState) =>
  state.holdTypeDetails.dropdownOptions.pdcAssigneeDropdownOptions;
export const getRiskRankDropdownOptions = (state: RootState) => state.holdTypeDetails.dropdownOptions.riskRankOptions;
export const getRequestingDivisionOptions = (state: RootState) =>
  state.holdTypeDetails.dropdownOptions.requestingDivisionOptions;
export const getDefectCategoryOptions = (state: RootState) =>
  state.holdTypeDetails.dropdownOptions.defectCategoryOptions;
export const getRootCauseOptions = (state: RootState) => state.holdTypeDetails.dropdownOptions.rootCauseOptions;
export const getCompanyResponsibleOptions = (state: RootState) =>
  state.holdTypeDetails.dropdownOptions.companyResponsibleOptions;
export const getSupplierResponsibleOptions = (state: RootState) =>
  state.holdTypeDetails.dropdownOptions.supplierResponsibleOptions;
export const getStartDateTypeOptions = (state: RootState) => state.holdTypeDetails.dropdownOptions.startDateTypeOptions;
export const getSeriesDropdownOptions = (state: RootState) =>
  state.holdTypeDetails.dropdownOptions.seriesDropdownOptions;
export const getModelDropdownOptions = (state: RootState) => state.holdTypeDetails.dropdownOptions.modelDropdownOptions;
export const getYardToPDCState  = (state: RootState) => state.holdTypeDetails.yardToPDC;