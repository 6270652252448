const env = {
  clientId: '8337ae83-e09b-416c-9ff7-cb748a713b11',
  authority: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
  navigateToLoginRequestUrl: false,
  redirectUri: 'https://one.vmgmt.cubeqa.toyota.com',
  API_BASE_URL: 'https://api.hm.cubeqa.toyota.com',
  DOCUMENT_URL: 'https://docs.hm.cubeqa.toyota.com',
  GRAPHQL_BASE_URL: 'https://graph.hm.cubeqa.toyota.com/graphql',
  POLICY_URL: 'https://api.hm.cubeqa.toyota.com/v1/roleconsumption/policy',
  apiKey: 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
};

export const qaEnv = env;
