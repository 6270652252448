import { Typography } from '@mui/material';
import { Button, Modal } from 'cubee2e-cube-global-components';
import React from 'react';
import { deleteModalLabelStyles, deleteModalStyles } from './ConfirmCancelModalStyles';

interface ConfirmCancelModalProps {
  openConfirmCancelModal?: boolean;
  setOpenConfirmCancelModal?: Function;
  onConfirmCancelDone?: Function;
}

const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = (props: ConfirmCancelModalProps) => {
  
  const { openConfirmCancelModal, setOpenConfirmCancelModal, onConfirmCancelDone } = props;
  
  const handleCancel = () => {
    setOpenConfirmCancelModal(false);
  };
  const handleClose = () => {
    setOpenConfirmCancelModal(false);
  };
  
  const handleConfirmCancel = () => {
    onConfirmCancelDone(true)
  }
  
  return (
      <Modal
        openModal={openConfirmCancelModal}
        label={<p style={deleteModalLabelStyles}>Want to Leave?</p>}
        actionButtonProps={{
          disabled: false,
          variant: 'danger',
          label: 'Leave and Discard Changes',
          onClick: () => handleConfirmCancel(),
        }}
        modalButton={<Button></Button>}
        variant='Danger modal'
        bodyText={
          <Typography variant="bodyLong" component={'p'} sx={{ paddingBottom: '0px', marginBottom: '-2rem', fontFamily: 'ToyotaType-Regular' }}>
            Your progress and any changes will be discarded if you leave.
            <br />
            <br />
            Are you sure you want to leave?
          </Typography>
        }
        handleCancel={handleCancel}
        handleClose={handleClose}
        closeIcon={null}
        modalStyles={deleteModalStyles}
      />
  );
};

export default ConfirmCancelModal;
