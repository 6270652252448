import { DataTableView, Icon } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import fileIcon from '../../assets/svg/xlsFileIcon.svg';
import { getPreviewData, useAppDispatch, useAppSelector } from '../../store';
import { setCurrentUploadStage } from '../../store/features/HoldRequest/slice';
import { formatNumber } from '../../utils';

export const RenderDocumentPreview = ({
  importFile,
  setImportFile,
}: {
  importFile: File;
  setImportFile: React.Dispatch<React.SetStateAction<File>>;
}) => {
  const [previewColumns, setPreviewColumns] = useState([]);

  const dispatch = useAppDispatch();
  const parsedData = useAppSelector(getPreviewData);
  useEffect(() => {
    const previewDocumentColumns = parsedData.headers.map((x) => {
      return {
        field: `${x}`,
        name: `${x}`,
        width: 180,
        resizable: true,
        sortable: false,
        pinnable: false,
        hidable: false,
        fixed: false,
        type: 'string',
        pinned: parsedData.parsedBy.includes(x),
      };
    });
    setPreviewColumns(previewDocumentColumns);
  }, [parsedData]);
  return (
    <div
      className="container"
      style={{
        width: '1219px',
        maxHeight: '367px',
        margin: '0px -24px -54px',
        padding: '0px 22px 22px',
      }}
    >
      <div
        style={{
          width: '1171px',
          height: '95px',
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          <img src={fileIcon} alt="xls" />
          <div
            style={{
              display: 'flex',
              height: '44px',
              padding: '0px 0px 0px 11px',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ height: '24px' }}>{importFile?.name || 'Upload File'}</div>
            <div style={{ height: '16px', fontSize: '12px' }}>
              {formatNumber((importFile?.size || 0) / 1000) + 'kb'}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: '44px',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'space-between',
          }}
        >
          <Icon
            name="Close"
            onClick={() => {
              setImportFile(undefined);
              dispatch(setCurrentUploadStage(0));
            }}
            sx={{
              fontSize: '1rem',
              margin: '4px',
            }}
          />
          <div style={{ height: '16px', fontSize: '12px', color: '#22A63E' }}>{'Uploaded!'}</div>
        </div>
      </div>
      <DataTableView
        className="container"
        sx={{
          position: 'relative',
          overflow: 'auto',
          padding: 0,
          width: '1171px',
          maxHeight: '225px',
          border: '1px',
          borderColor: '#D8DCDE',
          borderStyle: 'solid',
          borderRadius: '4px',
          '&.MuiContainer-root.MuiContainer-maxWidthLg': {
            maxWidth: 'auto',
            padding: 0,
          },
        }}
        tableData={{
          rowsData: [...parsedData.data],
          dropDownOptions: [],
          loading: parsedData.data.length === 0 ? true : false,
          reloading: false,
          count: parsedData.data.length,
        }}
        columns={previewColumns}
        sorts={[{ field: 'VIN', order: 'ASC' }]}
        handleCurrentColumnUpdate={(columns) => setPreviewColumns(columns)}
        checkboxEnabled={false}
        inlineFiltersEnabled={false}
        selectedItems={[]}
        ellipsisIcon={{
          enabled: false,
          renderIcon: <></>,
        }}
        filterAlleyComponent={<></>}
        paginationComponent={<></>}
        handleSelectedItemUpdate={() => {}}
        handlePagination={() => {}}
        handleReset={() => {}}
        filters={undefined}
        pagination={undefined}
        handleRowCellClick={() => {}}
        handleCurrentSortUpdate={() => {}}
        handleApplyFilter={() => {}}
        handleDeleteFilter={() => {}}
      />
    </div>
  );
};
