// TextBox.js
import { TextInput } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setVehicleTypeSpecification } from '../../../../../store/features/HoldTypeDetails/slice';
 
const TextBox = ({ index, setFooterDisable }) => {
  const reduxInput = useAppSelector((state) => state.holdTypeDetails.modelSpecification.vehicleDtl[index].vehicleTypeSpecification || "");
  const [update, setUpdate] = useState(reduxInput);
  const dispatch = useAppDispatch();
 
  useEffect(() => {
    setUpdate(reduxInput);
  }, [reduxInput]);
 
  const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdate(event.target.value);
    setFooterDisable(false);
  };
 
  const handleBlur = () => {
    dispatch(setVehicleTypeSpecification({index,update}));
  };
 
  return (
    <TextInput
      id={`vehicle${index}`}
      maxWidth="22.78vw"
      // label="Vehicle Type Specification"
      label=""
      value={update}
      placeholder="Input text"
      onChange={handleVehicleChange}
      onBlur={handleBlur}
    />
  );
};
 
export default TextBox;