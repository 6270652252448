// @ts-nocheck
import { Cancel as CancelIcon, FileUploadOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Alert, AlertTitle, Stack } from '@mui/material';
import axios from 'axios';
import { Button, DataTableView, LoadingSpinner, Modal } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import fileIcon from '../../../../../assets/svg/xlsFileIcon.svg';
import { getEnv } from '../../../../../env/env';
import { getFileAttachments, getHoldReqNumber, getHoldStatus } from '../../../../../store';
import { setFileAttachments } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { BrowserStorage } from '../../../../../utils/browserStorage';
import './ImportModal.scss';
import {
  importActions,
  maxAttempts,
  modalStages,
  parseTimeout,
  supportedDocFormats,
  uploadDocStyle,
} from './ImportModalConstants';
import { CheckStatusAPIs, ImportModalProps } from './ImportModalDataShape';
interface DocumentPreviewData {
  parsedBy: string[];
  headers: string[];
  data: any[];
  count: number;
  isGoCanvas: number;
  isVehicleFilter: number;
  holdStatus?: string;
}

function ImportModal(props: ImportModalProps) {
  const dispatch = useAppDispatch();
  const [importFile, setImportFile] = useState<File>();
  const [showModal, setShowModal] = useState<Number>(false);
  const [isimport, setIsimport] = useState<boolean>(false);
  const [docId, setDocID] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [manaullyEnteredVins, setManaullyEnteredVins] = useState<string[]>([]);
  const [vinList, setVinList] = useState<string[]>(props.vinList);
  const [tableRows, setTableRows] = useState<any[]>(props.tableRows);
  const [currentUploadStage, setCurrentUploadStage] = useState<number>(0);
  const [uploadStageError, setUploadStageError] = useState<string>('');
  const [action, setAction] = useState<number>(-1);
  const [parsedData, setParsedData] = useState<DocumentPreviewData>({
    parsedBy: ['VIN'],
    headers: [''],
    data: [{}],
    count: 0,
  });
  const [uploadedDate, setUploadedDate] = useState<string>('');
  const authToken = useAppSelector((state) => state.auth).idToken;
  const [documentDetails, setDocumentDetails] = useState<
    { documentId: string; documentPath: string; name: string } | {}
  >({});
  const sessionId = BrowserStorage.getSessionId() || '20240722T055243_0532282f-64f4-4c75-a1e4-7b7fdfaf2ff9';
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));

  const {
    getRootProps,
    getInputProps,
    open: openFile,
    acceptedFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { 'text/csv': supportedDocFormats },
    validator(file) {
      if (file && file.name) {
        const fileExt = file.name.split('.').pop();
        if (!supportedDocFormats.some((x) => x === `.${fileExt}`)) {
          props.setNotification({ fieldName: 'error', field: 'Unsupported File Type.' });
        }
      }
    },
  });

  const formatNumber = (number: Number) => {
    return (number || 0).toLocaleString('en-US');
  };

  const getDateAndTime = (resDate) => {
    if (resDate == '') {
      return '-';
    } else {
      const newDate = new Date(resDate);
      let hours = newDate.getHours();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const formatDate =
        ('0' + (newDate.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + newDate.getDate()).slice(-2) +
        '/' +
        newDate.getFullYear() +
        ' at ' +
        ('0' + hours).slice(-2) +
        ':' +
        ('0' + newDate.getMinutes()).slice(-2) +
        ':' +
        ('0' + newDate.getSeconds()).slice(-2) +
        ' ' +
        ampm;
      return formatDate;
    }
  };

  const getDocumentPreview = async (documentId: string) => {
    const url = `${getEnv().API_BASE_URL}/hold/QChold/getDocumentPreview/VEHICLE_FILTER/${documentId}`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.get(url, config);

      setParsedData((prevData) => ({
        ...prevData,
        headers: response?.data?.data?.body?.headers,
        data: response?.data?.data?.body?.parsed,
        count: response?.data?.data?.body?.totalCount,
        parsedBy: response?.data?.data?.body?.keyColumns,
        isGoCanvas: response?.data?.data?.body?.isGoCanvas,
        isVehicleFilter: response?.data?.data?.body?.isVehicleFilter,
      }));
      setCurrentUploadStage(7);
    } catch (error: any) {
      setParsedData((prevData) => ({
        ...prevData,
        headers: [],
        data: [{}],
        count: 0,
      }));
      setUploadStageError(error.message || 'Failed to preview document');
      setCurrentUploadStage(4);
    }
  };
  const PDCRequestDataDetails = useAppSelector((state) => state.holdTypeDetails);
  const reqNumber = useAppSelector(getHoldReqNumber);
  const PDCRequestData = useAppSelector((state) => state.holdTypeDetails);
  const fileList = useAppSelector(getFileAttachments);
  const holdStatus = useAppSelector(getHoldStatus);

  const initDocumentUpload = async (file: File) => {
    const url = `${getEnv().API_BASE_URL}/hold/uploadDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    let fileExt = file.name.split('.').pop();

    setCurrentUploadStage(1);

    let reqData = {
      name: file.name,
      holdType: 'QUALITY_HOLD',
      userId: userData?.employeeId,
      userName: userData?.userName,
      documentType: 'VEHICLE_FILTER',
      documentFormat: fileExt,
      sessionId: sessionId || '',
      qcNumber: reqNumber,
      sessionInfo: {
        systemContextFunction: 'testfn',
        sessionDominantRole: 'admin',
      },
    };

    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(url, reqData, config);
      if (response?.status?.toString() !== '201' || response?.data?.data?.responseData?.status?.toString() !== '201') {
        throw new Error(response?.data?.data?.errorMessage || 'Error Uploading File');
      }
      //response from meta api
      const documentId = response?.data?.data?.responseData?.response?.documentId;
      setDocID(documentId);
      const documentPath = response?.data?.data?.responseData?.response?.documentPath;
      setUploadedDate(getDateAndTime(response?.data?.data?.responseData?.response?.uploadedDate || ''));

      //document upload
      const urlUpload = `${getEnv().DOCUMENT_URL}/${documentPath}`;
      delete config['x-api-key'];

      setIsimport(true);
      const responseUpload = await axios.put(urlUpload, file, { ...config, timeout: 10000 });
      if (responseUpload.status === 200) {
        setTimeout(() => {
          setDocumentDetails({ documentId, documentPath, name: file.name });
          if (fileName !== 'data.csv' && action !== 1) {
            getDocumentPreview(documentId);
          } else {
            setCurrentUploadStage(8);
          }
        }, parseTimeout);
      } else {
        throw new Error('Could not upload file.');
      }
    } catch (error: any) {
      setUploadStageError(error.message || 'Missing document path');
      setCurrentUploadStage(4);
    }
  };

  const checkParseStatus = async ({ attempts, documentId, documentPath }: CheckStatusAPIs) => {
    setShowModal(true);
    if (!attempts) {
      attempts = 0;
    }
    //setCurrentUploadStage(2);
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/getParseStatus/VEHICLE_FILTER/${documentId}`;

    const response = await axios.get(url, config);
    if (response.status === 200) {
      const parseStatus = response.data.data.body.parseStatus;
      const parseError = response.data.data.body.parseError;
      if (
        (parseStatus === 'No Status' && parseError === 'No Error') ||
        (parseStatus === 'Parsing' && parseError === 'No Error')
      ) {
        if (attempts < maxAttempts) {
          setTimeout(() => {
            checkParseStatus({
              attempts: attempts + 1,
              documentId,
              documentPath,
            });
          }, parseTimeout);
        } else {
          setCurrentUploadStage(5);
        }
      } else if (parseStatus === 'Validating' || parseStatus === 'Partially Parsed' || parseStatus === 'Parsed') {
        checkValidationStatus({ ...documentDetails });
      } else {
        setUploadStageError(parseError?.error || parseError);
        setCurrentUploadStage(5);
      }
    } else {
      setCurrentUploadStage(5);
      return;
    }
  };

  const checkValidationStatus = async ({ attempts, documentId, documentPath }: CheckStatusAPIs) => {
    if (!attempts) {
      attempts = 0;
    }
    setCurrentUploadStage(3);

    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/getParseStatus/VEHICLE_FILTER/${documentId}`;
    const response = await axios.get(url, config);
    if (response.data.data.statusCode?.toString() === '200') {
      const parseStatus = response.data.data.body.parseStatus;
      const parseError = response.data.data.body.parseError;
      if (parseStatus === 'Validating' && parseError === 'No Error') {
        if (attempts < maxAttempts) {
          setTimeout(() => {
            checkValidationStatus({
              attempts: attempts + 1,
              documentId,
              documentPath,
            });
          }, parseTimeout);
        } else {
          setCurrentUploadStage(6);
        }
      } else if (parseStatus === 'Partially Parsed' || parseStatus === 'Parsed') {
        if (action !== 0) {
          let newAttachments = fileList.fileMetadata;
          newAttachments = [
            {
              id: { count: fileList.fileMetadata?.length + 1 },
              data: {
                filename: documentDetails.name,
                title: documentDetails.name,
                description: '-',
                documentId: documentDetails.documentId,
                uploadedBy: userData?.userName,
                uploadedDate: uploadedDate,
                uploadFilePath: documentDetails.documentPath,
                isdeletable: true,
                action: (
                  <div
                    style={{ width: '56px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <button onClick={() => handleOpenFile(documentId, file.name)} style={{ cursor: 'pointer' }}>
                      <FileDownloadOutlinedIcon sx={{ fontSize: '24px' }} />
                    </button>
                    <button onClick={() => handleDeleteEvent({ documentId })} style={{ cursor: 'pointer' }}>
                      <DeleteOutlinedIcon sx={{ color: '#C40818', fontSize: '24px' }} />
                    </button>
                  </div>
                ),
              },
              isActive: true,
            },
            ...newAttachments,
          ];
          dispatch(setFileAttachments({ fileMetadata: newAttachments }));
        }
        props.loadUploadedFile({
          fileName: fileName,
          documentId: documentDetails?.documentId,
          fileDescription: [''],
          uploadPath: documentDetails?.documentPath,
          action: importActions[action]?.value,
        });
        setImportFile(undefined);
        uploadSuccess();
      } else {
        setUploadStageError(parseError?.error || parseError);
        setCurrentUploadStage(6);
      }
    } else {
      setCurrentUploadStage(6);
    }
  };

  const uploadSuccess = () => {
    // props.setNotification({ fieldName: "success", field: "List Imported Successfully." });

    // handleModalCancel();
    setShowModal(false);
    setImportFile(undefined);
    setFileName('');
    setManaullyEnteredVins([]);
    setCurrentUploadStage(0);
    setUploadStageError('');
    // setImportFile(undefined);
  };

  const handleModalCancel = () => {
    if (isimport === true) {
      deleteImportFile();
    }
    setIsimport(false);
    setShowModal(false);
    setImportFile(undefined);
    setFileName('');
    setManaullyEnteredVins([]);
    setCurrentUploadStage(0);
    setUploadStageError('');
  };

  const temp = useAppSelector((state) => state.pdcRequestData);
  const deleteImportFile = async () => {
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    let documentId = docId;
    let reqData = {
      userId: userData?.employeeId,
      qcNumber: reqNumber,
      documentId: documentId,
    };

    const apiBaseUrl = `${getEnv().API_BASE_URL}`;
    const urls = `${apiBaseUrl}/hold/QChold/deleteDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const configs = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
      data: reqData,
    };

    try {
      const response = await axios.delete(urls, configs);
      if (response.status === 200) {
        const updatedFileAttachmentList: any = fileList.fileMetadata.filter(
          (file) => file.data.documentId !== documentId,
        );
        let rows = updatedFileAttachmentList;
        dispatch(setFileAttachments({ fileMetadata: updatedFileAttachmentList }));
      }
    } catch (error) {}
  };

  const getModalHeader = () => {
    let headerText = '';
    if (currentUploadStage === 0 || currentUploadStage === 1 || currentUploadStage === 2 || currentUploadStage === 3) {
      headerText = 'Upload File or Enter VINs';
    } else if (currentUploadStage === 4 || currentUploadStage === 5 || currentUploadStage === 6) {
      headerText = 'Error During File Import';
    } else if (currentUploadStage === 7) {
      headerText = 'Import Preview';
    } else if (currentUploadStage === 8) {
      headerText = 'Vehicle List Action';
    } else {
      return <></>;
    }

    return (
      <h2 className="container" style={{ margin: '4px 0px', fontWeight: '400' }}>
        {headerText}
      </h2>
    );
  };

  const getModalBodyComponent = () => {
    if (currentUploadStage === 0) {
      return (
        <>
          <p className="body-text">
            {
              'Step 1. Copy and paste VINs in and hit enter to confirm. Or utilize the uploader to upload a list of VINS or a Go Canvas File.'
            }
          </p>
          <p className="body-text">{'Step 2. Validate VINs'}</p>
          <p className="body-text">{'Step 3: Proceed to take action such as filter, add to hold, or update status.'}</p>
        </>
      );
    } else if (currentUploadStage === 1 || currentUploadStage === 2 || currentUploadStage === 3) {
      return (
        <p className="body-text">{`Please do not leave the page.${
          currentUploadStage === 3 ? 'Validating vehicles' : 'Large files may take a few minutes to import'
        }. `}</p>
      );
    } else if (currentUploadStage === 4 || currentUploadStage === 5 || currentUploadStage === 6) {
      return (
        <Alert
          severity="error"
          style={{
            borderLeft: '4px',
            borderColor: '#C40818',
            borderLeftStyle: 'solid',
          }}
          iconMapping={{
            error: <CancelIcon fontSize="inherit" />,
          }}
        >
          <AlertTitle>
            <b>{modalStages[currentUploadStage]}</b>
          </AlertTitle>
          {getErrorMessage()}
        </Alert>
      );
    } else if (currentUploadStage === 7) {
      return (
        <p className="body-text">{`This preview displays a sample of rows and columns from your file. Review the data below to ensure it has been imported correctly.`}</p>
      );
    } else if (currentUploadStage === 8) {
      return (
        <p className="body-text">
          How would you like to act on these{' '}
          {<span style={{ fontWeight: 'bold' }}>{formatNumber(parsedData.count)}</span>} VINs?
        </p>
      );
    } else {
      return <></>;
    }
  };

  const getErrorMessage = () => {
    if (currentUploadStage === 4) {
      return !!uploadStageError
        ? uploadStageError
        : `The file attached could not be uploaded. Please review your file and try again. If the issue persists, contact IT support.`;
    } else if (currentUploadStage === 5) {
      return !!uploadStageError
        ? uploadStageError
        : `Error parsing the uploaded file's data. Please review your file and try again. If the issue persists, contact IT support.`;
    } else if (currentUploadStage === 6) {
      return !!uploadStageError
        ? uploadStageError
        : `The data could not be validated. Please review your file and try again. If the issue persists, contact IT support.`;
    } else {
      return '';
    }
  };

  const handleManualVINEntries = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tableRows) {
      const headers = ['VIN No'];
      let csvContent = headers.join(',') + '\n';
      let uniqueVins = new Set<string>();
      const filteredRows = tableRows
        .filter((row) => e.includes(row.VIN))
        .filter((row) => {
          if (uniqueVins.has(row.VIN)) {
            return false;
          }
          uniqueVins.add(row.VIN);
          return true;
        });
      csvContent += filteredRows.map((row) => [row.VIN].join(',')).join('\n');
      const csvBlob = new Blob([csvContent], { type: 'text/csv' });
      const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });
      setImportFile(csvFile);
      setFileName(csvFile?.name);
      setManaullyEnteredVins(e);
    }
  };

  const getModalSlotComponent = ({ supportedDocFormats }) => {
    if (currentUploadStage === 0) {
      return (
        <div
          {...getRootProps()}
          className="container"
          style={{
            width: '944px',
            padding: '0px 23px',
            margin: `0px -24px -12px`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          {/* TODO: pull suggestions list from data table vins */}
          {/* <StringTextInput
            placeholderText="Enter VINs"
            suggestionsList={vinList}
            selectedValues={manaullyEnteredVins}
            sx={{
              width: '896px',
              height: '92px',
              padding: '16px',
              borderRadius: '4px 0px 0px 4px',
            }}
            handleChange={(e) => {
              handleManualVINEntries(e);
            }}
          /> */}
          <input {...getInputProps()} />
          <div
            className="dropzone-section"
            style={{
              width: '896px',
              height: '164px',
              margin: '26px 0px 5px',
              border: '1px',
              borderRadius: '4px',
              borderColor: '#909295',
              borderStyle: 'dashed',
            }}
          >
            <FileUploadOutlined sx={{ width: '24px', height: '24px', margin: '14px' }} fontSize="small" />
            <div style={{ fontSize: '14px' }}>
              Drag & Drop or{' '}
              <span className="dropzone-link" onClick={openFile}>
                Choose File
              </span>
            </div>
            <div style={{ fontSize: '14px' }}>
              (
              {supportedDocFormats.length > 0
                ? `${supportedDocFormats
                    .map((x) => x.replace('.', ''))
                    .join(', ')
                    .toUpperCase()}`
                : '(No supported formats available)'}
              )
            </div>
          </div>
        </div>
      );
    } else if (currentUploadStage === 1 || currentUploadStage === 2 || currentUploadStage === 3) {
      return (
        <div className="container loading-section">
          <LoadingSpinner />
        </div>
      );
    } else if (currentUploadStage === 7) {
      return (
        <div
          className="container"
          style={{
            width: '1219px',
            maxheight: '367px',
            margin: '0px -24px -54px',
            padding: '0px 22px 22px',
          }}
        >
          <div
            style={{
              width: '1171px',
              height: '95px',
              padding: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
              <img src={fileIcon} alt="xls" />
              <div
                style={{
                  display: 'flex',
                  height: '44px',
                  padding: '0px 0px 0px 11px',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ height: '24px' }}>{importFile?.name || 'Upload File'}</div>
                <div style={{ height: '16px', fontSize: '12px' }}>
                  {formatNumber((importFile?.size || 0) / 1000) + 'kb'}
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                height: '44px',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'space-between',
              }}
            >
              <CloseIcon
                name="Close"
                onClick={() => {
                  setImportFile(undefined);
                  setCurrentUploadStage(0);
                }}
                sx={{
                  fontSize: '1rem',
                  margin: '4px',
                }}
              />
              <div style={{ height: '16px', fontSize: '12px', color: '#22A63E' }}>{'Uploaded!'}</div>
            </div>
          </div>
          <DataTableView
            className="container"
            sx={{
              position: 'relative',
              overflow: 'auto',
              padding: 0,
              width: '1171px',
              maxHeight: '225px',
              border: '1px',
              borderColor: '#D8DCDE',
              borderStyle: 'solid',
              borderRadius: '4px',
              '&.MuiContainer-root.MuiContainer-maxWidthLg': {
                maxWidth: 'auto',
                padding: 0,
              },
            }}
            tableData={{
              rowsData: [...parsedData.data],
              dropDownOptions: [],
              loading: false,
              reloading: false,
              count: parsedData.data.length,
            }}
            columns={parsedData.headers.map((x) => {
              return {
                field: `${x}`,
                name: `${x}`,
                width: 180,
                resizable: true,
                sortable: false,
                pinnable: false,
                hidable: false,
                fixed: false,
                type: 'string',
                pinned: parsedData.parsedBy.includes(x),
              };
            })}
            sorts={[{ field: 'VIN', order: 'ASC' }]}
            filterAlleyComponent={<></>}
            paginationComponent={<></>}
            checkboxEnabled={false}
            inlineFiltersEnabled={false}
            selectedItems={[]}
            ellipsisIcon={{
              enabled: false,
              renderIcon: <></>,
            }}
            handleSelectedItemUpdate={() => {}}
            rowAboveInlineFilters={{
              enabled: false,
              rowData: {},
            }}
            toastNotification={{
              isEnabled: false,
              type: '',
              field: '',
            }}
          />
        </div>
      );
    } else if (currentUploadStage === 8) {
      return (
        <div
          className="container"
          style={{
            width: '944',
            margin: '0px -24px 0px',
            padding: '0px 22px 0px',
          }}
        >
          <div
            style={{
              width: '896px',
              height: '16px',
              fontSize: '12px',
              fontWeight: '400',
              marginBottom: '4px',
              color: '#58595B',
            }}
          >
            {'Choose Action'}
          </div>
          <div
            style={{
              width: '896px',
            }}
          >
            {importActions.map((actn, i) => {
              return (
                <div
                  style={{
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    marginBottom: '4px',
                    padding: '8px 0px 8px 0px',
                  }}
                >
                  <input
                    id={actn.value}
                    type="radio"
                    value={actn.value}
                    checked={action === i}
                    style={{ marginLeft: 0 }}
                    //TEMPORARY
                    disabled={actn.value === 'add' && holdStatus === 'Completed'}
                    onChange={() => {
                      setAction(i);
                    }}
                  />
                  <label for={actn.value} style={{ fontSize: '14px', fontWeight: '400' }}>
                    {actn.label}
                  </label>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const goToPrevious = () => {
    if (currentUploadStage === 7) {
      setCurrentUploadStage(0);
    } else if (currentUploadStage > 7) {
      setCurrentUploadStage(currentUploadStage - 1);
    }
  };

  useEffect(() => {
    if (documentDetails && fileName === 'data.csv') {
      setCurrentUploadStage(8);
    }
  }, [documentDetails]);

  const getActionButtonProps = () => {
    return (
      <div>
        {[7, 8, 9].includes(currentUploadStage) ? (
          <Button
            sx={{ marginRight: '16px', height: '40px', fontFamily: "'ToyotaType-Regular', sans-serif" }}
            variant="tertiary"
            label={'Previous'}
            onClick={() => {
              goToPrevious();
            }}
          />
        ) : (
          <Button
            sx={{ marginRight: '16px', height: '40px', fontFamily: "'ToyotaType-Regular', sans-serif" }}
            variant="tertiary"
            label={'Cancel'}
            onClick={() => {
              handleModalCancel();
            }}
          />
        )}
        {[0, 7, 8, 9].includes(currentUploadStage) ? (
          <Button
            disabled={
              (currentUploadStage < 7 && fileName !== 'data.csv') || (currentUploadStage === 8 && action === -1)
            }
            variant="primary"
            label={currentUploadStage === 8 ? 'Submit' : 'Continue'}
            onClick={() => {
              if (importFile) {
                if (fileName === 'data.csv' && currentUploadStage === 0) {
                  initDocumentUpload(importFile);
                }
                if (currentUploadStage === 7) {
                  setCurrentUploadStage(8);
                }
                if (currentUploadStage === 8) {
                  checkParseStatus({ ...documentDetails });
                  if (action !== 0) {
                    let newAttachments = fileList.fileMetadata;
                    newAttachments = [
                      {
                        id: { count: fileList.fileMetadata.length + 1 },
                        data: {
                          filename: documentDetails.name,
                          title: documentDetails.name,
                          description: '-',
                          documentId: documentDetails.documentId,
                          uploadedBy: userData?.userName,
                          uploadedDate: uploadedDate,
                          uploadFilePath: documentDetails.documentPath,
                          isdeletable: true,
                          action: (
                            <div
                              style={{
                                width: '56px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <button
                                onClick={() => handleOpenFile(documentId, file.name)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FileDownloadOutlinedIcon sx={{ fontSize: '24px' }} />
                              </button>
                              <button onClick={() => handleDeleteEvent({ documentId })} style={{ cursor: 'pointer' }}>
                                <DeleteOutlinedIcon sx={{ color: '#C40818', fontSize: '24px' }} />
                              </button>
                            </div>
                          ),
                        },
                        isActive: true,
                      },
                      ...newAttachments,
                    ];
                    dispatch(setFileAttachments({ fileMetadata: newAttachments }));
                  }
                  props.loadUploadedFile({
                    fileName: fileName,
                    documentId: documentDetails?.documentId,
                    fileDescription: [''],
                    uploadPath: documentDetails?.documentPath,
                    action: importActions[action].value,
                  });
                  setImportFile(undefined);
                  uploadSuccess();
                }
              }
            }}
          />
        ) : (
          <Button
            variant="primary"
            label={'Start Over'}
            onClick={() => {
              handleModalCancel();
              setImportFile(undefined);
            }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (acceptedFiles?.length === 1) {
      let fileExt = acceptedFiles[0].name.split('.').pop();
      if (supportedDocFormats.some((x) => x === `.${fileExt}`)) {
        setImportFile(acceptedFiles[0] as File);
        setFileName(acceptedFiles[0]?.name || '');
        initDocumentUpload(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles]);

  return (
    <>
      <Modal
        closeIcon={
          [1, 2, 3].includes(currentUploadStage) ? (
            <></>
          ) : (
            <CloseIcon
              name="Close"
              onClick={() => {
                handleModalCancel();
              }}
              sx={{
                position: 'absolute',
                fontSize: '2rem',
                right: 0,
                top: 0,
                margin: '24px',
                padding: '8px',
                cursor: 'pointer',
              }}
            />
          )
        }
        handleCancel={() => {
          handleModalCancel();
        }}
        modalButton={
          <Button
            label=""
            variant="ghost"
            icon={<FileUploadOutlined fontSize="medium" sx={{ marginTop: '4px' }} />}
            onClick={() => {
              setShowModal(true);
            }}
          />
        }
        openModal={showModal}
        modalStyles={uploadDocStyle}
        optionalLabel={getModalHeader()}
        footerOptionalLabel={''}
        variant={
          currentUploadStage === 1 || currentUploadStage === 2 || currentUploadStage === 3
            ? 'Passive modal'
            : 'Transactional modal'
        }
        bodyText={
          <div
            className="container"
            style={{
              width: '944px',
              padding: '0px 23px',
              margin: `0px -24px ${
                currentUploadStage === 4 || currentUploadStage === 5 || currentUploadStage === 6 ? '-32px' : '0px'
              }`,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {getModalBodyComponent()}
          </div>
        }
        actionButtons={<Stack direction={'row'}>{getActionButtonProps()}</Stack>}
        slotComponent={getModalSlotComponent({ supportedDocFormats })}
        label={undefined}
      ></Modal>
    </>
  );
}

export default ImportModal;
