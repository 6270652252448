import { BrowserAuthError, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'

export const getMSToken = async (instance: IPublicClientApplication, renewal: boolean) => { 
  const activeAccount = instance.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: ["user.read"],
    account: activeAccount,
  };

  const accessRenewalRequest = {
    scopes: ["user.read"],
    account: activeAccount,
    forceRefresh: true,
  };

  try {
    const response = await instance.acquireTokenSilent(renewal ? accessRenewalRequest : accessTokenRequest);
    return response;
    
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      return instance.acquireTokenRedirect(accessTokenRequest);
    } else {
      throw error; // Rethrow any other errors not handled explicitly
    }
  }
}

export const userLogout = async (instance: IPublicClientApplication) => {
  localStorage.clear();
  instance.logoutRedirect();
}

