import axios from "axios";
import { getEnv } from "../../env/env";


export namespace HoldReqAtionsServices {

    export const getApplicableActions = async (HoldReqNumber, authToken, employeeId) => {
        const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

        const config = {
            headers: {
                'Authorization': `${authToken}`,
                'x-api-key': apikey,
                'Content-Type': 'application/json',
            }
        };
        const url = `${getEnv().API_BASE_URL}/hold/QChold/getApplicableActions/${HoldReqNumber}/${employeeId}`;
        try {
            const response = await axios.get(url, config);
            const applicableActions = response?.data?.data;
            if (applicableActions?.statusCode === 200) {
                let resData = {
                    status: "success",
                    data: applicableActions?.body
                }
                return resData;
            }
            else {
                let resData = {
                    status: "error",
                    data: {
                        "isCompleteEnable": false,
                        "isCancelEnable": false
                    }
                }
                return resData;
            }

        } catch (error: any) {
            let resData = {
                status: "error",
                data: {
                    "isCompleteEnable": false,
                    "isCancelEnable": false
                }
            }
            return resData;
        }
    }



    export const cancelHoldRequest = async (HoldReqNumber, authToken, employeeId, processCall, cancelingReason, holdStatus) => {
        const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

        const config = {
            headers: {
                'Authorization': `${authToken}`,
                'x-api-key': apikey,
                'Content-Type': 'application/json',
            }
        };
        let url = "";
    if((processCall === "cancel" && holdStatus ==="Pending Approval") || (processCall === "cancelApprove" && holdStatus ==="Pending Approval"))
        {
            url = `${getEnv().API_BASE_URL}/hold/QChold/cancelHoldRequest`;           
        }
        else if(processCall === "cancelDeny" && holdStatus ==="Pending Approval")
        {
            url = `${getEnv().API_BASE_URL}/hold/QChold/denyActiveHoldRequest`;
        } 
        else if(processCall === "cancel")
        {
            url = `${getEnv().API_BASE_URL}/hold/QChold/cancelActiveHoldRequest`;
        }        
        else
        {
            url = `${getEnv().API_BASE_URL}/hold/QChold/completeHoldRequest`;
        }

        const requestBody = {
            "userID": employeeId,
            "qcNumber": HoldReqNumber
        }
        if(cancelingReason !== "")
        {
            requestBody["reason"] = cancelingReason
        }
        try {
            const response = await axios.post(url, requestBody, config);
            const applicableActions = response?.data?.data;
            if (applicableActions?.statusCode === 201) {
                let resData = {
                    status: "success",
                    data: applicableActions?.body
                }
                return resData;
            }
            else {
                let resData = {
                    status: "error",
                    data: {
                        "isCompleteEnable": false,
                        "isCancelEnable": false
                    }
                }
                return resData;
            }

        } catch (error: any) {
            let resData = {
                status: "error",
                data: {
                    "isCompleteEnable": false,
                    "isCancelEnable": false
                }
            }
            return resData;
        }
    }
}

export default HoldReqAtionsServices;