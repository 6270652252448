import ToyotaTypeBoldWoff from '../assets/fonts/ToyotaType-Bold.woff';
import ToyotaTypeBoldWoff2 from '../assets/fonts/ToyotaType-Bold.woff2';
import ToyotaTypeBoldItWoff from '../assets/fonts/ToyotaType-BoldIt.woff';
import ToyotaTypeBoldItWoff2 from '../assets/fonts/ToyotaType-BoldIt.woff2';
import ToyotaTypeBookWoff from '../assets/fonts/ToyotaType-Book.woff';
import ToyotaTypeBookWoff2 from '../assets/fonts/ToyotaType-Book.woff2';
import ToyotaTypeBookItWoff from '../assets/fonts/ToyotaType-BookIt.woff';
import ToyotaTypeBookItWoff2 from '../assets/fonts/ToyotaType-BookIt.woff2';
import ToyotaTypeItalicWoff from '../assets/fonts/ToyotaType-Italic.woff';
import ToyotaTypeItalicWoff2 from '../assets/fonts/ToyotaType-Italic.woff2';
import ToyotaTypeRegularWoff from '../assets/fonts/ToyotaType-Regular.woff';
import ToyotaTypeRegularWoff2 from '../assets/fonts/ToyotaType-Regular.woff2';
import ToyotaTypeSemiboldWoff from '../assets/fonts/ToyotaType-Semibold.woff';
import ToyotaTypeSemiboldWoff2 from '../assets/fonts/ToyotaType-Semibold.woff2';
import ToyotaTypeSemiboldItWoff from '../assets/fonts/ToyotaType-SemiboldIt.woff';
import ToyotaTypeSemiboldItWoff2 from '../assets/fonts/ToyotaType-SemiboldIt.woff2';

export const typography = `
    @font-face {
        font-family: 'Toyota Type Book';
        src: local('ToyotaTypeBook'), local('Toyota Type Book'), local('ToyotaType-Book'),
            url('${ToyotaTypeBookWoff2}') format('woff2'),
            url('${ToyotaTypeBookWoff}') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type Book Italic';
        src: local('ToyotaTypeBookItalic'), local('Toyota Type Book Italic'), local('ToyotaType-BookIt'),
            url('${ToyotaTypeBookItWoff2}') format('woff2'),
            url('${ToyotaTypeBookItWoff}') format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type';
        src: local('ToyotaType'), local('Toyota Type'), local('ToyotaType-Regular'),
            url('${ToyotaTypeRegularWoff2}') format('woff2'),
            url('${ToyotaTypeRegularWoff}') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type Italic';
        src: local('ToyotaTypeItalic'), local('Toyota Type Italic'), local('ToyotaType-Italic'),
            url('${ToyotaTypeItalicWoff2}') format('woff2'),
            url('${ToyotaTypeItalicWoff}') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type Semibold';
        src: local('ToyotaTypeSemibold'), local('Toyota Type Semibold'), local('ToyotaType-Semibold'),
            url('${ToyotaTypeSemiboldWoff2}') format('woff2'),
            url('${ToyotaTypeSemiboldWoff}') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type Semibold Italic';
        src: local('ToyotaTypeSemiboldIt'), local('Toyota Type Semibold Italic'), local('ToyotaType-SemiboldIt'),
            url('${ToyotaTypeSemiboldItWoff2}') format('woff2'),
            url('${ToyotaTypeSemiboldItWoff}') format('woff');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type Bold';
        src: local('ToyotaType'), local('Toyota Type Bold'), local('ToyotaType-Bold'),
            url('${ToyotaTypeBoldWoff2}') format('woff2'),
            url('${ToyotaTypeBoldWoff}') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Toyota Type Bold Italic';
        src: local('ToyotaTypeBoldItalic'), local('Toyota Type Bold Italic'), local('ToyotaType-BoldIt'),
            url('${ToyotaTypeBoldItWoff2}') format('woff2'),
            url('${ToyotaTypeBoldItWoff}') format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }
`;
