import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface State {
  msName: string;
  msUsername: string;
  msUserRoles: Array<string>;
  accessToken: string;
  idToken: string;
  sessionId: string;
}

const initialState: State = {
  msName: '',
  msUsername: '',
  msUserRoles: [],
  accessToken: '',
  idToken: '',
  sessionId: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<State>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
      localStorage.setItem('HM_sessionId', action.payload);
    },
  },
});

export const { setUser, setSessionId } = authSlice.actions;

export default authSlice.reducer;
