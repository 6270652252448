import { BrowserAuthOptions, Configuration, RedirectRequest } from '@azure/msal-browser';
import { getEnv } from '../env/env';

// Config object to be passed to Msal on creation

const env = getEnv();

const auth: BrowserAuthOptions = {
  clientId: env.clientId,
  authority: env.authority,
  navigateToLoginRequestUrl: env.navigateToLoginRequestUrl,
  redirectUri: env.redirectUri,
};

export const msalConfig: Configuration = {
  auth: auth,
  cache: {
    cacheLocation: 'localStorage',
    // storeAuthStateInCookie: false
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ['user.read'],
};
