import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { DataTableShape } from 'src/types';
import { initialState } from './initialState';
import { DataTable, DocumentDetails, DocumentPreviewData, DownloadStatus } from './types';

const holdRequestSlice = createSlice({
  name: 'holdRequest',
  initialState,
  reducers: {
    setAppLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHoldRequestStatus: (state, action: PayloadAction<any>) => {
      console.log('Action payload:', action.payload, state);
      const { pdcStatus, yardStatus } = action.payload;

      // Update pdcStatus and yardStatus in the state
      state.pdcStatus = pdcStatus;
      state.yardStatus = yardStatus;
    },
    setHoldRequestStatusCounts: (state, action: PayloadAction<any>) => {
      console.log('Action payload:', action.payload);
      state.counts = action.payload;
    },
    setHoldRequestPageViewList: (state, action: PayloadAction<any>) => {
      console.log('Action payload:', action.payload);
      state.pageViewList = action.payload.pageViewList;
    },
    setPageLimit: (state, action: PayloadAction<any>) => {
      console.log('Action setPagination payload:', action.payload);
      state.dataTable.pagination.size = action.payload;
    },
    setPageCount: (state, action: PayloadAction<any>) => {
      console.log('Action setPagination payload:', action.payload);
      state.dataTable.pagination.page = action.payload;
    },
    setPagination: (state, action: PayloadAction<any>) => {
      console.log('Action setPagination payload:', action.payload);
      state.dataTable.pagination = action.payload;
    },
    setFilter: (state, action: PayloadAction<DataTableShape.Filter[] | []>) => {
      console.log('gvhbvdese', action.payload);

      state.dataTable.filters = action.payload;
    },
    setSort: (state, action: PayloadAction<any>) => {
      state.dataTable.sorts = action.payload;
    },
    setVehicleList: (state, action: PayloadAction<any>) => {
      state.dataTable.tableData.rowsData = action.payload;
    },
    setColumns: (state, action: PayloadAction<any>) => {
      state.dataTable.columns = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<any>) => {
      state.dataTable.totalCount = action.payload;
    },
    setReload: (state, action: PayloadAction<any>) => {
      state.dataTable.tableData.reloading = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.dataTable.tableData.loading = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<any>) => {
      state.dataTable.selectedItems = action.payload;
    },
    setInlineFilterValues: (state, action: PayloadAction<any>) => {
      state.dataTable.inlineFilterValues = action.payload;
    },
    setDataTable: (state, action: PayloadAction<DataTable>) => {
      state.dataTable = action.payload;
    },
    setTableCount: (state, action: PayloadAction<number>) => {
      state.dataTable.tableData.count = action.payload;
      state.dataTable.totalCount = action.payload;
    },
    setDownloadStatus: (state, action: PayloadAction<DownloadStatus>) => {
      state.downloadStatus = action.payload;
    },
    setRefresh: (state) => {
      state.dataTable.filters = [];
      state.dataTable.sorts = [];
      state.dataTable.pagination = { page: 1, size: 25 };
      state.dataTable.tableData.loading = true;
    },
    setTableHeader: (state, action: PayloadAction<DataTableShape.DataTableHeaderProps>) => {
      state.dataTable.tableHeader = action.payload;
    },
    setSelectedView: (
      state,
      action: PayloadAction<{ label: ReactNode; shortName?: ReactNode; count?: string; id?: number }>,
    ) => {
      state.selectedView = action.payload;
    },
    setLastRefreshDate: (state, action: PayloadAction<string>) => {
      state.lastRefreshDate = action.payload;
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    setViewUpdated: (state, action: PayloadAction<boolean>) => {
      state.viewInfo.viewUpdated = action.payload;
    },
    setViewName: (state, action: PayloadAction<string>) => {
      state.viewInfo.viewName = action.payload;
    },
    setDocumentDetails: (state, action: PayloadAction<DocumentDetails>) => {
      state.documentDetails = action.payload;
    },
    setDocumentId: (state, action: PayloadAction<string>) => {
      state.documentDetails.documentId = action.payload;
    },
    setPreviewData: (state, action: PayloadAction<DocumentPreviewData>) => {
      state.previewData = action.payload;
    },
    setCurrentUploadStage: (state, action: PayloadAction<number>) => {
      state.currentUploadStage = action.payload;
    },
    setUploadStageError: (state, action: PayloadAction<string>) => {
      state.uploadStageError = action.payload;
    },
    setUploadedDate: (state, action: PayloadAction<string>) => {
      state.documentDetails.uploadedDate = action.payload;
    },
    setCanvasVehicleListingCounts: (
      state,
      action: PayloadAction<{
        duplicateVinsCount: number;
        invalidVinsCount: number;
        notAssociatedWithHoldCount: number;
        totalCount: number;
        validVinsCount: number;
      }>,
    ) => {
      state.canvasVehicleListing.counts = action.payload;
    },
    setCanvasVehicleList: (state, action: PayloadAction<any>) => {
      state.canvasVehicleListing.vehicleList = action.payload;
    },
    setCanvasVehicleListColumns: (state, action: PayloadAction<any>) => {
      state.canvasVehicleListing.columns = action.payload;
    },
    setCanvasVehicleListLoading: (state, action: PayloadAction<boolean>) => {
      state.canvasVehicleListing.loading = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setViewName,
  setViewUpdated,
  setHoldRequestStatus,
  setHoldRequestPageViewList,
  setColumns,
  setFilter,
  setPagination,
  setSelectedItems,
  setSort,
  setVehicleList,
  setReload,
  setInlineFilterValues,
  setDataTable,
  setPageLimit,
  setTableCount,
  setPageCount,
  setHoldRequestStatusCounts,
  setDownloadStatus,
  setLoading,
  setRefresh,
  setTableHeader,
  setSelectedView,
  setLastRefreshDate,
  setAppLoader,
  setSidebar,
  setDocumentDetails,
  setDocumentId,
  setPreviewData,
  setCurrentUploadStage,
  setUploadStageError,
  setUploadedDate,
  setCanvasVehicleListingCounts,
  setCanvasVehicleList,
  setCanvasVehicleListColumns,
  setCanvasVehicleListLoading,
} = holdRequestSlice.actions;
export default holdRequestSlice.reducer;
