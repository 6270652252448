import axios, { AxiosRequestConfig } from 'axios';
import { getEnv } from '../env/env';

const controller = new AbortController();
const signal = controller.signal;

export const downloadFileServiceApi = async (
  link: string,
  authToken: string,
  handleNotification?: (filter: any) => void,
  callback?: () => void,
) => {
  const downloadURL = `${getEnv().DOCUMENT_URL}/${link}`;
  const configs: AxiosRequestConfig = {
    signal,
    headers: {
      Authorization: `${authToken}`,
    },
    responseType: 'blob',
  };
  const responseFile = await axios.get(downloadURL, configs);
  if (responseFile.status === 200) {
    const splitPath = link.split('/');
    const filename = splitPath[splitPath.length - 1];
    const contentType = responseFile.headers['content-type'] || 'application/octet-stream';
    const blob = new Blob([responseFile.data], { type: contentType });
    const downloadUrl = window.URL.createObjectURL(blob);

    const contentDisposition = responseFile.headers['content-disposition'];
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = filename; // Use the extracted filename
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(downloadUrl);
    if (callback) callback();
    handleNotification({ fieldName: 'success', field: 'File Download Successfully' });
  } else {
    handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
  }
};

export const uploadParseApi = async (documentId: string, authToken: string, documentType: string) => {
  const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
  const config = {
    headers: {
      Authorization: `${authToken}`,
      'x-api-key': apikey,
      'Content-Type': 'application/json',
    },
  };

  const url = `${getEnv().API_BASE_URL}/hold/QChold/getParseStatus/${documentType}/${documentId}`;

  const response = await axios.get(url, config);
  return response;
};
