import { RootState } from '../../store';

export const getHoldRequestStatusCount = (state: RootState) => state.holdRequest.counts;
export const getPdcStatus = (state: RootState) => state.holdRequest.pdcStatus;
export const getYardStatus = (state: RootState) => state.holdRequest.yardStatus;

export const getPagination = (state: RootState) => state.holdRequest.dataTable.pagination;
export const getFilter = (state: RootState) => state.holdRequest.dataTable.filters;
export const getSort = (state: RootState) => state.holdRequest.dataTable.sorts;

export const getRecords = (state: RootState) => state.holdRequest.dataTable.tableData.rowsData;

export const getColumns = (state: RootState) => state.holdRequest.dataTable.columns;

export const getTotalCount = (state: RootState) => state.holdRequest.dataTable.totalCount;
export const getLoading = (state: RootState) => state.holdRequest.dataTable.tableData.loading;
export const getReloading = (state: RootState) => state.holdRequest.dataTable.tableData.reloading;
export const getDataTable = (state: RootState) => state.holdRequest.dataTable;
export const getDownloadStatus = (state: RootState) => state.holdRequest.downloadStatus;
export const getSelectedItems = (state: RootState) => state.holdRequest.dataTable.selectedItems;
export const getInlineFilterValues = (state: RootState) => state.holdRequest.dataTable.inlineFilterValues;
export const getSelectedView = (state: RootState) => state.holdRequest.selectedView;
export const getLastRefreshDate = (state: RootState) => state.holdRequest.lastRefreshDate;
export const getAppLoader = (state: RootState) => state.holdRequest.loading;
export const getSidebar = (state: RootState) => state.holdRequest.sidebarOpen;
export const getViewName = (state: RootState) => state.holdRequest.viewInfo?.viewName || '';
export const getViewUpdated = (state: RootState) => state.holdRequest.viewInfo?.viewUpdated || false;
export const getDocumentDetails = (state: RootState) => state.holdRequest.documentDetails;
export const getPreviewData = (state: RootState) => state.holdRequest.previewData;
export const getCurrentUploadStage = (state: RootState) => state.holdRequest.currentUploadStage;
export const getUploadStageError = (state: RootState) => state.holdRequest.uploadStageError;
export const getUploadedDate = (state: RootState) => state.holdRequest.documentDetails.uploadedDate;
export const getCanvasVehicleListingCounts = (state: RootState) => state.holdRequest.canvasVehicleListing.counts;
export const getCanvasVehicleList = (state: RootState) => state.holdRequest.canvasVehicleListing.vehicleList;
export const getCanvasVehicleListColumns = (state: RootState) => state.holdRequest.canvasVehicleListing.columns;
export const getCanvasVehicleListLoading = (state: RootState) => state.holdRequest.canvasVehicleListing.loading;
