import { Grid, styled } from '@mui/material';
import { Divider, Dropdown, TextInput } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import {
  getParts,
  getRequestingDivisionOptions
} from '../../../../../store/features/HoldTypeDetails/selectors';
import { setPartDetail } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { AccordionContent } from '../../../../../types/Shapes/Accordion.shape';
import { RenderItem } from '../RenderItem';
import './Parts.scss';

type RowData = {
  id: number;
  data: {
    partno: string;
    name: string;
    supplier: string;
    icfForPartIssue: string;
    suppliercode: string;
  };
  isActive: boolean;
};

const StyledGrid = styled(Grid)({
  display: 'block',
  padding: '16px 8px 16px 8px',
});

const StyledGridRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
});

const StyledGridRow2 = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '1.5rem 1rem',
});

const StyledGridColumn = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

const DeleteGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '.25rem',
  alignSelf: 'stretch',
  marginBottom: '1rem',
  cursor: 'pointer',
});

const PartDetails: React.FC<AccordionContent> = ({ setFooterDisable, index, handleDelete }) => {
  const dispatch = useAppDispatch();
  const parts = useAppSelector(getParts);
  const [updatedData, setUpdatedData] = useState<any>(parts[index]||{});
  const [icfDropdown, setICFDropdown] = useState<boolean>(false);
  const icfOptionList = useAppSelector(getRequestingDivisionOptions);

  useEffect(() => {
    setUpdatedData({ ...parts.partDtls[index] });
  }, [parts]);

  const handleBlur = () => {
    dispatch(setPartDetail({index:index, update:updatedData}));
  };

  const handlePartNumber = (value: any, index: number) => {
    setFooterDisable(false);
    setUpdatedData({
      ...updatedData,
      ['partNumber']: value 
    });
  };

  const handleICF = (option: { optionLabel: string; id: string }, index: number) => {
    dispatch(
      setPartDetail({
        index:index,
        update: {
          ...updatedData,
          icfForPartIssue: {
            optionLabel: option?.optionLabel || '',
            id: option?.id || '',
          }
        }
      }),
    );
    setFooterDisable(false);
  };

  return (
    <div key={updatedData.id}>
      <StyledGridRow>
        <StyledGridColumn item xs={12} sm={6} md={4} lg={4}>
          <RenderItem
            label="Part Number"
            value={
              <TextInput
                label=""
                maxWidth="22vw"
                value={updatedData.partNumber || ""}
                onChange={(e) => {
                  handlePartNumber(e.target.value, index);
                }}
                onBlur={handleBlur}
              />
            }
          />
        </StyledGridColumn>
        <StyledGridColumn item xs={12} sm={6} md={4} lg={4}>
          <RenderItem
            label="Name"
            value={
              <TextInput
                label=""
                maxWidth="22vw"
                value={updatedData.partName || ""}
                onChange={(e) => {
                  setUpdatedData({ ...updatedData, partName: e.target.value });
                  setFooterDisable(false);
                }}
                onBlur={handleBlur}
              />
            }
          />
        </StyledGridColumn>
        <StyledGridColumn item xs={5.7} sm={5.7} md={3.7} lg={3.7}>
          <RenderItem
            label="Supplier"
            value={
              <TextInput
                label=""
                maxWidth="22vw"
                value={updatedData.partSupplier || ""}
                onChange={(e) => {
                  setUpdatedData({ ...updatedData, partSupplier: e.target.value });
                  setFooterDisable(false);
                }}
                onBlur={handleBlur}
              />
            }
          />
        </StyledGridColumn>
        <DeleteGrid item lg={0.2} md={0.2} sm={0.2} xs={0.2} sx={{ marginTop: '7.875rem' }}>
          <span
            onClick={() => handleDelete(index)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="black"
              />
            </svg>
          </span>
        </DeleteGrid>
      </StyledGridRow>

      <StyledGridRow>
        <StyledGridColumn item xs={12} sm={6} md={4} lg={4}>
          <RenderItem
            label="Supplier Code"
            value={
              <TextInput
                label=""
                maxWidth="22vw"
                value={updatedData.supplierCode || ""}
                onChange={(e) => {
                  setUpdatedData({ ...updatedData, supplierCode: e.target.value  });
                  setFooterDisable(false);
                }}
                onBlur={handleBlur}
              />
            }
          />
        </StyledGridColumn>
        <StyledGridColumn item xs={12} sm={6} md={4} lg={4}>
          <RenderItem
            label="Internal Control Function for Part"
            value={
              <Dropdown
                variant="singleSelect"
                sx={{ width: '440px' }}
                onOpen={() => setICFDropdown(true)}
                onClose={() => setICFDropdown(false)}
                onChange={(e) => {
                  handleICF(e, index);
                }}
                optionsList={icfOptionList}
                open={icfDropdown}
                selectedOptions={updatedData.icfForPartIssue || {id:'', optionLabel:''}}
              />
            }
          />
        </StyledGridColumn>
      </StyledGridRow>
      <StyledGridRow>
        <Divider
          variant={'inset'}
          sx={{
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '100%',
            marginLeft: '0px',
          }}
        />
      </StyledGridRow>
    </div>
  );
};

export default PartDetails;
