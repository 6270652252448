import { Box, Typography, styled } from '@mui/material';
import { Button, useNotificationContext } from 'cubee2e-cube-global-components';
import React,  { useState } from 'react';
import { getHoldReqNumber } from '../../../../../store';
import { EditAndSubmitQCApi, getHoldRequestDetailsApi } from '../../../../../store/features/HoldTypeDetails/api';
import { setEditPanel } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

import ConfirmCancelModal from '../../../../ConfirmCancel';

interface RewFooterProps {
  setFooterDisable?: React.Dispatch<React.SetStateAction<boolean>>;
  footerDisable?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}
const FooterTypography = styled(Typography)({
  fontFamily: 'ToyotaType-Regular',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  verticalAlign: 'middle',
  display: 'contents',
});

export const EditFooter: React.FC<RewFooterProps> = ({ setFooterDisable, footerDisable, setEdit }) => {
  const dispatch = useAppDispatch();
  const hold = useAppSelector(getHoldReqNumber);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState<boolean>(false);
  const { dispatchNotification } = useNotificationContext();

  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };
  const handleCancel = () => {
    if(!footerDisable){
      setOpenConfirmCancelModal(true);
    }   
    else{
      onConfirmCancelDone(true)
    }  
  };

  const handleApprove = async () => {
    const responseSaveAsDraft = await dispatch(EditAndSubmitQCApi(true, undefined, () => {}));
    console.log(143, responseSaveAsDraft);
    if (responseSaveAsDraft?.statusCode === 201 || responseSaveAsDraft?.statusCode === 200) {
      dispatch(setEditPanel('none'));
      handleNotification({ fieldName: 'success', field: `Hold updated successfully` });
    } else {
      let errMSg = responseSaveAsDraft?.response?.data?.errorMessage || '';
      if (!errMSg || errMSg === '') {
        errMSg = `Something went wrong. Please try again later.`;
      }
      handleNotification({ fieldName: 'error', field: errMSg });
    }
    setFooterDisable(true);
  };
  const onConfirmCancelDone = (bFlag:boolean) =>{
     setFooterDisable(true);
     dispatch(setEditPanel('none'));
     dispatch(getHoldRequestDetailsApi(hold));
  }
  return (
    <Box
      display="flex"
      padding="10px 0rem"
      justifyContent="space-between"
      bottom={0}
      width="100%"
      zIndex="1"
      bgcolor="#F1F2F4"
      gap="0px"
      border="1px 0px 0px 0px"
    >
      <Box>
        <Button
          variant="ghost"
          size="small"
          onClick={handleCancel}
          label={<FooterTypography>Cancel</FooterTypography>}
          sx={{
            marginLeft: '24px',
            '&:hover': {
              backgroundColor: '#DDDDDD',
            },
            textTransform: 'none',
            borderRadius: '4px',
            padding: '12px 16px',
          }}
        />
      </Box>
      <Box>
        <Button
          variant="primary"
          size="small"
          sx={{
            marginRight: '24px',
            textTransform: 'none',
            borderRadius: '4px',
            padding: '12px 16px',
          }}
          onClick={handleApprove}
          icon={'Save'}
          label={'Save Changes'}
          disabled={footerDisable}
        />
      </Box>
      {openConfirmCancelModal && (          
            <ConfirmCancelModal
              openConfirmCancelModal
              setOpenConfirmCancelModal={setOpenConfirmCancelModal}              
              onConfirmCancelDone={onConfirmCancelDone}
            />
        )}
    </Box>
  );
};
