import { Chip } from 'cubee2e-cube-global-components';
import React from 'react';
import { formatDate } from './dateFormats';
import { formatNumber } from './general';
const chipComHeight = '1.5rem';
type ChipColorSchemeType =
  | 'default'
  | 'confirmation'
  | 'success'
  | 'warning'
  | 'info'
  | 'primary'
  | 'error'
  | 'purple'
  | 'darkPurple'
  | 'lightPurple'
  | 'lightYellow'
  | 'primaryContainer'
  | 'confirmationContainer'
  | 'warningContainer'
  | 'errorContainer'
  | 'iconBlue';
type ChipVariants = 'outlined' | 'filled';

const HSCustomize = (label: string | number | JSX.Element) => {
  const labelMappings: Record<
    string | number,
    { text: string | JSX.Element; color: string; icon: string; variant: ChipVariants }
  > = {
    '': { text: 'N/A', color: 'default', icon: '', variant: 'outlined' },
    '1': {
      text: <span style={{ color: '#00000056' }}>Draft</span>,
      color: 'primaryContainer',
      icon: 'BorderColor',
      variant: 'filled',
    },
    '2': { text: 'Pending Approval', color: 'errorContainer', icon: 'AccessTimeOutlined', variant: 'filled' },
    '3': { text: 'Active Hold', color: 'warningContainer', icon: 'Build', variant: 'filled' },
    '4': { text: 'Completed', color: 'confirmationContainer', icon: 'Check', variant: 'filled' },
    '5': { text: 'Canceled', color: 'default', icon: 'DoDisturbOnOutlined', variant: 'outlined' },
    '6': { text: 'In Progress', color: 'errorContainer', icon: 'AccessTimeOutlined', variant: 'filled' },
    '7': { text: 'Pending Update', color: 'errorContainer', icon: 'AccessTimeOutlined', variant: 'filled' },
    '8': { text: 'Cancellation In Progress', color: 'default', icon: 'RemoveCircleOutline', variant: 'outlined' },
    Canceled: { text: 'Canceled', color: 'default', icon: 'RemoveCircleOutline', variant: 'outlined' },
  };

  const defaultLabel = { text: label, color: 'default', icon: '', variant: 'filled' };
  const { text, color, icon, variant } = labelMappings[label as keyof typeof labelMappings] || defaultLabel;

  return (
    <>
      <Chip
        label={text}
        colorScheme={color as ChipColorSchemeType}
        variant={variant as ChipVariants}
        iconName={icon}
        freeText={label !== ''}
        sx={{
          marginRight: '0.5rem',
          fontFamily: 'ToyotaType-Regular',
          height: chipComHeight,
          ...(label === '1' && {
            backgroundColor: '#00000004',
            '& .MuiChip-icon': { color: '#565656' },
            '& .MuiChip-label': { color: '#00000004' },
          }),
        }}
      />
    </>
  );
};

let PDCPELeadCustmize = (label: string | number | JSX.Element, index: number, creatorLabel?: string) => {
  index = index % 10;
  let classN = 'profileImage profilecolor' + index;
  if (label == '' || label == null || label == 'null') {
    return (
      <>
        <span style={{ color: 'rgba(0,0,0,0.3)' }}>{creatorLabel ? '' : 'Unassigned'}</span>
      </>
    );
  } else {
    return (
      <>
        <span>{label}</span>
      </>
    );
  }
};

const HRCustomize = (label: string | number | JSX.Element) => {
  return (
    <>
      <Chip
        label={label === '' ? 'N/A' : label === '1' ? 'PDC' : label === '2' ? 'Yard' : label}
        variant={label === '' ? 'outlined' : 'filled'}
        iconName={
          label === 'PDC'
            ? 'Report'
            : label === 'Yard'
            ? 'Pause'
            : label === '1'
            ? 'Report'
            : label === '2'
            ? 'Pause'
            : ''
        }
        freeText={label === '' ? false : true}
        sx={{
          marginRight: '0.5rem',
          backgroundColor: '#FFFFFF',
          border: '1px solid #000000',
          borderRadius: '4px',
          '& .MuiChip-icon': { color: '#000000' },
          fill: '#ffffff',
          height: chipComHeight,
        }}
      ></Chip>
    </>
  );
};

let IntRiskRankCustomize = (label: string | number | JSX.Element) => {
  return (
    <Chip
      label={label === '' ? 'N/A' : label ? label : ''}
      colorScheme={
        label === 'S'
          ? 'errorContainer'
          : label === 'A'
          ? 'warningContainer'
          : label === 'B'
          ? 'lightPurple'
          : label === 'C'
          ? 'primaryContainer'
          : 'default'
      }
      variant={label === '' ? 'outlined' : 'filled'}
    ></Chip>
  );
};

let CurRiskRankCustomize = (label: string | number | JSX.Element) => {
  return (
    <Chip
      label={label === '' ? 'N/A' : label ? label : ''}
      colorScheme={
        label === 'S'
          ? 'errorContainer'
          : label === 'A'
          ? 'warningContainer'
          : label === 'B'
          ? 'lightPurple'
          : label === 'C'
          ? 'primaryContainer'
          : 'default'
      }
      variant={label === '' ? 'outlined' : 'filled'}
    ></Chip>
  );
};

export const formatRowData = (rows: any) => {
  return (
    rows?.map((vehicle: any, index: number) => ({
      HoldStatus: HSCustomize(
        vehicle.HoldStatusKey === null
          ? '-'
          : vehicle.HoldStatusKey === 'true'
          ? ''
          : vehicle.HoldStatusKey === 'false'
          ? ''
          : vehicle.HoldStatusKey === 'null'
          ? ''
          : vehicle.HoldStatusKey,
      ),
      Assignee: PDCPELeadCustmize(vehicle.PEAssignee === null ? '' : vehicle.PEAssignee, index),
      HoldRequest: vehicle.HoldRequest === null ? '-' : vehicle.HoldRequest,
      CreatedDate: formatDate(vehicle.DateCreated),
      CreatedBy: PDCPELeadCustmize(vehicle.CreatedBy, index),
      HoldInitiatedDate:
        vehicle.HoldInitiatedDate === null
          ? '-'
          : vehicle.HoldInitiatedDate === 'null'
          ? '-'
          : vehicle?.HoldInitiatedDate === undefined
          ? '-'
          : formatDate(vehicle?.HoldInitiatedDate),
      HoldType: HRCustomize(vehicle.HoldType === null ? '-' : vehicle.HoldType === 'null' ? '-' : vehicle.HoldType),
      // IssueIdentifier: vehicle.IssueIdentifier === null ? "-" : vehicle.IssueIdentifier === "null" ? "-" : vehicle.IssueIdentifier,
      HoldYear: vehicle.PDCYear === null ? '-' : vehicle.PDCYear === 'null' ? '-' : vehicle.PDCYear,
      ModelYear:
        vehicle.ModelYear === null
          ? '-'
          : vehicle.ModelYear === 'null'
          ? '-'
          : vehicle.ModelYear.filter((x) => !!x).join(' , '),
      SalesSeries:
        vehicle.Series === null ? '-' : vehicle.Series === 'null' ? '-' : vehicle.Series.filter((x) => !!x).join(' , '),
      InitialRiskRank:
        vehicle.InitialRiskRank === null
          ? '-'
          : vehicle.InitialRiskRank === 'null'
          ? '-'
          : vehicle.InitialRiskRank === undefined
          ? '-'
          : vehicle.InitialRiskRank === 'undefined'
          ? '-'
          : IntRiskRankCustomize(vehicle.InitialRiskRank),
      CurrentRiskRank:
        vehicle.CurrentRiskRank === null
          ? '-'
          : vehicle.CurrentRiskRank === 'null'
          ? '-'
          : vehicle.CurrentRiskRank === undefined
          ? '-'
          : vehicle.CurrentRiskRank === 'undefined'
          ? '-'
          : CurRiskRankCustomize(vehicle.CurrentRiskRank),
      // Completion: vehicle.Completion,
      // GroupIdentifier: vehicle.GroupIdentifier === null ? "-" : vehicle.GroupIdentifier === "null" ? "-" : vehicle.GroupIdentifier,
      PDCSource: vehicle.PDCSource === null ? '-' : vehicle.PDCSource === 'null' ? '-' : vehicle.PDCSource,
      // PDCYear: vehicle.PDCYear === null ? "-" : vehicle.PDCYear === "null" ? "" : (vehicle.PDCYear).split('-')[0],
      ICF:
        vehicle.ICF === null
          ? '-'
          : vehicle.ICF === 'null'
          ? '-'
          : vehicle.ICF === undefined
          ? '-'
          : vehicle.ICF === 'undefined'
          ? '-'
          : vehicle.ICF.filter((x) => !!x).join(' , '),
      CompanyResponsible:
        vehicle.CompanyResponsible === null
          ? '-'
          : vehicle.CompanyResponsible === 'null'
          ? '-'
          : vehicle.CompanyResponsible === undefined
          ? '-'
          : vehicle.CompanyResponsible === 'undefined'
          ? '-'
          : vehicle.CompanyResponsible,
      contact:
        vehicle.contact === null
          ? '-'
          : vehicle.contact === 'null'
          ? '-'
          : vehicle.contact === undefined
          ? '-'
          : vehicle.contact === 'undefined'
          ? '-'
          : vehicle.contact,
      SupplierResponse:
        vehicle.SupplierResponse === null
          ? '-'
          : vehicle.SupplierResponse === 'null'
          ? '-'
          : vehicle.SupplierResponse === undefined
          ? '-'
          : vehicle.SupplierResponse === 'undefined'
          ? '-'
          : vehicle.SupplierResponse,
      PartNumber:
        vehicle.PartNumber === null
          ? '-'
          : vehicle.PartNumber === 'null'
          ? '-'
          : vehicle.PartNumber === undefined
          ? '-'
          : vehicle.PartNumber === 'undefined'
          ? '-'
          : vehicle.PartNumber.filter((x) => !!x).join(' , '),
      RootCause:
        vehicle.RootCause === null
          ? '-'
          : vehicle.RootCause === 'null'
          ? '-'
          : vehicle.RootCause === undefined
          ? '-'
          : vehicle.RootCause === 'undefined'
          ? '-'
          : vehicle.RootCause,
      DefectCategory:
        vehicle.DefectCategory === null
          ? '-'
          : vehicle.DefectCategory === 'null'
          ? '-'
          : vehicle.DefectCategory === undefined
          ? '-'
          : vehicle.DefectCategory === 'undefined'
          ? '-'
          : vehicle.DefectCategory,
      PDCNotificationDate:
        vehicle.PDCNotificationDate === null
          ? '-'
          : vehicle.PDCNotificationDate === 'null'
          ? '-'
          : vehicle.PDCNotificationDate === undefined
          ? '-'
          : vehicle.PDCNotificationDate === 'undefined'
          ? '-'
          : vehicle.PDCNotificationDate,
      PDCRequestDate:
        vehicle.PDCRequestDate === null
          ? '-'
          : vehicle.PDCRequestDate === 'null'
          ? '-'
          : vehicle.PDCRequestDate === undefined
          ? '-'
          : vehicle.PDCRequestDate === 'undefined'
          ? '-'
          : vehicle.PDCRequestDate,
      KickOffMeetingDate:
        vehicle.PDCKOMtgDate === null
          ? '-'
          : vehicle.PDCKOMtgDate === 'null'
          ? '-'
          : vehicle.PDCKOMtgDate === undefined
          ? '-'
          : vehicle.PDCKOMtgDate === 'undefined'
          ? '-'
          : vehicle.PDCKOMtgDate,
      QualityAlertIssueDate:
        vehicle.QualityAlertIssueDate === null
          ? '-'
          : vehicle.QualityAlertIssueDate === 'null'
          ? '-'
          : vehicle.QualityAlertIssueDate === undefined
          ? '-'
          : vehicle.QualityAlertIssueDate === 'undefined'
          ? '-'
          : vehicle.QualityAlertIssueDate,
      // QualityAlertNumber: vehicle.QualityAlertNumber === null ? "-" : vehicle.QualityAlertNumber === "null" ? "-" : vehicle.QualityAlertNumber === undefined ? "-" : vehicle.QualityAlertNumber === "undefined" ? "-" : vehicle.QualityAlertNumber,
      SMDNotifiedDate:
        vehicle.SMDNotifiedDate === null
          ? '-'
          : vehicle.SMDNotifiedDate === 'null'
          ? '-'
          : vehicle.SMDNotifiedDate === undefined
          ? '-'
          : vehicle.SMDNotifiedDate === 'undefined'
          ? '-'
          : vehicle.SMDNotifiedDate,
      ApprovedRepairProcedureDate:
        vehicle.ApprovedRepairProcedureDate === null
          ? '-'
          : vehicle.ApprovedRepairProcedureDate === 'null'
          ? '-'
          : vehicle.ApprovedRepairProcedureDate === undefined
          ? '-'
          : vehicle.ApprovedRepairProcedureDate === 'undefined'
          ? '-'
          : vehicle.ApprovedRepairProcedureDate,
      OpCodeCostShareLetterDate:
        vehicle.OpCodeCostShareLetterDate === null
          ? '-'
          : vehicle.OpCodeCostShareLetterDate === 'null'
          ? '-'
          : vehicle.OpCodeCostShareLetterDate === undefined
          ? '-'
          : vehicle.OpCodeCostShareLetterDate === 'undefined'
          ? '-'
          : vehicle.OpCodeCostShareLetterDate,
      InspectionTime:
        vehicle.InspectionTime === null
          ? '-'
          : vehicle.InspectionTime === 'null'
          ? '-'
          : vehicle.InspectionTime === undefined
          ? '-'
          : vehicle.InspectionTime === 'undefined'
          ? '-'
          : vehicle.InspectionTime,
      TMNAPDCETC:
        vehicle.TMNAPDCETC === null
          ? '-'
          : vehicle.TMNAPDCETC === 'null'
          ? '-'
          : vehicle.TMNAPDCETC === undefined
          ? '-'
          : vehicle.TMNAPDCETC === 'undefined'
          ? '-'
          : vehicle.TMNAPDCETC,
      PDCFieldActivityCompletionDate:
        vehicle.PDCFieldActivityCompletionDate === null
          ? '-'
          : vehicle.PDCFieldActivityCompletionDate === 'null'
          ? '-'
          : vehicle.PDCFieldActivityCompletionDate === undefined
          ? '-'
          : vehicle.PDCFieldActivityCompletionDate === 'undefined'
          ? '-'
          : vehicle.PDCFieldActivityCompletionDate,
      Approvals: vehicle.Approvals === null ? '-' : vehicle.Approvals === 'null' ? '-' : vehicle.Approvals,
      // RepairTime: vehicle.RepairTime === null ? "-" : vehicle.RepairTime === "null" ? "-" : vehicle.RepairTime === undefined ? "-" : vehicle.RepairTime === "undefined" ? "-" : vehicle.RepairTime,

      HoldAge: formatNumber(vehicle.ageofrequest || '0'),
      TotalVehicles: formatNumber(vehicle.TotalVehiclesSubmitted || '0'),
      VehiclesCompleted: formatNumber(vehicle.VehiclesReleasedKpi || '0'),
      InEvaluation: formatNumber(vehicle.ReadyForRelease || '0'),
      OnHold: formatNumber(vehicle.OnHold || '0'),
      VehiclesPendingApproval: formatNumber(vehicle.PendingApproval || '0'),
      VehiclesInvalid: formatNumber(vehicle.VehiclesInvalid || '0'),
      Shipped: formatNumber(vehicle.VehiclesIneligible || '0'),
      TotalVehiclesSubmitted: formatNumber(vehicle.Approved || '0'),
      // Completion: (100 * ((vehicle.VehiclesReleasedKpi || 0) / ((vehicle.TotalVehiclesSubmitted > 0) ? vehicle.TotalVehiclesSubmitted : 1))).toFixed(2),
      VehiclesCompletedPer: Number(vehicle.Completion || '0.00').toFixed(2),
      //! Check counts
    })) || []
  );
};
