import { Grid } from '@mui/material';
import { Button, Chip, DataTableView, Icon } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import { AccordionPanel } from '../../../../types/Shapes/Accordion.shape';
import DashAccordion from '../../../DashAccordion';
import { StyledGrid } from '../PDCDetails.styles';
import { ActivityPanel } from './ActivityPanel';

const renderStatusChip = (status: string) => {
  return <Chip label={status} colorScheme="confirmation" iconName="CheckOutlined" />;
};

const columns = [
  {
    id: 1,
    field: 'eventname',
    name: 'Event name',
    type: 'string',
    width: 60,
  },
  {
    id: 2,
    field: 'owner',
    name: 'Owner',
    type: 'string',
    width: 60,
  },
  {
    id: 3,
    field: 'recommendeddate',
    name: 'Recommended Completion Date',
    type: 'string',
    width: 60,
  },
  {
    id: 4,
    field: 'completiondate',
    name: 'Completion Date',
    type: 'string',
    width: 60,
  },
  {
    id: 5,
    field: 'status',
    name: 'Status',
    type: 'string',
    width: 60,
  },
  {
    id: 6,
    field: 'notes',
    name: 'Notes',
    type: 'string',
    width: 60,
  },
];
const rows = [
  {
    id: 1,
    recommendeddate: '12/22/2023',
    completiondate: '',
    eventname: 'PDC Request Party',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 2,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Hold Notice Sent',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 3,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Kick Off Meeting',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 4,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Hand Over Notice',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 5,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Notification Letter',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 6,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Offical Notice Received',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 7,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Quality Alert Issued',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 8,
    recommendeddate: '12/22/2023',
    completiondate: '',
    eventname: 'Quality Alert Number',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 9,
    recommendeddate: '12/22/2023',
    completiondate: '',
    eventname: 'Instruction Routing Direction',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 10,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Inspection Instructions Received',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 11,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Inspection Instructions Approved',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 12,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Inspection Instructions Sent',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 13,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Repair Instruction Received',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 14,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Repair Instruction Approved',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 15,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Repair Instruction Sent',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 16,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Parts Shippingt',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 17,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: '3rd Party Kick Off',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 18,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: '3rd Party Training',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 19,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Traveler Notices',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
    notes: <Icon name="FileOpenOutlined" />,
  },
  {
    id: 20,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Cost Share Meeting',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 21,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Approve Warranty Set Up Sheet',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 22,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Workplace Chemical Protection Program (WCPP) Reviewed',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 23,
    recommendeddate: '12/22/2023',
    completiondate: '',
    eventname: 'Recurrence Prevention (RP)',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
  {
    id: 24,
    recommendeddate: '12/22/2023',
    completiondate: '12/22/2023',
    eventname: 'Export Vessel info Request',
    status: renderStatusChip('completed'),
    owner: 'Michael Amber',
  },
];
export const Activity = () => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [pdcEventDetail, setPDCEventDetail] = useState({});

  const getContentText = () => {
    return (
      <StyledGrid>
        <DataTableView
          handlePagination={() => {}}
          handleReset={() => {}}
          tableHeader={{
            title: 'PDC Events',
            tableCount: `21 items`,
            viewlabel: 'View by Event',
            sortLabel: 'Sorted by Event',
            enableSelectedItemsBanner: true,
          }}
          filters={{ appliedFilters: [] }}
          columns={columns}
          pagination={{ page: 1, size: 25 }}
          tableData={{
            rowsData: rows,
            count: rows.length,
            dropDownOptions: [],
            loading: false,
            reloading: false,
          }}
          sx={{ paddingRight: '0px', paddingLeft: '0px', minWidth: '100%' }}
          handleRowCellClick={(data) => {
            setPanelOpen(true);
            setPDCEventDetail(data['row']);
          }}
          handleSelectedItemUpdate={() => {}}
          handleCurrentColumnUpdate={() => {}}
          handleCurrentSortUpdate={() => {}}
          handleApplyFilter={() => {}}
          handleDeleteFilter={() => {}}
        />
        {/* <StructuredList    
                    variant={'columnlist'}
                    listVariant={'default'}
                    alignment={'hanging'}
                    padding={'12px'}
                    rows={rows}
                    columns={columns}
                    fullBorder={true}
                /> */}
        <Button label="Add Event" icon="Add" variant="secondary" sx={{ marginTop: '1rem', padding: '1rem' }} />
        <ActivityPanel
          open={panelOpen}
          handleClose={() => setPanelOpen(false)}
          pdcEventDetail={pdcEventDetail}
          columns={columns}
        />
      </StyledGrid>
    );
  };

  const filePanels: AccordionPanel[] = [
    {
      panel: '1',
      ContentText: getContentText(),
      HeaderText: <div className="accordion header">PDC Events </div>,
      headerMargin: '0rem',
      headerPadding: '1.5rem',
      contentMargin: '0rem',
      contentPadding: '0.5rem',
      headerColor: '#F1F2F4',
      contentColor: '#FFFFFF',
      defaultExpanded: true,
      headerFontSize: '14px',
      headerFontColor: '#000',
    },
  ];

  return (
    <Grid
      item
      xs={12}
      sx={{
        height: 'auto',
        background: 'var(--sys-light-Background-background, #fff)',
        padding: '0px',
        marginBottom: '16px',
        // marginTop: "7.5rem"
      }}
    >
      {filePanels.map((item, i) => (
        <DashAccordion key={i} {...item} />
      ))}
    </Grid>
  );
};
