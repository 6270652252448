import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { TextInput } from 'cubee2e-cube-global-components';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  getEditPanel,
  getHoldStatus,
  getIssueResolution,
  getYardToPDCState,
} from '../../../../../store/features/HoldTypeDetails/selectors';
import { setIssueResolution } from '../../../../../store/features/HoldTypeDetails/slice';
import { IssueResolution as IssueResolutionType } from '../../../../../store/features/HoldTypeDetails/types';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { RenderItem } from '../RenderItem';

const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '1.5rem 1rem',
});
const StyledGrid2 = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

const IssueResolution = ({ setFooterDisable }) => {
  const [edit, setEdit] = useState<boolean>(true);
  const issueResolution = useAppSelector(getIssueResolution);
  const editPanel = useAppSelector(getEditPanel);
  const holdStatus = useAppSelector(getHoldStatus);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const dispatch = useAppDispatch();
  const [updatedData, setUpdatedData] = useState<IssueResolutionType>(issueResolution);

  useEffect(() => {
    setUpdatedData({ ...issueResolution });
  }, [issueResolution]);

  const handleBlur = () => {
    dispatch(setIssueResolution(updatedData));
  };

  const renderItem = (label: string, value: ReactNode, isRequired: boolean = false) => {
    return <RenderItem label={label} value={value} isRequired={isRequired} />;
  };

  const handlePDCActionDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedData({
      ...updatedData,
      pdcActionDirectionAtYardPort: event.target.value,
    });
    setFooterDisable(false);
  };

  const handleProcessContainment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedData({
      ...updatedData,
      processContainmentMethod: event.target.value,
    });
    setFooterDisable(false);
  };

  const handleFlowOutPrevention = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedData({
      ...updatedData,
      flowOutPreventionMethod: event.target.value,
    });
    setFooterDisable(false);
  };

  const isEdit = holdStatus === 'Draft' || editPanel === '4' || isyardToPDC;

  return (
    <StyledGrid container>
      <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
        {renderItem(
          'PDC Action Direction at Yard/Port',
          !isEdit ? (
            updatedData.pdcActionDirectionAtYardPort ?? '-'
          ) : (
            <TextInput
              label=""
              maxWidth="440px"
              value={updatedData.pdcActionDirectionAtYardPort}
              onChange={handlePDCActionDirection}
              onBlur={handleBlur}
            />
          ),
          false,
        )}
      </StyledGrid2>
      <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
        <RenderItem
          label="Process Containment Method"
          value={
            !isEdit ? (
              updatedData?.processContainmentMethod ?? '-'
            ) : (
              <TextInput
                label=""
                value={updatedData?.processContainmentMethod}
                onChange={handleProcessContainment}
                maxWidth="440px"
                onBlur={handleBlur}
              />
            )
          }
        />
      </StyledGrid2>
      <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
        <RenderItem
          label={'Flow-Out Prevention Method'}
          value={
            !isEdit ? (
              updatedData?.flowOutPreventionMethod ?? '-'
            ) : (
              <TextInput
                label=""
                value={updatedData?.flowOutPreventionMethod}
                onChange={handleFlowOutPrevention}
                maxWidth="440px"
                onBlur={handleBlur}
              />
            )
          }
        />
      </StyledGrid2>
    </StyledGrid>
  );
};

export default IssueResolution;
