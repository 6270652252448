import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  Button,
  IconButton,
  LoadingSpinner,
  Modal,
  StructuredList,
  TextInput,
  useNotificationContext,
} from 'cubee2e-cube-global-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import FileUploadDragAndDrop from '../../../../Components/FileUpload/FileUploadDragAndDrop';
import { downloadFileServiceApi } from '../../../../services';
import { getDocumentDetails, getFileAttachments, getHoldReqNumber, getHoldStatus } from '../../../../store';
import { uploadDocumentApi } from '../../../../store/features/HoldRequest/api';
import {
  EditAndSubmitQCApi,
  deleteDocumentApi,
  uploadFileDocumentAttachmentApi,
} from '../../../../store/features/HoldTypeDetails/api';
import { setFileAttachments } from '../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { UploadDocumentTypes } from '../../../../types';
import { currentDateAndTime } from '../../../../utils';
import { BrowserStorage } from '../../../../utils/browserStorage';
import './Attachments.scss';

const uploadDocStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '500px',
  maxWidth: '700px',
  minHeight: '300px',
  bgcolor: 'background.paper',
  boxShadow: 15,
  borderRadius: '4px',
  '& .MuiTypography-bodyLong': { padding: '0px 0px 0px 0px' },
  '& .MuiGrid-container': { paddingBottom: '10px' },
};

const deleteModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '430px',
  minHeight: '240px',
  bgcolor: 'background.paper',
  boxShadow: 15,
  borderRadius: '4px',
  '& .MuiTypography-bodyLong': { padding: '0px 0px 0px 0px' },
  '& .MuiGrid-container': { paddingBottom: '22px' },
};
const StyledGridRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '0rem 0rem',
});
const StyledGridColumn = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 0rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

interface editFileAttachmentParam {
  authToken: string;
  documentId: any;
  documentPath: string;
  fileNameWithExtension: string;
  uploadedUserName?: string;
  uploadedDate?: string;
  newDesc?: string;
}
const supportedDocFormats = ['.png', '.jpg', 'jpeg', '.pdf', '.csv', '.xls', '.xlsx'];

const Attachments = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [acceptedFiles, setAcceptedFiles] = useState<readonly FileWithPath[]>([]);
  const [docId, setDocId] = useState<number>(0);
  const [downloadFileEvent, setDownloadFileEvent] = useState<boolean>(false);
  const [uploadButtonDisable, setUploadButtonDisable] = useState<boolean>(true);
  const [AttachFile, setAttachFile] = useState<string>('1');
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const [fileDescription, setFileDescription] = useState<string>('');

  const { dispatchNotification } = useNotificationContext();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const fileAttachments = useAppSelector(getFileAttachments);
  const qcNumber = useAppSelector(getHoldReqNumber);
  const holdStatus = useAppSelector(getHoldStatus);
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
  const documentDetails = useAppSelector(getDocumentDetails);
  const sessionId = BrowserStorage.getSessionId();

  const authToken = auth.idToken;

  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };

  const handleOpenFile = async (documentId: number) => {
    setDownloadFileEvent(true);
    const file = fileAttachments?.fileMetadata?.find((file) => parseInt(file?.documentId) === documentId);

    if (!file) {
      setDownloadFileEvent(false);
      const filter = {
        fieldName: 'error',
        field: `Unable to download file. Please try again later.`,
      };
      handleNotification(filter);

      return;
    }
    downloadFileServiceApi(file.uploadFilePath, authToken, handleNotification, () => {
      setDownloadFileEvent(false);
    });
  };

  const handleDelete = () => {
    setDeleteModal(false);
    dispatch(deleteDocumentApi(docId, handleNotification));
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setAttachFile('1');
    setUploadButtonDisable(true);
  };

  const uploadMiscFile = (desc) => {
    setShowModal(false);
    dispatch(
      uploadDocumentApi(
        file,
        UploadDocumentTypes.MISCELLANEOUS,
        handleNotification,
        (documentId: string, documentPath: string) => {
          setDocId(Number(documentId));
          // editFileAttachment(documentId, documentPath);
          const fileNameWithExtension = documentPath.substring(documentPath.lastIndexOf('/') + 1);
          dispatch(
            setFileAttachments({
              fileMetadata: [
                ...fileAttachments.fileMetadata,
                {
                  fileTitle: fileNameWithExtension,
                  fileDescription: desc ? desc : '',
                  documentId: documentId,
                  documentType: UploadDocumentTypes.MISCELLANEOUS,
                  uploadFilePath: '',
                  uploadedBy: 0,
                  uploadedUserName: '',
                  uploadedDate: '',
                  name: '',
                },
              ],
            }),
          );
          dispatch(EditAndSubmitQCApi(false));
        },
      ),
    );
  };

  useEffect(() => {
    if (acceptedFiles?.length === 1) {
      setAttachFile('2');
      setUploadButtonDisable(false);
      let fileExt = acceptedFiles[0].name.split('.').pop();
      if (supportedDocFormats.some((x) => x === `.${fileExt}`)) {
        setAttachFile('3');
        setFile(acceptedFiles[0]);
      } else {
        setShowModal(false);
      }
    }
  }, [acceptedFiles]);

  const handleDeleteEvent = (documentId: number, fileName: string) => {
    setDeleteModal(true);
    setFileName(fileName);
    setDocId(documentId);
  };

  const editDocDescription = (docId, name, description) => {
    setAttachFile('3');
    setShowModal(true);
    setEditDescription(true);
    setDocId(docId);
    setFileName(name);
    if (!!description) {
      setFileDescription(description);
    }
  };

  const updateDescription = async (newDescription?: string) => {
    setShowModal(false);
    setAttachFile('1');
    setFileName('');
    setEditDescription(false);
    dispatch(
      uploadFileDocumentAttachmentApi(
        {
          userId: userData?.employeeId,
          qcNumber: qcNumber,
          documentId: docId,
          description: newDescription,
        },
        handleNotification,
      ),
    );
  };

  const RenderAttachFileUpload = () => {
    const [newDesc, setNewDesc] = useState<string>(fileDescription);
    const handleDescriptionBlur = () => {
      setFileDescription(newDesc);
    };
    return (
      <Modal
        handleClose={handleModalCancel}
        handleCancel={handleModalCancel}
        label={editDescription ? 'Edit File Description' : 'File Attachment'}
        openModal={showModal}
        modalStyles={uploadDocStyle}
        optionalLabel={''}
        footerOptionalLabel={''}
        variant={'Transactional modal'}
        bodyText={
          <div
            className="container"
            style={{
              width: '600px',
              padding: '0px 1px',
              margin: `0px 0px '0px'`,
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '0px',
              marginBottom: '32px',
            }}
          >
            {AttachFile === '2' ? (
              <>
                <div style={{ paddingTop: '35px', paddingBottom: '35px' }}>
                  <LoadingSpinner variant={'loading-spinner'} />
                </div>
              </>
            ) : AttachFile === '3' ? (
              <>
                <StyledGridRow container sx={{ paddingBottom: '0px' }}>
                  <StyledGridColumn item xs={12} sm={12} md={12} lg={12} sx={{ width: '130%' }}>
                    <Typography variant="bodyLong">File Name </Typography>
                    <Typography variant="bodyLong">{fileName}</Typography>
                  </StyledGridColumn>

                  <StyledGridColumn item xs={12} sm={6} md={6} lg={6}>
                    <TextInput
                      textInputVariant="textarea"
                      maxLength={500}
                      sx={{ width: '598px', '& .MuiInputBase-input': { padding: '10px 12px' } }}
                      value={newDesc}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setNewDesc(e.target.value);
                      }}
                      label={'File Description'}
                      onBlur={handleDescriptionBlur}
                    />
                  </StyledGridColumn>
                </StyledGridRow>
              </>
            ) : (
              'Add New Miscellanous File'
            )}
          </div>
        }
        actionButtons={
          <Stack direction={'row'}>
            <Button
              sx={{ marginRight: '16px', height: '40px' }}
              variant="tertiary"
              label={'Cancel'}
              onClick={handleModalCancel}
            />
            <Button
              sx={{ marginRight: '16px', height: '40px' }}
              disabled={editDescription ? false : uploadButtonDisable}
              variant="primary"
              label={editDescription ? 'Save' : 'Continue'}
              onClick={() => {
                if (editDescription) {
                  updateDescription(newDesc);
                } else {
                  uploadMiscFile(newDesc);
                }
              }}
            />
          </Stack>
        }
        slotComponent={
          AttachFile === '1' && (
            <FileUploadDragAndDrop
              handleAcceptedFiles={(files: readonly FileWithPath[]) => setAcceptedFiles(files)}
              supportedDocFormats={supportedDocFormats}
              acceptFormats={{
                'text/csv': ['.csv', '.xls', '.xlsx'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
              }}
            />
          )
        }
        modalButton={
          <Button
            label={'Attach File'}
            icon={'FileUploadOutlined'}
            variant="secondary"
            size="small"
            onClick={() => setShowModal(true)}
          />
        }
        closeIcon={''}
      />
    );
  };

  const columns = [
    {
      field: 'filename',
      name: 'File Name',
      type: 'string',
    },
    {
      field: 'uploadedBy',
      name: 'Uploaded By',
      type: 'string',
    },
    {
      field: 'uploadedDate',
      name: 'Uploaded On',
      type: 'string',
    },
    {
      field: 'description',
      name: 'Description',
      type: 'string',
    },
    {
      field: 'action',
      name: 'Action',
      type: 'string',
    },
  ];

  const rows = fileAttachments?.fileMetadata.map((file, index) => {
    return {
      id: index, // Extract 'count' from the 'id' object
      documentId: file.documentId,
      data: {
        filename: file.name, // Access nested properties correctly
        // title: file.fileTitle, // Access nested properties correctly
        description: file.fileDescription, // Access nested properties correctly
        uploadedBy: file?.uploadedUserName,
        uploadedDate: currentDateAndTime(file?.uploadedDate),
        action: (
          <Stack sx={{ flexDirection: 'row' }} gap={0.2}>
            <IconButton
              name="Edit"
              sx={{ fontSize: '24px', cursor: 'pointer' }}
              onClick={() => editDocDescription(file.documentId, file.filename, file.description)}
            />
            <IconButton
              name="FileDownloadOutlined"
              sx={{ fontSize: '24px' }}
              onClick={() => handleOpenFile(file.documentId)}
              style={{ cursor: 'pointer' }}
            />
            {file.isdeletable && (
              <IconButton
                name="DeleteOutlined"
                sx={{ cursor: 'pointer' }}
                disabled={[UploadDocumentTypes.APPENDIX_A, UploadDocumentTypes.APPENDIX_C].includes(file.documentType)}
                onClick={() => handleDeleteEvent(file.documentId, file.filename || file.name)}
              />
            )}
          </Stack>
        ),
      },
      isActive: true,
    };
  });

  return (
    <Grid container>
      <StructuredList
        variant="columnlist"
        padding="10px"
        listHeader=""
        rows={rows}
        columns={columns}
        alignment={'hanging'}
        sx={{ marginTop: '6rem', marginBottom: '1rem' }}
      />
      {!['Canceled', 'Completed'].includes(holdStatus) && <RenderAttachFileUpload />}
      <>
        <Modal
          closeIcon={''}
          handleCancel={() => {
            setDownloadFileEvent(false);
          }}
          handleClose={() => {}}
          modalButton={<Button></Button>}
          openModal={downloadFileEvent}
          label={'Download File'}
          variant="Passive modal"
          bodyText={'File Download is in Progress. Please wait...'}
          slotComponent={
            <div
              style={{
                padding: '68px',
                textAlign: 'center',
                width: '480px',
              }}
            >
              <LoadingSpinner variant="loading-spinner" />
            </div>
          }
        />
        <Modal
          modalButton={<></>}
          openModal={deleteModal}
          variant={'Danger modal'}
          label={'Delete File'}
          bodyText={
            <Stack direction="column">
              <Typography variant="bodyLong" sx={{ marginBottom: '24px' }}>
                Are you sure you want to permanently delete this file?
              </Typography>
              <Typography variant="bodyLong">
                File Name: {fileName} <br />
              </Typography>
            </Stack>
          }
          actionButtonProps={{
            variant: 'danger',
            onClick: () => void handleDelete(),
            label: 'Delete',
          }}
          handleClose={() => setDeleteModal(false)}
          handleCancel={() => setDeleteModal(false)}
          closeIcon={''}
          modalStyles={deleteModalStyle}
        />
      </>
    </Grid>
  );
};
export default Attachments;
