import { Stack, Typography } from '@mui/material';
import { RadioButtonGroup, TextInput, useNotificationContext } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';

import { FileWithPath } from 'react-dropzone';
import {
  getCanvasVehicleListingCounts,
  getFileAttachments,
  getIssueDetails,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { getParsedDataApi, uploadDocumentApi } from '../../store/features/HoldRequest/api';

import { useNavigate } from 'react-router-dom';
import { uploadParseApi } from '../../services';
import { setCurrentUploadStage } from '../../store/features/HoldRequest/slice';
import { setCreateHoldType, setFileAttachments, setIssueDetails } from '../../store/features/HoldTypeDetails/slice';
import theme from '../../theme';
import { ParseStatusEnum, UploadDocumentTypes } from '../../types';
import FileUploadDragAndDrop from '../FileUpload/FileUploadDragAndDrop';
import { CheckStatusAPIs } from './CreateHoldRequestModal';
import { maxAttempts, parseTimeout } from './CreateHoldRequestModalConstant';

const supportedDocFormats = ['.pdf'];
const SelectHoldTypeModal = ({ handleModalCancel }: { handleModalCancel: () => void }) => {
  const [selectedType, setSelectedType] = useState<string>('');
  const [issueTitle, setIssueTitle] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState<readonly FileWithPath[]>([]);
  const navigate = useNavigate();
  const { dispatchNotification } = useNotificationContext();

  const dispatch = useAppDispatch();
  const authToken = useAppSelector((state) => state.auth).idToken;
  let fileAttachments = useAppSelector(getFileAttachments);
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
  const issuedetails = useAppSelector(getIssueDetails);
  const countDetails = useAppSelector(getCanvasVehicleListingCounts);
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };
  useEffect(() => {
    if (acceptedFiles?.length === 1) {
      let fileExt = acceptedFiles[0].name.split('.').pop();
      if (supportedDocFormats.some((x) => x === `.${fileExt}`)) {
        dispatch(setCurrentUploadStage(7));
        dispatch(
          uploadDocumentApi(
            acceptedFiles[0],
            UploadDocumentTypes.APPENDIX_A,
            handleNotification,
            (docId: string, path: string) => {
              uploadCallback(docId, path, acceptedFiles[0]);
            },
          ),
        );
      }
    }
  }, [acceptedFiles]);

  const uploadCallback = (documentId: string, documentPath: string, documentDetails: File) => {
    checkParseStatus({ documentId, documentPath, documentDetails });
  };

  const checkParseStatus = async ({ attempts, documentId, documentPath, documentDetails }: CheckStatusAPIs) => {
    if (!attempts) {
      attempts = 0;
    }
    const response = await uploadParseApi(documentId, authToken, UploadDocumentTypes.APPENDIX_A);

    if (response.status === 200) {
      const parseStatus = response.data.data.body.parseStatus;
      const parseError = response.data.data.body.parseError;
      if (parseStatus === ParseStatusEnum.Failed) {
        dispatchNotification({
          open: true,
          variant: 'toast',
          type: 'error',
          iconEnabled: true,
          text: `Upload ${UploadDocumentTypes.APPENDIX_A} ${parseStatus}`,
        });
        handleModalCancel();
      } else {
        if (
          [
            ParseStatusEnum.NoStatus,
            ParseStatusEnum.Started,
            ParseStatusEnum.Parsing,
            ParseStatusEnum.Validating,
          ].includes(parseStatus) &&
          (!parseError || parseError === 'No Error')
        ) {
          if (attempts < maxAttempts) {
            setTimeout(() => {
              checkParseStatus({
                attempts: attempts + 1,
                documentId,
                documentPath,
                documentDetails,
              });
            }, parseTimeout);
          } else {
            setCurrentUploadStage(4);
          }
        } else if (
          [ParseStatusEnum.PartiallyParsed, ParseStatusEnum.Parsed, ParseStatusEnum.ParsedSuccessfully].includes(
            parseStatus,
          )
        ) {
          let fileList: any[] = [...fileAttachments.fileMetadata];
          fileList.push({
            documentId: documentId,
            uploadFilePath: documentPath,
            fileTitle: documentDetails.name,
            fileDescription: '',
            documentType: UploadDocumentTypes.APPENDIX_A,
            isdeletable: false,
            uploadedBy: userData?.employeeId,
            uploadedUserName: userData?.userName,
            uploadedDate: '',
            name: documentDetails.name,
          });
          dispatch(
            setFileAttachments({
              fileMetadata: fileList,
            }),
          );
          dispatch(getParsedDataApi({ authToken, documentId, documentPath }, 'Create', navigate));
        } else {
          setCurrentUploadStage(4);
        }
      }
    }
  };

  const getCount = () => {
    return countDetails.validVinsCount;
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let type = event.target.value;
    setSelectedType(type);
    dispatch(setCreateHoldType(type));
  };

  const handleIssueTitle = (e: any) => {
    setIssueTitle(e.target.value);
    dispatch(setIssueDetails({ ...issuedetails, issueTitle: e.target.value }));
  };

  const RenderRequestType = () => {
    return (
      <RadioButtonGroup
        title={
          <Typography variant="bodyShortSemibold" color={theme.palette.text.primary}>
            {`What type of hold would you like to add this ${getCount()} VIN/s to?`}{' '}
            <span className="spanError">*</span>
          </Typography>
        }
        options={[
          { id: 'PDC', label: 'PDC Hold' },
          { id: 'Yard', label: 'Yard Hold' },
        ]}
        selected={{ id: selectedType, label: selectedType }}
        handleChange={handleTypeChange}
        alignment="horizontal"
        sx={{ marginBottom: '24px' }}
      />
    );
  };

  return (
    <Stack direction="column" gap={1}>
      <RenderRequestType />
      <TextInput
        maxLength={30}
        value={issueTitle}
        onChange={handleIssueTitle}
        placeholder="Enter Issue Title"
        maxWidth="320px"
        label={
          <Typography variant="bodyShort" color={theme.palette.brand.toyotaGray}>
            Issue Title <span className="spanError">*</span>
          </Typography>
        }
      />
      {selectedType === 'PDC' && (
        <Stack direction={'column'} gap={1}>
          <Typography variant="label" color={theme.palette.brand.toyotaGray}>
            Attach Appendix A Document
          </Typography>

          <FileUploadDragAndDrop
            supportedDocFormats={supportedDocFormats}
            handleAcceptedFiles={(files: readonly FileWithPath[]) => setAcceptedFiles(files)}
            acceptFormats={{ 'text/csv': ['.pdf'] }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default SelectHoldTypeModal;
