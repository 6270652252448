import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledGrid = styled(Grid)({
  display: 'block',
  // padding: '1.5rem 1rem',
});

export const StyledGridRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
});

export const StyledGrid2 = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

export const TypoGraphy = styled(Typography)({
  color: 'var(--sys-light-Text-variant, #58595B)',
  fontFamily: 'ToyotaType-Regular',
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1rem',
});

export const TypoGraphy2 = styled(Typography)({
  color: 'var(--sys-light-Text-default, #000)',
  fontFamily: 'ToyotaType-Regular',
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1rem',
});

export const StyledDatePicker = styled('div')`
  width: 22vw;
  direction: rtl;

  .MuiInputBase-root {
    width: 22vw;
    height: 40px;
  }

  .MuiInputBase-input {
    font-size: 13px;
    font-family: 'ToyotaType-Regular';
    color: #58595b;
    text-align: end;
  }

  .MuiInputAdornment-root {
    direction: rtl;
  }

  .MuiOutlinedInput-root {
    background-color: white;
  }
`;
