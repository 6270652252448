import { Box } from '@mui/material';
import { AppEventService } from '@scft/root-config';
import axios from 'axios';
import { LoadingSpinner } from 'cubee2e-cube-global-components';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.scss';
import FeatureAccess from './Components/AccessProvider/FeatureAccess';
import HoldRequestDetails from './Components/AllHoldRequest/HoldRequestDetails';
import { Sidebar } from './Components/Sidebar/Sidebar';
import { HoldManagement } from './pages/HoldManagement';
import { getHoldStatusCountsApi } from './store/features/HoldRequest/api';
import { getAppLoader, getSidebar } from './store/features/HoldRequest/selectors';
import { setAppLoader, setRefresh } from './store/features/HoldRequest/slice';
import { useAppDispatch, useAppSelector } from './store/hooks';
const CustomDrawer = lazy(() => import('./Components/CustomDrawer/CustomDrawer'));

export const getUserDetails = async (authidToken) => {
  const url = `https://graph.microsoft.com/beta/me`;
  const config = {
    headers: {
      Authorization: `${authidToken}`,
    },
  };
  try {
    let response = await axios.get(url, config);
    let status = response.status.toString();
    if (status !== '200' && status !== '201') {
      throw new Error();
    }
    const employeeId = response?.data?.employeeId
      ? parseInt(response?.data?.employeeId)
      : response?.data?.securityIdentifier.split('-').pop();
    const userName = response?.data?.mailNickname;
    const userInfo = {
      employeeId,
      userName,
    };

    console.log('gvbhjnkm', employeeId, userInfo);
    localStorage.setItem('HM-UserInfo', JSON.stringify(userInfo));
    return userInfo;
  } catch (error) {}
};

const App = () => {
  const auth = useAppSelector((state) => state.auth);
  const authidToken = auth.accessToken;
  const [mfeEvent, setMfeEvent] = React.useState<any>();
  const [sidebarShow, setSidebarShow] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getAppLoader);
  const sidebarOpen = useAppSelector(getSidebar);
  React.useEffect(() => {
    const subscription = AppEventService?.subscribeEvent()?.subscribe((event: any) => {
      if (event && event.to === 'HOLD') {
        setMfeEvent(event);
      }
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  //fetching logged user details
  useEffect(() => {
    getUserDetails(authidToken);
  }, []);

  useEffect(() => {
    console.log('TEST_EVT_UE:', mfeEvent);
    dispatch(setAppLoader(false));
    dispatch(setRefresh());
  }, [mfeEvent]);

  useEffect(() => {
    dispatch(getHoldStatusCountsApi());
  }, []);

  const ShowSidebar = (val) => {
    setSidebarShow(val);
  };
  const AppLayout = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: sidebarOpen ? '240px' : '56px',
            transition: 'width 0.3s',
            height: '100%',
            position: 'fixed',
            marginTop: '7.5rem',
            marginLeft: '1.125rem',
          }}
        >
          {sidebarShow && <Sidebar />}
          <Outlet />
        </Box>
        <Box
          sx={{
            marginLeft: sidebarShow ? (sidebarOpen ? '240px' : '56px') : '0px',
            transition: 'margin-left 0.3s',
            flexGrow: 1,
            height: '105vh',
            overflowY: 'scroll',
            paddingLeft: '16px',

            '&::-webkit-scrollbar': {
              width: 0,
            },
            scrollbarWidth: 'none',
          }}
        >
          <Routes>
            <Route
              path="/HoldManagement"
              element={
                <FeatureAccess featureName={'PDCHoldView'} ShowSidebar={ShowSidebar}>
                  <HoldManagement ShowSidebar={ShowSidebar} />
                </FeatureAccess>
              }
            />
            <Route
              path="/HoldManagement/QChold/:qcHoldId"
              element={
                <FeatureAccess featureName={'PDCHoldView'} ShowSidebar={ShowSidebar}>
                  <HoldRequestDetails ShowSidebar={ShowSidebar} />
                </FeatureAccess>
              }
            />
          </Routes>
        </Box>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner variant="loading-spinner" />
      </Box>
    );
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner variant="loading-spinner" />}>
        <CustomDrawer />
      </Suspense>
      <AppLayout />
    </BrowserRouter>
  );
};

export default App;
