export const BrowserStorageConst = {
    HMAccessToken: 'AccessToken',
    HMIdToken: 'IdToken'
}

export const noteOptions = [
  {
    seq_no: 1,
    note_name: 'Open',
  },
  {
    seq_no: 2,
    note_name: 'Open Export Monitor',
  },
  {
    seq_no: 3,
    note_name: 'Complete',
  },
  {
    seq_no: 4,
    note_name: 'Pending',
  },
  {
    seq_no: 5,
    note_name: 'Cancelled',
  },
  {
    seq_no: 6,
    note_name: 'Awaiting Request',
  },
  {
    seq_no: 7,
    note_name: 'PCR - Cancelled',
  },
  {
    seq_no: 8,
    note_name: 'PCR to QC',
  }
];

export const menuOptions = [
  {
    optionLabel: 'Move to \"Ready for Release\"',
    id: 'readyForRelease',
    section: 'top',
    disabled: false,
  }
];