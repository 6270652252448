import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { loginRequest } from '../../config/authConfig';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getMSToken } from '../../services/auth-actions';
import { setUser } from '../../store/features/auth/authSlice';
import { useAppDispatch } from '../../store/hooks';
import { BrowserStorage } from '../../utils/browserStorage';
import { BrowserStorageConst } from '../../utils/constants';

interface DecodedToken extends JwtPayload {
  exp?: number;
}

const Authenticator: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tokenExpiresAt] = useSessionStorage('HM-IdTokenExpiresAt', '0');
  const [isTokenValid, setTokenValid] = useState(false);
  const [refreshToken, setRefreshToken] = useState<boolean>(false);

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (instance && inProgress === InteractionStatus.None) {
      if (!isAuthenticated) {
        callAuth();
      } else if (isAuthenticated) {
        getAuthData(false);
        if (refreshToken) {
          window.location.reload();
          setRefreshToken(false);
        }
      }
    }
  }, [inProgress, isAuthenticated]);

  /**
   * Function to Handle the Login.
   */
  async function callAuth() {
    try {
      await instance.ssoSilent(loginRequest);
    } catch (error) {
      await instance.loginPopup(loginRequest);
    }
  }

  const getAuthData = async (renewal: boolean) => {
    const response: any = await getMSToken(instance, renewal);
    const auth = {
      msName: response?.account?.name,
      msUsername: response?.account?.username,
      msUserRoles: response?.account?.idTokenClaims?.roles,
      accessToken: response?.accessToken,
      idToken: response?.idToken,
    };

    BrowserStorage.setItem(BrowserStorageConst.HMAccessToken, response?.accessToken);
    BrowserStorage.setItem(BrowserStorageConst.HMIdToken, response?.idToken);
    const expireTime = getExpirationTimestamp(response?.idToken);
    BrowserStorage.setSessionItem('IdTokenExpiresAt', expireTime?.toString());
    dispatch(setUser(auth));
    setTokenValid(true);
  };

  const getExpirationTimestamp = (token: string): number | undefined => {
    try {
      const decoded: DecodedToken = jwtDecode<DecodedToken>(token);
      return decoded?.exp;
    } catch (error) {
      return undefined;
    }
  };

  useEffect(() => {
    if (parseInt(tokenExpiresAt) > 0) {
      let interval: NodeJS.Timeout;
      const timeRemaining: number = parseInt(tokenExpiresAt) * 1000 - Math.floor(Date.now());
      if (timeRemaining > 5 * 60000) {
        interval = setInterval(() => {
          refresh();
        }, timeRemaining - 5 * 60000);
      } else {
        refresh();
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [tokenExpiresAt, refreshToken, inProgress]);

  const refresh = () => {
    if (inProgress === InteractionStatus.None) {
      setRefreshToken(true);
      setTokenValid(false);
      BrowserStorage.clear();
      callAuth();
    }
  };

  return <>{isTokenValid && children}</>;
};

export default Authenticator;
