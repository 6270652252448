export const baseUrl = 'sample';

export const supportedDocFormats = ['.csv', '.xls', '.xlsx'];

export const maxAttempts = 20;

export const parseTimeout = 1000;

export const uploadStages = ['Uploading file', 'Parsing data', 'Validating Data'];

export const modalStages = [
  'Start Import',
  ...uploadStages,
  'Upload Failed',
  'Parsing Failed',
  'Validation Failed',
  'Preview',
  'SelectAction',
  'TableView',
];

export const importActions = [
  { label: 'Use to Search QC Vehicles', value: 'filter' },
  { label: 'Request Add to Hold', value: 'add' },
  { label: 'Update Status', value: 'update' },
];

export const getColumnName = (field: string) => {};
