const env = {
  clientId: '3ac6441c-121a-4bfe-8074-2a4603e8a128',
  authority: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
  navigateToLoginRequestUrl: false,
  redirectUri: 'https://one.vmgmt.cubeuat.toyota.com',
  API_BASE_URL: 'https://api.hm.cubeuat.toyota.com',
  DOCUMENT_URL: 'https://docs.hm.cubeuat.toyota.com',
  // GRAPHQL_BASE_URL: "https://graph.hm.cubeuat.toyota.com"
  GRAPHQL_BASE_URL: 'https://mhrsgaowanfjvkwptpyrrr6ghu.appsync-api.us-west-2.amazonaws.com/graphql',
  POLICY_URL: 'https://api.hm.cubeuat.toyota.com/v1/roleconsumption/policy',
  apiKey: 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
};

export const uatEnv = env;
