import moment from 'moment';

export const formatDate = (dateString: string) => {
  if (!!!dateString) {
    return '-';
  } else {
    {
      return new Date(dateString.replace('Z', ''))
        .toLocaleDateString()
        .split('/')
        .map((x) => (x.length > 1 ? x : `0${x}`))
        .join('/');
    }
  }
};

export const dateFormat = (unfrmDate: string | number | Date) => {
  if (unfrmDate === '' || unfrmDate === 'undefined' || unfrmDate === undefined || unfrmDate === null) {
    return '';
  } else {
    const newDate = new Date(unfrmDate);
    let formatDate = '';
    if (isNaN(newDate.getMonth()) || isNaN(newDate.getDate()) || isNaN(newDate.getFullYear())) {
      formatDate = '';
    } else {
      formatDate =
        ('0' + (newDate.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + newDate.getDate()).slice(-2) +
        '/' +
        newDate.getFullYear();
    }
    return formatDate;
  }
};

export const UTCDateFormat = (delayDate: string) => {
  if (delayDate === '' || delayDate === 'undefined' || delayDate === undefined || delayDate === null) {
    return '-';
  } else {
    return delayDate?.substring(5, 7) + `/` + delayDate?.substring(8, 10) + `/` + delayDate?.substring(0, 4);
  }
};

export const currentDateAndTime = (date: string) => {
  const newDate = date ? new Date(date) : new Date();
  let hours = newDate.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return (
    ('0' + (newDate.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + newDate.getDate()).slice(-2) +
    '/' +
    newDate.getFullYear() +
    ' at ' +
    ('0' + hours).slice(-2) +
    ':' +
    ('0' + newDate.getMinutes()).slice(-2) +
    ':' +
    ('0' + newDate.getSeconds()).slice(-2) +
    ' ' +
    ampm
  );
};

//September 12, 2024 at 7:00AM
export const getCurrentDateAndTime = () => {
  const formattedDate = moment().format('MMMM DD, YYYY [at] h:mma');

  // Capitalize the AM/PM part
  return formattedDate.replace(/(am|pm)/, (match) => match.toUpperCase());
};
