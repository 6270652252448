import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AccessContext } from './AccessProvider';

const FeatureAccess: React.FC<{
  children: React.ReactNode;
  featureName: string;
  ShowSidebar: Function;
}> = ({ children, featureName, ShowSidebar }) => {
  const { accessPolicy } = useContext(AccessContext);
  const location = useLocation();
  useEffect(() => {
    console.log('AccessPolicy', accessPolicy);
  }, [accessPolicy]);

  const renderByCondition = () => {
    if (!accessPolicy?.PolicyEnabled) {
      return <></>;
    } else if (accessPolicy?.PolicyEnabled && featureName && accessPolicy[featureName]) {
      return children;
    } else {
      ShowSidebar(false);
      return <Navigate to={`/HoldManagement/UnAuthorized`} />;
    }
  };
  return <>{renderByCondition()}</>;
};

export default FeatureAccess;
