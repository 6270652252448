import { devEnv } from "./env.dev";
import { localEnv } from "./env.local";
import { qaEnv } from "./env.qa";
import { uatEnv } from "./env.uat";

export const getEnv = () => {
  let env: any = {};
  switch (window.location.host) {
    case "localhost:3000":
      env = localEnv;
      break;
    case "one.vehmgmtdev.toyota.com":
      env = devEnv;
      break;
    case "one.vmgmt.cubedev.toyota.com":
      env = devEnv;
      break;
    case "one.vmgmt.cubeqa.toyota.com":
      env = qaEnv;
      break;
    case "one.vmgmt.cubeuat.toyota.com":
      env = uatEnv;
      break;
    default:
      break;
  }
  return env;
};
