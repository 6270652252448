import { getContrastRatio } from '@mui/material';

import { colors } from './colors';
import { ThemeShape } from '../types';

const _getContrastText = (color: string) =>
  getContrastRatio(color, colors.common.white) > 3 ? colors.common.white : colors.common.black;

export const palette: ThemeShape.PaletteShape = {
  divider: colors.secondary[80],
  common: { ...colors.common },
  background: {
    default: colors.secondary[100],
    light: colors.secondary[90],
    dark: colors.secondary[80],
    paper: colors.neutral[100],
    table: '#FCFCFD',
    surfacePaper: colors.common.white,
    surfaceLight: colors.secondary[90],
    surfaceDark: colors.secondary[20],
    surfaceDarkest: colors.secondary[10],
  },
  text: {
    primary: colors.common.black,
    secondary: colors.secondary[30],
    disabled: colors.secondary[50],
    white: colors.common.white,
    link: colors.primary[50],
    error: colors.error[50],
  },
  neutral: {
    n92: '#e6e6e6',
    main: colors.common.transparent,
    dark: colors.neutral[10],
    light: '#767676',
    contrastText: colors.common.white,
    ...colors.neutral,
  },
  primary: {
    main: colors.common.black,
    light: colors.secondary[90],
    dark: colors.secondary[20],
    contrastText: _getContrastText(colors.common.black),
    ...colors.primary,
  },
  secondary: {
    main: colors.secondary[10],
    light: colors.secondary[90],
    dark: colors.secondary[20],
    contrastText: _getContrastText(colors.secondary[10]),
    ...colors.secondary,
  },
  success: {
    main: colors.confirmationGreen[50],
    light: colors.confirmationGreen[80],
    dark: colors.confirmationGreen[30],
    medium: colors.confirmationGreen[100],
    contrastText: _getContrastText(colors.confirmationGreen[50]),
    ...colors.confirmationGreen,
  },
  warning: {
    main: colors.warning[50],
    light: colors.warning[80],
    dark: colors.warning[30],
    medium: colors.warning[100],
    contrastText: _getContrastText(colors.warning[50]),
    ...colors.warning,
  },
  info: {
    main: colors.primary[50],
    light: colors.primary[100],
    dark: colors.primary[20],
    medium: colors.primary[100],
    contrastText: _getContrastText(colors.primary[50]),
    ...colors.primary,
  },
  error: {
    main: colors.error[50],
    light: colors.error[80],
    dark: colors.error[30],
    medium: colors.error[100],
    contrastText: _getContrastText(colors.error[50]),
    ...colors.error,
  },
  purple: { ...colors.purple },
  teal: { ...colors.teal },
  yellow: { ...colors.yellow },
  cyan: { ...colors.cyan },
  magenta: { ...colors.magenta },
  brand: {
    toyotaRed: '#eb0a1e',
    toyotaGray: '#58595b',
    lexusGold: '#b6a171',
    lexusGray: '#adadad',
  },
  data: {
    darkTeal: '#004646',
    lightTeal: '#54aaaa',
    orange: '#ff832b',
    darkRed: '#8b0611',
    errorContainer: '#feebed',
    onErrorContainer: '#76050e',
    purple: '#5900d9',
  },
};
