import moment from 'moment';
import { DropdownOptions, FileAttachments, FileMetadata } from '../store/features/HoldTypeDetails/types';
import { DataTableShape } from '../types';

export const formatNumber = (number: Number) => {
  return (number || 0).toLocaleString('en-US');
};

export const formatInlineFilters = (
  inlineFilters: DataTableShape.Filter[],
  val: DataTableShape.Filter,
): DataTableShape.Filter[] => {
  const filters = [...inlineFilters, val];
  const result = filters.reduce((acc: { [key: string]: DataTableShape.Filter }, { field, value, type, name }) => {
    acc[field] ??= { field: field, value: [], type: type, name: name };
    if (Array.isArray(value)) {
      acc[field].value = value;
    } else {
      (acc[field].value as Array<string | number | boolean>)?.push(value);
    }

    return acc;
  }, {});
  return Object.values(result);
};

export const createSortedOptions = (items, labelKey, idKey) => {
  if (Array.isArray(items)) {
    const options = items
      .map((item) => ({
        optionLabel: item[labelKey] ? item[labelKey].toUpperCase() : 'UNKNOWN',
        id: item[idKey] || 'N/A',
      }))
      .filter((option) => option.optionLabel !== 'UNKNOWN');

    return options.sort((a, b) => a.optionLabel.localeCompare(b.optionLabel));
  }
  return [];
};

export const formatFileAttachment = (reduxFileMetaData: FileAttachments): FileMetadata[] => {
  let fileMetadata = [];
  if (reduxFileMetaData?.fileMetadata) {
    reduxFileMetaData.fileMetadata.forEach((fileAttachmentData, index) => {
      fileMetadata.push({
        uploadFilePath: fileAttachmentData?.uploadFilePath,
        fileTitle: fileAttachmentData?.fileTitle,
        fileDescription: fileAttachmentData?.fileDescription || '',
        pathStore: fileAttachmentData?.uploadFilePath,
        removeFile: false,
        isdeletable: fileAttachmentData?.isdeletable,
        documentId: fileAttachmentData?.documentId,
        documentType: fileAttachmentData?.documentType,
        name: fileAttachmentData?.name,
        uploadedBy: fileAttachmentData?.uploadedBy,
        uploadedDate: fileAttachmentData?.uploadedDate,
        uploadedUserName: fileAttachmentData?.uploadedUserName,
      });
    });
  }
  return fileMetadata;
};

export const formatIssueDetails = (issueDetails) => {
  return {
    requestingDivision: issueDetails.requestingDivision?.id || '',
    issueHLRootCause: issueDetails.rootCause?.id || '',
    issueDefectCategory: issueDetails.defectCategory?.id || '',
    dateIssueFound: issueDetails.dateFound ? moment(issueDetails.dateFound).format('MM/DD/YYYY') : '',
    placeFound: issueDetails.placeFound || '',
    detailedIssueDescription: issueDetails.detailedIssueDescription || '',
    issueTitle: issueDetails.issueTitle || '',
    problemReportingChain: Array.isArray(issueDetails.problemReportingChain)
      ? issueDetails.problemReportingChain.join(' -> ')
      : issueDetails.problemReportingChain,
    issueNotificationDate: moment(issueDetails.issueNotificationDate).format('MM/DD/YYYY'),
  };
};

export const formatInternalDetails = (details) => {
  return {
    companyResponsible: details.companyResponsible?.id || '',
    icf: details.icf?.id || '',
    supplierResponsible: details.supplierResponsible?.id || '',
  };
};

export const formatHoldDetails = (holdDetails) => {
  return {
    anticipatedDelayTiming: holdDetails.anticipatedDelayTiming.length > 0 ? holdDetails.anticipatedDelayTiming : null,
    pdcPEAssignee: holdDetails.pdcPEAssignee.optionLabel,
    employeeId: holdDetails.pdcPEAssignee.id,
    riskRank: holdDetails.riskRank.id,
  };
};

export const formatApprovals = (approvals) => {
  return {
    approvalUsers: approvals.map((approver) => {
      return {
        userId: 100,
        firstName: approver.firstName,
        lastName: approver.lastName,
        jobTitle: approver.jobTitle,
        email: approver.email,
      };
    }),
  };
};

export const validateDropdownValue = (
  dropdownOptions: DropdownOptions,
  dropdown: string,
  parsedData: string,
): string => {
  let dropdownArray = dropdownOptions[dropdown];
  return dropdownArray.map((x) => x.optionLabel).includes(parsedData) ? parsedData : '';
};

export const formatParts = (partsArray) => {
  return {
    partDtls: partsArray.map((part) => {
      return {
        partNumber: part.partNumber,
        partName: part.partName,
        partSupplier: part.partSupplier,
        icfForPartIssue: part?.icfForPartIssue?.id,
        supplierCode: part.supplierCode,
      };
    }),
  };
};

export const modelSpecs = (modelSpecs) => {
  return {
    vehicleDtl: modelSpecs.vehicleDtl.map((detail) => {
      return {
        vehicleTypeSpecification: detail.vehicleTypeSpecification,
        modelYear: detail.modelYear?.value?.id || '',
        series: detail.series?.value.id || '',
        model: detail.model?.value.map((model) => model.id),
      };
    }),
    startDateType: modelSpecs.startDateType?.optionLabel || '',
    vinStartDateTime: modelSpecs.vinStartDateTime || '',
    vinStartLogicReasoning: modelSpecs.vinStartLogicReasoning,
    endDateType: modelSpecs.endDateType?.optionLabel || '',
    vinEndDateTime: modelSpecs.vinEndDateTime || '',
    vinEndLogicReasoning: modelSpecs.vinEndLogicReasoning || '',
  };
};

export const seriesDropdownValues = (series, modelYear) => {
  if (modelYear === '') {
    return [];
  }
  const filteredSeries = series.filter((model) => model.modelYear.toString() === modelYear);
  const seriesNames = new Set(
    filteredSeries
      .filter((series) => series.orderingSeriesName !== null && series.modelCode != null)
      .map((series) => series.orderingSeriesName),
  );
  const sortedSeriesNames = Array.from(seriesNames).sort((a: any, b: any) => a.localeCompare(b));
  return [...new Set(sortedSeriesNames)].map((series) => ({ id: series, optionLabel: series }));
};

export const modelModelDropdownValues = (models, modelYear, modelSeries) => {
  if (modelYear === '' || modelSeries === '') {
    return [];
  }
  const filteredModels = models
    .filter((model) => model.modelYear.toString() === modelYear)
    .filter((model) => model.orderingSeriesName === modelSeries);
  return filteredModels
    .filter((model) => model.modelCode !== null)
    .map((model) => ({ id: model.modelCode, optionLabel: model.modelCode }));
};
