import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker, Dropdown, TextInput } from 'cubee2e-cube-global-components';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  getDefectCategoryOptions,
  getEditPanel,
  getHoldStatus,
  getHoldType,
  getIssueDetails,
  getRequestingDivisionOptions,
  getRootCauseOptions,
  getYardToPDCState,
} from '../../../../../store/features/HoldTypeDetails/selectors';
import { setIssueDetails } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import theme from '../../../../../theme';
import { RenderItem } from '../RenderItem';
import './IssueDetails.scss';
//Styles
const StyledGridRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '1.5rem 1rem',
});
const StyledGridColumn = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

const IssueDetails = ({ setFooterDisable }) => {
  const [tagValue, setTagValue] = useState<string>();
  const [reqDivDropdown, setReqDivDropdown] = useState<boolean>(false);
  const [RootCauDropdown, setRootCauDropdown] = useState<boolean>(false);
  const [defCatDropdown, setDefCatDropdown] = useState<boolean>(false);
  const [dateFoundOpen, setDateFoundOpen] = useState(false);
  const [notificationDateOpen, setNotificationDateOpen] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  //const issueDetails: IssueDetailsType = useAppSelector(getIssueDetails);
  const issueDetails = useAppSelector(getIssueDetails);

  const editPanel = useAppSelector(getEditPanel);
  const holdStatus = useAppSelector(getHoldStatus);
  const requestingDivisionOptions = useAppSelector(getRequestingDivisionOptions);
  const rootCauseOptionsList = useAppSelector(getRootCauseOptions);
  const defectCategoryOptionsList = useAppSelector(getDefectCategoryOptions);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const holdType = useAppSelector(getHoldType);

  const [updatedData, setUpdatedData] = useState<any>(issueDetails);
  useEffect(() => {
    setUpdatedData({ ...issueDetails });
  }, [issueDetails]);

  const handleReqDivDropdownOpen = () => {
    setReqDivDropdown(true);
  };
  const handleReqDivDropdownClose = () => {
    setReqDivDropdown(false);
  };
  const handleRootCauDropdownOpen = () => {
    setRootCauDropdown(true);
  };
  const handleRootCauDropdownClose = () => {
    setRootCauDropdown(false);
  };
  const handleDefCatDropdownOpen = () => {
    setDefCatDropdown(true);
  };
  const handleDefCatDropdownClose = () => {
    setDefCatDropdown(false);
  };

  const handleBlur = () => {
    dispatch(setIssueDetails(updatedData));
  };

  const handleReqDiv = (option: { optionLabel: string; id: string }) => {
    dispatch(setIssueDetails({ ...updatedData, requestingDivision: option }));
    setFooterDisable(false);
  };

  const handleIssueTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    let issueT = event.target.value;
    setUpdatedData({
      ...updatedData,
      issueTitle: issueT,
    });
    setFooterDisable(false);
  };

  const handleRootCause = (option: any) => {
    dispatch(setIssueDetails({ ...updatedData, rootCause: option }));
    setFooterDisable(false);
  };

  const handleDefectCategory = (option: any) => {
    dispatch(setIssueDetails({ ...updatedData, defectCategory: option }));
    setFooterDisable(false);
  };

  const handlePlaceFound = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedData({
      ...updatedData,
      placeFound: event.target.value,
    });
    setFooterDisable(false);
  };

  const handleIssueDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const issueD = event.target.value;
    setUpdatedData({
      ...updatedData,
      detailedIssueDescription: issueD,
    });
    setFooterDisable(false);
  };

  const handleDateFound = (date: Dayjs | null) => {
    if (date) {
      dispatch(setIssueDetails({ ...updatedData, dateFound: date.toString() }));
      setFooterDisable(false);
    }
  };

  const handleNotificationFound = (date: Dayjs | null) => {
    if (date) {
      dispatch(setIssueDetails({ ...updatedData, notificationDate: date.toString() }));
      setFooterDisable(false);
    }
  };

  const addTags = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tagValue) {
      setUpdatedData({
        ...updatedData,
        problemReportingChain: [...updatedData.problemReportingChain, tagValue.replaceAll('\n', '')],
      });
      setTagValue('');
      setFooterDisable(false);
    }
  };

  const deleteTag = (val: string) => {
    const remainingTags = updatedData.problemReportingChain?.filter((t) => t !== val);
    setUpdatedData({
      ...updatedData,
      problemReportingChain: remainingTags,
    });
    setFooterDisable(false);
  };
  const splitProblemReportingChain = Array.isArray(updatedData.problemReportingChain)
    ? typeof updatedData.problemReportingChain[0] === 'string' && updatedData.problemReportingChain.length === 1
      ? updatedData.problemReportingChain[0].split('->').map((item) => item.trim())
      : updatedData.problemReportingChain
    : [];

  const handleProblemReportingChain = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTagValue(value);
  };
  const renderItem = (label: string, value: ReactNode, isRequired: boolean = false) => {
    return <RenderItem label={label} value={value} isRequired={isRequired} />;
  };
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const formatDate = (delayDate: string) => {
    if (!delayDate || delayDate === 'undefined' || delayDate === null) {
      return null;
    }

    // Parse the ISO date string
    const date = new Date(delayDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    // Extract month, day, and year, and format as MM/DD/YYYY
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };
  const isEdit = holdStatus === 'Draft' || editPanel === '2' || isyardToPDC;

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center', padding: '1rem' }}>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Issue Title',
          !isEdit ? (
            updatedData.issueTitle ?? '-'
          ) : (
            <TextInput
              label=""
              maxWidth="440px"
              value={updatedData.issueTitle}
              onChange={handleIssueTitle}
              onBlur={handleBlur}
            />
          ),
          isEdit ? true : false,
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Notification Date',
          !isEdit ? (
            formatDate(updatedData.notificationDate) ?? '-'
          ) : (
            <DatePicker
              value={updatedData.notificationDate ? dayjs(updatedData.notificationDate) : null}
              handleChange={handleNotificationFound}
              open={notificationDateOpen}
              handleOpen={(value: boolean) => setNotificationDateOpen(value)}
              disablePastDays={false}
              width="440px"
            />
          ),
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Requesting Division',
          !isEdit ? (
            updatedData.requestingDivision.optionLabel ?? '-'
          ) : (
            <Dropdown
              variant="singleSelect"
              selectedOptions={updatedData.requestingDivision}
              onOpen={handleReqDivDropdownOpen}
              onClose={handleReqDivDropdownClose}
              onChange={handleReqDiv}
              optionsList={requestingDivisionOptions}
              open={reqDivDropdown}
              sx={{ width: '440px' }}
            />
          ),
          isEdit ? true : false,
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Place Found',
          !isEdit ? (
            updatedData.placeFound ?? '-'
          ) : (
            <TextInput
              maxWidth="440px"
              label=""
              value={updatedData.placeFound}
              onChange={handlePlaceFound}
              ref={inputRef}
              onBlur={handleBlur}
            />
          ),
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Date Found',
          !isEdit ? (
            formatDate(updatedData.dateFound) ?? '-'
          ) : (
            <DatePicker
              value={updatedData.dateFound ? dayjs(updatedData.dateFound) : null}
              handleChange={handleDateFound}
              open={dateFoundOpen}
              handleOpen={(value: boolean) => setDateFoundOpen(value)}
              disablePastDays={false}
              width="440px"
            />
          ),
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Problem Reporting Chain',
          !isEdit ? (
            updatedData.problemReportingChain ?? '-'
          ) : (
            <div className="tagInput">
              <div className="tagInputChild">
                {splitProblemReportingChain.map((item, index) => (
                  <React.Fragment key={index}>
                    <button>
                      {item}
                      <span onClick={() => deleteTag(item)}>X</span>
                    </button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 12" fill="none">
                      <path
                        d="M7.99984 2.66602L7.05984 3.60602L10.7798 7.33268H2.6665V8.66602H10.7798L7.05984 12.3927L7.99984 13.3327L13.3332 7.99935L7.99984 2.66602Z"
                        fill="#58595B"
                      />
                    </svg>
                  </React.Fragment>
                ))}
                <input
                  type="text"
                  value={tagValue}
                  onChange={(e) => handleProblemReportingChain(e)}
                  onKeyDown={addTags}
                  style={{ minHeight: '2.375rem' }}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          ),
          false,
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Root Cause (High Level)',
          !isEdit ? (
            updatedData.rootCause.optionLabel ?? '-'
          ) : (
            <Dropdown
              sx={{ width: '440px' }}
              placeholderText="Select an option"
              optionsList={rootCauseOptionsList}
              selectedOptions={updatedData.rootCause}
              onOpen={handleRootCauDropdownOpen}
              onClose={handleRootCauDropdownClose}
              open={RootCauDropdown}
              onChange={handleRootCause}
            />
          ),
          false,
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderItem(
          'Defect Category',
          !isEdit ? (
            updatedData.defectCategory.optionLabel ?? '-'
          ) : (
            <Dropdown
              sx={{ width: '440px' }}
              optionsList={defectCategoryOptionsList}
              selectedOptions={updatedData.defectCategory}
              onOpen={handleDefCatDropdownOpen}
              onClose={handleDefCatDropdownClose}
              open={defCatDropdown}
              onChange={handleDefectCategory}
            />
          ),
          false,
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {renderItem(
          'Detailed Issue Description (Root Cause)',
          !isEdit ? (
            updatedData.detailedIssueDescription ?? '-'
          ) : (
            <TextInput
              multiline={true}
              fullWidth
              label=""
              value={updatedData.detailedIssueDescription}
              placeholder="Input text"
              onChange={handleIssueDescription}
              onBlur={handleBlur}
              sx={{
                backgroundColor: 'white',
                width: '76.8vw',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '22vw',
                },
                [theme.breakpoints.between('sm', 'md')]: {
                  maxWidth: '22vw',
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  maxWidth: '75.8vw',
                },
              }}
            />
          ),
          false,
          // holdType === 'PDC' || isEdit ? true : false,
        )}
      </Grid>
    </Grid>
  );
};

export default IssueDetails;
