import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HoldDetailsMetrics from './HoldDetailsMetrics';
import VehicleDataTable from './VehicleDataTable';
import './Vehicles.scss';

const Vehicles = (props: {
  requestRefresh: number;
  holdId: string;
  submissionDate: string;
  onRefresh: Function;
  selectedKPIFilter: Object | null;
  setSelectedKPIFilter: Function;
  handleTableUpdate: Function;
  tableData: Object;
  sendRefreshCall: Function;
  holdType: string;
  holdStatus: string;
  lastrefreshedDT: string;
}) => {
  const [refresh, setRefresh] = useState<number>(0);

  const tableUpdated = (val) => {
    // the callback. Use a better namesetsolventstate(solventstate);
    if (val === '1') {
      props.sendRefreshCall('1');
    }
  };

  const vehicleActionUpdate = () => {
    setRefresh((prev) => prev + 1);
    props.onRefresh();
    props.sendRefreshCall('1');
  };

  useEffect(() => {
    setRefresh((prev) => prev + 1);
  }, [props.requestRefresh]);

  return (
    <Stack
      sx={{
        flexDirection: 'column', marginTop: "7.5rem"
      }}
    >
      <HoldDetailsMetrics
        onRefresh={() => {
          setRefresh((prev) => prev + 1);
          props.onRefresh();
          props.sendRefreshCall('1');
        }}
        setKPIFilter={(data) => {
          props.setSelectedKPIFilter(data);
        }}
        lastrefreshedDT={props.lastrefreshedDT}
      />
      <VehicleDataTable
        holdId={props.holdId}
        submissionDate={props.submissionDate}
        selectedKPIFilter={props.selectedKPIFilter}
        refresh={refresh}
        handleTableUpdate={props.handleTableUpdate}
        vehicleAction={vehicleActionUpdate}
        tableData={props.tableData}
        tableUpdated={tableUpdated}
        holdType={props.holdType}
        holdStatus={props.holdStatus}
      />
    </Stack>
  );
};
export default Vehicles;
