import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model } from '../../../types';
import { holdRequest } from './initialState';
import {
  Comments,
  DropdownOptions,
  FileAttachments,
  HoldDetails,
  IssueDetails,
  IssueResolution,
  ModelSpecification,
  partDetail,
  Parts,
  RequesterApprovals,
  ToyotaInternalDetails,
} from './types';

export const holdRequestStateSlice = createSlice({
  name: 'HoldTypeDetails',
  initialState: holdRequest,
  reducers: {
    //Hold Request Details Reducers

    setIssueDetails: (state, action: PayloadAction<IssueDetails>) => {
      state.issueDetails = action.payload;
    },
    setToyotaInternalDetails: (state, action: PayloadAction<ToyotaInternalDetails>) => {
      state.toyotaInternalDetails = action.payload;
    },
    setIssueResolution: (state, action: PayloadAction<IssueResolution>) => {
      state.issueResolution = action.payload;
    },
    setModelSpecification: (state, action: PayloadAction<ModelSpecification>) => {
      state.modelSpecification = action.payload;
    },
    setVehicleTypeSpecification: (state, action: PayloadAction<{ index: string; update: string }>) => {
      state.modelSpecification.vehicleDtl[action.payload.index].vehicleTypeSpecification = action.payload.update;
    },
    setParts: (state, action: PayloadAction<Parts>) => {
      state.parts = action.payload;
    },
    setPartDetail: (state, action: PayloadAction<{index: number, update: partDetail}>) => {
      state.parts.partDtls[action.payload.index] = action.payload.update;
    },
    setRequesterApprovals: (state, action: PayloadAction<RequesterApprovals>) => {
      state.requesterApprovals = action.payload;
    },
    setFileAttachments: (state, action: PayloadAction<FileAttachments>) => {
      state.fileAttachments = action.payload;
    },

    //Hold Request State Reducers
    setEditPanel: (state, action: PayloadAction<string>) => {
      state.requestState.editPanel = action.payload;
    },
    setHoldReqNumber: (state, action: PayloadAction<string>) => {
      state.requestState.holdRequestNumber = action.payload;
    },
    setDetailSuccess: (state) => {
      state.requestState.updateSuccess = true;
    },
    setDetailFail: (state) => {
      state.requestState.updateSuccess = false;
    },
    setFetchingDetailsData: (state, action: PayloadAction<boolean>) => {
      state.requestState.loading = action.payload;
    },
    setHoldType: (state, action: PayloadAction<string>) => {
      state.holdType = action.payload;
    },
    setyardToPDCState: (state, action: PayloadAction<boolean>) => {
      state.yardToPDC = action.payload;
    },
    setCreateHoldType: (state, action: PayloadAction<string>) => {
      state.requestState.createHoldType = action.payload;
    },
    setHoldStatus: (state, action: PayloadAction<string>) => {
      state.holdStatus = action.payload;
    },
    setIsDraft: (state, action: PayloadAction<boolean>) => {
      state.requestState.isDraft = action.payload;
    },
    setCommentsList: (state, action: PayloadAction<Comments[]>) => {
      state.requestState.commentList = action.payload;
    },
    setPendingApprovalType: (state, action: PayloadAction<string>) => {
      state.pendingApprovalType = action.payload;
    },

    setUpdatedUserID: (state, action: PayloadAction<string>) => {
      state.updatedUserId = action.payload;
    },

    setDocumentId: (state, action: PayloadAction<string>) => {
      state.requestState.documentId = action.payload;
    },
    setDropdownOptions: (state, action: PayloadAction<DropdownOptions>) => {
      state.dropdownOptions = action.payload;
    },
    //dropdown reducers
    setPDCAssigneeDropdownOptions: (
      state,
      action: PayloadAction<{ optionLabel: string; id: string; employeeId: string }[]>,
    ) => {
      state.dropdownOptions.pdcAssigneeDropdownOptions = action.payload;
    },
    setRiskRankOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.riskRankOptions = action.payload;
    },
    setRequestingDivisionOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.requestingDivisionOptions = action.payload;
    },
    setDefectCategoryOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.defectCategoryOptions = action.payload;
    },
    setRootCauseOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.rootCauseOptions = action.payload;
    },
    setCompanyResponsibleOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.companyResponsibleOptions = action.payload;
    },
    setSupplierResponsibleOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.supplierResponsibleOptions = action.payload;
    },
    setStartDateTypeOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.startDateTypeOptions = action.payload;
    },
    setSeriesDropdownOptions: (state, action: PayloadAction<{ optionLabel: string; id: string }[]>) => {
      state.dropdownOptions.seriesDropdownOptions = action.payload;
    },
    setModelDropdownOptions: (state, action: PayloadAction<Model[]>) => {
      state.dropdownOptions.modelDropdownOptions = action.payload;
    },
    setCreatedUserData: (state, action: PayloadAction<any>) => {
      state.createdUserData = action.payload;
    },
    setEdit: (state, action: PayloadAction<boolean>) => {
      state.requestState.edit = action.payload;
    },
    setHoldDetails: (state, action: PayloadAction<HoldDetails>) => {
      state.holdDetails = action.payload;
    },
    setHoldTypeRefresh: (state, action: PayloadAction<HoldDetails>) => {
      state.issueDetails = holdRequest.issueDetails;
      state.modelSpecification = holdRequest.modelSpecification;
      state.issueResolution = holdRequest.issueResolution;
      state.toyotaInternalDetails = holdRequest.toyotaInternalDetails;
      state.holdDetails = holdRequest.holdDetails;
      state.fileAttachments = holdRequest.fileAttachments;
      state.parts = holdRequest.parts;
      state.requesterApprovals = holdRequest.requesterApprovals;
      state.holdType = holdRequest.holdType;
      state.holdStatus = holdRequest.holdStatus;
      state.createdBy = holdRequest.createdBy;
      state.createdUserData = holdRequest.createdUserData;
      state.yardToPDC = holdRequest.yardToPDC;
      state.pendingApprovalType = holdRequest.pendingApprovalType;
      state.updatedUserId = holdRequest.updatedUserId;
      state.initiatedBy = holdRequest.initiatedBy;
      state.comments = holdRequest.comments;
      state.requestState = holdRequest.requestState;
      state.dropdownOptions = holdRequest.dropdownOptions;
    },
  },
});

export const {
  setIssueDetails,
  setToyotaInternalDetails,
  setIssueResolution,
  setModelSpecification,
  setVehicleTypeSpecification,
  setParts,
  setPartDetail,
  setRequesterApprovals,
  setFileAttachments,
  setEditPanel,
  setHoldReqNumber,
  setDetailSuccess,
  setDetailFail,
  setFetchingDetailsData,
  setHoldType,
  setyardToPDCState,
  setCreateHoldType,
  setHoldStatus,
  setIsDraft,
  setPDCAssigneeDropdownOptions,
  setCommentsList,
  setRiskRankOptions,
  setPendingApprovalType,
  setUpdatedUserID,
  setDocumentId,
  setRequestingDivisionOptions,
  setDefectCategoryOptions,
  setRootCauseOptions,
  setCompanyResponsibleOptions,
  setSupplierResponsibleOptions,
  setStartDateTypeOptions,
  setCreatedUserData,
  setEdit,
  setSeriesDropdownOptions,
  setModelDropdownOptions,
  setDropdownOptions,
  setHoldDetails,
  setHoldTypeRefresh,
} = holdRequestStateSlice.actions;

export default holdRequestStateSlice.reducer;
