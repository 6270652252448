import { FiberManualRecord } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Button, Icon, Menu } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import { getViewUpdated } from '../../store/features/HoldRequest/selectors';
import { useAppSelector } from '../../store/hooks';

export const SavePageView = ({
  savePageViewList,
  selectedPageView,
  viewUpdated,
  handleMenuItemClick,
}: {
  savePageViewList: any;
  selectedPageView: string;
  viewUpdated: boolean;
  handleMenuItemClick: (id: string) => void;
}) => {
  const viewUpdate = useAppSelector(getViewUpdated);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMenuItems, setSelectedMenuItems] = useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let savePageViewOptions: any = [
    {
      id: 'saveAs',
      optionLabel: 'Save as...',
      section: 'top',
    },
    { id: 'save', optionLabel: 'Save', section: 'top', disabled: !!!selectedPageView || !viewUpdate },
    { id: 'managePageView', optionLabel: 'Manage Page Views', section: 'top' },
    {
      id: '',
      optionLabel: `PAGE VIEWS (
                          ${
                            savePageViewList && Object.keys(savePageViewList).length > 0
                              ? savePageViewList?.pinnedViews?.length || 0
                              : 0
                          }
                          )`,
      section: 'bottom',
    },
  ];
  if (!!selectedPageView) {
    savePageViewOptions.push({ id: 'default', optionLabel: 'Default View', section: 'top' });
  }

  if (savePageViewList && Object.keys(savePageViewList).length > 0 && savePageViewList['pinnedViews'].length > 0) {
    savePageViewList['pinnedViews']
      .sort((a, b) => parseInt(a.viewOrder) - parseInt(b.viewOrder))
      .map(
        (view) =>
          savePageViewOptions.push({
            section: 'bottom',
            id: view.viewName,
            optionLabel: (
              <>
                <Typography variant="bodyShort">
                  {view.viewName}
                  {view.isDefault && <Icon name="Star" fontSize="small" sx={{ margin: `4px 4px 0` }} />}
                </Typography>
              </>
            ),
          }),
        // <MenuItem key={view.viewName} value={view.viewName} sx={itemStyles} onClick={() => onClickViewName(view)}>
        //   {applyFontFamily(view.viewName)}
        //   {view.isDefault && <Star fontSize="24px" sx={{ margin: `0 4px 0` }} />}
        // </MenuItem>
      );
  }

  const onActionSelect = (selectedItems: string[]) => {
    setSelectedMenuItems(selectedItems);
    if (!!selectedItems[0]) {
      handleMenuItemClick(selectedItems[0]);
    }
  };

  return (
    <Menu
      variant="singleSelect"
      targetElement={
        <Button
          label={
            <Stack direction={'row'}>
              <Typography variant="bodyShort" sx={{
                display: 'flex',
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                {(!!selectedPageView && viewUpdate) && <FiberManualRecord sx={{ color: '#E66C02', height:"16px" }} />}
                {!!selectedPageView ? `Active: ${selectedPageView}` : 'Save Page View'}
              </Typography>
            </Stack>
          }
          icon={<Icon name="KeyboardArrowDown" fontSize="medium" />}
          onClick={handleClick}
          variant="tertiary"
          size="medium"
          sx={{height: "40px"}}
        />
      }
      anchorEl={anchorEl}
      handleClose={handleClose}
      onActionSelect={onActionSelect}
      selectedItems={selectedMenuItems}
      optionsList={savePageViewOptions}
    />
  );
};
