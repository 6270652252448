import { Grid, Typography, styled } from '@mui/material';
import { Button, Modal, StructuredList } from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import {
  getEditPanel,
  getHoldStatus,
  getParts,
  getRequestingDivisionOptions,
  getYardToPDCState,
} from '../../../../../store/features/HoldTypeDetails/selectors';
import { setParts } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { AccordionContent } from '../../../../../types/Shapes/Accordion.shape';
import PartDetails from './PartFields';
import './Parts.scss';

type RowData = {
  id: number;
  data: {
    partno: string;
    name: string;
    supplier: string;
    icfForPartIssue: string;
    suppliercode: string;
  };
  isActive: boolean;
};
const StyledGrid = styled(Grid)({
  display: 'block',
  padding: '16px 8px 16px 8px',
});

const StyledGridRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
});
const StyledGridRow2 = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '1.5rem 1rem',
});
const StyledGridColumn = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});
const DeleteGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '.25rem',
  alignSelf: 'stretch',
  marginBottom: '1rem',
});
const CancelTypography = styled(Typography)({
  color: '#000',
  fontFamily: 'ToyotaType-Semibold, sans-serif',
  fontSize: '1.5rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '2rem',
});
const CancelTypography2 = styled(Typography)({
  color: 'var(--sys-light-Text-variant, #58595B)',
  fontFamily: "'ToyotaType-Regular', sans-serif",
  fontSize: '.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.25rem' /* 142.857% */,
});
const Parts: React.FC<AccordionContent> = ({ setFooterDisable }) => {
  const [edit, setEdit] = useState<boolean>(true);
  const editPanel = useAppSelector(getEditPanel);
  const holdStatus = useAppSelector(getHoldStatus);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const dispatch = useAppDispatch();
  const parts = useAppSelector(getParts);
  const [showModal, setShowModal] = useState<number>(-1);
  const [icfDropdown, setICFDropdown] = useState<boolean>(false);
  const icfOptionList = useAppSelector(getRequestingDivisionOptions);

  const handleAddPart = () => {
    setFooterDisable(false);
    const newPart = {
      id: parts.length,
      partNumber: '',
      partName: '',
      partSupplier: '',
      supplierCode: '',
      icfForPartIssue: {},
    };
    dispatch(
      setParts({ ...parts, partDtls: [...parts.partDtls, newPart] }));
  };

  const handleDeletePart = () => {
    let index = showModal;
    dispatch(
      setParts({ ...parts, partDtls: parts.partDtls.filter((p,i) => i !== index) }));
    setFooterDisable(false);
    setShowModal(-1);
  }

  const isEdit = holdStatus === 'Draft' || editPanel === '6' || isyardToPDC;

  const columns = [
    {
      field: 'partno',
      name: 'Part No.',
      type: 'string',
    },
    {
      field: 'name',
      name: 'Name',
      type: 'string',
    },
    {
      field: 'supplier',
      name: 'Supplier',
      type: 'string',
    },
    {
      field: 'suppliercode',
      name: 'Supplier Code',
      type: 'string',
    },
    {
      field: 'icfForPartIssue',
      name: 'ICF for Part/Issue',
      type: 'string',
    },
  ];

  const rows: RowData[] = parts.partDtls.map((part, i) => {
    return {
      id: i,
      data: {
        partno: part.partNumber,
        name: part.partName,
        supplier: part.partSupplier,
        icfForPartIssue: part.icfForPartIssue?.optionLabel || '',
        suppliercode: part.supplierCode,
      },
      isActive: false,
    };
  });

  return (
    <>
      {!isEdit ? (
        <StyledGrid>
          <StructuredList
            variant="columnlist"
            padding="10px"
            listHeader=""
            rows={rows}
            columns={columns}
            alignment={'hanging'}
            sx={{ fontSize: '12px', fontFamily: 'ToyotaType-Regular' }}
          />
        </StyledGrid>
      ) : (
        <>
          {parts.partDtls.map((part, index) => (
            <PartDetails key={index+1} index={index} setFooterDisable={setFooterDisable} handleDelete={()=>{
              setShowModal(index);
            }}/>
          ))}
          <StyledGridColumn>
            <Grid item xs={12} md={12} sx={{ paddingTop: '.3125rem' }}>
              <Button variant="secondary" label="Add Part +" onClick={handleAddPart} />
            </Grid>
          </StyledGridColumn>
        </>
      )}
      <Modal
        modalButton={<span></span>}
        openModal={showModal>=0}
        variant={'Danger modal'}
        label={<CancelTypography>Remove Part?</CancelTypography>}
        bodyText={
          <CancelTypography2>
            If you remove a part from this list, details for this hold may be inaccurate. <br />
            <br />
            Are you sure you want to remove?
          </CancelTypography2>
        }
        actionButtonProps={{
          variant: 'danger',
          onClick: () => {
            handleDeletePart();
          },
          label: (
            <CancelTypography2
              sx={{
                color: 'var(--sys-light-Text-on-primary, var(--Default-Neutral-White, #FFF))',
              }}
            >
              Yes, Remove Part
            </CancelTypography2>
          ),
        }}
        handleClose={() => {}}
        handleCancel={() => {setShowModal(-1)}}
        closeIcon={''}
      />
    </>
  );
};

export default Parts;
