import axios, { AxiosRequestConfig } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { getEnv } from '../../../env/env';
import { downloadFileServiceApi } from '../../../services';
import { DatacoreApiResponse, Model, UploadDocumentTypes } from '../../../types';
import {
  createSortedOptions,
  formatApprovals,
  formatDate,
  formatFileAttachment,
  formatHoldDetails,
  formatInternalDetails,
  formatIssueDetails,
  formatParts,
  modelSpecs,
} from '../../../utils';
import { AppDispatch, RootState } from '../../store';
import { setCurrentUploadStage } from '../HoldRequest/slice';
import { setVehicleMetrics } from '../VehicleMetrics/vehicleMetricsSlice';
import {
  setCommentsList,
  setCreatedUserData,
  setDropdownOptions,
  setFetchingDetailsData,
  setFileAttachments,
  setHoldDetails,
  setHoldReqNumber,
  setHoldStatus,
  setHoldType,
  setIsDraft,
  setIssueDetails,
  setIssueResolution,
  setModelDropdownOptions,
  setModelSpecification,
  setPDCAssigneeDropdownOptions,
  setParts,
  setPendingApprovalType,
  setRequesterApprovals,
  setSeriesDropdownOptions,
  setToyotaInternalDetails,
  setUpdatedUserID,
  setyardToPDCState,
} from './slice';
import { DropdownOptions, Vehicle } from './types';
const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));

export const getHoldRequestDetailsApi = (HoldReqNumber: any) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setFetchingDetailsData(true));
    const state = getState();

    const auth = state.auth;
    const authToken = auth.idToken;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    const url = `${getEnv().API_BASE_URL}/hold/QChold/getHoldRequest/qcNumber/${HoldReqNumber}`;
    try {
      const response = await axios.get(url, config);
      if (response.status === 200) {
        dispatch(setFetchingDetailsData(false));
      }

      let HoldReqData: any = response?.data?.data?.data;

      let problemRchain = HoldReqData.issueDetails.problemReportingChain;
      let problemReportingChain = '';
      if (problemRchain.length > 0) {
        problemReportingChain = problemRchain[0];
        for (let i = 1; i < problemRchain.length; i++) {
          problemReportingChain = problemReportingChain + ' -> ' + problemRchain[i];
        }
      }

      const createdTrimDate = formatDate(HoldReqData?.createdDateTime || '');
      const initiatedTrimDate = formatDate(HoldReqData?.initiatedDateTime || '');
      const currentYear = new Date().getFullYear();

      if (HoldReqData?.issueDetails) {
        dispatch(setIssueDetails(HoldReqData?.issueDetails));
      }
      if (HoldReqData?.holdDetails) {
        dispatch(
          setHoldDetails({
            riskRank: HoldReqData?.holdDetails?.riskRank,
            pdcPEAssignee: {
              id: HoldReqData?.holdDetails?.employeeId,
              optionLabel: HoldReqData?.holdDetails?.pdcPEAssignee,
            },
            anticipatedDelayTiming: HoldReqData?.holdDetails?.anticipatedDelayTiming,
          }),
        );
      }
      if (HoldReqData?.issueResolution) {
        dispatch(setIssueResolution(HoldReqData?.issueResolution));
      }
      if (HoldReqData?.toyotaInternalDetails) {
        dispatch(setToyotaInternalDetails(HoldReqData?.toyotaInternalDetails));
      }
      if (HoldReqData?.parts) {
        if (HoldReqData?.parts?.partDtls.length === 0) {
          dispatch(
            setParts({
              partDtls: [
                {
                  partNumber: '',
                  partName: '',
                  partSupplier: '',
                  icfForPartIssue: {
                    optionLabel: '',
                    id: '',
                  },
                  supplierCode: '',
                },
              ],
            }),
          );
        } else {
          dispatch(setParts(HoldReqData?.parts));
        }
      }
      if (HoldReqData?.vehicleInfo) {
        let modifiedVehiclesData = HoldReqData?.vehicleInfo?.vehicleDtl.map((veh: Vehicle) => {
          return {
            vehicleTypeSpecification: veh.vehicleTypeSpecification || '',
            model: {
              open: false,
              value: veh.model,
              options: [],
            },
            modelYear: {
              open: false,
              value: veh.modelYear,
              options: [
                { optionLabel: (currentYear + 1).toString(), id: (currentYear + 1).toString() },
                { optionLabel: currentYear.toString(), id: currentYear.toString() },
                { optionLabel: (currentYear - 1).toString(), id: (currentYear - 1).toString() },
              ],
            },
            series: {
              open: false,
              value: veh.series,
              options: [],
            },
          };
        });
        if (modifiedVehiclesData === undefined || modifiedVehiclesData?.length === 0) {
          modifiedVehiclesData = [
            {
              vehicleTypeSpecification: '',
              modelYear: {
                open: false,
                value: {},
                options: [
                  { optionLabel: (currentYear + 1).toString(), id: (currentYear + 1).toString() },
                  { optionLabel: currentYear.toString(), id: currentYear.toString() },
                  { optionLabel: (currentYear - 1).toString(), id: (currentYear - 1).toString() },
                ],
              },
              series: { open: false, value: { optionLabel: '', id: '' }, options: [] },
              model: { open: false, value: [], options: [] },
            },
          ];
        }
        dispatch(setModelSpecification({ ...HoldReqData?.vehicleInfo, vehicleDtl: modifiedVehiclesData }));
      }

      if (HoldReqData?.requesterApprovals) {
        if (HoldReqData?.requesterApprovals?.approvalUsers.length > 0) {
          dispatch(setRequesterApprovals(HoldReqData?.requesterApprovals));
        } else {
          dispatch(
            setRequesterApprovals({
              approvalUsers: [{ fullName: '', firstName: '', lastName: '', jobTitle: '', email: '' }],
            }),
          );
        }
      }
      dispatch(
        setFileAttachments({
          ...HoldReqData?.fileAttachments,
          fileMetadata: HoldReqData?.fileAttachments.fileMetadata.map((fileAttachmentData, index) => ({
            uploadFilePath: fileAttachmentData?.uploadFilePath,
            fileTitle: fileAttachmentData?.fileTitle,
            fileDescription: fileAttachmentData?.fileDescription || '',
            pathStore: fileAttachmentData?.uploadFilePath,
            removeFile: false,
            isdeletable: fileAttachmentData?.isdeletable,
            documentId: fileAttachmentData?.documentid,
            documentType: fileAttachmentData?.docType,
            name: fileAttachmentData?.name,
            uploadedBy: fileAttachmentData?.uploadedBy,
            uploadedDate: fileAttachmentData?.uploadedDate,
            uploadedUserName: fileAttachmentData?.uploadedUserName,
          })),
        }),
      );
      dispatch(setPendingApprovalType(HoldReqData?.pendingApprovalType));
      dispatch(setUpdatedUserID(HoldReqData?.updatedUserId));

      dispatch(
        setCreatedUserData({
          createdDateTime: createdTrimDate,
          createdUserName: HoldReqData?.createdUserName,
          sinceCreated: HoldReqData?.daysSinceCreated,
          sinceInitiated: HoldReqData?.daysSinceInitiated,
          initiatedUserName: HoldReqData?.initiatedUserName,
          initiatedDateTime: initiatedTrimDate,
          convertedBy: HoldReqData?.convertedBy,
          convertedDateTime: HoldReqData?.convertedDateTime,
          convertedUserName: HoldReqData?.convertedUserName,
          daysSinceConverted: HoldReqData?.daysSinceConverted,
        }),
      );

      dispatch(setHoldReqNumber(HoldReqNumber));
      dispatch(setHoldType(HoldReqData?.holdType));
      dispatch(setHoldStatus(HoldReqData?.holdStatus));
      dispatch(setIsDraft(HoldReqData?.holdType === 'Draft'));

      let fetchDetails = {
        status: 'success',
        data: state,
      };

      return fetchDetails;
    } catch (error: any) {
      dispatch(setFetchingDetailsData(false));
      let resData = {
        status: 'error',
        data: [],
      };
      return resData;
    }
  };
};

export const getVehicleDetailsMetricsApi = (HoldReqNumber: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    // const { dispatchNotification } = useNotificationContext();

    const auth = state.auth;
    const authToken = auth.idToken;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    const url = `${getEnv().API_BASE_URL}/hold/QChold/getKpiCardCountData/${HoldReqNumber}`;
    try {
      const response = await axios.get(url, config);
      const VehiclesDetails = response?.data?.data?.kpiCards;

      const state = {
        vehiclemetrics: {
          pendingApproval: VehiclesDetails[0]['pendingapproval'].toString(),
          approved: VehiclesDetails[0]['approved'].toString(),
          onHold: VehiclesDetails[0]['onhold'].toString(),
          readyForRelease: VehiclesDetails[0]['readyforrelease'].toString(),
          vehiclesReleased: VehiclesDetails[0]['vehiclesreleased'].toString(),
          vehiclesInvalid: VehiclesDetails[0]['vehiclesinvalid'].toString(),
          vehiclesIneligible: VehiclesDetails[0]['vehiclesineligible'].toString(),
          totalVehiclesHeld: VehiclesDetails[0]['totalvehiclesheld'].toString(),
          totalVehiclesSubmitted: VehiclesDetails[0]['totalvehiclessubmitted'].toString(),
          shippedAfterNotice: VehiclesDetails[0]['shippedafternotice'].toString(),
        },
      };

      let resData = {
        status: 'success',
        data: state,
        // data: response?.data?.data?.data
      };
      dispatch(setVehicleMetrics(state.vehiclemetrics));
      //   return resData;
      // const HoldReqData = response?.data?.data?.data;
    } catch (error: any) {
      let resData = {
        status: 'error',
        data: [],
      };
      //   return resData;
    }
  };
};

export const getPDCAssigneeDropdownOptionsApi = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const auth = state.auth;
    const authToken = auth.idToken;
    const authAccessToken = auth.accessToken;
    const url = 'https://graph.microsoft.com/beta/groups/87568d2c-5358-45c6-ae03-2a2b5a95a2a6/members?$count=true';
    const config = {
      headers: {
        Authorization: `${authAccessToken}`,
      },
    };
    try {
      const response = await axios.get(url, config);

      const dropDownOptions = response?.data?.value?.map((option, i) => {
        const displayName = option?.displayName?.replace(/[ ]\([A-Za-z]+\)/, '');
        const employeeId = option?.employeeId;
        return { optionLabel: displayName, id: employeeId };
      });
      dispatch(setPDCAssigneeDropdownOptions(dropDownOptions));
    } catch (error) {}
  };
};

export const getHmStorageValuesApi = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const dropdownOptions = state.holdTypeDetails.dropdownOptions;
    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/QChold/getDropDownValues/hmstorage`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.get(url, config);
      const modifiedDropdownOptions: DropdownOptions = {
        ...dropdownOptions,
      };

      if (response.status === 200) {
        const riskRankOptions = response?.data?.data?.hmStorage?.[1];
        const riskRanks = riskRankOptions['HOLDMGMT.QC_HOLD_RISK_RANK'];
        if (Array.isArray(riskRanks)) {
          const riskRankOptions = createSortedOptions(riskRanks, 'QC_HOLD_RISK_RANK_NM', 'QC_HOLD_RISK_RANK_CD');
          // dispatch(setRiskRankOptions(riskRankOptions));
          modifiedDropdownOptions.riskRankOptions = riskRankOptions;
        }
        // fetching data for dropdown Company Responsible
        const CompanyResponsibleDropDownAPi = response?.data?.data?.hmStorage?.[2];
        const CompanyResponsible = CompanyResponsibleDropDownAPi['HOLDMGMT.QC_RESPONSIBLE_GROUP'];
        if (Array.isArray(CompanyResponsible)) {
          const CompanyResponsibleOptions = createSortedOptions(
            CompanyResponsible,
            'QC_RSPNSBL_GRP_NM',
            'QC_RSPNSBL_GRP_CD',
          );
          modifiedDropdownOptions.companyResponsibleOptions = CompanyResponsibleOptions;
          // dispatch(setCompanyResponsibleOptions(CompanyResponsibleOptions));
        }
        const ReqDivDropDownAPi = response?.data?.data?.hmStorage?.[3];
        const ReqDiv = ReqDivDropDownAPi['HOLDMGMT.QC_HOLD_ORIGIN_DIVISION'];
        if (Array.isArray(ReqDiv)) {
          const reqDivOptions = createSortedOptions(ReqDiv, 'QC_HOLD_ORIGIN_DIVISION_NM', 'QC_HOLD_ORIGIN_DIVISION_CD');
          modifiedDropdownOptions.requestingDivisionOptions = reqDivOptions;
          // dispatch(setRequestingDivisionOptions(ReqDivOptions));
        }
        //Getting data from Root Cause
        const ToyotaRootCauDropDownAPi = response?.data?.data?.hmStorage?.[4];
        const RootCau = ToyotaRootCauDropDownAPi['HOLDMGMT.QC_HOLD_RT_CAUSE_TYPE'];
        if (Array.isArray(RootCau)) {
          const rootCauOptions = createSortedOptions(RootCau, 'QC_HOLD_RT_CAUSE_TYPE_NM', 'QC_HOLD_RT_CAUSE_TYPE_CD');
          // dispatch(setRootCauseOptions(RootCauOptions));
          modifiedDropdownOptions.rootCauseOptions = rootCauOptions;
        }
        //Getting data from Defect Category
        const ToyotaIntDetDropDownAPi = response?.data?.data?.hmStorage?.[5];
        const DefectCat = ToyotaIntDetDropDownAPi['HOLDMGMT.QC_HOLD_DEFECT_CLASS'];
        if (Array.isArray(DefectCat)) {
          const defectCatOptions = createSortedOptions(DefectCat, 'QC_HOLD_DEFECT_CLASS_NM', 'QC_HOLD_DEFECT_CLASS_CD');
          // dispatch(setDefectCategoryOptions(DefectCatOptions));
          modifiedDropdownOptions.defectCategoryOptions = defectCatOptions;
        }

        //fetching data for dropdown SupplierResponsible
        const SupplierResponsible = [
          { optionLabel: 'YES', id: 'Y' },
          { optionLabel: 'NO', id: 'N' },
        ];
        if (Array.isArray(SupplierResponsible)) {
          const supplierResponsibleOptions = SupplierResponsible.map((item: any) => ({
            optionLabel: item.optionLabel,
            id: item.id,
          }));
          modifiedDropdownOptions.supplierResponsibleOptions = supplierResponsibleOptions;
          // dispatch(setSupplierResponsibleOptions(SupplierResponsibleOptions));
        }
        const dateType = response?.data?.data?.hmStorage?.[7]['HOLDMGMT.QC_DATE_TYPE'];
        if (Array.isArray(dateType)) {
          const startDate = dateType.map((item: any) => ({
            optionLabel: item.QC_DATE_TYPE_DESC,
            id: item.QC_DATE_TYPE_CD,
          }));
          // dispatch(setStartDateTypeOptions(StartDate));
          modifiedDropdownOptions.startDateTypeOptions = startDate;
        }
        dispatch(setDropdownOptions(modifiedDropdownOptions));
      }
    } catch (error) {
      return error;
    }
  };
};

export const addCommentApi = (payload: { qcNumber: string; comments: string; userId: string }) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/QChold/addComments`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(url, payload, config);
      if (response.data.message === 'Created Successfully') {
        dispatch(getCommentsListApi(payload));
      }
      return response.data;
    } catch (error) {
      return error;
    }
  };
};

export const deleteCommentApi = (payload: { title: string; commentId: number; userId: string }, qcNumber: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const data = {
      qcNumber: qcNumber,
      userId: payload.userId,
    };
    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/QChold/deleteComments`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
      data: payload,
    };
    try {
      const response = await axios.delete(url, config);
      if (response.data.message === ' Deleted SuccesFully') {
        dispatch(getCommentsListApi(data));
      }
      return response.data;
    } catch (error) {
      return error;
    }
  };
};

export const getCommentsListApi = (payload: { qcNumber: string; userId: string }) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const auth = state.auth;
    const authToken = auth.idToken;
    const url = `${getEnv().API_BASE_URL}/hold/QChold/listComments`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(url, payload, config);
      if (response.status === 200) {
        dispatch(setCommentsList(response.data));
      }
      return response.data;
    } catch (error) {
      return error;
    }
  };
};

export const createQCApi = (
  navigate: NavigateFunction,
  callback?: () => void,
  handleNotification?: (filter: any) => void,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;
    const sessionId = localStorage.getItem(`HM_sessionId`);
    const vehicleImportFile = state.holdTypeDetails.fileAttachments.fileMetadata.find(
      (file) => file.documentType === UploadDocumentTypes.INITIALVEHICLESIMPORT,
    );
    const holdDetails = formatHoldDetails(state.holdTypeDetails.holdDetails);
    const issueDetails = formatIssueDetails(state.holdTypeDetails.issueDetails);
    const formatFileAttData = formatFileAttachment(state.holdTypeDetails.fileAttachments);
    const holdType = state.holdTypeDetails.requestState.createHoldType;
    
    let createQCPayload: any = {
      issueDetails: issueDetails,
      holdDetails: holdDetails,
      fileAttachments: { fileMetadata: formatFileAttData },
      userId: userData?.employeeId,
      userName: userData?.userName,
      holdType: holdType === 'PDC' ? '1' : '2',
      sessionId: sessionId,
      documentID: vehicleImportFile?.documentId,
      sessionInfo: {
        systemContextFunction: 'testfn',
        sessionDominantRole: 'admin',
      },
      isSaveForLater: true, //For Draft creation
    };

    if (holdType === 'PDC') {
      const issueResolution = state.holdTypeDetails.issueResolution;
      const toyotaInternalDetails = formatInternalDetails(state.holdTypeDetails.toyotaInternalDetails);
      const requesterApprovals = formatApprovals(state.holdTypeDetails.requesterApprovals.approvalUsers);
      const parts = formatParts(state.holdTypeDetails.parts.partDtls);
      const modelSpecifications = modelSpecs(state.holdTypeDetails.modelSpecification);
      createQCPayload = {
        ...createQCPayload,
        issueResolution: issueResolution,
        toyotaInternalDetails: toyotaInternalDetails,
        parts: parts,
        requesterApprovals: requesterApprovals,
        vehicleInfo: modelSpecifications,
      };
    }

    const url = `${getEnv().API_BASE_URL}/hold/QChold/createQC`;
    const apikey = `${getEnv().apiKey}`;
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(url, createQCPayload, config);
      if (response.status === 201) {
        navigate(`/HoldManagement/QChold/${response.data.body.qcNumber}`);
        dispatch(getHoldRequestDetailsApi(response.data.body.qcNumber));
        if (handleNotification)
          handleNotification({
            fieldName: 'success',
            field: `New hold with QC number ${response.data.body.qcNumber} has been created successfully`,
          });
        if (callback) callback();
      } else {
        if (handleNotification)
          handleNotification({ fieldName: 'error', field: 'Something Went Wrong. Please Try later' });
      }
      return response.data;
    } catch (error) {
      dispatch(setCurrentUploadStage(5));
      if (handleNotification)
        handleNotification({ fieldName: 'error', field: 'Something Went Wrong. Please Try later' });
      return error;
    }
  };
};

export const EditAndSubmitQCApi = (
  edit?: boolean,
  convertToPDC?: boolean,
  callback?: () => void,

) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;
    const qcNumber = state.holdTypeDetails.requestState.holdRequestNumber;
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    const issueDetails = formatIssueDetails(state.holdTypeDetails.issueDetails);
    const holdDetails = formatHoldDetails(state.holdTypeDetails.holdDetails);

    const holdType = state.holdTypeDetails.holdType;
    const holdStatus = state.holdTypeDetails.holdStatus;
    const isYardToPDC = state.holdTypeDetails?.yardToPDC;
    const formatFileAttData = formatFileAttachment(state.holdTypeDetails.fileAttachments);
    const sessionId = localStorage.getItem(`HM_sessionId`);

    console.log('issueDetails', issueDetails);

    console.log('issueDetails', issueDetails);

    const formatIssueDetailsApi = { ...issueDetails, placeIssueFound: issueDetails?.placeFound };
    delete formatIssueDetailsApi['placeFound'];
    let editQCPayload: any = {
      issueDetails: formatIssueDetailsApi,
      holdDetails: holdDetails,
      sessionId: sessionId || '',
      userId: userData?.employeeId,
      userName: userData?.userName,
      qcNumber: qcNumber,
      sessionInfo: {
        systemContextFunction: 'testfn',
        sessionDominantRole: 'admin',
      },
      fileAttachments: { fileMetadata: formatFileAttData },
      ...(() => {
        if (holdStatus === 'Draft' && !edit) {
          return { isCreateHold: true, holdType: holdType === 'PDC' ? '1' : '2' };
        } else if (holdStatus === 'Draft' && edit) {
          return { isSaveForLater: true, holdType: holdType === 'PDC' ? '1' : '2' };
        } else if (isYardToPDC) {
          return { isYardToPDC: true };
        } else {
          return { holdType: holdType === 'PDC' ? '1' : '2' };
        }
      })(),
    };

    if (holdType === 'PDC' || convertToPDC) {
      const issueResolution = state.holdTypeDetails.issueResolution;
      const toyotaInternalDetails = formatInternalDetails(state.holdTypeDetails.toyotaInternalDetails);
      const requesterApprovals = formatApprovals(state.holdTypeDetails.requesterApprovals.approvalUsers);
      const parts = formatParts(state.holdTypeDetails.parts.partDtls);
      const modelSpecifications = modelSpecs(state.holdTypeDetails.modelSpecification);
      editQCPayload = {
        ...editQCPayload,
        issueResolution: issueResolution,
        toyotaInternalDetails: toyotaInternalDetails,
        parts: parts,
        requesterApprovals: requesterApprovals,
        vehicleInfo: modelSpecifications,
      };
    }

    const url = `${getEnv().API_BASE_URL}/hold/QChold/editQC`;

    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.put(url, editQCPayload, config);
      if (response.status === 200) {
        console.log('response', response);
        dispatch(getHoldRequestDetailsApi(qcNumber));
        dispatch(setCurrentUploadStage(0));
        if (isYardToPDC) {
          dispatch(setyardToPDCState(false));
        }
        callback();
      }
      return response.data;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  };
};

export const getVehicleModelsDropdownApi = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const auth = state.auth;
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 1, currentYear, currentYear + 1];
    // try {
    let allModels: Model[] = [];
    for (const year of years) {
      const url = `${getEnv().API_BASE_URL}/hold/QChold/getDropDownValues/datacore`;
      const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
      const config = {
        headers: {
          Authorization: auth.idToken,
          'x-api-key': apikey,
          'Content-Type': 'application/json',
        },
        params: {
          modelYear: year,
        },
      };
      const response = await axios.get<DatacoreApiResponse>(url, config);
      allModels = [...allModels, ...response.data.data.dataCore];
    }
    dispatch(setModelDropdownOptions(allModels));
    const seriesDropdownOptions = allModels
      .map((model) => {
        return {
          optionLabel: model.orderingSeriesName,
          id: model.orderingSeriesName,
        };
      })
      .sort((a, b) => a?.optionLabel?.localeCompare(b?.optionLabel));
    console.log('jb nm,', seriesDropdownOptions);
    dispatch(setSeriesDropdownOptions(seriesDropdownOptions));
    // } catch (err) {
    //   dispatch(setVehicleError('Error fetching data...'));
    // }
  };
};

export const uploadFileDocumentAttachmentApi = (payload: any, handleNotification: (filter: any) => void) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const auth = state.auth;

    const fileAttachment = state.holdTypeDetails.fileAttachments;
    const urls = `${getEnv().API_BASE_URL}/hold/QChold/updateDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const configs = {
      headers: {
        Authorization: auth.idToken,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.put(urls, payload, configs);
      if (response.status === 200) {
        dispatch(
          setFileAttachments({
            fileMetadata: fileAttachment?.fileMetadata.map((x) => {
              if (x.documentId === payload.documentId) {
                return { ...x, fileDescription: payload.description };
              } else {
                return x;
              }
            }),
          }),
        );
        handleNotification({
          fieldName: 'success',
          field: `File Description has been updated successfully.`,
        });
      }
    } catch (error) {
      handleNotification({
        fieldName: 'error',
        field: `Something went wrong.`,
      });
    } finally {
    }
  };
};

export const deleteDocument = (documentId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    const qcNumber = state.holdTypeDetails.requestState.holdRequestNumber;
    const auth = state.auth;
    const apiBaseUrl = `${getEnv().API_BASE_URL}`;
    const urls = `${apiBaseUrl}/hold/QChold/deleteDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    let reqData = {
      userId: userData?.employeeId,
      qcNumber: qcNumber,
      documentId: documentId,
    };
    const configs = {
      headers: {
        Authorization: `${auth.idToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
      data: reqData,
    };
    try {
      const response = await axios.delete(urls, configs);
    } catch {
      console.log('Error deleting document');
    }
  };
};

export const downloadVehiclesApi = (payload: any, documentId: string, handleNotification: (filter: any) => void) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    const qcNumber = state.holdTypeDetails.requestState.holdRequestNumber;
    const auth = state.auth;
    const apiKey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    const config = {
      headers: {
        Authorization: `${auth.idToken}`,
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/getParsedListing/${
      UploadDocumentTypes.INITIALVEHICLESIMPORT
    }/${documentId}/download`;

    try {
      const response = await axios.post(url, payload, config);
      if (response.status === 201) {
        const link = response.data.data;
        const urlLink = `${getEnv().DOCUMENT_URL}/${link}`;
        const configs: AxiosRequestConfig = {
          headers: {
            Authorization: `${auth.idToken}`,
          },
          responseType: 'blob',
        };
        downloadFileServiceApi(link, auth.idToken, handleNotification);
      } else {
        handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
      }
    } catch (error) {
      handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
    }
  };
};

export const deleteDocumentApi = (documentId: number, handleNotification: (filter: any) => void) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const auth = state.auth;
    const authToken = auth.idToken;

    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    const qcNumber = state.holdTypeDetails.requestState.holdRequestNumber;
    const fileAttachments = state.holdTypeDetails.fileAttachments;
    let reqData = {
      userId: userData?.employeeId,
      qcNumber: qcNumber,
      documentId: documentId,
    };

    const apiBaseUrl = `${getEnv().API_BASE_URL}`;
    const urls = `${apiBaseUrl}/hold/QChold/deleteDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const configs = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
      data: reqData,
    };

    try {
      const response = await axios.delete(urls, configs);
      if (response.status === 200) {
        const updatedFileAttachmentList: any = fileAttachments?.fileMetadata?.filter(
          (file) => file.documentId !== documentId,
        );
        dispatch(setFileAttachments({ fileMetadata: updatedFileAttachmentList }));
        handleNotification({
          fieldName: 'success',
          field: `File Deleted Successfully.`,
        });
      } else {
        handleNotification({
          fieldName: 'error',
          field: `Unable to delete file. Please try again later.`,
        });
      }
    } catch (error) {
      handleNotification({
        fieldName: 'error',
        field: `Unable to delete file. Please try again later.`,
      });
    }
  };
};
