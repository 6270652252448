export const BrowserStorage = {
  setItem: (key: string, value: string) => {
    localStorage.setItem(`HM-${key}`, value);
  },
  getItem: (key: string) => {
    return localStorage.getItem(`HM-${key}`);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(`HM-${key}`);
  },
  clear: () => {
    for (let index = 0; index < localStorage.length; index++) {
      const element = localStorage.key(index);
      if (element.startsWith('HM-')) {
        localStorage.removeItem(element);
      }
    }

    for (let index = 0; index < sessionStorage.length; index++) {
      const element = sessionStorage.key(index);
      if (element.startsWith('HM-')) {
        sessionStorage.removeItem(element);
      }
    }
  },
  setSessionItem: (key: string, value: string) => {
    sessionStorage.setItem(`HM-${key}`, value);
  },
  getSessionItem: (key: string) => {
    return sessionStorage.getItem(`HM-${key}`);
  },
  getSessionId: () => {
    return localStorage.getItem(`HM_sessionId`);
  },
};
