import { ExpandMore } from '@mui/icons-material';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, Paper, Stack, Typography, styled } from '@mui/material';
import { Accordion, IconButton } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  getCreatedUserData,
  getEditPanel,
  getFileAttachments,
  getHoldStatus,
  getHoldType,
  getIssueDetails,
  getYardToPDCState,
  getPendingApprovalType,
} from '../../../../store/features/HoldTypeDetails/selectors';
import { setEditPanel } from '../../../../store/features/HoldTypeDetails/slice';
import theme from '../../../../theme';
import { AccordionProps } from '../../../../types/Shapes/Accordion.shape';
import AppendixA from './AppendixA';
import { EditFooter } from './EditFooter/EditFooter';
import HoldDetails from './HoldDetails';
import IssueDetails from './IssueDetails';
import IssueResolution from './IssueResolution';
import { ModelSpecifications } from './ModelSpecifications/ModelSpecifications';
import Parts from './Parts';
import { RenderItem } from './RenderItem';
import { RequesterApprovals } from './RequesterApprovals/RequesterApprovals';
import ToyotaInternalDetails from './ToyotaInternalDetails';
const DetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ContentContainer = styled('div')({
  flexGrow: 1, // Makes content take up available space
  padding: '1rem',
});
const Details = ({ convertToPDC }: { convertToPDC: boolean }) => {
  const [expandedPanel, setExpandedPanel] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true
  });

  const holdStatus = useAppSelector(getHoldStatus);
  const fileAttachments = useAppSelector(getFileAttachments);
  const holdType = useAppSelector(getHoldType);
  const editPanel = useAppSelector(getEditPanel);
  const dispatch = useAppDispatch();
  const issueDetails = useAppSelector(getIssueDetails);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const createdUserData = useAppSelector(getCreatedUserData);
  const pendingApprovalType = useAppSelector(getPendingApprovalType);

  const [footerDisable, setFooterDisable] = useState<boolean>(true);
  const handleAccordionChange = (panel: string, isExpanded: boolean) => {
    setExpandedPanel({
      ...expandedPanel,
      [panel]: isExpanded
    });
  };

  const showEditIcon =
    (!['Completed', 'Cancelled'].includes(holdStatus) || !['1'].includes(editPanel)) && !convertToPDC;
  const ShowExpandIcon = !['Draft'].includes(holdStatus) && !convertToPDC;
  const panels: AccordionProps[] =
    (convertToPDC || holdType === 'PDC' || pendingApprovalType === 'YardToPDC')
      ? [
          ...((holdStatus === 'Draft' && holdType === 'PDC') || isyardToPDC
            ? [
                {
                  panel: '0',
                  accordionContent: <AppendixA />,
                  header: 'Upload Appendix A',
                  onChange: handleAccordionChange,
                  isExpanded: expandedPanel['0'],
                  isRequired: true,
                },
              ]
            : []),
          {
            panel: '1',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <HoldDetails setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '1' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}{' '}
              </DetailsContainer>
            ),
            header: 'Hold Details',
            isRequired: false,
            onChange: handleAccordionChange,
            isExpanded: expandedPanel['1'],
            // isExpanded: true,
          },
          {
            panel: '2',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <IssueDetails setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '2' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}
              </DetailsContainer>
            ),
            header: 'Issue Details',
            onChange: handleAccordionChange,
            isExpanded: expandedPanel['2']
          },
          {
            panel: '3',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <ToyotaInternalDetails setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '3' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}
              </DetailsContainer>
            ),
            header: 'Toyota Internal Details',
            onChange: handleAccordionChange,
            // isExpanded: !ShowExpandIcon || expandedPanel === '4',
            isExpanded: expandedPanel['3'],
          },
          {
            panel: '4',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <IssueResolution setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '4' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}{' '}
              </DetailsContainer>
            ),
            header: 'Issue Resolution',
            onChange: handleAccordionChange,
            // isExpanded: !ShowExpandIcon || expandedPanel === '5',
            isExpanded: expandedPanel['4'],
          },
          {
            panel: '5',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <ModelSpecifications setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '5' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}{' '}
              </DetailsContainer>
            ),
            header: 'Model Specifications',
            onChange: handleAccordionChange,
            // isExpanded: !ShowExpandIcon || expandedPanel === '6',
            isExpanded: expandedPanel['5'],
          },
          {
            panel: '6',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <Parts text={''} height={10} setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '6' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}{' '}
              </DetailsContainer>
            ),
            header: 'Parts (if applicable)',
            onChange: handleAccordionChange,
            // isExpanded: !ShowExpandIcon || expandedPanel === '7',
            isExpanded: expandedPanel['6'],
          },
          {
            panel: '7',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <RequesterApprovals setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '7' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}{' '}
              </DetailsContainer>
            ),
            header: 'Authorized By',
            onChange: handleAccordionChange,
            // isExpanded: !ShowExpandIcon || expandedPanel === '8',
            isExpanded: expandedPanel['7'],
          },
        ]
      : [
          {
            panel: '1',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <HoldDetails setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '1' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}{' '}
              </DetailsContainer>
            ),
            header: 'Hold Details',
            isRequired: false,
            onChange: handleAccordionChange,
            // isExpanded: !ShowExpandIcon || expandedPanel === '2',
            isExpanded: expandedPanel['1'],
          },
          {
            panel: '2',
            accordionContent: (
              <DetailsContainer>
                <ContentContainer>
                  <IssueDetails setFooterDisable={setFooterDisable} />
                </ContentContainer>
                {!['Draft'].includes(holdStatus) && editPanel === '2' && (
                  <EditFooter setFooterDisable={setFooterDisable} footerDisable={footerDisable} />
                )}
              </DetailsContainer>
            ),
            header: 'Issue Details',
            onChange: handleAccordionChange,
            isExpanded: expandedPanel['2']
          },
        ];

  const RenderDetailsAccordion = () => {
    return (
      <Grid container spacing={2}>
        {panels.map((item, i) => (
          <Grid item xs={12} key={i}>
            <Accordion
              {...item}
              header={
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="bodyShortSemibold">
                    {item.header}
                    {item.isRequired && <span className="spanError">*</span>}
                  </Typography>
                  {showEditIcon && ShowExpandIcon && (
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setEditPanel(item.panel));
                        console.log('Edit icon clicked');
                      }}
                      name="Edit"
                      sx={{
                        right: 40,
                        top: 6,
                        position: 'absolute',
                        color: 'black'
                      }}
                    />
                  )}
                </Stack>
              }
              accordionSummaryProps={{
                expandIcon: ShowExpandIcon && <ExpandMore sx={{color: 'black'}} />,
                sx: {
                  backgroundColor: '#F1F2F4',
                  position: 'relative',
                  borderRadius: '4px',
                  borderTop: 'none',
                },
              }}
              accordionDetailProps={{
                sx: {
                  padding: 0,
                  backgroundColor: theme.palette.background.paper,
                },
              }}
              sx={{
                flexShrink: 0,
                width: open ? '100%' : '40px',
                marginTop: '1rem',
                border: 0,
                background: 'none',
                boxShadow: 'none',
                marginBottom: '2rem', // 2rem space between accordions
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };
  const DetailsHeader = () => {
    const createdUserDetails = [
      {
        label: 'Created By:',
        value: createdUserData?.createdUserName,
      },
      {
        label: 'Created On:',
        value: createdUserData?.createdDateTime,
      },
      {
        label: 'Converted By:',
        value: createdUserData?.convertedUserName ? createdUserData?.convertedUserName : '-',
      },
      {
        label: 'Age Since Submitted:',
        value: createdUserData?.sinceCreated ? `(${createdUserData.sinceCreated} Days)` : '-',
      },
      {
        label: 'Initiated By:',
        value: createdUserData?.initiatedUserName ? createdUserData?.initiatedUserName : '-',
      },
      {
        label: 'Approved On:',
        value: createdUserData?.initiatedDateTime ? createdUserData?.initiatedDateTime : '-',
      },
      {
        label: 'Age Since Approved:',
        value: createdUserData?.sinceInitiated ? `(${createdUserData?.sinceInitiated} Days)` : '-',
      },
      {
        label: 'Initiated By:',
        value: createdUserData?.initiatedUserName ? createdUserData?.initiatedUserName : '-',
      },
    ];
    return (
      <Paper
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '24px',
          marginBottom: '1rem',
          boxShadow: 'none',
          marginTop: '6rem',
        }}
      >
        <Stack direction={'column'}>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {issueDetails?.issueTitle}
          </Typography>
          <Grid container sx={{ marginTop: '5px' }} spacing={2}>
            {createdUserDetails.map((item) => (
              <Grid item xs={3}>
                <RenderItem label={item.label} value={item.value} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Paper>
    );
  };
  return (
    <TabPanel value="1" className="tabpanelcss">
      <DetailsHeader />
      <RenderDetailsAccordion />
    </TabPanel>
  );
};

export default Details;
