import { ThemeShape } from '../types';

export const variantToSize = {
  h1: '1.5rem',
  h2: '1.25rem',
  h3: '1rem',
  body1: '0.875rem',
  body2: '2rem',
  caption: '0.75rem',
};

const fontWeights = {
  normal: 400,
  semiBold: 600,
};

const fontFamilies = {
  toyotaType: 'Toyota Type',
  toyotaTypeSemiBold: 'Toyota Type Semibold',
};

const defaultTypographyProps = {
  fontSize: variantToSize.body1,
  fontWeight: fontWeights.normal,
  fontFamily: fontFamilies.toyotaType,
  lineHeight: '1rem',
  fontStyle: 'normal',
};

// Define your custom typography options that extend the existing TypographyOptions
export const customTypography: ThemeShape.TypographyVariantsOptions = {
  fontFamily: 'Toyota Type',
  h1: {
    ...defaultTypographyProps,
    fontSize: variantToSize.h1,
    lineHeight: '2rem',
  },
  h1Semibold: {
    ...defaultTypographyProps,
    fontSize: variantToSize.h1,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
    lineHeight: '2rem',
  },
  h2: {
    ...defaultTypographyProps,
    fontSize: variantToSize.h2,
    lineHeight: '1.75rem',
  },
  h2Semibold: {
    ...defaultTypographyProps,
    fontSize: variantToSize.h2,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
    lineHeight: '1.75rem',
  },
  h3: {
    ...defaultTypographyProps,
    fontSize: variantToSize.h3,
    lineHeight: '1.375rem',
  },
  h3Semibold: {
    ...defaultTypographyProps,
    fontSize: variantToSize.h3,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
    lineHeight: '1.375rem',
  },
  bodyShort: {
    ...defaultTypographyProps,
  },
  bodyShortSemibold: {
    ...defaultTypographyProps,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
  },
  bodyLong: {
    ...defaultTypographyProps,
    lineHeight: '1.25rem',
  },
  bodyLongSemibold: {
    ...defaultTypographyProps,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
    lineHeight: '1.25rem',
  },
  caption: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
    lineHeight: '1.125rem',
  },
  boldCaption: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
    lineHeight: '1.125rem',
  },
  label: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
  },
  labelRegular: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
  },
  labelSemibold: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
  },
  labelUppercase: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
    textTransform: 'uppercase',
  },
  labelUppercaseSemibold: {
    ...defaultTypographyProps,
    fontSize: variantToSize.caption,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
    textTransform: 'uppercase',
  },
  button: {
    ...defaultTypographyProps,
  },
  buttonSemibold: {
    ...defaultTypographyProps,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaTypeSemiBold,
  },
  displayRegular: {
    fontSize: variantToSize.body2,
    fontWeight: fontWeights.normal,
    fontFamily: fontFamilies.toyotaType,
    lineHeight: '2.5rem',
    fontStyle: 'normal',
  },
  displaySemibold: {
    fontSize: variantToSize.body2,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamilies.toyotaType,
    lineHeight: '2.5rem',
    fontStyle: 'normal',
  },
};
