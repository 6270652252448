import { HoldRequestColumns } from '../../../Constants';
import { HoldRequestState } from './types';

export const initialState: HoldRequestState = {
  viewInfo: {
    viewName: '',
    viewUpdated: false,
  },
  downloadStatus: {
    downloadSuccess: false,
    isError: false,
    downloading: false,
  },
  dataTable: {
    totalCount: 0,
    columns: HoldRequestColumns,
    sorts: [],
    pagination: {
      page: 1,
      size: 25,
    },
    filters: [],
    tableData: {
      count: 0,
      rowsData: [
        {
          id: '',
          urn: '',
          HoldStatus: '',
          Assignee: '',
          HoldRequest: '',
          HoldType: '',
          HoldYear: '',
          ModelYear: '',
          SalesSeries: '',
          TotalVehicles: '',
          VehiclesCompleted: '',
          InEvaluation: '',
          OnHold: '',
          HoldAge: '',
          VehiclesInvalid: '',
          Shipped: '',
          TotalVehiclesSubmitted: '',
          InitialRiskRank: '',
          CurrentRiskRank: '',
          Approvals: '',
          VehiclesCompletedPer: '',
          GroupIdentifier: '',
          PDCSource: '',
          PDCYear: '',
          ICF: '',
          CompanyResponsible: '',
          contact: '',
          SupplierResponse: '',
          PartNumber: '',
          RootCause: '',
          DefectCategory: '',
          PDCNotificationDate: '',
          PDCRequestDate: '',
          KickOffMeetingDate: '',
          QualityAlertIssueDate: '',
          QualityAlertNumber: '',
          SMDNotifiedDate: '',
          ApprovedRepairProcedureDate: '',
          OpCodeCostShareLetterDate: '',
          InspectionTime: '',
          TMNAPDCETC: '',
          PDCFieldActivityCompletionDate: '',
          RepairTime: '',
        },
      ],
      dropDownOptions: [],
      loading: false,
      reloading: false,
    },
    tableHeader: {
      title: 'Hold Activity',
      tableCount: `0 items`,
      viewlabel: '',
      sortLabel: 'Sorted by None',
      enableSelectedItemsBanner: true,
      selectedItemsText: '',
    },
    selectedItems: [],
    inlineFilterValues: [],
  },
  sidebarOpen: true,
  pageViewList: [],
  pdcStatus: [],
  loading: true,
  yardStatus: [],
  counts: {
    yardCount: 0,
    pdcCount: 0,
    all: 0,
  },
  lastRefreshDate: '',
  selectedView: {
    label: 'All Quality Compliance Holds',
    count: '',
    id: 0,
    shortName: 'AQCH',
  },
  documentDetails: {
    documentId: '',
    documentPath: '',
    name: '',
    uploadedDate: '',
  },

  previewData: {
    parsedBy: ['VIN'],
    headers: [''],
    data: [{}],
    count: 0,
    isGoCanvas: 0,
    isVehicleFilter: 0,
  },
  currentUploadStage: 0,
  uploadStageError: '',
  canvasVehicleListing: {
    vehicleList: [],
    columns: [],
    loading: false,
    counts: {
      duplicateVinsCount: 0,
      invalidVinsCount: 0,
      notAssociatedWithHoldCount: 0,
      totalCount: 0,
      validVinsCount: 0,
    },
  },
};
