import { CubeAuth } from 'cube-auth-lib';
import { AuthorizationResult } from 'cube-auth-lib/dist/common/interfaces';

export class RoleManager {
  private accessToken: string;
  private idToken: string;
  private policyUrl: string;
  private roles: AuthorizationResult[] = [];
  private isAuthInitialized: boolean = false; // Track initialization status

  constructor(accessToken: string, idToken: string, policyUrl: string) {
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.policyUrl = policyUrl;
  }

  // Helper method to check if CubeAuth is initialized
  private async ensureAuthInitialized(): Promise<void> {
    if (!this.isAuthInitialized) {
      await CubeAuth.initialize({
        accessToken: this.accessToken,
        idToken: this.idToken,
        policyUrl: this.policyUrl,
      });
      this.isAuthInitialized = true; // Set the flag to true after initialization
      console.log('CubeAuth initialized successfully');
    }
  }

  // Public method to initialize CubeAuth (if not already initialized)
  public async initializeAuth(): Promise<void> {
    try {
      await this.ensureAuthInitialized();
    } catch (error) {
      console.error(`Initialization failed: ${error.message}`);
      throw new Error(`Initialization failed: ${error.message}`);
    }
  }

  // Getter for isAuthenticated to check if CubeAuth is initialized
  public get isAuthenticated(): boolean {
    return this.isAuthInitialized;
  }

  // Fetch roles for features
  public async fetchRoles(featureNames: string[]): Promise<AuthorizationResult[]> {
    try {
      await this.ensureAuthInitialized(); // Ensure CubeAuth is initialized
      this.roles = await CubeAuth.authorizedByFeatures(featureNames);
      return this.roles;
    } catch (error) {
      console.error(`Fetching roles failed: ${error.message}`);
      throw new Error(`Fetching roles failed: ${error.message}`);
    }
  }

  // Check if a user is authorized for a specific permission
  public async checkPermission(featureName: string, permissionName: string): Promise<boolean> {
    try {
      await this.ensureAuthInitialized(); // Ensure CubeAuth is initialized
      const isAuthorized = await CubeAuth.authorizedByPermission(featureName, permissionName);
      console.log('isAuthorized', isAuthorized);
      return isAuthorized;
    } catch (error) {
      console.error(`Permission check failed: ${error.message}`);
      throw new Error(`Permission check failed: ${error.message}`);
    }
  }

  // Get all fetched roles
  public getRoles(): AuthorizationResult[] {
    return this.roles;
  }

  // Clear cached roles
  public clearRolesCache(): void {
    CubeAuth.clearCache();
    this.roles = [];
    this.isAuthInitialized = false; // Reset flag after clearing cache
    console.log('Roles cache cleared');
  }
}
