const env = {
  clientId: '785b8924-051f-4acc-bb5c-57eeb7a93b0c',
  authority: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
  navigateToLoginRequestUrl: false,
  redirectUri: 'https://one.vehmgmtdev.toyota.com',
  API_BASE_URL: 'https://api.hm.cubedev.toyota.com',
  DOCUMENT_URL: 'https://one.hmdev.toyota.com',
  GRAPHQL_BASE_URL: 'https://graph.hm.cubedev.toyota.com/graphql',
  POLICY_URL: 'https://api.hm.cubedev.toyota.com/v1/roleconsumption/policy',
  apiKey: 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70',
};

export const devEnv = env;
