import {
  Comments,
  DropdownOptions,
  FileAttachments,
  HoldRequest,
  IssueDetails,
  IssueResolution,
  ModelSpecification,
  Parts,
  RequesterApprovals,
  RequestState,
  ToyotaInternalDetails,
} from './types';

// init states for hold requests
const dropdownOptions: DropdownOptions = {
  pdcAssigneeDropdownOptions: [
    {
      optionLabel: '',
      id: '',
      employeeId: '',
    },
  ],
  riskRankOptions: [{ optionLabel: '', id: '' }],
  requestingDivisionOptions: [{ optionLabel: '', id: '' }],
  defectCategoryOptions: [{ optionLabel: '', id: '' }],
  rootCauseOptions: [{ optionLabel: '', id: '' }],
  companyResponsibleOptions: [{ optionLabel: '', id: '' }],
  supplierResponsibleOptions: [{ optionLabel: '', id: '' }],
  startDateTypeOptions: [{ optionLabel: '', id: '' }],
  seriesDropdownOptions: [{ optionLabel: '', id: '' }],
  modelDropdownOptions: [],
};
const issueDetails: IssueDetails = {
  issueTitle: '',
  dateFound: '',
  requestingDivision: {
    id: '',
    optionLabel: '',
  },
  detailedIssueDescription: '',
  rootCause: {
    id: '',
    optionLabel: '',
  },
  defectCategory: {
    id: '',
    optionLabel: '',
  },
  notificationDate: '',
  placeFound: '',
  problemReportingChain: [''],
  delayDays: -1,
  delayTiming: '',
};

const toyotaInternalDetails: ToyotaInternalDetails = {
  icf: {
    id: '',
    optionLabel: '',
  },
  companyResponsible: {
    id: '',
    optionLabel: '',
  },
  supplierResponsible: {
    id: '',
    optionLabel: '',
  },
};

const issueResolution: IssueResolution = {
  pdcActionDirectionAtYardPort: '',
  processContainmentMethod: '',
  flowOutPreventionMethod: '',
};

const modelSpecification: ModelSpecification = {
  vehicleDtl: [
    {
      vehicleTypeSpecification: '',
      modelYear: {
        open: false,
        value: { optionLabel: '2024', id: '2024' },
        options: [
          { optionLabel: '2025', id: '2025' },
          { optionLabel: '2024', id: '2024' },
          { optionLabel: '2023', id: '2023' },
        ],
      },
      series: { open: false, value: { optionLabel: '', id: '' }, options: [] },
      model: { open: false, value: [], options: [] },
    },
  ],
  startDateType: {
    id: '',
    optionLabel: '',
  },
  vinStartDateTime: '',
  vinStartLogicReasoning: '',
  endDateType: {
    id: '',
    optionLabel: '',
  },
  vinEndDateTime: '',
  vinEndLogicReasoning: '',
};

const parts: Parts = {
  partDtls: [
    {
      partNumber: '',
      partName: null,
      partSupplier: '',
      icfForPartIssue: {
        id: '',
        optionLabel: '',
      },
      supplierCode: '',
    },
  ],
};

const requesterApprovals: RequesterApprovals = {
  approvalUsers: [
    {
      fullName: '',
      jobTitle: null,
      email: null,
      firstName: '',
      lastName: '',
    },
  ],
};

const fileAttachments: FileAttachments = {
  fileMetadata: [],
};

const requestState: RequestState = {
  loading: false,
  createHoldType: '',
  isDraft: false,
  editPanel: '0',
  edit: false,
  documentId: '',
  holdRequestNumber: '',
  updateSuccess: null,
  commentList: [],
};

const comments: Comments = {
  comment: '',
  date: '',
  qcholdreqid: 0,
  commentid: 0,
  title: '',
  name: '',
  isuser: false,
  isdeletable: false,
};
export const holdRequest: HoldRequest = {
  holdType: '',
  holdStatus: '',
  createdBy: 0,
  createdUserData: {
    convertedBy: null,
    createdUserName: '',
    createdDateTime: '',
    daysSinceCreated: 0,
    initiatedDateTime: null,
    daysSinceInitiated: null,
    convertedDateTime: null,
    daysSinceConverted: null,
    initiatedUserName: null,
    convertedUserName: null,
  },
  yardToPDC: false,
  pendingApprovalType: null,
  updatedUserId: '',
  initiatedBy: '',
  fileAttachments: fileAttachments,
  requesterApprovals: requesterApprovals,
  parts: parts,
  modelSpecification: modelSpecification,
  issueResolution: issueResolution,
  toyotaInternalDetails: toyotaInternalDetails,
  issueDetails: issueDetails,
  comments: comments,
  requestState: requestState,
  holdDetails: {
    anticipatedDelayTiming: '',
    pdcPEAssignee: { id: '', optionLabel: '' },
    riskRank: {
      id: '',
      optionLabel: '',
    },
    updateDelay: false,
  },

  dropdownOptions: dropdownOptions,
};
