import { DataTableShape } from '../types';

export let HoldRequestColumns: DataTableShape.ColumnData[] = [
  {
    field: 'HoldStatus',
    name: 'Hold Status',
    width: 200,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 1,
  },
  {
    field: 'Assignee',
    name: 'Assignee',
    width: 180,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 2,
    inlineFilterWidth: 200,
  },
  {
    field: 'HoldRequest',
    name: 'Hold Request No.',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    copyIcon: true,
    id: 3,
  },
  {
    field: 'CreatedDate',
    name: 'Created Date',
    width: 180,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 4,
  },
  {
    field: 'CreatedBy',
    name: 'Created By',
    width: 180,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 5,
  },
  {
    field: 'HoldInitiatedDate',
    name: 'Hold Initiated Date',
    width: 180,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 6,
  },
  {
    field: 'HoldYear',
    name: 'Hold Year',
    width: 180,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 7,
  },
  {
    field: 'HoldType',
    name: 'Hold Type',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 8,
  },
  {
    field: 'ModelYear',
    name: 'Model Year',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 9,
  },
  {
    field: 'SalesSeries',
    name: 'Sales Series',
    width: 180,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 10,
  },
  {
    field: 'TotalVehicles',
    name: 'Total Vehicles',
    width: 160,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 11,
  },
  {
    field: 'HoldAge',
    name: 'Hold Age',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 12,
  },
  {
    field: 'VehiclesPendingApproval',
    name: 'Vehicles Pending Approval',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 13,
  },
  {
    field: 'OnHold',
    name: 'On Hold',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 14,
  },
  {
    field: 'InEvaluation',
    name: 'In Evaluation',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 15,
  },
  {
    field: 'VehiclesCompleted',
    name: 'Vehicles Completed',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 16,
  },
  {
    field: 'VehiclesCompletedPer',
    name: '% Vehicles Completed',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 17,
  },
  {
    field: 'Shipped',
    name: 'Shipped',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 18,
  },
  {
    field: 'VehiclesInvalid',
    name: 'Invalid',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 19,
  },
  {
    field: 'TotalVehiclesSubmitted',
    name: 'Submitted',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    valueAlign: 'end',
    id: 20,
  },
  {
    field: 'InitialRiskRank',
    name: 'Initial Risk Rank',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 21,
  },
  {
    field: 'CurrentRiskRank',
    name: 'Current Risk Rank',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 22,
  },
  {
    field: 'Approvals',
    name: 'Approvals',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 23,
  },
  // {
  //   field: "GroupIdentifier",
  //   name: "Group Identifier",
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: "string",
  //   pinned: false,
  // },
  {
    field: 'PDCSource',
    name: 'PDC Source',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 24,
  },
  // {
  //   field: "PDCYear",
  //   name: "PDC Year",
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: "string",
  //   pinned: false,
  // },
  {
    field: 'ICF',
    name: 'ICF - Internal Control Function',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 25,
  },
  {
    field: 'CompanyResponsible',
    name: 'Company Responsible',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 26,
  },
  {
    field: 'contact',
    name: 'Contact',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 27,
  },
  {
    field: 'SupplierResponse',
    name: 'Supplier Responsible',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 28,
  },
  {
    field: 'PartNumber',
    name: 'Part Number',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 29,
  },
  {
    field: 'RootCause',
    name: 'Root Cause (High Level)',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 30,
  },
  {
    field: 'DefectCategory',
    name: 'Defect Category',
    width: 150,
    resizable: true,
    sortable: true,
    fixed: false,
    type: 'string',
    pinned: false,
    id: 31,
  },
  // {
  //   field: 'PDCNotificationDate',
  //   name: 'Notification Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 32,
  // },
  // {
  //   field: 'PDCRequestDate',
  //   name: 'Requested Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 33,
  // },
  // {
  //   field: 'KickOffMeetingDate',
  //   name: 'Kick Off Meeting Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 34,
  // },
  // {
  //   field: 'QualityAlertIssueDate',
  //   name: 'QA - Quality Alert Issue Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 35,
  // },
  // // {
  // //   field: 'QualityAlertNumber',
  // //   name: 'Quality Alert No.',
  // //   width: 150,
  // //   resizable: true,
  // //   sortable: true,
  // //   fixed: false,
  // //   type: "string",
  // //   pinned: false,
  // // },
  // {
  //   field: 'SMDNotifiedDate',
  //   name: 'SMD Notified (Export) Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 36,
  // },
  // {
  //   field: 'ApprovedRepairProcedureDate',
  //   name: 'Approved Repair Procedure Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 37,
  // },
  // {
  //   field: 'OpCodeCostShareLetterDate',
  //   name: 'Op Code & Cost Share Letter Date (Workplace Chemical Protection Program)',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 38,
  // },
  // {
  //   field: 'InspectionTime',
  //   name: 'Inspection Timing',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 39,
  // },
  // {
  //   field: 'TMNAPDCETC',
  //   name: 'ETC Estimated time of Completion',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 40,
  // },
  // {
  //   field: 'PDCFieldActivityCompletionDate',
  //   name: 'PDC Field Activity Completion Date',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: 'string',
  //   pinned: false,
  //   id: 41,
  // },
  // {
  //   field: 'RepairTime',
  //   name: 'Repair Timing',
  //   width: 150,
  //   resizable: true,
  //   sortable: true,
  //   fixed: false,
  //   type: "string",
  //   pinned: false,
  // },
];

export const dropdownOptions = [
  { label: 'Y', value: 'yes' },
  { label: 'N', value: 'no' },
];

export const getKey = (key: string) => {
  switch (key) {
    case 'KickOffMeetingDate':
      key = 'PDCKOMtgDate';
      break;
    case 'VehiclesPendingApproval':
      key = 'PendingApproval';
      break;
    case 'SalesSeries':
      key = 'Series';
      break;
    case 'CreatedDate':
      key = 'DateCreated';
      break;
    case 'HoldYear':
      key = 'PDCYear';
      break;
    case 'VehiclesCompleted':
      key = 'VehiclesReleasedKpi';
      break;
    case 'InEvaluation':
      key = 'ReadyForRelease';
      break;
    case 'HoldAge':
      key = 'ageofrequest';
      break;
    case 'Assignee':
      key = 'PEAssignee';
      break;
    case 'TotalVehiclesSubmitted':
      key = 'Approved';
      break;
    case 'TotalVehicles':
      key = 'TotalVehiclesSubmitted';
      break;
    case 'Drafts':
    case 'Pending':
    case 'ActiveHold':
    case 'Completed':
    case 'Canceled':
      key = 'HoldStatus';
  }
  return key;
};

export const HOLD_STATUS_MAPPING = {
  draft: '1',
  'pending approval': '2',
  'active hold': '3',
  completed: '4',
  canceled: '5',
  'in progress': '6',
  'pending update': '7',
};

export const HOLD_TYPE_MAPPING = {
  pdc: '1',
  yard: '2',
};

export const parseMaxAttempts = 20;
export const parseTimeout = 1000;
