import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Dropdown } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import {
  getCompanyResponsibleOptions,
  getEditPanel,
  getHoldStatus,
  getRequestingDivisionOptions,
  getSupplierResponsibleOptions,
  getToyotaInternalDetails,
  getYardToPDCState,
} from '../../../../../store/features/HoldTypeDetails/selectors';
import { setToyotaInternalDetails } from '../../../../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { AccordionContent } from '../../../../../types/Shapes/Accordion.shape';
import { RenderItem } from '../RenderItem';
import './ToyotaInternalDetails.scss';

//Styles
const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: '1.5rem 1rem',
});
const StyledGrid2 = styled(Grid)({
  display: 'flex',
  padding: '0.75rem 0px 0.75rem 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.625rem',
  alignSelf: 'stretch',
});

const ToyotaInternalDetails: React.FC<AccordionContent> = ({ DetailsP, setFooterDisable }) => {
  const [icfDropdown, setICFDropdown] = useState<boolean>(false);
  const [companyResponsibleDropdown, setCompanyResponsibleDropdown] = useState<boolean>(false);
  const [supplierResponsibleDropdown, setSupplierResponsibleDropdown] = useState<boolean>(false);

  const holdStatus = useAppSelector(getHoldStatus);
  const toyotaInternalDetails = useAppSelector(getToyotaInternalDetails);
  const editPanel = useAppSelector(getEditPanel);
  const icfOptionList = useAppSelector(getRequestingDivisionOptions);
  const companyResponsibleOptionsList = useAppSelector(getCompanyResponsibleOptions);
  const supplierResponsibleOptionList = useAppSelector(getSupplierResponsibleOptions);
  const dispatch = useAppDispatch();
  const isyardToPDC = useAppSelector(getYardToPDCState);

  const getLabel = (input) => {
    return (supplierResponsibleOptionList.find(x=>x.id === input)?.optionLabel || input);
  }

  const handleICF = (option: any) => {
    dispatch(setToyotaInternalDetails({ ...toyotaInternalDetails, icf: option }));
    setFooterDisable(false);
  };

  const handleCompanyResponsible = (option: any) => {
    dispatch(
      setToyotaInternalDetails({
        ...toyotaInternalDetails,
        companyResponsible: option,
      }),
    );
    setFooterDisable(false);
  };

  const handleSupplierResponsible = (option: any) => {
    dispatch(
      setToyotaInternalDetails({
        ...toyotaInternalDetails,
        supplierResponsible: option,
      }),
    );
    setFooterDisable(false);
  };

  const isEdit = holdStatus === 'Draft' || editPanel === '3' || isyardToPDC;

  return (
    <StyledGrid container>
      <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
        <RenderItem
          label="Internal Control Function"
          value={
            !isEdit ? (
              toyotaInternalDetails?.icf?.optionLabel ?? '-'
            ) : (
              <Dropdown
                variant="singleSelect"
                sx={{ width: '440px' }}
                onOpen={() => setICFDropdown(true)}
                onClose={() => setICFDropdown(false)}
                onChange={handleICF}
                optionsList={icfOptionList}
                open={icfDropdown}
                selectedOptions={toyotaInternalDetails.icf}
              />
            )
          }
        />
      </StyledGrid2>

      <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
        <RenderItem
          label="Company Responsible"
          value={
            !isEdit ? (
              toyotaInternalDetails?.companyResponsible?.optionLabel ?? '-'
            ) : (
              <Dropdown
                variant="singleSelect"
                sx={{ width: '440px' }}
                onOpen={() => setCompanyResponsibleDropdown(true)}
                onClose={() => setCompanyResponsibleDropdown(false)}
                onChange={handleCompanyResponsible}
                optionsList={companyResponsibleOptionsList}
                open={companyResponsibleDropdown}
                selectedOptions={toyotaInternalDetails.companyResponsible}
              />
            )
          }
        />
      </StyledGrid2>
      <StyledGrid2 item xs={12} sm={6} md={4} lg={4}>
        <RenderItem
          label="Supplier Responsible"
          value={
            !isEdit ? (
              toyotaInternalDetails?.supplierResponsible?.optionLabel ??
              getLabel(toyotaInternalDetails?.supplierResponsible) ??
              '-'
            ) : (
              <Dropdown
                variant="singleSelect"
                sx={{ width: '440px' }}
                onOpen={() => setSupplierResponsibleDropdown(true)}
                onClose={() => setSupplierResponsibleDropdown(false)}
                onChange={handleSupplierResponsible}
                optionsList={supplierResponsibleOptionList}
                open={supplierResponsibleDropdown}
                selectedOptions={
                  toyotaInternalDetails.supplierResponsible?.id
                    ? toyotaInternalDetails.supplierResponsible
                    : {
                        id: toyotaInternalDetails.supplierResponsible,
                        optionLabel: getLabel(toyotaInternalDetails.supplierResponsible),
                      }
                }
              />
            )
          }
        />
      </StyledGrid2>
    </StyledGrid>
  );
};

export default ToyotaInternalDetails;
