import axios, { AxiosResponse, AxiosError } from 'axios';
import { endpoint } from './endpoint';
import { getEnv } from '../../env/env';
 
interface RequestParams {
    reqHeaders?: Record<string, any>;
    params?: Record<string, any>;
    authToken?: string;
}
 
interface ApiResponse {
    data?: any;
    headers?: any;
    status?: number;
}

async function createRequest({ reqHeaders, params, authToken }: RequestParams) {
    
    const xApiKey = "cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70"
    
    //const baseURL = endpoint.BASE_URL_STAGING;
    const baseURL = `${getEnv().API_BASE_URL}`;
    const headers = {
        'Content-Type': reqHeaders?.['Content-Type'] || 'application/json',
        Accept: 'application/json',
        ...(authToken && { Authorization: authToken }),
        ...(xApiKey && {"x-api-key": xApiKey}),
        ...reqHeaders,
    };
 
    return axios.create({
        baseURL,
        responseType: 'json',
        headers,
        params,
    });
}
 
export async function apiHandler({
    url,
    method,
    headers: reqHeaders,
    data: jsonData,
    params,
    authToken,
}: {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    headers?: Record<string, any>;
    data?: any;
    params?: Record<string, any>;
    authToken?: string;
}): Promise<ApiResponse> {
    try {
        const request = await createRequest({ reqHeaders, params, authToken });
        let result: AxiosResponse;
        switch (method) {
            case 'POST':
                result = await request.post(url, jsonData);
                break;
            case 'PUT':
                result = await request.put(url, jsonData);
                break;
            case 'DELETE':
                result = await request.delete(url);
                break;
            default:
                result = await request.get(url);
        }
        const { data, headers, status } = result;
        return { data, headers, status };
    } catch (error) {
        handleCatchBlock(error);
        if (axios.isAxiosError(error)) {
            const { data, headers, status } = (error as AxiosError).response || {};
            return { data, headers, status };
        } else {
            return {
                data: { error_code: 500, error_message: 'Error in getting data' },
            };
        }
    }
}
 
export const handleCatchBlock = (error: any) => {
};