// @ts-nocheck
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button, LoadingSpinner, Modal, useNotificationContext } from 'cubee2e-cube-global-components';
import _ from 'lodash';
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { apiHandler } from '../../assets/api';
import { endpoint } from '../../assets/api/endpoint';
import './AllHoldRequest.scss';

import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../App';
import { HOLD_STATUS_MAPPING, HOLD_TYPE_MAPPING, HoldRequestColumns, getKey } from '../../Constants';
import { getHoldRequestDetailsApi, getHoldType, getVehicleDetailsMetricsApi } from '../../store';
import { getHoldStatusCountsApi, getVehicleListApi } from '../../store/features/HoldRequest/api';
import {
  getDataTable,
  getFilter,
  getHoldRequestStatusCount,
  getLastRefreshDate,
  getPdcStatus,
  getSelectedView,
  getSort,
} from '../../store/features/HoldRequest/selectors';
import {
  setColumns,
  setFilter,
  setInlineFilterValues,
  setLoading,
  setPagination,
  setRefresh,
  setReload,
  setSelectedItems,
  setSort,
  setTableHeader,
  setViewName,
  setViewUpdated,
} from '../../store/features/HoldRequest/slice';
import { setHoldReqNumber, setHoldStatus, setHoldType } from '../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import theme from '../../theme';
import { DataTableShape } from '../../types';
import { formatNumber } from '../../utils';
import { AccessContext } from '../AccessProvider/AccessProvider';
import { CreateHoldRequestModal } from '../CreateHoldRequest/CreateHoldRequestModal';
import { SavePageView } from '../SavePageView/SavePageView';
import { AllHoldRequestDataTable } from './AllHoldRequestDataTable/AllHoldRequestDataTable';
import KpiCard from './KpiCard';
let pdcNumber = localStorage.getItem('HM-PDCNumber');
const gridName = 'Hold List';
const SaveAsModal = lazy(() => import('../SavePageView/SaveAsModal/SaveAsModal'));
const ManagePageView = lazy(() => import('../SavePageView/ManagePageViewModal/ManagePageView'));
const controller = new AbortController();
const signal = controller.signal;

function AllHoldRequest() {
  //# SAVE PAGE VIEW - STATES
  const [viewUpdated, setViewUpdate] = useState<boolean>(false);
  const [openSaveAsModal, setOpenSaveAsModal] = useState<boolean>(false);
  const [openManagePageViewModal, setOpenManagePageViewModal] = useState<boolean>(false);
  const [savePageViewList, setSavePageViewList] = useState<{}>({});
  const [selectedPageView, setSelectedPageView] = useState<string>('');
  const [savePageLoader, setSavePageLoader] = useState<boolean>(false);
  let [currentColumns, setCurrentColumns] = useState<[]>(HoldRequestColumns.slice(0, 9));
  const [hasPermission, setHasPermission] = useState<boolean>(false);

  //Redux hooks
  const navigate = useNavigate();

  const { dispatchNotification } = useNotificationContext();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(getFilter);
  const sort = useAppSelector(getSort);
  const pdcStatus = useAppSelector(getPdcStatus);
  const auth = useAppSelector((state) => state.auth);
  const holdType = useAppSelector(getHoldType);
  const { columns, pagination, tableHeader, inlineFilterValues, sorts } = useAppSelector(getDataTable);
  const selectedView = useAppSelector(getSelectedView);
  const lastrefreshedDT = useAppSelector(getLastRefreshDate);
  const qcHoldStatusCount = useAppSelector(getHoldRequestStatusCount);
  const [HoldCountInfo, setHoldCountInfo] = useState({
    selectKpiLabel: 'QC Hold Count',
    selectKpiCount: qcHoldStatusCount.all,
  });
  const authToken = auth.idToken;
  const authAccessToken = auth.accessToken;
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo') || '{}');
  const { roleManager } = useContext(AccessContext);
  useEffect(() => {
    const checkUserPermission = async () => {
      if (roleManager) {
        try {
          const permissionGranted = await roleManager.checkPermission('PDC Hold', 'Create');
          setHasPermission(permissionGranted);
        } catch (error) {
          console.error('Error checking permission:', error);
          setHasPermission(false);
        }
      }
    };

    checkUserPermission();
  }, [roleManager]);
  useEffect(() => {
    dispatch(getVehicleListApi(handleNotification));
    dispatch(getHoldStatusCountsApi());
    const holdTypeObj = filter.find((filterdata) => filterdata.field === 'HoldType');
    if (holdTypeObj?.query === '1') {
      setHoldCountInfo({
        selectKpiLabel: 'PDC Hold Count',
        selectKpiCount: qcHoldStatusCount.pdcCount,
      });
    } else if (holdTypeObj?.query === '2') {
      setHoldCountInfo({
        selectKpiLabel: 'Yard Hold Count',
        selectKpiCount: qcHoldStatusCount.yardCount,
      });
    } else {
      setHoldCountInfo({
        selectKpiLabel: 'QC Hold Count',
        selectKpiCount: qcHoldStatusCount.all,
      });
      dispatch(setHoldType('0'));
    }
  }, [filter, sort, pagination]);

  useEffect(() => {
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  }, [holdType]);

  useEffect(() => {
    getPageViewList();
    dispatch(setLoading(true));
    dispatch(getHoldStatusCountsApi());
  }, []);

  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };

  const getPageViewList = async (noDefault = false) => {
    let userDetails = userData;

    if (!userData || Object.keys(userDetails).length <= 0) {
      let details = await getUserDetails(authAccessToken);
      userDetails = details;
    }

    const result = await apiHandler({
      url: endpoint.PAGE_VIEW_LIST + `?userId=${userDetails?.employeeId}&gridName=${gridName}`,
      method: 'GET',
      authToken,
    });

    if (result) {
      setSavePageViewList(result?.data?.pageViews);
      let defaultView = result?.data?.pageViews?.pinnedViews?.find((x) => x.isDefault);
      if (defaultView && !noDefault) {
        onClickViewName(defaultView);
      } else if (!noDefault) {
        unsetView(result?.data?.pageViews);
      }
    }
  };

  const getAndSetPageViews = (pageViews, oldName, newName) => {
    setSavePageViewList(pageViews);
    if (selectedPageView === oldName) {
      setSelectedPageView(newName);
      dispatch(setViewName(newName));
    }
    if ([...(pageViews?.pinnedViews || []), ...(pageViews?.unPinnedViews || [])].length <= 1) {
      unsetView();
    }
  };

  const handleReset = () => {
    dispatch(setRefresh());
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };

  const handleSelectedItemUpdate = (selectedItems: any) => {
    dispatch(setSelectedItems(selectedItems));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };

  const handleRowCellClick = (data: any) => {
    // Fetch hold request and related data from the clicked row
    const holdReqNo = data?.row?.HoldRequest;
    const HoldType = data?.row?.HoldType?.props?.children?.props?.label;
    const HoldStatus = data?.row?.HoldStatus?.props?.children?.props?.label;
    const DraftCheck = data?.row?.HoldStatus?.props?.children?.props?.label?.props?.children; // Ensure this structure is correct
    const createdDate = data?.row?.CreatedDate;

    dispatch(setHoldType(HoldType));
    dispatch(setHoldReqNumber(holdReqNo));
    dispatch(setHoldStatus(HoldStatus));
    // dispatch(setSubmissionDate(createdDate));

    navigate(`/HoldManagement/QChold/${holdReqNo}`);
    dispatch(getVehicleDetailsMetricsApi(holdReqNo));

    // dispatch(getHoldRequestDetailsAPI(holdReqNo));
    dispatch(getHoldRequestDetailsApi(holdReqNo));
  };

  const handleCurrentSortUpdate = async (newSort: DataTableShape.SortData[]) => {
    let sortUpdate = [
      ...newSort.map((x) => {
        let columnName = getKey(x.field);
        let fieldName = HoldRequestColumns.find((y) => y.field === x.field).name;
        let fieldOrder = x.order;
        return {
          ...x,
          field: x.field,
          columnName: columnName,
          fieldName: fieldName,
          order: fieldOrder,
        };
      }),
    ];
    handleNotification({
      fieldName: 'success',
      field: `Sort applied to ${sortUpdate[0].fieldName} in ${
        sortUpdate[0].order === 'ASC' ? 'ascending' : 'descending'
      } order.`,
    });
    dispatch(setSort(sortUpdate));
    dispatch(setReload(true));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };

  const handlePaginationUpdatedRows = (pagination: any) => {
    dispatch(setReload(true));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
    dispatch(setPagination({ page: pagination.page, size: pagination.size }));
  };

  const handleDeleteFilter = (field: string) => {
    let newFilterObj = [...filter].filter((x) => x.field !== field);

    dispatch(setFilter([...newFilterObj]));
    dispatch(setInlineFilterValues(_.omit(inlineFilterValues, field)));

    dispatch(setPagination({ page: 1, size: 25 }));

    if (!viewUpdated && (selectedPageView?.length || 0) > 0) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };

  const opDetails = (input: string) => {
    const [opPart, valuePart] = input.split('=');
    return {
      op: {
        compare: opPart.length <= 1,
        equalOp: opPart.length < 1,
      },
      input: valuePart ?? opPart,
    };
  };

  const getHoldStatusValue = (filter: DataTableShape.Filter) => {
    const val = filter.value[0].toString().toLowerCase();
    const { op, input } = opDetails(val);

    if (!input?.length) return { ...filter };

    const case2 = op.compare && !op.equalOp;
    const statusArr = input.split(',').flatMap((inputItem) => {
      inputItem = inputItem.trim();
      return Object.keys(HOLD_STATUS_MAPPING)
        .filter((status) => {
          const cleanedStatus = status.replaceAll(' ', '');
          return case2 ? !cleanedStatus.match(inputItem) : cleanedStatus.match(inputItem);
        })
        .map((status) => HOLD_STATUS_MAPPING[status].toString());
    });

    const query1 = statusArr.join(',');
    const input1 = statusArr
      .map((statusValue) =>
        Object.keys(HOLD_STATUS_MAPPING).find((key) => HOLD_STATUS_MAPPING[key].toString() === statusValue),
      )
      .filter(Boolean)
      .join(',');

    return {
      ...filter,
      value: [input1],
      query: query1,
    };
  };
  const isLooseMatch = (key, searchValue) => {
    return searchValue.split('').every((char) => key.includes(char));
  };
  const getHoldTypeValue = (filter: DataTableShape.Filter) => {
    const val = filter.value[0].toString().toLowerCase().replaceAll(' ', '');
    const key = Object.keys(HOLD_TYPE_MAPPING).find((key) => {
      const searchedValue = isLooseMatch(key, val);
      if (searchedValue) {
        return key;
      }
    });
    const value = key ? HOLD_TYPE_MAPPING[key] : null;
    return {
      ...filter,
      value: [key],
      query: value,
    };
  };

  const handleApplyFilter = (filtersobject: DataTableShape.Filter) => {
    if (Array.isArray(filtersobject.value) && !filtersobject.value?.length) {
      handleDeleteFilter(filtersobject.field);
      return;
    }

    let appliedFilters = [...filter];
    if (filtersobject.field === 'HoldStatus') {
      appliedFilters = [...appliedFilters].filter((x) => x.name !== filtersobject?.name);
      appliedFilters = [...appliedFilters, getHoldStatusValue(filtersobject)];
    } else if (filtersobject.field === 'HoldType') {
      appliedFilters = [...appliedFilters].filter((x) => x.field !== filtersobject?.field);
      dispatch(setHoldType(HOLD_TYPE_MAPPING[filtersobject?.value[0].toString().toLowerCase()]));
      appliedFilters = [...appliedFilters, getHoldTypeValue(filtersobject)];
    } else {
      appliedFilters = [...appliedFilters].filter((x) => x.name !== filtersobject?.name);
      appliedFilters = [...appliedFilters, filtersobject];
    }

    dispatch(setFilter(appliedFilters));
    dispatch(setInlineFilterValues({}));
    // dispatch(setInlineFilterValues({ ...inlineFilterValues, [filtersobject.field]: filtersobject.value }));
    dispatch(setPagination({ page: 1, size: 25 }));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
    handleNotification({
      fieldName: 'success',
      field: `Filter applied to ${filtersobject.field}.`,
    });
  };

  const handleApplyCustomizeColumns = (customizedColumns: any[]) => {
    dispatch(setColumns(customizedColumns));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };

  const handleMenuItemClick = (item: any) => {
    if (item === 'saveAs') {
      setOpenSaveAsModal(true);
    } else if (item === 'managePageView') {
      setOpenManagePageViewModal(true);
    } else if (item === 'save') {
      updatePageView();
    } else if (item === 'default') {
      unsetView();
    } else {
      onClickViewName(savePageViewList['pinnedViews'].find((x) => x.viewName === item));
    }
  };

  const onClickViewName = (view) => {
    if (!view || Object.keys(view)?.length <= 0) {
      return;
    }
    let sortObj = view.viewData.orderBy || (sorts.length > 0 ? sorts[0] : {});
    sortObj = {
      ...sortObj,
      ...(Object.keys(sortObj)?.length > 0
        ? {
            columnName: getKey(sortObj.field),
            fieldName: columns.find((x) => x.field === sortObj.field)?.name || sorts[0]?.fieldName,
          }
        : {}),
    };
    dispatch(setSort([sortObj]));
    dispatch(setPagination({ ...(view.viewData.page || { page: 1, size: 25 }) }));
    dispatch(
      setColumns(
        [...(view?.viewData?.tableCurrentColumns || currentColumns)].map((x) => ({ ...x, isPinned: x.pinned })),
      ),
    );
    dispatch(
      setTableHeader({
        ...tableHeader,
        sortlabel: `Sorted by ${sortObj.fieldName || 'None'}`,
      }),
    );
    dispatch(setFilter([...view.viewData.filters]));
    setCurrentColumns([...(view.viewData.tableCurrentColumns || currentColumns)]);

    if (view.userId !== 0) {
      setSelectedPageView(view.viewName);
      dispatch(setViewName(view.viewName));
    } else {
      setSelectedPageView('');
      dispatch(setViewName(''));
    }
    setViewUpdate(false);
    dispatch(setViewUpdated(false));
  };

  const handleColumnUpdate = (columns: DataTableShape.ColumnData[]) => {
    dispatch(setColumns(columns));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };

  const unsetView = (list: {} | null) => {
    list = list || savePageViewList;
    if (!list?.unPinnedViews?.find((x) => x.userId === 0)) {
      return;
    }
    onClickViewName(list?.unPinnedViews?.find((x) => x.userId === 0));
  };

  const updatePageView = async () => {
    setSavePageLoader(true);

    let view = [...(savePageViewList?.pinnedViews || []), ...(savePageViewList?.unPinnedViews || [])].find(
      (x) => x.viewName === selectedPageView,
    );
    if (!view) {
      return;
    }

    const payload = {
      userId: userData?.employeeId,
      gridName: gridName,
      isUpdateViewData: true,
      pageViews: [
        {
          viewName: selectedPageView,
          isDefault: view.isDefault,
          isPinned: view.isPinned,
          viewOrder: view.viewOrder,
          seqNo: view.seqNo,
          viewData: {
            page: pagination,
            orderBy: sorts[0],
            tableCurrentColumns: columns,
            filters: filter,
          },
        },
      ],
    };

    try {
      const result = await apiHandler({
        url: endpoint.PAGE_VIEW_UPDATE,
        method: 'PUT',
        authToken,
        data: payload,
      });

      if (['200', '201'].includes(result.status.toString())) {
        setViewUpdate(false);
        dispatch(setViewUpdated(false));
        handleNotification({ fieldName: 'success', field: 'View details updated.' });
        getPageViewList(true);
      }
      setSavePageLoader(false);
    } catch (error) {
      setSavePageLoader(false);
      handleNotification({ fieldName: 'error', field: 'View update Failed.' });
    }
  };

  const pdcStatusObj = pdcStatus[0];
  const getCount = (...values) => {
    const total = values.reduce((acc, val) => acc + (parseInt(val) || 0), 0);
    return total > 0 ? formatNumber(total.toString()) : '-';
  };
  const handleKpiCardFilter = (kpiCardFilter: DataTableShape.Filter) => {
    const filterObj = filter.filter((fil) => fil.name !== 'Hold Status');
    dispatch(setFilter([...filterObj, kpiCardFilter]));
    if (!viewUpdated && !!selectedPageView) {
      setViewUpdate(true);
      dispatch(setViewUpdated(true));
    }
  };
  const KpiCards = [
    {
      label: 'Drafts',
      count: pdcStatusObj?.draft ? formatNumber(pdcStatusObj.draft) : '-',
      handleClick: () => handleKpiCardFilter({ field: 'Drafts', name: 'Hold Status', value: ['draft'], query: '1' }),
      refreshedDate: lastrefreshedDT,
    },
    {
      label: 'Pending',
      count: getCount(pdcStatusObj?.pendingrequest, pdcStatusObj?.pendingupdate),
      refreshedDate: lastrefreshedDT,
      handleClick: () =>
        handleKpiCardFilter({
          field: 'Pending',
          name: 'Hold Status',
          value: ['pending approval,pending update'],
          query: '2,7',
        }),
    },
    {
      label: 'Active Holds',
      count: getCount(pdcStatusObj?.openrequest, pdcStatusObj?.inprogress),
      handleClick: () =>
        handleKpiCardFilter({ field: 'ActiveHold', name: 'Hold Status', value: ['active,in progress'], query: '3,6' }),
      refreshedDate: lastrefreshedDT,
    },
    {
      label: 'Completed',
      count: pdcStatusObj?.completedrequest ? formatNumber(pdcStatusObj.completedrequest) : '-',
      handleClick: () =>
        handleKpiCardFilter({ field: 'Completed', name: 'Hold Status', value: ['completed'], query: '4' }),
      refreshedDate: lastrefreshedDT,
    },
    {
      label: 'Canceled',
      count: pdcStatusObj?.cancelledrequest ? formatNumber(pdcStatusObj.cancelledrequest) : '-',
      handleClick: () =>
        handleKpiCardFilter({ field: 'Canceled', name: 'Hold Status', value: ['canceled'], query: '5,8' }),
      refreshedDate: lastrefreshedDT,
    },
  ];

  const RenderSavingChangesModal = () => {
    return (
      <Modal
        modalButton={<Button></Button>}
        openModal={savePageLoader}
        label={'Saving Changes'}
        closeIcon={''}
        variant="Passive modal"
        bodyText={`You made modifications to your ${selectedPageView}.Please wait while changes are being saved.`}
        slotComponent={
          <div
            style={{
              padding: '68px',
              textAlign: 'center',
            }}
          >
            <LoadingSpinner variant="loading-spinner" />
          </div>
        }
      />
    );
  };

  const RenderSaveAsModal = () => {
    return (
      <Suspense fallback={<></>}>
        <SaveAsModal
          openSaveAsModal
          setOpenSaveAsModal={setOpenSaveAsModal}
          setSelectedValue={(viewData) => {
            setSelectedPageView(viewData);
            dispatch(setViewName(viewData));
            dispatch(setViewUpdated(false));
            setViewUpdate(false);
            getPageViewList(true);
            handleNotification({ fieldName: 'success', field: 'New view saved.' });
          }}
          savePageViewList={savePageViewList}
          gridViewName={gridName}
          saveData={{
            page: {
              page: pagination.page,
              size: pagination.size,
            },
            filters: filter,
            orderBy: sorts[0],
            tableCurrentColumns: [...columns],
          }}
        />
      </Suspense>
    );
  };

  if (pdcNumber) {
    const filter = {
      fieldName: 'success',
      field: `Hold Request #${pdcNumber} Pending Approval`,
    };
    handleNotification(filter);
    localStorage.removeItem('HM-PDCNumber');
  }
  const RenderHeader = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          ml: '1rem',
          mr: '1rem',
          position: 'sticky',
          top: 0, // Ensure the header sticks to the top
          zIndex: 1100, // Ensure the header stays above other content
          background: '#fff', // Add background to avoid transparency when scrolling
          mb: '1rem',
          borderBottom: '1px solid #ddd', // Optional: a border for visual separation
        }}
      >
        <Stack
          sx={{
            padding: '1rem',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderRadius: '4px',
            background: '#fff',
          }}
        >
          <Stack direction="column" gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h3">Current View:</Typography>
              <Typography variant="h3Semibold">{selectedView?.label}</Typography>
            </Stack>
            <Typography variant="labelRegular" sx={{ color: theme.palette.secondary[30] }}>
              {lastrefreshedDT}
            </Typography>
          </Stack>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }} gap={2}>
            {hasPermission && <CreateHoldRequestModal />}
            <SavePageView
              savePageViewList={savePageViewList}
              selectedPageView={selectedPageView}
              viewUpdated={viewUpdated}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Stack>
        </Stack>
      </Grid>
    );
  };

  const RenderQCHoldCount = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          position: 'relative',
          overflowY: 'auto',
          height: 'auto',
          ml: '1rem',
          mr: '1rem',
        }}
      >
        <Stack
          sx={{
            padding: '1rem',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderRadius: '4px',
            background: '#fff',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3Semibold">{HoldCountInfo?.selectKpiLabel}</Typography>
          <Typography variant="h3">{formatNumber(HoldCountInfo?.selectKpiCount)}</Typography>
        </Stack>
      </Grid>
    );
  };

  useEffect(() => {
    let actionreq = localStorage.getItem('actionRequest');
    if (actionreq !== null) {
      actionreq = JSON.parse(actionreq);
      if (actionreq?.status === 'success') {
        handleNotification(actionreq?.message);
        localStorage.removeItem('actionRequest');
      }
    }
  }, []);
  return (
    <Box
      sx={{
        marginTop: '7.5rem', // Total height of the header
        height: 'calc(100vh - 7.5rem)',
        overflowY: 'auto',
        position: 'relative',
        zIndex: '999',
      }}
    >
      <Grid container>
        {/* Sticky Header */}
        <RenderHeader />
        <RenderQCHoldCount />

        {/* KPI Cards */}
        <Grid item xs={12} sx={{ margin: '1rem' }}>
          <KpiCard kpiCards={KpiCards} />
        </Grid>

        {/* Data Table - Sticky */}
        <Grid
          item
          xs={12}
          sx={{
            margin: '0rem 1rem 1rem 1rem',
            overflow: 'hidden',
            position: 'sticky',
            top: '7.5rem', // Ensure the table stays below the header
            zIndex: 1000, // Ensure it's under the header but above the content
            background: '#fff', // Ensure it doesn’t become transparent
            borderBottom: '1px solid #ddd', // Optional: a border for visual separation
          }}
        >
          <AllHoldRequestDataTable
            handleReset={handleReset}
            handleApplyFilter={handleApplyFilter}
            handleDeleteFilter={handleDeleteFilter}
            handlePagination={handlePaginationUpdatedRows}
            handleRowCellClick={handleRowCellClick}
            handleSelectedItemUpdate={handleSelectedItemUpdate}
            handleCurrentSortUpdate={handleCurrentSortUpdate}
            handleApplyCustomizeColumns={handleApplyCustomizeColumns}
            handleCurrentColumnUpdate={handleColumnUpdate}
          />
        </Grid>

        {/* Modals */}
        {openSaveAsModal && <RenderSaveAsModal />}
        {openManagePageViewModal && (
          <Suspense fallback={<></>}>
            <ManagePageView
              viewsList={savePageViewList}
              openManagePageViewModal
              setOpenManagePageViewModal={setOpenManagePageViewModal}
              getAndSetPageViews={(e, oldName, newName) => getAndSetPageViews(e, oldName, newName)}
              gridViewName={gridName}
            />
          </Suspense>
        )}

        <RenderSavingChangesModal />
      </Grid>
    </Box>
  );
}

export default AllHoldRequest;
