import { NavigateFunction } from 'react-router-dom';
import { IssueDetailsData } from '../../../types';
import { validateDropdownValue } from '../../../utils';
import { AppDispatch, RootState } from '../../store';
import { setCurrentUploadStage } from '../HoldRequest/slice';
import { createQCApi } from './api';
import {
  setHoldDetails,
  setIssueDetails,
  setIssueResolution,
  setModelSpecification,
  setParts,
  setRequesterApprovals,
  setToyotaInternalDetails,
} from './slice';
import {
  DropdownOptions,
  IssueDetails,
  IssueResolution,
  ModelSpecification,
  Parts,
  RequesterApprovals,
  ToyotaInternalDetails,
} from './types';

export const parseAndSetData = (parsedData: any, type: 'Details' | 'Create', navigate?: NavigateFunction) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    let currentYear = new Date().getFullYear();
    const state = getState();
    parseHoldDetails(parsedData, dispatch);
    parseIssueDetails(parsedData, dispatch, state.holdTypeDetails.dropdownOptions, state.holdTypeDetails.issueDetails);
    parseIssueResolution(parsedData, dispatch);
    parseToyotaDetails(parsedData, dispatch, state.holdTypeDetails.dropdownOptions);
    parseParts(parsedData, dispatch, state.holdTypeDetails.dropdownOptions);
    parseModelSpec(parsedData, currentYear, dispatch, state.holdTypeDetails.dropdownOptions);
    parseAuthorizedBy(parsedData, dispatch);
    if (navigate && type === 'Create') {
      dispatch(setCurrentUploadStage(6));
      dispatch(createQCApi(navigate));
    }
  };
};

const parseIssueDetails = (
  parsedData: any,
  dispatch: AppDispatch,
  dropdown: DropdownOptions,
  issueDetailsState: IssueDetailsData,
) => {
  let validatedRequestingDivision = validateDropdownValue(
    dropdown,
    'requestingDivisionOptions',
    parsedData?.requestingDivision || '',
  );
  let validatedIssueHLRootCause = validateDropdownValue(
    dropdown,
    'rootCauseOptions',
    parsedData?.issueHLRootCause || '',
  );
  let validateIssueDefectCategory = validateDropdownValue(
    dropdown,
    'defectCategoryOptions',
    parsedData?.issueDefectCategory || parsedData?.issueDefectCategory_Id || '',
  );

  let issueDetails: IssueDetails = {
    issueTitle: issueDetailsState?.issueTitle
      ? issueDetailsState?.issueTitle
      : parsedData?.issueHighlevelDescription || parsedData?.issueTitle || '',
    dateFound: parsedData?.dateIssueFound || '',
    requestingDivision: {
      id: validatedRequestingDivision,
      optionLabel: validatedRequestingDivision,
    },
    detailedIssueDescription: parsedData?.detailedIssueDescription || '',
    rootCause: {
      id: validatedIssueHLRootCause,
      optionLabel: validatedIssueHLRootCause,
    },
    defectCategory: {
      id: validateIssueDefectCategory,
      optionLabel: validateIssueDefectCategory,
    },
    notificationDate: parsedData?.issueNotificationDate || '',
    placeFound: parsedData?.placeFound || '',
    problemReportingChain:
      parsedData?.problemReportingChain.trim() !== '' ? parsedData?.problemReportingChain.split('-->') : [],
    delayDays: 0,
    delayTiming: '',
  };
  dispatch(setIssueDetails(issueDetails));
};

const parseIssueResolution = (parsedData: any, dispatch: AppDispatch) => {
  let issueResolution: IssueResolution = {
    pdcActionDirectionAtYardPort: parsedData?.pdcActionDirectionAtYardPort || '',
    processContainmentMethod: parsedData?.processContainmentMethod || '',
    flowOutPreventionMethod: parsedData?.flowOutPreventionMethod || '',
  };
  dispatch(setIssueResolution(issueResolution));
};

const parseAuthorizedBy = (parsedData: any, dispatch: AppDispatch) => {
  let approverList = [
    {
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
    },
  ];

  if (Array.isArray(parsedData?.requesterApprovalsList) && parsedData?.requesterApprovalsList?.length > 0) {
    approverList = parsedData?.requesterApprovalsList?.map((x) => ({
      fullName: `${x?.data?.firstName || ''} ${x?.data?.lastName || ''}`,
      firstName: x?.data?.firstName || '',
      lastName: x?.data?.lastName || '',
      email: x?.data?.email || '',
      jobTitle: x?.data?.jobtitle || '',
    }));
  } else if (parsedData?.firstName || parsedData?.lastName || parsedData.email || parsedData.jobtitle) {
    approverList = [
      {
        fullName: `${parsedData?.firstName || ''} ${parsedData?.lastName || ''}`,
        firstName: parsedData?.firstName || '',
        lastName: parsedData?.lastName || '',
        email: parsedData.email || '',
        jobTitle: parsedData.jobtitle || '',
      },
    ];
  }

  let requestorApproval: RequesterApprovals = {
    approvalUsers: approverList,
  };

  dispatch(setRequesterApprovals(requestorApproval));
};

const parseHoldDetails = (parsedData: any, dispatch: AppDispatch) => {
  const holdDetails = {
    pdcPEAssignee: { id: parsedData?.PDCPEAssignee, optionLabel: parsedData?.PDCPEAssignee },
    riskRank: {
      id: parsedData?.riskRank || '',
      optionLabel: parsedData?.riskRank || '',
    },
    anticipatedDelayTiming: parsedData?.anticipatedDelayTiming || '',
  };
  dispatch(setHoldDetails(holdDetails));
};

const parseToyotaDetails = (parsedData: any, dispatch: AppDispatch, dropdown: DropdownOptions) => {
  let validatedRequestingDivision = validateDropdownValue(dropdown, 'requestingDivisionOptions', parsedData?.ICF || '');
  let validatedCompanyResponsible = validateDropdownValue(
    dropdown,
    'companyResponsibleOptions',
    parsedData?.companyResponsible || '',
  );
  let validatedSupplierResponsible = validateDropdownValue(
    dropdown,
    'supplierResponsibleOptions',
    parsedData?.supplierResponsible || '',
  );
  let toyotaInternalDetails: ToyotaInternalDetails = {
    icf: {
      id: validatedRequestingDivision,
      optionLabel: validatedRequestingDivision,
    },
    companyResponsible: {
      id: validatedCompanyResponsible,
      optionLabel: validatedCompanyResponsible,
    },
    supplierResponsible: {
      id: validatedSupplierResponsible,
      optionLabel: validatedSupplierResponsible,
    },
  };
  dispatch(setToyotaInternalDetails(toyotaInternalDetails));
};

const parseParts = (parsedData: any, dispatch: AppDispatch, dropdown: DropdownOptions) => {
  let validatedRequestingDivision = validateDropdownValue(
    dropdown,
    'requestingDivisionOptions',
    parsedData?.icfForPartIssue || '',
  );

  let partsList = [
    {
      partNumber: '',
      partName: '',
      partSupplier: '',
      icfForPartIssue: { id: '', optionLabel: '' },
      supplierCode: '',
      index: 0,
    },
  ];

  if (
    parsedData.partNumber ||
    parsedData.partName ||
    parsedData.partSupplier ||
    parsedData.icfForPartIssue ||
    parsedData.partSupplierCode
  ) {
    partsList = [
      {
        partNumber: parsedData.partNumber || '',
        partName: parsedData.partName || '',
        partSupplier: parsedData.partSupplier || '',
        icfForPartIssue: { id: validatedRequestingDivision || '', optionLabel: validatedRequestingDivision || '' },
        supplierCode: parsedData.partSupplierCode || '',
        index: 0,
      },
    ];
  }

  let parts: Parts = {
    partDtls: partsList,
  };

  dispatch(setParts(parts));
};

const parseModelSpec = (parsedData: any, currentYear: number, dispatch: AppDispatch, dropdown: DropdownOptions) => {
  let vehicleList = [];

  if (parsedData?.modelYear || parsedData?.series || parsedData?.code || parsedData?.vehicleTypeSpecification) {
    let validatedSeries = validateDropdownValue(dropdown, 'seriesDropdownOptions', parsedData?.series || '');
    let validatedModel = validateDropdownValue(dropdown, 'modelDropdownOptions', parsedData?.code || '');

    vehicleList = [
      {
        modelYear: {
          open: false,
          value: { id: parsedData?.modelYear || '', optionLabel: parsedData?.modelYear || '' },
          options: [(currentYear + 1).toString(), currentYear.toString(), (currentYear - 1).toString()].map((x) => ({
            id: x,
            optionLabel: x,
          })),
        },
        model: {
          open: false,
          value: validatedModel ? [validatedModel] : [],
          options: [],
        },
        vehicleTypeSpecification: parsedData?.vehicleTypeSpecification || '',
        series: {
          open: false,
          value: { id: validatedSeries, optionLabel: validatedSeries },
          options: [],
        },
      },
    ];
  } else {
    vehicleList = [
      {
        modelYear: {
          open: false,
          value: { id: '', optionLabel: '' },
          options: [(currentYear + 1).toString(), currentYear.toString(), (currentYear - 1).toString()].map((x) => ({
            id: x,
            optionLabel: x,
          })),
        },
        model: {
          open: false,
          value: [],
          options: [],
        },
        vehicleTypeSpecification: '',
        series: {
          open: false,
          value: { id: '', optionLabel: '' },
          options: [],
        },
      },
    ];
  }

  let modelSpecifications: ModelSpecification = {
    startDateType: { id: '', optionLabel: '' },
    vinStartDateTime: '',
    vinStartLogicReasoning: '',
    endDateType: { id: '', optionLabel: '' },
    vinEndDateTime: '',
    vinEndLogicReasoning: '',
    vehicleDtl: vehicleList,
  };

  dispatch(setModelSpecification(modelSpecifications));
};
