// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  max-width: 100vw;
  background-color: #F8F8F9 !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,oCAAoC;AACtC","sourcesContent":["body {\n  margin: 0;\n  min-width: 320px;\n  min-height: 100vh;\n  max-width: 100vw;\n  background-color: #F8F8F9 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
