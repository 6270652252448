// @ts-nocheck
import { AccessTimeOutlined, CloseFullscreen } from '@mui/icons-material';
import BackHandIcon from '@mui/icons-material/BackHand';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsRailwayFilledSharpIcon from '@mui/icons-material/DirectionsRailwayFilledSharp';
import FactoryIcon from '@mui/icons-material/Factory';
import GarageIcon from '@mui/icons-material/Garage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Alert, Box, Grid, LinearProgress, Snackbar, SnackbarContent, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios, { AxiosRequestConfig } from 'axios';
import {
  Button,
  Chip,
  DataTableHeader,
  DataTableView,
  LoadingSpinner,
  Modal,
  Pagination,
  useNotificationContext,
} from 'cubee2e-cube-global-components';
import { useEffect, useState } from 'react';
import { getVehicleDetailsMetricsApi } from '../../../../../store';
import cancel from '../../../../../assets/svg/cancel.svg';
import cancelwarning from '../../../../../assets/svg/cancelwarning.svg';
import check from '../../../../../assets/svg/check.svg';
import fileIcon from '../../../../../assets/svg/xlsFileIcon.svg';
import { getEnv } from '../../../../../env/env';
import { useAppSelector } from '../../../../../store/hooks';
import './GoCanvas.scss';
import { uploadDocStyle } from './GoCanvasConstants';
import { ImportModalProps } from './GoCanvasDataShape';
const countStyling = styled(Typography)({
  color: 'var(--sys-light-Text-variant, #58595B)',

  /* Body/Long Regular */
  fontFamily: 'ToyotaType-Regular',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px' /* 142.857% */,
});
const HeaderTypography = styled(Typography)({
  color: '#000',
  fontFamily: 'ToyotaType-Semibold',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '32px',
});
const BodyTypography = styled(Typography)({
  color: '#000',
  fontFamily: 'ToyotaType-Regular',
  width: '784px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineheight: '20px',
  paddingBottom: '0px',
});
// const CancelTypography = styled(Typography)({
//   color: '#000',
//   fontFamily: "ToyotaType-Semibold",
//   fontSize: '1.5rem',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '2rem',
// });
// const CancelTypography2 = styled(Typography)({
//   color: 'var(--sys-light-Text-variant, #58595B)',
//   fontFamily: "ToyotaType-Regular",
//   fontSize: '.875rem',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '1.25rem', /* 142.857% */
// });

function GoCanvasModal(props: ImportModalProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pagination, setPagination] = useState<{ pageCount: number; limitCount: number }>({
    pageCount: 1,
    limitCount: 15,
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const [limitCount, setLimitCount] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Object[]>([]);
  const [vehicleList, setVehicleList] = useState<Object[]>([]);
  const [selectedText, setSelectedText] = useState<string>(`0 items selected`);
  const [selectedRecs, setSelectedRecs] = useState<Object>({});
  const [selectAll, setSelectAll] = useState<Boolean>(false);
  const authToken = useAppSelector((state) => state.auth).idToken;
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
  const [downloadFileEvent, setDownloadFileEvent] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loadDataTable, setLoadDataTable] = useState(false);
  const [reload, setReload] = useState(false);
  const [dataTableCancel, setDataTableCancel] = useState(false);
  const { dispatchNotification } = useNotificationContext();
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };

  const getResetButton = (filterArray) => {
    return (
      <Button
        variant="tertiary"
        sx={{ padding: '0.375rem 1rem', alignItems: 'end', right: 0 }}
        onClick={handleReset}
        size="small"
        label="Clear Table Filters"
        disabled={filterArray.length > 0 ? false : true}
      />
    );
  };

  const handleReset = () => {
    setFilters([]);
    setReload(true);
    getVehicleList([]);
  };

  const handleApplyFilter = (filterObject) => {
    if (['LifecycleStatus'].includes(filterObject.field)) {
      console.log('unsupported filter');
      return;
    }
    setReload(true);
    getVehicleList([
      ...filters.filter((x) => x.field !== filterObject.field),
      { ...filterObject, value: [filterObject.value[0].toUpperCase()] },
    ]);
  };

  const handleDeleteFilter = (inField) => {
    setFilters((filters) => [...filters.filter((x) => x.field !== inField)]);
    setReload(true);
    getVehicleList([...filters.filter((x) => x.field !== inField)]);
  };

  let columns = [
    {
      field: 'Series',
      name: 'Sales Series',
      width: 90,
      resizable: true,
      sortable: true,
      fixed: false,
      type: 'string',
      pinned: false,
      vhColumn: true,
    },
    {
      field: 'VIN',
      name: 'VIN',
      width: 209,
      resizable: true,
      sortable: true,
      fixed: false,
      type: 'string',
      pinned: false,
      // copyIcon: true,
    },
    {
      field: 'URN',
      name: 'URN',
      width: 209,
      resizable: true,
      sortable: true,
      fixed: false,
      type: 'string',
      pinned: false,
      vhColumn: true,
      // copyIcon: true,
    },
    {
      field: 'status',
      name: 'Upload Status',
      width: 150,
      sortable: true,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'HoldStatus',
      name: 'From Hold Status',
      width: 200,
      sortable: true,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    {
      field: 'newHoldStatus',
      name: 'To Hold Status',
      width: 200,
      sortable: true,
      fixed: false,
      type: 'string',
      pinned: false,
    },
    // {
    //     field: "CurrentLocation",
    //     name: "Current Location",
    //     width: 205,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // },
    // {
    //   field: "LifecycleStatus",
    //   name: "Lifecycle Status",
    //   width: 184,
    //   resizable: true,
    //   sortable: true,
    //   fixed: false,
    //   type: "string",
    //   pinned: false,
    // },
    // {
    //     field: "Location",
    //     name: "Plant",
    //     width: 115,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // },
    // {
    //     field: "ActualLineOffDate",
    //     name: "Production Date",
    //     width: 184,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // },
    // {
    //     field: "DestCountry",
    //     name: "Destination Country",
    //     width: 167,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // },
    // {
    //     field: "Region",
    //     name: "Region",
    //     width: 87,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // },
    // {
    //     field: "SoldDate",
    //     name: "Sold Date",
    //     width: 160,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // }, {
    //     field: "Katashiki",
    //     name: "Katashiki",
    //     width: 170,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // }, {
    //     field: "MYSpec",
    //     name: "MY Spec",
    //     width: 90,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // }, {
    //     field: "AssemblySequence",
    //     name: "Assembly Sequence",
    //     width: 160,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // }, {
    //     field: "SoldSequence",
    //     name: "Sold Sequence",
    //     width: 120,
    //     resizable: true,
    //     sortable: true,
    //     fixed: false,
    //     type: "string",
    //     pinned: false,
    // }
  ];

  //addtoHOld
  {
    if (props.action === 'add') {
      columns = [
        {
          field: 'UploadStatus',
          name: 'Upload Status',
          width: 160,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'Series',
          name: 'Series',
          width: 90,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'VIN',
          name: 'VIN',
          width: 209,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'DestinationCountry',
          name: 'Destination Country',
          width: 209,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'HoldStatus',
          name: 'Hold Status',
          width: 149,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'currentDisacharge',
          name: 'Current Discharge Location',
          width: 200,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'etaWindow',
          name: 'ETA Window',
          width: 87,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'Region',
          name: 'Region',
          width: 87,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'CurrentLocation',
          name: 'Current Location',
          width: 205,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'vehicleLifeCycleStatus',
          name: 'Vehicle Life Cycle Status',
          width: 205,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
        {
          field: 'vehicleLifeSubCycleStatus',
          name: 'Vehicle Life Sub Cycle Status',
          width: 205,
          resizable: true,
          sortable: true,
          fixed: false,
          type: 'string',
          pinned: false,
        },
      ];
    }
  }

  // ? Selection logic start section --------------------------------------------------------------------

  const getSelectedItemsCount = () => {
    let paginatedRecords = Object.values({ ...selectedRecs });
    if ([].concat(...paginatedRecords).length === 0 && selectAll) {
      return totalCount;
    }
    let totalLen = 0;
    let offset = 0;

    paginatedRecords.forEach((x) => {
      totalLen =
        totalLen +
        (x.length > 0
          ? selectAll
            ? pagination.limitCount - x.length
            : x.length
          : selectAll
          ? pagination.limitCount
          : 0);
    });

    if (selectAll) {
      let unvisitedPages = Math.ceil(totalCount / pagination.limitCount) - paginatedRecords.length;
      if (!paginatedRecords[unvisitedPages - 1]) {
        offset = pagination.limitCount * (unvisitedPages - 1);
        let rem = totalCount % pagination.limitCount;
        offset = offset + (rem > 0 ? rem : pagination.limitCount);
      } else {
        offset = offset + pagination.limitCount;
      }
      totalLen = totalLen + offset;
    }

    return totalLen;
  };

  const handleSelectInversion = (selectedItems: Vehicle[], rowData?: Vehicle[]) => {
    if (!rowData) {
      rowData = [...(vehicleData || [])];
    }
    if (!selectAll) {
      return selectedItems;
    }
    let returnArray = rowData.filter((x) => !selectedItems.some((y) => y.id === x.id));
    return returnArray;
  };

  const handleSelectedItemUpdate = async (selectedItems: Vehicle[]) => {
    let paginatedRecords = { ...selectedRecs };
    let newlySelected = selectedItems.length;
    let currentSelected: number = (paginatedRecords[pagination.pageCount] || []).length;
    currentSelected = selectAll ? pagination.limitCount - currentSelected : currentSelected;
    let totalSelected = getSelectedItemsCount();
    if (Math.abs(newlySelected - currentSelected) <= 1) {
      setSelectedRecs((recs) => ({ ...recs, [pagination.pageCount]: [...handleSelectInversion(selectedItems)] }));
      let count = totalSelected + (newlySelected - currentSelected);
      setSelectedText(`${formatNumber(count)} items selected`);
    } else {
      handleSelectAllSelection(currentSelected, totalSelected);
    }
  };

  const handleSelectAllSelection = (currentSelected: Number, totalSelected: Number) => {
    let selectedAllRecords = totalSelected.toString() === totalCount.toString() && selectAll;
    if (selectedAllRecords || currentSelected === pagination.limitCount || currentSelected === totalCount) {
      resetSelection(selectedAllRecords);
    } else {
      selectPageActionHandler();
    }
  };

  const resetSelection = (selectedAll: boolean, filterReset?: boolean) => {
    if (filterReset) {
      setSelectedRecs({});
      return;
    }

    setSelectedText(
      `${formatNumber(selectedAll ? 0 : getSelectedItemsCount() - pagination.limitCount)} items selected`,
    );

    if (selectAll) {
      setSelectAll(false);
      setSelectedRecs({});
    } else {
      let selected = { ...selectedRecs, [pagination.pageCount]: handleSelectInversion([]) };
      setSelectedRecs(selected);
    }
  };

  const selectPageActionHandler = () => {
    let selectionLength =
      getSelectedItemsCount() -
      handleSelectInversion(selectedRecs[pagination.pageCount] || []).length +
      Math.min(pagination.limitCount, totalCount, totalCount - (pagination.pageCount - 1) * pagination.limitCount);
    let data = selectAll ? [] : vehicleData;
    if (selectionLength.toString() === totalCount.toString()) {
      setSelectAll(true);
      data = [];
    }
    setSelectedRecs({
      ...selectedRecs,
      [pagination.pageCount]: [...(data || [])],
    });
    setSelectedText(`${formatNumber(selectionLength)} items selected`);
  };

  // ? Selection logic end section --------------------------------------------------------------------

  const applyFontFamily = (text, sx? = {}) => <div style={{ ...sx, fontFamily: 'ToyotaType-Regular' }}>{text}</div>;

  let LifecycleStatusChip = (label: string | number | JSX.Element) => {
    return <Chip label={label} colorScheme="primaryContainer" variant="filled"></Chip>;
  };

  let CurrentLocationIcon = (label: string) => {
    return label === 'Rail Location'
      ? { icon: <DirectionsRailwayFilledSharpIcon />, label }
      : label === 'Truck Location'
      ? { icon: <LocalShippingIcon />, label }
      : label === 'Dealership'
      ? { icon: <GarageIcon />, label }
      : label === 'Plant Location'
      ? { icon: <FactoryIcon />, label }
      : label === 'Vessel Location'
      ? { icon: <DirectionsBoatIcon />, label }
      : { icon: null, label };
  };

  const CurrentLocationComponent = (label: string) => {
    const { icon, label: locationLabel } = CurrentLocationIcon(label);
    return (
      <div style={{ display: 'flex' }}>
        {icon && (
          <div
            style={{
              padding: '4px',
              gap: '8px',
              borderRadius: '4px',
              height: '30px',
              width: '32px',
              backgroundColor: '#143A8C',
              color: '#FFFFFF',
            }}
          >
            {icon}
          </div>
        )}
        <div style={{ margin: '6px 6px', fontFamily: 'ToyotaType-Regular' }}>{locationLabel}</div>
      </div>
    );
  };
  let UpdateStatusIcon = (label: string) => {
    return label?.toUpperCase() === 'Valid'.toUpperCase()
      ? { icon: <img src={check} alt="Cancel icon" />, label }
      : label === 'Invalid'
      ? { icon: <img src={cancel} alt="Cancel icon" />, label }
      : label === 'Not Found in PDC'
      ? { icon: <img src={cancel} alt="Cancel icon" />, label }
      : label === 'Not in Hold'
      ? { icon: <img src={cancel} alt="Cancel icon" />, label }
      : label === 'Duplicate'
      ? { icon: <img src={cancelwarning} alt="Cancel icon" />, label }
      : { icon: null, label };
  };
  const getBackgroundColor = (label: string) => {
    switch (label.toUpperCase()) {
      case 'Valid'.toUpperCase():
        return '#E9F6EC';
      case 'Invalid'.toUpperCase():
        return '#FEEBED';
      case 'Not Found in PDC'.toUpperCase():
        return '#FEEBED';
      case 'Not in Hold'.toUpperCase():
        return '#FEEBED';
      case 'Duplicate'.toUpperCase():
        return '#FDF0E6';
      default:
        return '#FFFFFF';
    }
  };

  const UpdateStatusComponent = (label: string) => {
    const { icon, label: locationLabel } = UpdateStatusIcon(label);
    const backgroundColor = getBackgroundColor(label);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && (
          <div
            style={{
              padding: '4px',
              gap: '8px',
              borderRadius: '4px',
              height: '30px',
              width: '32px',
              paddingLeft: '8px',
              background: backgroundColor,
            }}
          >
            {icon}
          </div>
        )}
        <div style={{ margin: '6px 6px', fontFamily: 'ToyotaType-Regular' }}>
          {locationLabel === 'Invalid' ? locationLabel + ' VIN' : locationLabel}
        </div>
      </div>
    );
  };

  const HoldStatusIcon = (inLabel: string = '') => {
    const label = inLabel.toLowerCase().replaceAll(' ', '');
    return ['1', 'pending', 'pendingupdate', 'pendingforapproval'].includes(label)
      ? { icon: <PanoramaFishEyeIcon />, label: 'Pending' }
      : ['2', 'onhold'].includes(label)
      ? { icon: <BackHandIcon />, label: inLabel }
      : ['3', 'readyforrelease', 'readytorelease', 'evaluationpending', 'inevaluation'].includes(label)
      ? { icon: <CheckIcon />, label: inLabel }
      : ['5', 'invalid'].includes(label)
      ? { icon: <ClearIcon />, label: 'Invalid' }
      : ['4', 'completed'].includes(label)
      ? { icon: <CheckIcon />, label: 'Completed' }
      : ['6', 'notifycqs'].includes(label)
      ? { icon: <PanoramaFishEyeIcon />, label: inLabel }
      : ['7', 'holdinitiationinprogress', 'inprogress'].includes(label)
      ? { icon: <AccessTimeOutlined />, label: 'Hold Initiation in progress' }
      : ['8', 'completeinprogress', 'inprogress'].includes(label)
      ? { icon: <AccessTimeOutlined />, label: 'Complete in progress' }
      : ['SAN','11'].includes(label)
      ? { icon: <ClearIcon />, label: 'SAN' }
      : ['invalid'].includes(label)
      ? { icon: <ClearIcon />, label: 'Invalid' }
      : ['VALID'].includes(label.toUpperCase())
      ? { icon: <CheckIcon />, label: 'Valid' }
      : { icon: null, label: inLabel };
  };

  const HoldStatusComp = (label: string, sx = {}) => {
    const { icon, label: iconLabel } = HoldStatusIcon(label);
    return (
      <div style={{ display: 'flex' }}>
        {icon && (
          <div
            style={{
              padding: '4px',
              gap: '8px',
              borderRadius: '4px',
              height: '30px',
              width: '32px',
              backgroundColor: '#E66C02',
              color: '#FFFFFF',
              ...sx
            }}
          >
            {icon}
          </div>
        )}
        <div style={{ margin: '6px 6px', fontFamily: 'ToyotaType-Regular', fontWeight: 400, fontSize: '14px' }}>
          {iconLabel}
        </div>
      </div>
    );
  };

  const getStatus = (statusInfo: string, vehicleStatus: string) => {
    statusInfo = statusInfo.toLowerCase().replace(' ', '');
    const returnValue =
      statusInfo === 'readytorelease' || statusInfo === 'readyforrelease'
        ? vehicleStatus === 'onhold' || vehicleStatus === '2'
          ? '3'
          : vehicleStatus
        : statusInfo || '';
  };

  let vehicleData = vehicleList.map((x, i) => {
    const index = (i + (pagination.pageCount - 1) * pagination.limitCount).toString();
    return {
      id: index,
      Series: applyFontFamily(x.salesSeriesName || ''),
      VIN: applyFontFamily(x.vin || ''),
      URN: applyFontFamily(x.urn || ''),
      DestCountry: applyFontFamily(x.documentData['Delivery Country Name'] || '-'),
      status: HoldStatusComp(x.status || 'Invalid', {
        backgroundColor: x?.status?.toUpperCase() === 'Valid'.toUpperCase() ? '#E9F6EC': '#FEEBED',
        color: x?.status?.toUpperCase() === 'Valid'.toUpperCase() ? '#000': '#FFFFFF'
      }),
      HoldStatus: HoldStatusComp(x.status && x.status?.toUpperCase() === 'Valid'.toUpperCase() ? x.vehicleStatus?.toString() : '-'),
      newHoldStatus: HoldStatusComp(x.status && x.status?.toUpperCase() === 'Valid'.toUpperCase() ? x.newHoldStatus?.toString() : '-'),
      CurrentLocation: CurrentLocationComponent(x.CurrentLocation || 'Plant Location'),
      Location: applyFontFamily(x.manufacturingLocation?.plantShortDescription || '-'),
      ActualLineOffDate: applyFontFamily(x.vehicleDates?.actualLineOffDate || '-'),
      SoldDate: applyFontFamily(x.retailSales?.rdrDate || '-'),
      LifecycleStatus: LifecycleStatusChip(x.vehicleEvent?.vehicleLifeCycleStatus || '-'),
      Katashiki: applyFontFamily(x.order?.katashikiDescription || '-'),
      MYSpec: applyFontFamily(x.MYSpec || '-'),
      AssemblySequence: applyFontFamily(x.AssemblySequence || '-'),
      SoldSequence: applyFontFamily(x.SoldSequence || '-'),
    };
  });
  {
    if (props.action === 'add') {
      vehicleData = vehicleList.map((x, i) => {
        const index = (i + (pagination.pageCount - 1) * pagination.limitCount).toString();
        return {
          id: index,
          UploadStatus: UpdateStatusComponent(x.uploadStatus || ''),
          Series: applyFontFamily(x.salesSeriesName || ''),
          VIN: applyFontFamily(x.vin || ''),
          DestinationCountry: applyFontFamily(x.destinationCountry || '-'),
          HoldStatus: HoldStatusComp(x.status.toUpperCase() === 'Valid'.toUpperCase() ? 'Valid' : '-'),
          etaWindow: applyFontFamily(x.etaWindow || ''),
          Region: applyFontFamily(x.currentRegionCode || ''),
          CurrentLocation: CurrentLocationComponent(x.CurrentLocation || 'Plant Location'),
          currentDisacharge: applyFontFamily(x.manufacturingLocation?.plantShortDescription || '-'),
          vehicleLifeCycleStatus: LifecycleStatusChip(x.vehicleEvent?.vehicleLifeCycleStatus || '-'),
          vehicleLifeSubCycleStatus: x.vehicleEvent?.lifecycleSubstatusDescription || '-',
        };
      });
    }
  }

  const getColumnName = (field: string) => {
    let column = field;
    switch (field) {
      case 'Series':
        column = 'salesSeriesName';
        break;
      case 'VIN':
        column = 'vin';
        break;
      case 'URN':
        column = 'urn';
        break;
      case 'DestCountry':
        column = 'documentData.Delivery Country Name';
        break;
      case 'status':
        column = 'status';
        break;
      case 'HoldStatus':
        column = 'vehicleStatus';
        break;
      case 'newHoldStatus':
        column = 'toStatus';
        break;
      case 'Location':
        column = 'plantShortDescription';
        break;
      case 'ActualLineOffDate':
        column = 'actualLineOffDate';
        break;
      case 'SoldDate':
        column = 'rdrDate';
        break;
      case 'LifecycleStatus':
        column = 'vehicleLifeCycleStatus';
        break;
      case 'Katashiki':
        column = 'katashikiDescription';
        break;
      case 'MYSpec':
        column = 'MYSpec';
        break;
      case 'AssemblySequence':
        column = 'AssemblySequence';
        break;
      case 'SoldSequence':
        column = 'SoldSequence';
        break;
    }
    return column;
  };

  const getFilterObject = (filters: Object[]) => {
    let filterObj = {};
    filters.forEach((x) => {
      let columnName = getColumnName(x.field);
      let op = 'na';
      let arrObj = x.value[0].split('=');
      if (columns.find((y) => y.field === x.field)?.vhColumn) {
        op = 'eq';
        arrObj = [x.value[0]];
      } else {
        if (arrObj.length > 1 && arrObj[0].length > 0) {
          op = arrObj[0] === '!' ? 'notIn' : arrObj[0] === '>' ? 'gte' : arrObj[0] === '<' ? 'lte' : 'na';
        } else if (arrObj.length > 1 && arrObj[0].length === 0) {
          op = 'eq';
        } else if (arrObj.length === 1) {
          op = arrObj[0][0] === '>' ? 'gt' : arrObj[0][0] === '<' ? 'lt' : 'na';
          if (op === 'na') {
            op = arrObj[0].split(',').length > 1 ? 'in' : 'like';
          }
        }
      }
      filterObj[`${columnName}`] = { [op]: arrObj[arrObj.length - 1].split(',') };
    });
    return filterObj;
  };

  const getVehicleList = async (filter: Object[] = filters) => {
    let filterQuery = getFilterObject(filter);

    const apiKey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      filter: filterQuery,
      qcNumber: props.qcNumber,
      limit: pagination.limitCount,
      offset: (pagination.pageCount - 1) * pagination.limitCount,
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/canvasVehicleListing/VEHICLE_FILTER/${props.documentId}`;
    try {
      const response = await axios.post(url, reqData, config);
      if (response?.data) {
        setVehicleList(response.data.data.vehicles);
        setTotalCount(response.data.data.finalCount);
        setFilters(filter);
      }
    } catch (error) {
      if (error.status === 404) {
        handleNotification({ fieldName: 'success', field: 'No vehicles found.' });
      } else {
        handleNotification({ fieldName: 'error', field: 'Something went wrong.' });
      }
    } finally {
      setLoading(false);
      setReload(false);
    }
  };

  const formatNumber = (number: Number) => {
    return (number || 0).toLocaleString('en-US');
  };

  const submitUpdate = async () => {
    setLoading(true);
    const apiKey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      qcNumber: props.qcNumber,
      condition: selectAll ? 'ALL' : 'NONE',
      documentId: props.documentId,
      vehicles: Object.values(selectedRecs)
        .flat()
        .map((x) => x.VIN?.props?.children),
      userId: userData?.employeeId,
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/updateVehicleStatus`;
    try {
      const response = await axios.put(url, reqData, config);
      const mes = response.data.message;
      if (!response?.data?.errorCode) {
        props.loadUploadedFile({});
        handleNotification({ fieldName: 'success', field: 'Vehicle Status Updated' });
        dispatch(getVehicleDetailsMetricsApi(props.qcNumber));
      } else {
        handleNotification({ fieldName: 'error', field: mes });
        throw new Error(response?.data?.message || 'Could not update vehicles.');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      handleNotification({ fieldName: 'error', field: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  const actionLink = () => {
    return (
      <text
        style={{ cursor: 'pointer', color: 'blue' }}
        onClick={() => {
          if (!selectAll) {
            setSelectAll(true);
            setSelectedRecs({ [pagination.pageCount]: [] });
            setSelectedText(`${formatNumber(totalCount)} items selected`);
          } else {
            setSelectAll(false);
            setSelectedRecs({});
            setSelectedText(`0 items selected`);
          }
        }}
      >{`${selectAll ? 'Unselect' : 'Select'} All ${formatNumber(totalCount)}`}</text>
    );
  };

  const handleModalCancel = () => {
    setDataTableCancel(true);
    setModalOpen(false);
    //props.setShowImport(false);
  };
  const handleDataTableCancelPrevious = () => {
    setModalOpen(true);
    setLoadDataTable(true);
    setTimeout(() => {
      setLoadDataTable(false);
    }, 100);
  };
  const handleDataTableCancel = () => {
    setDataTableCancel(false);
    props.setShowImport(false);
  };

  const [count, setCount] = useState({
    duplicateVinsCount: 0,
    invalidVinsCount: 0,
    notAssociatedWithHoldCount: 0,
    totalCount: 0,
    validVinsCount: 0,
  });
  useEffect(() => {
    const HandleCount = async () => {
      const url = `${getEnv().API_BASE_URL}/hold/QChold/canvasVehicleListing/VEHICLE_FILTER/${props.documentId}/${
        props.qcNumber
      }/count`;
      const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

      const config = {
        headers: {
          Authorization: authToken,
          'x-api-key': apikey,
        },
      };
      try {
        const response = await axios.get(url, config);
        setCount(response.data.data);
      } catch (error) {}
    };
    if (props.action === 'add') {
      setSnackBar(true);
      setDisableButton(true);
      setTimeout(() => {
        HandleCount();
      }, 2000);
    } else {
      HandleCount();
    }
  }, []);
  const handleDownload = async () => {
    const apiKey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';
    // setDownloadFileEvent(true)
    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      qcNumber: props.qcNumber,
      limit: totalCount,
      offset: (pagination.pageCount - 1) * pagination.limitCount,
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/canvasVehicleListing/VEHICLE_FILTER/${props.documentId}/download`;

    try {
      const response = await axios.post(url, reqData, config);
      if (response.status === 201) {
        const link = response.data.data;
        const urlLink = `${getEnv().DOCUMENT_URL}/${link}`;
        const configs: AxiosRequestConfig = {
          headers: {
            Authorization: `${authToken}`,
          },
          responseType: 'blob',
        };
        const responseFile = await axios.get(urlLink, configs);
        const splitPath = link.split('/');
        const filename = splitPath[splitPath.length - 1];
        if (responseFile.status === 200) {
          const contentType = responseFile.headers['content-type'] || 'application/octet-stream';
          const blob = new Blob([responseFile.data], { type: contentType });
          const downloadUrl = window.URL.createObjectURL(blob);

          const contentDisposition = responseFile.headers['content-disposition'];
          const downloadLink = document.createElement('a');
          downloadLink.href = downloadUrl;
          downloadLink.download = filename; // Use the extracted filename
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(downloadUrl);
          handleNotification({ fieldName: 'success', field: 'File Download Successfully' });
        } else {
          handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
        }
      } else {
        handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
      }
    } catch (error) {
      handleNotification({ fieldName: 'error', field: 'Something went wrong. Please try again later.' });
    }
  };
  //loader with progress bar
  const [progressCount, setProgressCount] = useState(0);
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const [snackBar, setSnackBar] = useState(false);

  const handleCount = () => {
    if (progressCount < 100) {
      const time = setTimeout(() => {
        setProgressCount((prevCount) => prevCount + 10);
      }, 1000);
    } else if (progressCount == 100) {
      setNotificationSuccess(true);
    }
    return () => clearTimeout(time);
  };
  useEffect(() => {
    if (snackBar === true && progressCount >= 10) {
      handleCount();
    }
    if (snackBar === false) {
      setNotificationSuccess(false);
      setProgressCount(0);
    }
  }, [snackBar, progressCount]);

  const AddtoHold = async () => {
    setProgressCount(10);
    const apiKey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const config = {
      headers: {
        Authorization: authToken,
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      qcNumber: props.qcNumber,
      limit: pagination.limitCount,
      offset: (pagination.pageCount - 1) * pagination.limitCount,
    };

    const url = `${getEnv().API_BASE_URL}/hold/QChold/popupVehicleListing/appendixc/${props.documentId}`;
    try {
      const response = await axios.post(url, reqData, config);
      if (response.status === 201) {
        setProgressCount(70);
        setDisableButton(false);
        setVehicleList(response.data.data.vehicles);
        setTotalCount(response.data.data.finalCount);
      } else {
        setSnackBar(false);
        setProgressCount(0);
        handleNotification({ fieldName: 'error', field: 'Failed to Pop-Up Vehicle Listing.' });
      }
    } catch (error) {
      setSnackBar(false);
      setProgressCount(0);
      handleNotification({ fieldName: 'error', field: 'Failed to Pop-Up Vehicle Listing.' });
    }
  };
  const actionButtonModal = () => {
    return (
      <>
        <Grid sx={{ margin: '8px' }}>
          <Button
            variant="tertiary"
            label={'Previous'}
            onClick={() => {
              handleDataTableCancelPrevious();
            }}
          />
        </Grid>
        <Grid sx={{ margin: '8px' }}>
          <Button
            variant="danger"
            label={'Cancel'}
            onClick={() => {
              handleDataTableCancel();
            }}
          />
        </Grid>
      </>
    );
  };
  const HandleOpenDataTable = () => {
    setLoadDataTable(true);
    setModalOpen(true);
    setSnackBar(false);
    setTimeout(() => {
      setLoadDataTable(false);
    }, 200);
  };

  const getModalHeader = () => {
    return (
      <h2 className="container" style={{ margin: '4px 0px', fontWeight: '400' }}>
        {'Select Vehicles to Action'}
      </h2>
    );
  };

  const getModalBodyComponent = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          className="container"
          style={{
            width: '70vw',
            margin: `0px -24px '0px`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p className="body-text">Status will be updated for valid VINs.</p>
          <Box component="ul" p={0} m={0} style={{ listStyleType: 'square', display: 'flex', gap: '16px' }}>
            <Box>
              <countStyling>{count?.totalCount} items</countStyling>
            </Box>
            <Box component="li" style={{ marginLeft: '16px' }}>
              <countStyling>{count?.validVinsCount} Valid</countStyling>
            </Box>
            <Box component="li" style={{ marginLeft: '16px' }}>
              <countStyling>{count?.invalidVinsCount} Invalid</countStyling>
            </Box>
            <Box component="li" style={{ marginLeft: '16px' }}>
              <countStyling>{count?.notAssociatedWithHoldCount} Not Found in PDC</countStyling>
            </Box>
            <Box component="li" style={{ marginLeft: '16px' }}>
              <countStyling>{count?.duplicateVinsCount} Duplicates</countStyling>
            </Box>
            <Box component="li" style={{ marginLeft: '16px' }}>
              <countStyling>Sorted by -</countStyling>
            </Box>
          </Box>
        </div>
        <div>
          <Button label={<SaveAltIcon />} onClick={() => handleDownload()} />
        </div>
      </div>
    );
  };
  {
    /* <Modal
  closeIcon={''}
  handleCancel={() => { setDownloadFileEvent(false) }}
  handleClose={() => { }}
  modalButton={<Button></Button>}
  openModal={downloadFileEvent}
  label={<CancelTypography >Download File</CancelTypography>}
  variant='Passive modal'
  bodyText={<CancelTypography2>File Download is in Progress. Please wait...</CancelTypography2>}
  slotComponent={<div
    style={{
      padding: '68px',
      textAlign: 'center',
      width:"480px"
    }} >
    <LoadingSpinner variant='loading-spinner' />
  </div>} /> */
  }
  const getModalSlotComponent = () => {
    return (
      <div
        className="container"
        style={{
          width: `92vw`,
          height: `60vh`,
          margin: `0px -24px -36px`,
          padding: `0px 16px 0px`,
        }}
      >
        <div
          style={{
            ...(loading
              ? {
                  minHeight: `400px`,
                }
              : {}),
            width: `90vw`,
            height: `61vh`,
            margin: 0,
            padding: 0,
            fontFamily: 'ToyotaType-Regular',
            border: '1px',
            borderColor: '#D8DCDE',
            borderStyle: 'solid',
            borderRadius: '4px',
            ...(loading || vehicleList.length <= 0
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}),
          }}
        >
          {loading ? (
            <LoadingSpinner />
          ) : vehicleList.length > 0 ? (
            vehicleList && (
              <>
                {
                  <DataTableHeader
                    sx={{
                      width: '100%',
                    }}
                    title={``}
                    tableCount={`${formatNumber(totalCount)} items `}
                    viewlabel={selectedText}
                    sortLabel={actionLink()}
                    enableSelectedItemsBanner={false}
                    toolbarIcons={() => <></>}
                  />
                }
                <DataTableView
                  className="container"
                  sx={{
                    position: 'relative',
                    overflow: 'auto',
                    padding: 0,
                    width: '100%',
                    minHeight: '200px',
                    height: '38vh',
                    minWidth: '100%',
                  }}
                  tableData={{
                    rowsData: [...vehicleData],
                    dropDownOptions: [],
                    loading: loadDataTable,
                    reloading: reload,
                    count: totalCount,
                  }}
                  columns={columns.map((x) => ({
                    ...x,
                    sortable: false,
                    pinnable: false,
                    hidable: false,
                  }))}
                  sorts={[{ field: 'VIN', order: 'ASC' }]}
                  handleRowCellClick={() => {}}
                  handleDeleteFilter={handleDeleteFilter}
                  filters={{
                    appliedFilters: filters,
                    resetButton: getResetButton(filters),
                  }}
                  handleReset={handleReset}
                  inlineFilterValues={{}}
                  handleApplyFilter={handleApplyFilter}
                  paginationComponent={<></>}
                  checkboxEnabled={true}
                  selectedItems={handleSelectInversion(selectedRecs[pagination.pageCount] || [])}
                  ellipsisIcon={{
                    enabled: false,
                    renderIcon: <></>,
                  }}
                  handleCurrentColumnUpdate={() => {}}
                  handleSelectedItemUpdate={handleSelectedItemUpdate}
                  toastNotification={{
                    isEnabled: false,
                    type: '',
                    field: '',
                  }}
                />
                <Pagination
                  sx={{
                    width: '100%',
                    padding: 0,
                  }}
                  pagination={{ page: pagination.pageCount, size: pagination.limitCount }}
                  sizeOptions={[15]}
                  totalCount={loading ? 0 : totalCount}
                  handlePaginationChange={(data) => {
                    setPagination({ ...pagination, pageCount: data.page });
                  }}
                />
              </>
            )
          ) : (
            applyFontFamily('No Data')
          )}
        </div>
      </div>
    );
  };
  const getActionButtonProps = () => {
    return {
      disabled: (count?.duplicateVinsCount > 0 || (getSelectedItemsCount() <= 0)),
      variant: 'primary',
      label: 'Confirm',
      onClick: () => {
        if (props.action === 'add') {
        } else {
          submitUpdate();
        }
      },
    };
  };

  useEffect(() => {
    if (props.action === 'add') {
      AddtoHold();
    } else {
      setLoading(true);
      setModalOpen(true);
      getVehicleList();
    }
  }, [pagination]);

  return (
    <>
      <Modal
        closeIcon={
          <CloseFullscreen
            name="Close"
            onClick={() => {
              // handleModalCancel();
              // handle full screen
            }}
            sx={{
              position: 'absolute',
              fontSize: '2rem',
              right: 0,
              top: 0,
              margin: '24px',
              padding: '8px',
              cursor: 'pointer',
            }}
          />
        }
        handleCancel={handleModalCancel}
        modalButton={<Button></Button>}
        openModal={modalOpen}
        modalStyles={uploadDocStyle}
        optionalLabel={getModalHeader()}
        footerOptionalLabel={''}
        variant="Transactional modal"
        bodyText={getModalBodyComponent()}
        actionButtonProps={getActionButtonProps()}
        slotComponent={getModalSlotComponent()}
        label={undefined}
      ></Modal>
      {/* progress bar */}
      <Snackbar open={snackBar} key={'bottom' + 'center'} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <SnackbarContent
          sx={{ backgroundColor: 'transparent', boxShadow: 'none', padding: 0 }}
          message={
            <Alert
              severity={notificationSuccess ? 'success' : 'info'}
              icon={<img src={fileIcon} alt="documentFile" style={{ marginRight: '40px' }} />}
              sx={{ width: '100%', backgroundColor: notificationSuccess ? '#E9F6EC' : '#E9F0FF' }}
            >
              <Box display="flex" flexDirection="column" width="100%" gap="10px">
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap="200px">
                  <Box>
                    <Typography sx={{ fontFamily: 'ToyotaType-Semibold' }}>{props?.fileName}</Typography>
                    <Typography sx={{ fontFamily: 'ToyotaType-Regular' }}>
                      {' '}
                      {notificationSuccess ? (
                        <Typography sx={{ color: '#22A63E', fontFamily: 'ToyotaType-Semibold' }}>
                          {' '}
                          All VINs Uploaded!{' '}
                        </Typography>
                      ) : (
                        "Loading All VIN's in the  File....."
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="10px">
                    <Button
                      variant="tertiary"
                      size="small"
                      label={<Typography sx={{ fontFamily: 'ToyotaType-Regular' }}>Cancel</Typography>}
                      onClick={() => {
                        setSnackBar(false);
                      }}
                      disabled={disableButton}
                    />
                    <Button
                      variant="ghost"
                      size="small"
                      label=""
                      icon={<OpenInFullIcon />}
                      onClick={() => HandleOpenDataTable()}
                      disabled={disableButton}
                    />
                  </Box>
                </Box>
                {notificationSuccess ? (
                  'Expand to select vehicles to action on.'
                ) : (
                  <LinearProgress
                    variant="determinate"
                    value={progressCount}
                    sx={{
                      width: '440px',
                      marginTop: '10px',
                      borderRadius: '5px',
                    }}
                  />
                )}
              </Box>
            </Alert>
          }
        />
      </Snackbar>
      <Modal
        openModal={dataTableCancel}
        variant="Danger modal"
        modalButton={<Button></Button>}
        handleClose={false}
        handleCancel={false}
        closeIcon={<></>}
        label={<HeaderTypography variant="h1">Cancel</HeaderTypography>}
        bodyText={
          <BodyTypography>
            Are you sure you want to cancel? All the vehicles you imported will not be saved
          </BodyTypography>
        }
        actionButtons={<Stack direction={'row'}>{actionButtonModal()}</Stack>}
      />
    </>
  );
}

export default GoCanvasModal;
