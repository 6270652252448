import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
  renderType: "createRoot",
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return (<div> Tiggering Hold Management MFE Error Boundary.</div>);
  },
});

export const bootstrap = [lifecycles.bootstrap];
export const mount = [lifecycles.mount];
export const unmount = [lifecycles.unmount];