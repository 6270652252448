import React, { useEffect, useState } from 'react';
import { LoadingSpinner, Modal, TextInput } from 'cubee2e-cube-global-components';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import './HoldRequestActions.scss';
import HoldReqAtionsServices from './HoldRequestService';



const CancelTypography = styled(Typography)({
    color: '#000',
    fontFamily: "ToyotaType-Regular",
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '2rem',
    height: '64px',
    paddingBottom: '24px',
    marginBottom: "0px"
});
const CancelTypography2 = styled(Typography)({
    color: 'var(--sys-light-Text-variant, #58595B)',
    fontFamily: "ToyotaType-Regular",
    fontSize: '.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem', /* 142.857% */
});

const TypoGraphy = styled(Typography)({
    color: 'var(--sys-light-Text-variant, #58595B)',
    fontFamily: "'ToyotaType-Regular', sans-serif", //"Toyota Type"
    fontSize: '.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1rem',
});

const Star = styled('span')({
    fontSize: '.75rem',
    fontWeight: 600,
    lineHeight: 'var(--spacingsm)',
    textAlign: 'left',
    color: '#C40818',
});

function HoldRequestActions(props) {

    const [cancelActionModal, setCancelActionModal] = useState<boolean>(true);
    const [currentView, setCurrentView] = useState<string>("confirmmsg");
    const [cancelingReason, setCancelingReason] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [letterCounts, setLetterCounts] = useState<number>(0);


    useEffect(() => {
        if (props?.modalCall === "cancelDeny") {
            setButtonDisabled(true);
        }
        else
        {
            setButtonDisabled(false);        
        }
    }, [])
    const QCActionsModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (props?.RequestType == "Deny" ? '702px' : "502px"),
        minHeight: '240px',
        bgcolor: 'background.paper',
        boxShadow: 15,
        borderRadius: '4px',
        '& .MuiTypography-bodyLong': { padding: "0px 0px 0px 0px" },
        '& .MuiGrid-container': { paddingBottom: "32px", marginBottom: "0px" },
        '& .MuiGrid-container .MuiGrid-item': { marginBottom: "0px" },
        '& .MuiGrid-container .MuiGrid-item h1': { marginTop: "0px" }
        ,
    };

    const actionHandling = async () => {
        setCurrentView("loader");
        const fetchCancelDetails = await HoldReqAtionsServices.cancelHoldRequest(props?.HoldReqNum, props?.authToken, props?.employeeId, props?.modalCall, cancelingReason, props?.holdStatus);
        if (fetchCancelDetails?.status === "success") {
            props.updateStatusCall("success");
        }
        else {
            props.updateStatusCall("error");
        }
        setCurrentView("confirmmsg");
        setCancelActionModal(false);
    }
    const closeActionModal = async () => {
        props.closeModal();
        setCancelActionModal(false);
    }

    const CancelReasonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cancelReason = event.target.value;

        if (props?.modalCall === "cancelDeny") {
            if(cancelReason.length === 0)
            {
                setButtonDisabled(true);
            }
            else
            {
                setButtonDisabled(false);
            }
            if (cancelReason.length <= 300) {
                setLetterCounts(cancelReason.length);
                setCancelingReason(cancelReason);
            }
        }
        else {
            if (cancelReason.length <= 5000) {
                setLetterCounts(cancelReason.length);
                setCancelingReason(cancelReason);
            }
        }
    }

    return (
        <>
            <Modal
                modalButton={<span></span>}
                openModal={cancelActionModal}
                variant={'Danger modal'}
                label={<>{props?.modalCall === "cancel" ? (<CancelTypography>Cancel</CancelTypography>) : props?.modalCall === "cancelApprove" ? (<CancelTypography>Approve Vehicles?</CancelTypography>) : props?.modalCall === "cancelDeny" ? (<CancelTypography>Deny Request</CancelTypography>) : (<CancelTypography>Complete</CancelTypography>)}</>}
                bodyText=
                {<>{currentView == "confirmmsg" && props?.modalCall === "cancel" ? (<CancelTypography2 style={{ paddingBottom: "0px" }}>
                    Cancel will release all vehicles in the hold.
                    <br /><br />
                    Would you like to continue?
                    <div className='cancelRequest'>
                        <TextInput
                            multiline={true}
                            label=""
                            textInputVariant="textarea"
                            value={cancelingReason}
                            onChange={CancelReasonHandler}
                            placeholder={"Enter reason for canceling hold"}
                            sx={{
                                width: '100%',
                                display: "block",
                                color: "#58595B",
                                fontSize: "14px",
                                fontFamily: "ToyotaType-Regular",
                                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                    width: '100%',
                                    alignItems: "flex-start",
                                    padding: '16px !important',
                                    fontSize: "14px",
                                    color: "#58595B",
                                    fontFamily: "ToyotaType-Regular",
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '5.75rem',
                                }
                            }}
                        />
                        <TypoGraphy style={{ width: '100%', marginTop: "8px" }}>
                            <span style={{ float: 'inline-end' }}>{letterCounts}/5000</span>
                        </TypoGraphy>
                    </div>
                </CancelTypography2>) : currentView == "confirmmsg" && props?.modalCall === "cancelDeny" ? (<CancelTypography2 style={{ paddingBottom: "0px" }}>
                    Please provide a short reason for denying the request in the field below.
                    <div className='cancelRequest'>
                        <TypoGraphy style={{ width: '100%', marginBottom: "8px" }}>Reason for Denying <Star>*</Star>{' '}
                            <span style={{ float: 'inline-end' }}>{letterCounts}/300</span>
                        </TypoGraphy>
                        <TextInput
                            multiline={true}
                            label=""
                            textInputVariant="textarea"
                            value={cancelingReason}
                            onChange={CancelReasonHandler}
                            placeholder={props?.modalCall === "cancelDeny" ? "":"Enter reason for canceling hold"}
                            sx={{
                                width: '100%',
                                display: "block",
                                color: "#58595B",
                                fontSize: "14px",
                                fontFamily: "ToyotaType-Regular",
                                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                    width: '100%',
                                    alignItems: "flex-start",
                                    padding: '16px !important',
                                    fontSize: "14px",
                                    color: "#58595B",
                                    fontFamily: "ToyotaType-Regular",
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '5.75rem',
                                }
                            }}
                        />
                    </div>
                </CancelTypography2>) : currentView == "confirmmsg" && props?.modalCall === "complete" ? (<CancelTypography2 style={{ paddingBottom: "0px" }}>
                    All vehicles in the hold must have canceled or completed hold status to complete hold. There are {props.totalVINs} VINs that are not canceled or completed.
                    <br /><br />
                    Would you like to update all vehicles?
                </CancelTypography2>) : currentView == "confirmmsg" && props?.modalCall === "cancelApprove" ? (<CancelTypography2 style={{ paddingBottom: "0px" }}>
                    Approving this request will add these vehicles to the hold.
                    <br /><br />
                    Are you sure you want to approve?
                </CancelTypography2>) : (<CancelTypography2 style={{ paddingBottom: "0px" }}>
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>  <LoadingSpinner variant='loading-spinner' /> </div>
                </CancelTypography2>)
                }</>}

                actionButtonProps={{
                    variant: (props?.modalCall === "cancel" ? "danger" : props?.modalCall === "cancelDeny" ? "danger" : 'primary'),
                    onClick: () => void actionHandling(),
                    disabled: buttonDisabled,
                    label: (
                        <CancelTypography2
                            sx={{
                                color: 'var(--sys-light-Text-on-primary, var(--Default-Neutral-White, #FFF))',
                            }}
                        >
                            {props?.modalCall === "cancel" ? ("Cancel Hold") : props?.modalCall === "cancelApprove" ? ("Approve Vehicles") : props?.modalCall === "cancelDeny" ? ("Deny") : ("Update")}
                        </CancelTypography2>
                    ),
                }}
                handleClose={() => { closeActionModal() }}
                handleCancel={() => { closeActionModal() }} closeIcon={''}
                modalStyles={QCActionsModalStyle}
            />

        </>
    );
}
export default HoldRequestActions;