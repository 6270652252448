import CheckIcon from '@mui/icons-material/Check';
import { Button, DataTableView, Icon, Pagination, useNotificationContext } from 'cubee2e-cube-global-components';
import { DataTableShape, PaginationShape } from 'cubee2e-cube-global-components/dist/src/types';
import React, { useEffect, useState } from 'react';
import {
  getCanvasVehicleList,
  getCanvasVehicleListColumns,
  getCanvasVehicleListLoading,
  getCanvasVehicleListingApi,
  getCanvasVehicleListingCounts,
  getCanvasVehicleListingCountsApi,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { setCanvasVehicleListColumns, setCanvasVehicleListLoading } from '../../store/features/HoldRequest/slice';
import { getColumnName } from './CreateHoldRequestModalConstant';

export const RenderVehicleListTable = ({
  documentId,
  pagination,
  setPagination,
}: {
  documentId: string | undefined;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageCount: number;
      limitCount: number;
    }>
  >;
  pagination: { pageCount: number; limitCount: number };
}) => {
  const [selectedText, setSelectedText] = useState<string>(`0 items selected`);
  const [filters, setFilters] = useState([]);
  const [selectedRecs, setSelectedRecs] = useState([]);

  const [reload, setReload] = useState(false);
  const dispatch = useAppDispatch();

  const count = useAppSelector(getCanvasVehicleListingCounts);
  const vehicleList = useAppSelector(getCanvasVehicleList);
  const columns = useAppSelector(getCanvasVehicleListColumns);
  const { dispatchNotification } = useNotificationContext();
  const loading = useAppSelector(getCanvasVehicleListLoading);
  const authToken = useAppSelector((state) => state.auth).idToken;
  const currentColumns = columns?.map((column) => ({ ...column })); // Deep copy each column object

  const totalCount = count?.totalCount;

  useEffect(() => {
    getVehicleList([]);
  }, [pagination, documentId]);

  useEffect(() => {
    if (documentId) {
      dispatch(getCanvasVehicleListingCountsApi(documentId));
    } else {
      dispatch(setCanvasVehicleListLoading(true));
    }
  }, [documentId]);

  const HoldStatusIcon = (inLabel: string = '') => {
    if (!['Invalid', 'Duplicate', 'Valid'].includes(inLabel)) {
      return { label: inLabel };
    }

    return inLabel === 'Invalid'
      ? {
          icon: <Icon name="Clear" />,
          label: 'Invalid',
          backgroundColor: '#FEEBED',
        }
      : inLabel === 'Duplicate'
      ? {
          icon: <Icon name="Warning" />,
          label: 'Duplicate',
          backgroundColor: '#FDF0E6',
        }
      : {
          icon: <CheckIcon />,
          label: 'Valid',
          backgroundColor: '#E9F6EC',
        };
  };

  const HoldStatusComp = (label: string) => {
    const { icon, label: iconLabel, backgroundColor } = HoldStatusIcon(label);

    return (
      <div style={{ display: 'flex' }}>
        {icon && (
          <div
            style={{
              padding: '4px',
              gap: '8px',
              borderRadius: '4px',
              height: '30px',
              width: '32px',
              backgroundColor: backgroundColor || '#E66C02',
            }}
          >
            {icon}
          </div>
        )}
        <div style={{ margin: '6px 6px', fontFamily: 'ToyotaType-Regular', fontWeight: 400, fontSize: '14px' }}>
          {iconLabel}
        </div>
      </div>
    );
  };

  const handleReset = () => {
    setFilters([]);
    setReload(true);
    getVehicleList([]);
  };

  const getResetButton = (filterArray) => {
    return (
      <Button
        variant="tertiary"
        sx={{ padding: '0.375rem 1rem', alignItems: 'end', right: 0 }}
        onClick={handleReset}
        size="small"
        label="Clear Table Filters"
        disabled={filterArray.length > 0 ? false : true}
      />
    );
  };

  const handleApplyFilter = (filterObject) => {
    if (['LifecycleStatus'].includes(filterObject.field)) {
      console.log('unsupported filter');
      return;
    }
    setReload(true);
    getVehicleList([
      ...filters.filter((x: any) => x.field !== filterObject.field),
      { ...filterObject, value: [filterObject.value[0].toUpperCase()] },
    ]);
  };

  const handleDeleteFilter = (inField) => {
    setFilters((filters) => [...filters.filter((x: any) => x.field !== inField)]);
    setReload(true);
    getVehicleList([...filters.filter((x: any) => x.field !== inField)]);
  };

  const getFilterObject = (filters: Object[]) => {
    let filterObj = {};
    filters.forEach((x: any) => {
      let columnName = getColumnName(x.field);
      let op = 'na';
      let arrObj = x.value[0].split('=');
      if (columns.find((y) => y.field === x.field)?.vhColumn) {
        op = 'eq';
        arrObj = [x.value[0]];
      } else {
        if (arrObj.length > 1 && arrObj[0].length > 0) {
          op = arrObj[0] === '!' ? 'notIn' : arrObj[0] === '>' ? 'gte' : arrObj[0] === '<' ? 'lte' : 'na';
        } else if (arrObj.length > 1 && arrObj[0].length === 0) {
          op = 'eq';
        } else if (arrObj.length === 1) {
          op = arrObj[0][0] === '>' ? 'gt' : arrObj[0][0] === '<' ? 'lt' : 'na';
          if (op === 'na') {
            op = arrObj[0].split(',').length > 1 ? 'in' : 'like';
          }
        }
      }
      filterObj[`${columnName}`] = { [op]: arrObj[arrObj.length - 1].split(',') };
    });
    return filterObj;
  };

  const getVehicleList = async (filter: Object[] = filters) => {
    let filterQuery = getFilterObject(filter);
    setFilters(filter);
    const reqData = {
      filter: filterQuery,
      limit: pagination.limitCount,
      offset: (pagination.pageCount - 1) * pagination.limitCount,
    };
    if (documentId) {
      dispatch(getCanvasVehicleListingApi(documentId, reqData));
    }
  };
  const handleCurrentColumnUpdate = (columns: any) => {
    dispatch(setCanvasVehicleListColumns(columns));
  };

  return (
    <div
      style={{
        width: '100%',
        maxHeight: 'calc(90vh - 300px)', // Adjust max height of the scrollable area
        overflow: 'auto', // Makes only this section scrollable if content exceeds
        overflowX: 'auto',
      }}
    >
      <DataTableView
        sx={{
          position: 'relative',
          overflow: 'auto',
          padding: 0,
          width: '1171px',
          border: '1px',
          borderColor: '#D8DCDE',
          borderStyle: 'solid',
          borderRadius: '4px',
          '&.MuiContainer-root.MuiContainer-maxWidthLg': {
            maxWidth: 'auto',
            padding: 0,
          },
        }}
        tableData={{
          rowsData: vehicleList.map((x, i) => {
            const index = (i + (pagination.pageCount - 1) * pagination.limitCount).toString();
            return {
              id: index,
              ...x,
              'Upload Status': HoldStatusComp(x['Upload Status']),
              // disableCheckbox: true,
            };
          }),
          dropDownOptions: [],
          loading: loading,
          reloading: reload,
          count: count?.totalCount,
        }}
        columns={currentColumns}
        sorts={[{ field: 'Upload Status', order: 'ASC' }]}
        handleRowCellClick={() => {}}
        handleDeleteFilter={handleDeleteFilter}
        filters={{
          appliedFilters: filters,
          resetButton: getResetButton(filters),
        }}
        handleReset={handleReset}
        inlineFilterValues={{}}
        handleApplyFilter={handleApplyFilter}
        paginationComponent={
          <Pagination
            sx={{
              padding: 0,
            }}
            pagination={{ page: pagination.pageCount, size: pagination.limitCount }}
            sizeOptions={[15]}
            variant="advanced"
            totalCount={totalCount}
            handlePaginationChange={(data) => {
              setPagination({
                pageCount: data.page,
                limitCount: data.size,
              });
            }}
          />
        }
        checkboxEnabled={false}
        selectedItems={vehicleList.map((x, i) => {
          const index = (i + (pagination.pageCount - 1) * pagination.limitCount).toString();
          return {
            id: index,
            ...x,
            'Upload Status': HoldStatusComp(x['Upload Status']),
          };
        })}
        ellipsisIcon={{
          enabled: false,
          renderIcon: <></>,
        }}
        inlineFiltersEnabled={false}
        inlineFiltersComponent={<></>}
        filterAlleyComponent={<></>}
        handleCurrentColumnUpdate={handleCurrentColumnUpdate}
        handlePagination={function (pageInfo: PaginationShape.pageInfo): void {
          throw new Error('Function not implemented.');
        }}
        pagination={undefined}
        handleCurrentSortUpdate={function (sortedColumns: DataTableShape.SortData[]): void {
          throw new Error('Function not implemented.');
        }}
        handleSelectedItemUpdate={function (selectedItem: Object[], selectAllVariable?: 'all' | 'currentPage'): void {
          throw new Error('Function not implemented.');
        }}
      />
    </div>
  );
};
