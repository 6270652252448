export const endpoint = {
  BASE_URL_STAGING: 'https://debwu1f5l1.execute-api.us-west-2.amazonaws.com/',

  VEHICAL_LIST: 'dev/vehiclelist',
  PAGE_FILTER: 'dev/pagefilter',
  CASCADING_FILTER_LIST: 'dev/pagefilter',
  NSO_SERVICE: 'dev/rup/nso',
  RUP_SERVICE: 'dev/rup/rup',
  RUP_RELEASE: 'dev/earmark/rup/release',
  EARMARK_RELEASE: 'dev/v1/release',
  EARMARK_SERVICE: 'dev/v1/earmark',
  DEALER_API: 'dev/dealerslists',

  PAGE_VIEW_LIST: 'hold/QChold/pageview/list', //userId=609587&gridName=Hold List,
  PAGE_VIEW_SAVE: 'hold/QChold/pageview/save',
  PAGE_VIEW_UPDATE: 'hold/QChold/pageview/update',
  PAGE_VIEW_DELETE: 'hold/QChold/pageview/delete',  
};