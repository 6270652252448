import { ExpandMore } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AccordionDetails, AccordionSummary, IconButton, Accordion as MuiAccordion } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { setEditPanel } from '../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AccordionPanel } from '../../types/Shapes/Accordion.shape';
import './DashAccordion.scss';

interface AccordionProps extends AccordionPanel {
  headerPadding?: string;
  headerMargin?: string;
  contentMargin?: string;
  contentPadding?: string;
  backgroundColor?: string;
  headerColor?: string;
  contentColor?: string;
  defaultExpanded?: boolean;
  headerFontSize?: string;
  headerFontColor?: string;
}

const StyledMuiAccordion = styled(MuiAccordion)((props: AccordionProps) => ({
  borderBottom: '2.5rem',
  zIndex: '1',
  '&:before': { display: 'none' },
  marginBottom: '10px',
  //width: '46vw',
  flexShrink: 0,
  defaultExpanded: `${props.defaultExpanded}`,
}));
const StyledAccordionSummary = styled(AccordionSummary)((props: AccordionProps) => ({
  padding: `0 ${props.headerPadding ?? 0}`,
  marginLeft: `${props.headerMargin ?? 0}`,
  backgroundColor: `${props.headerColor}`,
  fontSize: `${props.headerFontSize ?? '14px'}`,
  display: 'flex',
  width: '52.22vw',
  aligntems: 'center',
  fontFamily: 'ToyotaType-Regular',
  fontWeight: 600,
  color: `${props.headerFontColor ?? '#000'}`,
  margin: '0px',

  //gap: '16px','
}));
const StyledAccordionDetails = styled(AccordionDetails)((props: AccordionProps) => ({
  padding: `0 ${props.contentPadding ?? 0}`,
  margin: '0rem',
  marginLeft: `${props.contentMargin ?? 0}`,
  backgroundColor: `${props.contentColor}`,
  width: '52.22vw',
  fontFamily: 'ToyotaType-Regular',
}));

const DashAccordion: React.FC<AccordionPanel> = ({
  panel,
  ContentText,
  HeaderText,
  headerMargin,
  headerPadding,
  contentMargin,
  contentPadding,
  headerFontColor,
  headerFontSize,
  headerColor = 'white',
  contentColor = 'white',
  editIcon,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const PDCRequestData = useAppSelector((state) => state.pdcRequestData);
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, panelIndex: string) => {
    e.stopPropagation();
    dispatch(setEditPanel(panelIndex));
  };

  return (
    <>
      <StyledMuiAccordion elevation={0} defaultExpanded={true} disableGutters>
        <StyledAccordionSummary
          className="accordionSum"
          headerPadding={headerPadding}
          headerMargin={headerMargin}
          headerColor={headerColor}
          expandIcon={<ExpandMore sx={{ color: 'black', backgroundColor: 'inherit' }} />}
          aria-controls={`panel${panel}a-content`}
          id={`panel${panel}a-header`}
          panel={''}
          headerFontSize={headerFontSize}
          headerFontColor={headerFontColor}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>{HeaderText}</div>
          {editIcon && (
            <IconButton
              onClick={(e) => handleButtonClick(e, panel)}
              sx={{ marginLeft: 'auto', color: '#000000', paddingTop: '0px', paddingBottom: '0px' }}
            >
              <EditOutlinedIcon />
            </IconButton>
          )}
        </StyledAccordionSummary>
        <StyledAccordionDetails
          className="accordionDet"
          panel={''}
          contentPadding={contentPadding}
          contentMargin={contentMargin}
          contentColor={contentColor}
          sx={{
            backgroundColor: contentColor,
            padding: contentPadding,
            margin: contentMargin,
          }}
        >
          {ContentText}
        </StyledAccordionDetails>
      </StyledMuiAccordion>
    </>
  );
};

export default DashAccordion;
