import { combineReducers, configureStore } from '@reduxjs/toolkit';
// reducers
import cascadingFilterReducer from './features/CascadingFilter/cascadingFilterSlice';
import customDrawerReducer from './features/CustomDrawer/customDrawerSlice';
import { holdRequestReducer } from './features/HoldRequest';
import { HoldTypeDetailsReducer } from './features/HoldTypeDetails';
import vehicleMetricsReducer from './features/VehicleMetrics/vehicleMetricsSlice';
import authReducer from './features/auth/authSlice';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  auth: authReducer,
  holdRequest: holdRequestReducer,
  customDrawer: customDrawerReducer,
  cascadingFilter: cascadingFilterReducer,
  holdTypeDetails: HoldTypeDetailsReducer,
  vehicleMetrics: vehicleMetricsReducer,
});
export const store: any = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
