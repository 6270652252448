import { Grid, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  Button,
  Divider,
  IconButton,
  LoadingSpinner,
  TextInput,
  useNotificationContext,
} from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import group from '../../../assets/svg/group.svg';
import { addCommentApi, deleteCommentApi, getCommentsListApi, getHoldReqNumber } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface CommentInterface {
  comment: string;
  date: string;
  name: string;
  title: string;
  commentid: number;
  isdeletable: boolean;
  isuser: boolean;
  qcholdreqid: number;
}
const NameTypography = styled(Typography)({
  color: 'var(--sys-light-Text-default, #000)',
  fontFamily: 'ToyotaType-Regular',
  fontsize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '16px',
});
const DateTypography = styled(Typography)({
  color: 'var(--sys-light-Text-variant, #58595B)',
  fontFamily: 'ToyotaType-Regular',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});
const TitleTypography = styled(Typography)({
  color: 'var(--sys-light-Text-default, #000)',
  fontFamily: 'ToyotaType-Regular',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});
const CommentGrid = styled(Grid)({
  width: '1112px',
  height: 'auto',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
  color: 'var(--sys-light-Text-default, #000)',
  fontFamily: 'ToyotaType-Regular',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});

const MAX_INPUT_LENGTH = 200;
const Comment = () => {
  const [inputText, setInputText] = useState<string>();
  const auth = useAppSelector((state) => state.auth);
  const { dispatchNotification } = useNotificationContext();
  const dispatch = useAppDispatch();
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo') || '{}');
  const PDCRequestData = useAppSelector((state) => state.holdTypeDetails);
  const qcNumber = useAppSelector(getHoldReqNumber);
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };
  const [comments, setComments] = useState<CommentInterface[]>([
    {
      comment: '',
      date: '',
      name: '',
      title: 'comments_tab',
      commentid: 1,
      isdeletable: false,
      isuser: false,
      qcholdreqid: 1,
    },
  ]);
  const [fetchData, setFetchData] = useState<boolean>(false);
  const getComment = async () => {
    const response = await dispatch(
      getCommentsListApi({ qcNumber: qcNumber, userId: userData?.employeeId.toString() }),
    );
    if (response.status === 200) {
      setComments(response.commentList);
      setFetchData(true);
    } else {
      handleNotification({ fieldName: 'error', field: 'Something Went Wrong. Please Try later' });
    }
  };
  useEffect(() => {
    getComment();
  }, []);

  const formatDate = (dateString: string): string => {
    if (!dateString) {
      return;
    }
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString('en-US', {
      timeZone: 'America/Chicago',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
    const timePart = date
      .toLocaleTimeString('en-US', {
        timeZone: 'America/Chicago',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace('AM', ' AM')
      .replace('PM', ' PM');
    return `${datePart} at ${timePart} CST`;
  };

  const handleDelete = async (index) => {
    const response = await dispatch(
      deleteCommentApi(
        {
          commentId: comments?.[index]?.commentid,
          userId: userData?.employeeId.toString(),
          title: comments?.[index]?.title,
        },
        qcNumber,
      ),
    );
    if (response.message === ' Deleted SuccesFully') {
      getComment();
      handleNotification({ fieldName: 'success', field: 'Comment deleted Successfully' });
    } else {
      handleNotification({ fieldName: 'error', field: 'Something Went Wrong. Please Try later' });
    }
  };
  const renderComment = ({
    date,
    comment,
    name,
    title,
    isdeletable,
    isuser,
  }: {
    date: string;
    comment: string;
    name: string;
    title: string;
    isdeletable: boolean;
    isuser: boolean;
  }) => {
    return (
      <Stack direction={'column'} sx={{ marginTop: '24px' }}>
        <Stack direction={'row'} gap={1}>
          <NameTypography>{name}</NameTypography>
          <DateTypography>{formatDate(date)}</DateTypography>
          {userData?.userName === name && (
            <IconButton
              //onClick={() => console.log("hello")}
              sx={{ padding: '0px', fontSize: '18px', color: '#000000' }}
              name={'EditOutlined'}
              disabled={true}
            />
          )}
          {isdeletable && isuser && (
            <IconButton
              onClick={() => handleDelete(comments.findIndex((c) => c.date === date))}
              sx={{ padding: '0px', fontSize: '18px', color: '#000000' }}
              name={'DeleteOutline'}
            />
          )}
        </Stack>
        {title !== 'comments_tab' ? (
          <TitleTypography>
            <span style={{ color: '#58595B' }}>Event: </span>
            {title}
          </TitleTypography>
        ) : (
          <TitleTypography sx={{ marginTop: '5px' }}></TitleTypography>
        )}
        <CommentGrid>{comment}</CommentGrid>
        <Divider variant="fullWidth" sx={{ marginTop: '24px' }} />
      </Stack>
    );
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleSave = async () => {
    const response = await dispatch(
      addCommentApi({ comments: inputText, qcNumber: qcNumber, userId: userData?.employeeId }),
    );
    handleCancel();
    if (response.message === 'Created Successfully') {
      getComment();
      handleNotification({ fieldName: 'success', field: 'Comment Added Successfully' });
    } else {
      handleNotification({ fieldName: 'error', field: 'Something Went Wrong. Please Try later' });
    }
  };

  const handleCancel = () => {
    setInputText('');
  };

  return (
    <>
      {fetchData ? (
        <Grid
          container
          xs={12}
          sx={{ height: '100%', padding: '1rem', flexDirection: 'column', marginTop: '7.5rem' }}
          component={Paper}
        >
          <Typography variant="h3Semibold">QC Hold Comments</Typography>
          <Grid item sx={{ marginTop: '24px', marginLeft: '1rem' }}>
            <TextInput
              textInputVariant={'textarea'}
              label={'Add Comment'}
              variant={'outlined'}
              placeholder={'Input text'}
              maxLength={500}
              sx={{ width: '100%' }}
              disabled={false}
              multiline={true}
              maxWidth="60%"
              value={inputText}
              onChange={handleChange}
            />
            {inputText?.length > 0 && (
              <Stack direction={'row'} gap={1} sx={{ marginTop: '24px' }}>
                <Button variant="tertiary" label="Cancel" size="small" onClick={handleCancel} />
                <Button variant="secondary" label="Save" size="small" onClick={handleSave} />
              </Stack>
            )}
          </Grid>

          {comments.length === 0 && (
            <Grid sx={{ margin: '10% 40%' }}>
              <img src={group} alt="group" />
              <Typography>No Comments</Typography>
            </Grid>
          )}
          {comments.length > 0 && (
            <Grid item sx={{ marginLeft: '1rem' }}>
              {comments.map((comment) => renderComment(comment))}
            </Grid>
          )}
        </Grid>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', margin: '10% 40%' }}>
          <LoadingSpinner variant="loading-spinner" />
        </div>
      )}
    </>
  );
};

export default Comment;
