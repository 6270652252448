import { Grid, Stack, Typography } from '@mui/material';
import { Button, Chip, Icon, Modal, useNotificationContext } from 'cubee2e-cube-global-components';
import React, { useState } from 'react';
import { SavePageView } from '../../../Components/SavePageView/SavePageView';
import {
  getHoldReqNumber,
  getHoldRequestDetailsApi,
  getHoldStatus,
  getHoldType,
  getIssueDetails,
  getModelSpecifications,
  getPendingApprovalType,
  getRequesterApprovals,
  getYardToPDCState,
  useAppDispatch,
  useAppSelector,
} from '../../../store';
import { EditAndSubmitQCApi } from '../../../store/features/HoldTypeDetails/api';
import { setHoldType } from '../../../store/features/HoldTypeDetails/slice';
import theme from '../../../theme';

export const HoldRequestDetailsHeader = ({
  holdReqNo,
  isActionbtndisabled,
  holdReqActions,
  getActionsModal,
  value,
  savePageViewList,
  selectedPageView,
  viewUpdated,
  handleMenuItemClick,
  handleConvertYardToPDC,
  lastrefreshedDT,
  convertToPDC,
  ConvertToPDCUpdate,
}: {
  holdReqNo: string;
  isActionbtndisabled: boolean;
  getActionsModal: (action: string) => void;
  holdReqActions: any;
  value: string;
  savePageViewList: any;
  selectedPageView: any;
  viewUpdated: any;
  handleMenuItemClick: () => void;
  handleConvertYardToPDC: () => void;
  lastrefreshedDT: string;
  convertToPDC: boolean;
  ConvertToPDCUpdate: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { dispatchNotification } = useNotificationContext();
  const holdStatus = useAppSelector(getHoldStatus);
  const holdType = useAppSelector(getHoldType);
  const issueDetails = useAppSelector(getIssueDetails);
  const modelSpecifications = useAppSelector(getModelSpecifications);
  const requesterApprovals = useAppSelector(getRequesterApprovals);
  const isyardToPDC = useAppSelector(getYardToPDCState);
  const qcNumber = useAppSelector(getHoldReqNumber);
  const pendingApprovaltype = useAppSelector(getPendingApprovalType);
  const savedChangesModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '502px',
    minHeight: '240px',
    bgcolor: 'background.paper',
    boxShadow: 15,
    borderRadius: '4px',
    '& .MuiTypography-bodyLong': { padding: '0px 0px 0px 0px' },
    '& .MuiGrid-container': { paddingBottom: '32px', marginBottom: '0px' },
    '& .MuiGrid-container .MuiGrid-item': { marginBottom: '0px' },
    '& .MuiGrid-container .MuiGrid-item h1': { marginTop: '0px' },
  };

  const [unsavedChangesModal, setUnsavedChangesModal] = useState<boolean>(false);
  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };

  const ShowUnsavedChangesModal = () => {
    ConvertToPDCUpdate(false);
    dispatch(setHoldType('Yard'));
    dispatch(getHoldRequestDetailsApi(qcNumber));
  };

  const closeModal = () => {
    setUnsavedChangesModal(false);
  };
  const issueMandatoryFields =
    issueDetails.issueTitle && issueDetails.requestingDivision?.optionLabel && issueDetails.detailedIssueDescription;
  const modelInfoMandatoryFields = modelSpecifications.vehicleDtl?.every(
    (veh) => veh.model?.value && veh.modelYear?.value?.optionLabel && veh.series?.value?.optionLabel,
  );
  const modelSpeciMandatoryFields =
    modelSpecifications.vinStartDateTime &&
    modelSpecifications.vinEndDateTime &&
    modelSpecifications.endDateType &&
    modelSpecifications.startDateType;
  const requestorFields = requesterApprovals?.approvalUsers?.every((user) => user.firstName && user.lastName);

  const pdcMandatoryFieldsCheck =
    issueMandatoryFields && modelInfoMandatoryFields && modelSpeciMandatoryFields && requestorFields;

  const yardMandatoryFieldCheck = issueDetails.issueTitle && issueDetails.requestingDivision?.optionLabel;

  const saveAsDraft = async () => {
    const responseSaveAsDraft = await dispatch(EditAndSubmitQCApi(true, convertToPDC, () => {}));
    console.log('tyuio', responseSaveAsDraft);

    if (responseSaveAsDraft?.statusCode === 201 || responseSaveAsDraft?.statusCode === 200) {
      handleNotification({ fieldName: 'success', field: `Hold has been successfully Save as Draft` });
    } else {
      let errMSg = responseSaveAsDraft?.response?.data?.errorMessage || '';
      if (!errMSg || errMSg === '') {
        errMSg = `Something went wrong. Please try again later.`;
      }

      handleNotification({ fieldName: 'error', field: errMSg });
    }
  };

  const cancelYardConversion = () => {
    setUnsavedChangesModal(true);
  };

  const submitForApproval = async () => {
    const responseForApproval = await dispatch(EditAndSubmitQCApi(false, convertToPDC, () => {}));

    if (responseForApproval?.statusCode === 201 || responseForApproval?.statusCode === 200) {
      handleNotification({ fieldName: 'success', field: `Hold has been successfully Submit For Approval` });
    }
    else if (responseForApproval?.status === 409)
    {
      const errMSg = `Vehicles are being attached to PDC. Please wait for a brief period and try again.`;
      handleNotification({ fieldName: 'warning', field: errMSg });
    } 
    else {
      console.log('responseForApproval', responseForApproval);
      let errMSg = responseForApproval?.response?.data?.errorMessage || '';
      if (!errMSg || errMSg === '') {
        errMSg = `Something went wrong. Please try again later.`;
      }

      handleNotification({ fieldName: 'error', field: errMSg });
    }
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        ml: '1rem',
        mr: '1rem',
        position: 'sticky',
        top: '8rem', // Adjusted to stay below BackToAllQC
        zIndex: 999, // High zIndex to stack properly
      }}
    >
      <Stack
        sx={{
          flexDirection: 'column',
          borderRadius: '4px',
          background: '#fff',
          padding: '1rem',
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" gap={1} alignItems={'center'}>
            <Typography variant="h3Semibold">{holdReqNo}</Typography>
            <Chip
              label={holdStatus}
              sx={{
                border: '1px solid #F1F2F4',
                background: '#F1F2F4',
                color: '#565656',
                '.MuiChip-icon': {
                  color: '#565656',
                },
              }}
              iconName={<Icon name="Description" sx={{ color: '#565656' }} fontSize="small" />}
            />
            <Chip
              label={holdType}
              variant="outlined"
              iconName="Report"
              sx={{ border: `1px solid ${theme.palette.secondary[50]}`, padding: '4px' }}
            />
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
            gap={2}
          >
            {holdStatus === 'Draft' && (
              <Button
                label="Save as Draft"
                variant="secondary"
                size="medium"
                disabled={isActionbtndisabled}
                onClick={() => saveAsDraft()}
                icon={<Icon name="Save" fontSize="small" />}
              />
            )}
            {isyardToPDC && (
              <Button
                label="Cancel Conversion"
                variant="tertiary"
                size="medium"
                disabled={isActionbtndisabled}
                onClick={() => cancelYardConversion()}
              />
            )}
            {(holdStatus === 'Draft' || isyardToPDC) && (
              <Button
                label="Submit for Approval"
                variant="primary"
                size="medium"
                // disabled={false}
                disabled={
                  holdType === 'PDC'
                    ? !pdcMandatoryFieldsCheck
                    : holdType === 'Yard' && isyardToPDC
                    ? !pdcMandatoryFieldsCheck
                    : !yardMandatoryFieldCheck
                }
                onClick={() => submitForApproval()}
              />
            )}
            {((holdReqActions.isCancelEnable && !convertToPDC) || (!convertToPDC && holdReqActions?.isCancelEnable && pendingApprovaltype !== 'HoldCancel')) && (
              <Button
                label="Request Cancel Hold"
                variant="tertiary"
                size="medium"
                disabled={isActionbtndisabled}
                onClick={() => getActionsModal('cancel')}
              />
            )}

            {holdReqActions.isCompleteEnable && (
              <Button
                label="Complete"
                variant="tertiary"
                size="medium"
                disabled={isActionbtndisabled}
                onClick={() => getActionsModal('complete')}
              />
            )}
            {value === '2' && (
              <>
                {/* <Button
                    label={
                      <Stack direction={'row'} gap={1}>
                        <Chip label={filterButtonChipValue} sx={filterBtnChipStyles} />
                        <Typography variant="button">{isDrawerOpen ? 'Hide Filters' : 'Filter'}</Typography>
                      </Stack>
                    }
                    variant="tertiary"
                    size="small"
                    icon={
                      isDrawerOpen ? (
                        <Close onClick={() => onDrawerClose()} style={{ fontSize: '15px', marginTop: '4px' }} />
                      ) : (
                        <FilterListIcon
                          onClick={() => onFilterButtonClick()}
                          style={{ fontSize: '15px', marginTop: '4px' }}
                        />
                      )
                    }
                    onClick={isDrawerOpen ? onDrawerClose : onFilterButtonClick}
                    sx={filterBtnStyles}
                  /> */}
                <SavePageView
                  savePageViewList={savePageViewList}
                  selectedPageView={selectedPageView}
                  viewUpdated={viewUpdated}
                  handleMenuItemClick={handleMenuItemClick}
                />
              </>
            )}
            {holdType === 'Yard' && !convertToPDC ? (
              <Button
                label={'Convert to PDC'}
                variant="primary"
                disabled={holdType === 'Yard' && holdStatus === 'Active Hold' ? false : true}
                size="medium"
                onClick={handleConvertYardToPDC}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {lastrefreshedDT}
        </Typography>
      </Stack>

      <Modal
        modalButton={<span></span>}
        openModal={unsavedChangesModal}
        variant={'Danger modal'}
        label={
          <Typography variant="h1" sx={{ marginTop: '-1rem', marginBottom: '24px', fontFamily: 'ToyotaType-Regular' }}>
            {'Want To Leave'}
          </Typography>
        }
        bodyText={
          <Typography variant="bodyLong" component={'p'} sx={{ marginBottom: '0px', fontFamily: 'ToyotaType-Regular' }}>
            Your progress and any changes will be discarded if you leave.
            <br />
            <br />
            Are you sure you want to leave?
          </Typography>
        }
        actionButtonProps={{
          variant: 'danger',
          onClick: () => void ShowUnsavedChangesModal(),
          label: 'Leave, and Discard Changes',
        }}
        handleClose={() => {
          closeModal();
        }}
        handleCancel={() => {
          closeModal();
        }}
        closeIcon={''}
        modalStyles={savedChangesModalStyle}
      />
    </Grid>
  );
};
