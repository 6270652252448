import { Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import theme from '../../../../theme';

export const RenderItem = ({ label, value, isRequired }: { label: string; value: ReactNode; isRequired?: boolean }) => {
  return (
    <Stack direction={'column'} justifyContent={'start'} gap={1}>
      <Typography
        variant="labelRegular"
        sx={{
          color: theme.palette.secondary[30],
        }}
      >
        {label}
        {isRequired && <span style={{ color: '#C40818' }}>*</span>}
      </Typography>
      <Typography
        variant="labelRegular"
        sx={{
          color: theme.palette.common.black,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};
