// @ts-nocheck
import { Grid, Stack, Typography } from '@mui/material';
import { IconButton } from 'cubee2e-cube-global-components';
import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import KpiCard from '../../../KpiCard';

function HoldDetailsMetrics(props: { onRefresh: Function; setKPIFilter?: Function; lastrefreshedDT: string }) {
  const metricsData = useAppSelector((state) => state.vehicleMetrics);
  let metricsDetails = {
    pendingApproval: metricsData.pendingApproval,
    approved: metricsData.approved,
    onHold: metricsData.onHold,
    readyForRelease: metricsData.readyForRelease,
    vehiclesReleased: metricsData.vehiclesReleased,
    vehiclesInvalid: metricsData.vehiclesInvalid,
    vehiclesIneligible: metricsData.vehiclesIneligible,
    totalVehiclesHeld: metricsData.totalVehiclesHeld,
    totalVehiclesSubmitted: metricsData.totalVehiclesSubmitted,
    shippedAfterNotice: metricsData.shippedAfterNotice,
  };

  const formatNumber = (number: Number) => {
    return number.toLocaleString('en-US');
  };

  const RenderVehicleMetricsHeader = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          position: 'relative',
          overflowY: 'auto',
          height: 'auto',
        }}
      >
        <Stack
          sx={{
            padding: '1rem',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderRadius: '4px',
            background: '#fff',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3Semibold">QC Hold Vehicle Count</Typography>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }} gap={0.5}>
            <Typography variant="h3">{formatNumber(metricsDetails.totalVehiclesSubmitted)}</Typography>
            <IconButton name="Cached" onClick={props.onRefresh} />
          </Stack>
        </Stack>
      </Grid>
    );
  };
  const kpiCards: KpiCardprops[] = [
    {
      label: 'Awaiting Approval',
      count: formatNumber(metricsDetails.pendingApproval),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'awaiting approval', value: 1 }),
      refreshedDate: props.lastrefreshedDT,
    },
    {
      label: 'On Hold',
      count: formatNumber(metricsDetails.onHold),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'on hold', value: [2, 4] }),
      refreshedDate: props.lastrefreshedDT,
    },
    {
      label: 'In Evaluation',
      count: formatNumber(metricsDetails.readyForRelease),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'in evaluation', value: 3 }),
      refreshedDate: props.lastrefreshedDT,
    },
    {
      label: 'Completed',
      count: formatNumber(metricsDetails.vehiclesReleased),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'completed', value: [4, 8] }),
      refreshedDate: props.lastrefreshedDT,
    },
    {
      label: 'SAN',
      count: formatNumber(metricsDetails.shippedAfterNotice),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'san', value: 100 }),
      refreshedDate: props.lastrefreshedDT,

      // handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'completed', value: [4, 8] }),
    },
    {
      label: 'Shipped',
      count: formatNumber(metricsDetails.vehiclesIneligible),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'shipped', value: 6 }),
      refreshedDate: props.lastrefreshedDT,
    },
    {
      label: 'Invalid',
      count: formatNumber(metricsDetails.vehiclesInvalid),
      handleClick: () => props.setKPIFilter && props.setKPIFilter({ label: 'invalid', value: 5 }),
      refreshedDate: props.lastrefreshedDT,
    },
  ];
  return (
    <Grid item sx={{ height: 'auto'}}>
      <RenderVehicleMetricsHeader />
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        <KpiCard kpiCards={kpiCards} />
      </Grid>
    </Grid>
  );
}

export default HoldDetailsMetrics;
