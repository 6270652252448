// @ts-nocheck
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import {
  Button,
  Icon,
  IconButton,
  LoadingSpinner,
  Modal,
  useNotificationContext,
} from 'cubee2e-cube-global-components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEnv } from '../../env/env';
import { uploadParseApi } from '../../services';
import {
  getCanvasVehicleListingCounts,
  getCreateHoldType,
  getCurrentUploadStage,
  getDocumentDetails,
  getFileAttachments,
  getIssueDetails,
} from '../../store';
import { uploadDocumentApi } from '../../store/features/HoldRequest/api';
import { setCurrentUploadStage, setDocumentId, setUploadStageError } from '../../store/features/HoldRequest/slice';
import { createQCApi, downloadVehiclesApi } from '../../store/features/HoldTypeDetails/api';
import { setFileAttachments, setHoldTypeRefresh } from '../../store/features/HoldTypeDetails/slice';
import { setSessionId } from '../../store/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ParseStatusEnum, UploadDocumentTypes } from '../../types';
import FileUploadDragAndDrop from '../FileUpload/FileUploadDragAndDrop';
import './CreateHoldRequestModal.scss';
import { maxAttempts, parseTimeout, supportedDocFormats } from './CreateHoldRequestModalConstant';
import { RenderDocumentPreview } from './RenderDocumentPreview';
import { RenderVehicleListTable } from './RenderVehicleListTable';
import SelectHoldTypeModal from './SelectHoldTypeModal';

const uploadDocStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '896px',
  maxWidth: '1300px',
  minHeight: '300px',
  maxHeight: '90vh', // Limit height to viewport percentage
  // overflow: 'auto', // Enable scrolling if content overflows
  bgcolor: 'background.paper',
  boxShadow: 15,
  borderRadius: '4px',
};

export interface ImportModalProps {
  setShowImport: React.Dispatch<React.SetStateAction<File>>;
  loadUploadedFile: () => void;
  documentId?: string;
  setNotification: React.Dispatch<React.SetStateAction<File>>;
  action: string;
  fileName: string;
}

export interface CheckStatusAPIs {
  attempts?: number;
  documentId: string;
  documentPath: string;
  documentDetails?: File;
}

export const CreateHoldRequestModal = (props: ImportModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [importFile, setImportFile] = useState<File>();
  const [showModal, setShowModal] = useState<Number>(false);
  const [isimport, setIsimport] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [manaullyEnteredVins, setManaullyEnteredVins] = useState<string[]>([]);
  const [pagination, setPagination] = useState<{ pageCount: number; limitCount: number }>({
    pageCount: 1,
    limitCount: 15,
  });

  const [parsedDocumentId, setParsedDocumentId] = useState<string | undefined>();
  const [acceptedFiles, setAcceptedFiles] = useState<readonly FileWithPath[]>([]);
  //Redux
  const authToken = useAppSelector((state) => state.auth).idToken;
  const PDCRequestData = useAppSelector((state) => state.holdTypeDetails);
  const documentDetails = useAppSelector(getDocumentDetails);
  const currentUploadStage = useAppSelector(getCurrentUploadStage);
  const count = useAppSelector(getCanvasVehicleListingCounts);
  const { dispatchNotification } = useNotificationContext();

  const totalCount = count?.totalCount;
  const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
  const documentId = documentDetails.documentId;
  const issueDetails = useAppSelector(getIssueDetails);
  const fileattachment = useAppSelector(getFileAttachments);

  const holdType = useAppSelector(getCreateHoldType);

  const handleNotification = (filter: any) => {
    dispatchNotification({
      open: true,
      variant: 'toast',
      type: filter.fieldName,
      iconEnabled: true,
      text: filter.field,
    });
  };

  const initHold = () => {
    dispatch(setHoldTypeRefresh());
  };

  const initDocumentUpload = (file: File) => {
    setIsimport(true);
    dispatch(setCurrentUploadStage(1));
    dispatch(setCurrentUploadStage(2));
    dispatch(uploadDocumentApi(file, UploadDocumentTypes.INITIALVEHICLESIMPORT));
  };

  const checkParseStatus = async ({ attempts, documentId, documentPath }: CheckStatusAPIs) => {
    if (!attempts) {
      attempts = 0;
    }
    const response = await uploadParseApi(documentId, authToken, UploadDocumentTypes.INITIALVEHICLESIMPORT);

    if (response.status === 200) {
      const parseStatus = response.data.data.body.parseStatus;
      const parseError = response.data.data.body.parseError;
      if (parseStatus === ParseStatusEnum.Failed) {
        dispatchNotification({
          open: true,
          variant: 'toast',
          type: 'error',
          iconEnabled: true,
          text: `Upload ${UploadDocumentTypes.INITIALVEHICLESIMPORT} ${parseStatus}`,
        });
        handleModalCancel();
      } else {
        if (
          parseStatus === ParseStatusEnum.NoStatus ||
          (parseStatus === ParseStatusEnum.NoStatus && parseError === ParseStatusEnum.NoError) ||
          (parseStatus === ParseStatusEnum.Parsing && parseError === ParseStatusEnum.NoError)
        ) {
          if (attempts < maxAttempts) {
            setTimeout(() => {
              checkParseStatus({
                attempts: attempts + 1,
                documentId,
                documentPath,
              });
            }, parseTimeout);
          } else {
          }
        } else if (
          [
            ParseStatusEnum.Validating,
            ParseStatusEnum.ParsedSuccessfully,
            ParseStatusEnum.PartiallyParsed,
            ParseStatusEnum.Parsed,
            ParseStatusEnum.Started,
          ].includes(parseStatus)
        ) {
          checkValidationStatus({ ...documentDetails });
        } else {
          dispatch(setUploadStageError(parseError?.error || parseError));
        }
      }
    }
  };

  const checkValidationStatus = async ({ attempts, documentId, documentPath }: CheckStatusAPIs) => {
    if (!attempts) {
      attempts = 0;
    }
    const response = await uploadParseApi(documentId, authToken, UploadDocumentTypes.INITIALVEHICLESIMPORT);
    const parseStatus = response.data.data.body.parseStatus;
    const parseError = response.data.data.body.parseError;
    if ([ParseStatusEnum.Validating, ParseStatusEnum.NoStatus].includes(parseStatus) && (!parseError || 'No Error')) {
      if (attempts < maxAttempts) {
        setTimeout(() => {
          checkValidationStatus({
            attempts: attempts + 1,
            documentId,
            documentPath,
          });
        }, parseTimeout);
      } else {
      }
    } else if (
      [ParseStatusEnum.PartiallyParsed, ParseStatusEnum.Parsed, ParseStatusEnum.ParsedSuccessfully].includes(
        parseStatus,
      )
    ) {
      dispatch(setDocumentId(documentDetails.documentId));
      dispatch(
        setFileAttachments({
          fileMetadata: [
            {
              documentId: documentDetails.documentId,
              uploadFilePath: documentDetails.documentPath,
              fileTitle: documentDetails.name,
              fileDescription: '',
              documentType: UploadDocumentTypes.INITIALVEHICLESIMPORT,
              isdeletable: false,
              uploadedBy: userData?.employeeId,
              uploadedUserName: userData?.userName,
              uploadedDate: '',
              name: documentDetails.name,
            },
          ],
        }),
      );
      setParsedDocumentId(documentDetails.documentId);

      setImportFile(undefined);
    } else {
      setUploadStageError(parseError?.error || parseError);
      setCurrentUploadStage(4);
    }
  };

  const uploadSuccess = () => {
    setShowModal(false);
    setImportFile(undefined);
    setFileName('');
    setManaullyEnteredVins([]);
    dispatch(setCurrentUploadStage(0));
    setUploadStageError('');
  };

  const handleModalCancel = () => {
    setIsimport(false);
    setShowModal(false);
    setImportFile(undefined);
    setFileName('');
    setManaullyEnteredVins([]);
    dispatch(setCurrentUploadStage(0));
    setUploadStageError('');
  };

  const handleCreateQC = () => {
    const sessionId = localStorage.getItem(`HM_sessionId`);
    dispatch(setSessionId(sessionId));
    dispatch(setCurrentUploadStage(6));
    dispatch(
      createQCApi(
        navigate,
        () => {
          handleModalCancel();
        },
        handleNotification,
      ),
    );
  };

  const deleteImportFile = async () => {
    const userData = JSON.parse(localStorage.getItem('HM-UserInfo'));
    let reqData = {
      userId: userData?.employeeId,
      qcNumber: PDCRequestData?.qcNumber,
      documentId: documentDetails.documentId,
    };

    const apiBaseUrl = `${getEnv().API_BASE_URL}`;
    const urls = `${apiBaseUrl}/hold/QChold/deleteDocument`;
    const apikey = 'cRxAFwKyHm31h6PlKpU5JtSTryncWCEasiUWyy70';

    const configs = {
      headers: {
        Authorization: `${authToken}`,
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
      data: reqData,
    };

    try {
      const response = await axios.delete(urls, configs);
      if (response.status === 200) {
        const updatedFileAttachmentList: any = PDCRequestData.fileAttachmentList.filter(
          (file) => file.data.documentId !== documentId,
        );
        let rows = updatedFileAttachmentList;
        dispatch(setFileAttachments({ fileMetadata: updatedFileAttachmentList }));
      }
    } catch (error) {}
  };

  const getSubHeaderText = () => {
    const headers = {
      0: 'Step 1: Upload File or Enter VINs',
      1: 'Step 1: Upload File or Enter VINs',
      2: 'Step 1: Upload File or Enter VINs',
      3: 'Step 2: Review Data from File',
      4: 'Step 3: Select VINs',
      5: 'Step 4: Select PDC or Yard Hold',
      6: 'Step 4: Select PDC or Yard Hold',
      7: 'Step 4: Select PDC or Yard Hold',
    };
    const headerText = headers[currentUploadStage] || null;
    return headerText ? (
      <Typography variant="caption" sx={{ marginTop: '-1rem' }}>
        {headerText}
      </Typography>
    ) : (
      <></>
    );
  };
  const getModalHeader = () => {
    return (
      <Stack direction={'column'} gap={2}>
        <Typography variant="h1">Create New Hold</Typography>
        {getSubHeaderText()}
      </Stack>
    );
  };

  const handleDownload = () => {
    const reqData = {
      limit: totalCount,
      offset: (pagination.pageCount - 1) * pagination.limitCount,
    };

    dispatch(downloadVehiclesApi(reqData, parsedDocumentId, handleNotification));
  };

  const getModalBodyComponent = () => {
    if (currentUploadStage === 0) {
      return (
        <Typography variant="bodyLong" component={'p'} sx={{ marginBottom: '32px' }}>
          Step 1. Begin selecting the vehicles by utilizing the uploader to upload a list of VINS.
          <br />
          Step 2. Review data from file.
          <br /> Step 3: Select VINs. <br />
          Step 4: Select whether you’d like to create a PDC or Yard Hold.
        </Typography>
      );
    } else if ([1, 2, 7].includes(currentUploadStage)) {
      return (
        <Typography variant="bodyLong" component={'p'}>
          {currentUploadStage === 7
            ? `Uploading Appendix A`
            : `Please do not leave the page. Large files may take longer to process.`}
        </Typography>
      );
    } else if (currentUploadStage === 3) {
      return (
        <Typography variant="bodyLong" component={'p'}>
          This preview displays a sample of rows and columns from your file. Review the data below to ensure it has been
          imported correctly.
        </Typography>
      );
    } else if (currentUploadStage === 4) {
      return (
        <div
          className="container"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: '1171px', // existing width
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Stack
            className="container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexBasis: '70%',
            }}
          >
            <Typography variant="h3">All Vehicles</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
              }}
              gap={1}
            >
              <Typography variant="bodyLong">{count?.totalCount} items</Typography>

              <Typography variant="bodyLong">{` ■ ${count?.validVinsCount} Valid`}</Typography>

              <Typography variant="bodyLong">{` ■ ${count?.invalidVinsCount} Invalid`}</Typography>

              <Typography variant="bodyLong">{` ■ ${count?.duplicateVinsCount} Duplicates`}</Typography>

              <Typography variant="bodyLong">{` ■ Sorted by Upload Status`}</Typography>
            </Stack>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'flex-end', flexBasis: '30%' }}>
            <IconButton name="SaveAlt" onClick={handleDownload} />
          </div>
        </div>
      );
    } else if ([6].includes(currentUploadStage)) {
      return (
        <Typography variant="bodyLong" component={'p'}>
          Please do not leave the page. Creating Hold Request.
        </Typography>
      );
    }
  };

  const getModalSlotComponent = () => {
    if (currentUploadStage === 0) {
      return (
        <FileUploadDragAndDrop
          supportedDocFormats={supportedDocFormats}
          handleAcceptedFiles={(files: readonly FileWithPath[]) => setAcceptedFiles(files)}
          acceptFormats={{ 'text/csv': supportedDocFormats }}
        />
      );
    } else if ([1, 2, 6, 7].includes(currentUploadStage)) {
      return (
        <div className="container loading-section">
          <LoadingSpinner />
        </div>
      );
    } else if (currentUploadStage === 3) {
      return <RenderDocumentPreview importFile={importFile} setImportFile={setImportFile} />;
    } else if (currentUploadStage === 4) {
      return (
        <RenderVehicleListTable documentId={parsedDocumentId} setPagination={setPagination} pagination={pagination} />
      );
    } else if (currentUploadStage === 5) {
      return <SelectHoldTypeModal handleModalCancel={handleModalCancel} />;
    }
  };

  const goToPrevious = () => {
    dispatch(setCurrentUploadStage(0));
  };

  const handleContinue = () => {
    if (fileName === 'data.csv' && currentUploadStage === 0) {
      initDocumentUpload(importFile);
    }

    if (currentUploadStage === 3) {
      dispatch(setCurrentUploadStage(4));
      checkParseStatus({ ...documentDetails });
    }

    if (currentUploadStage === 4) {
      dispatch(setCurrentUploadStage(5));
    }
  };

  const getActionButtonProps = () => {
    return (
      <div>
        {[1, 2, 3].includes(currentUploadStage) ? (
          <Button
            sx={{ marginRight: '16px', height: '40px' }}
            variant="tertiary"
            label={'Previous'}
            onClick={goToPrevious}
          />
        ) : (
          <Button
            sx={{ marginRight: '16px', height: '40px' }}
            variant="tertiary"
            label={'Cancel'}
            onClick={handleModalCancel}
          />
        )}
        {[0, 1, 2, 3, 4].includes(currentUploadStage) ? (
          <Button
            // disabled={currentUploadStage !== 3}
            variant="primary"
            label={currentUploadStage === 8 ? 'Submit' : 'Continue'}
            disabled={[3, 4].includes(currentUploadStage) ? false : true}
            onClick={handleContinue}
          />
        ) : (
          <Button
            variant="primary"
            label={'Confirm'}
            disabled={issueDetails.issueTitle !== '' && holdType !== '' ? false : true}
            onClick={() => {
              dispatch(setCurrentUploadStage(6));
              handleCreateQC();
              setImportFile(undefined);
            }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (acceptedFiles?.length === 1) {
      let fileExt = acceptedFiles[0].name.split('.').pop();
      if (supportedDocFormats.some((x) => x === `.${fileExt}`)) {
        setImportFile(acceptedFiles[0] as File);
        setFileName(acceptedFiles[0]?.name || '');
        initDocumentUpload(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles]);

  return (
    <Modal
      closeIcon={
        <Icon
          name="Close"
          onClick={handleModalCancel}
          sx={{
            position: 'absolute',
            fontSize: '2rem',
            right: 0,
            top: 0,
            margin: '24px',
            padding: '8px',
            cursor: 'pointer',
          }}
        />
      }
      handleCancel={handleModalCancel}
      modalButton={
        <Button
          label={'Create New Hold'}
          variant="primary"
          size="large"
          onClick={() => {
            initHold();
            setShowModal(true);
          }}
          icon={<Icon name="AddOutlined" fontSize="small" />}
          sx={{ height: '40px' }}
        />
      }
      openModal={showModal}
      modalStyles={uploadDocStyle}
      variant={'Transactional modal'}
      bodyText={getModalBodyComponent()}
      actionButtons={
        [6, 7].includes(currentUploadStage) ? (
          <></>
        ) : (
          <Stack
            sx={{
              position: 'relative', // Position relative to keep it aligned
              padding: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {getActionButtonProps()}
          </Stack>
        )
      }
      slotComponent={getModalSlotComponent()}
      label={getModalHeader()}
    />
  );
};
