import { Card, Icon } from 'cubee2e-cube-global-components';
import { Button, Grid, Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import theme from '../../theme';
import React from 'react';
import { getKPICardTooltipText } from '../../Constants';
import { InfoOutlined } from '@mui/icons-material';
export interface KpiCardprops {
  label: string;
  count: string;
  refreshedDate?: string;
  handleClick?: () => void;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 0.802px 2.89px 0.802px rgba(0, 0, 0, 0.15)',
    fontSize: 11,
  },
}));

const KpiCard = ({ kpiCards }: { kpiCards: KpiCardprops[] }) => {
  const RenderToolTipText = (item: KpiCardprops) => {
    return (
      <Stack px={2} py={1} sx={{ flexDirection: 'column' }} gap={1}>
        <Typography variant="bodyShortSemibold" color={theme.palette.common.black}>
          {item.label === 'SAN' ? 'Shipped After to Notification (SAN)' : item.label}
        </Typography>
        <Typography variant="caption" color={theme.palette.common.black}>
          {item.refreshedDate}
        </Typography>
        <Typography variant="bodyShort" color={theme.palette.common.black}>
          {getKPICardTooltipText(item.label)}
        </Typography>
      </Stack>
    );
  };
  return (
    <Grid container spacing={2}>
      {kpiCards?.map((item, index) => (
        <Grid item xs={12 / kpiCards.length} lg={12 / kpiCards.length} md={12 / kpiCards.length} key={index}>
          <Card
            titleText={
              <Stack direction={'row'} sx={{ alignItems: 'flex-start' }} gap={0.5}>
                <Typography
                  variant="labelSemibold"
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {item.label}
                </Typography>

                <StyledTooltip title={<RenderToolTipText {...item} />}>
                  <InfoOutlined fontSize="small" />
                </StyledTooltip>
              </Stack>
            }
            clickable={true}
            onClick={item?.handleClick}
            variant={'basic'}
            bodyContent={<Typography variant="displayRegular">{item.count}</Typography>}
            sx={{ cursor: 'pointer', boxShadow: 'none' }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default KpiCard;
