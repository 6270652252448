import { MenuItem, Typography } from '@mui/material';
import { Chip, ViewMenu } from 'cubee2e-cube-global-components';
import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getFilter,
  getHoldRequestStatusCount,
  getHoldStatusCountsApi,
  getHoldType,
  getSidebar,
  getVehicleListApi,
} from '../../store';
import {
  setFilter,
  setPagination,
  setReload,
  setSelectedItems,
  setSidebar,
} from '../../store/features/HoldRequest/slice';
import { setHoldType } from '../../store/features/HoldTypeDetails/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import theme from '../../theme';
import { formatNumber } from '../../utils';
import './Sidebar.scss';

export const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [expandedPanel, setExpandedPanel] = useState<string | boolean>('panel1');
  const sidebarOpen = useAppSelector(getSidebar);
  const holdType = useAppSelector(getHoldType);
  const holdRequestStatusCounts = useAppSelector(getHoldRequestStatusCount);
  const filter = useAppSelector(getFilter);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(sidebarOpen);
  }, [sidebarOpen]);

  const AccordionContent = ({
    viewCards,
  }: {
    viewCards: { label: ReactNode; shortName?: ReactNode; count?: string; id?: string }[];
  }) => {
    const handleViewCardClick = (card: { label: ReactNode; shortName?: ReactNode; count?: string; id?: string }) => {
      dispatch(setReload(true));
      const queryParam = card?.id === '1' ? 'PDC' : 'YARD';
      dispatch(setHoldType(card?.id.toString()));
      dispatch(setPagination({ page: 1, size: 25 }));

      if (card?.id === '1' || card?.id === '2') {
        dispatch(
          setFilter([
            {
              field: 'HoldType',
              value: [queryParam.toLowerCase()],
              query: card?.id.toString(),
              name: 'Hold Type',
              type: 'string',
            },
          ]),
        );
      } else if (card?.id === '0') {
        dispatch(setFilter([]));
      }
      dispatch(getVehicleListApi());
      dispatch(getHoldStatusCountsApi());
      dispatch(setSelectedItems(card));
    };

    return (
      // <Menu open={true} anchorEl={anchor}>
      viewCards.map((card, index) => {
        const isSelected = card.id === holdType;
        return open ? (
          <MenuItem
            key={card.id}
            selected={isSelected}
            onClick={() => {
              handleViewCardClick(card);
              navigate('/HoldManagement');
            }}
            sx={{
              borderRadius: '4px',
              border: '1px solid #DBDCDE',
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              padding: '12px 12px 12px 16px',
              height: '48px',
              marginBottom: '0.5rem',
              ':hover': {
                cursor: 'pointer',
                border: `1px solid ${theme.palette.common.black} `,
              },
              '&.Mui-selected': {
                border: `1px solid ${theme.palette.common.black}`,
                backgroundColor: theme.palette.secondary[90],
              },
            }}
          >
            <Typography component="span" variant="bodyShort" className="chipLabel">
              {card.label}
            </Typography>
            {card.count && (
              <Chip
                label={card.count}
                // colorScheme={isSelected ? 'iconBlue' : 'primaryContainer'}
                variant="filled"
                sx={{
                  backgroundColor: isSelected ? theme.palette.primary[10] : theme.palette.primary[100],
                  color: isSelected ? theme.palette.text.white : theme.palette.primary[10],
                }}
              />
            )}
          </MenuItem>
        ) : (
          <div
            onClick={() => {
              handleViewCardClick(card);
              navigate('/HoldManagement');
            }}
          >
            <Chip
              label={card.shortName}
              variant="outlined"
              sx={{
                border: `1px solid ${isSelected ? theme.palette.common.black : theme.palette.secondary[80]}`,
                backgroundColor: isSelected ? theme.palette.secondary[90] : theme.palette.common.white,
                height: '48px',
                marginBottom: '0.5rem',
              }}
            />
          </div>
        );
      })
    );
  };

  const handleAccordionChange = (panel: string, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const panels = [
    {
      panel: 'panel1',
      header: open ? 'QC Hold Type' : 'QC',
      onChange: handleAccordionChange, // Handle panel toggle
      isExpanded: expandedPanel === 'panel1',
      accordionContent: (
        <AccordionContent
          viewCards={[
            {
              label: 'All QC Holds',
              count: formatNumber(holdRequestStatusCounts.all),
              id: '0',
              shortName: 'AQCH',
            },
            { label: 'PDC Holds', count: formatNumber(holdRequestStatusCounts.pdcCount), id: '1', shortName: 'PDC' },
            { label: 'Yard Holds', count: formatNumber(holdRequestStatusCounts.yardCount), id: '2', shortName: 'YARD' },
          ]}
        />
      ),
    },
  ];

  return (
    <ViewMenu
      accordionPanels={panels}
      title={'Views'}
      onOpen={() => {
        dispatch(setSidebar(!open));
      }}
      open={open}
      sx={{ width: open ? '240px' : '56px', height: '82vh', marginBottom: '24px' }}
    />
  );
};
