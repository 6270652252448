export enum ParseStatusEnum {
  NoStatus = 'No Status',
  NoError = 'No Error',
  Parsing = 'Parsing',
  Validating = 'Validating',
  PartiallyParsed = 'Partially Parsed',
  ParsedSuccessfully = 'Parsed Successfully',
  Started = 'Started',
  Parsed = 'Parsed',
  Failed = 'Failed',
}
