import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { predefinedFilters as pre } from '../../../Constants/PredefinedFiltersConst';
import { lifecycleStatusList } from '../../../Constants/filters.constant';

const predefinedFilters = pre;
// Define a type for the slice state
interface CascadingFilter {
  auth: any;
  cfilter: any;
  isCascadingFilterOpen: boolean;
  resetCascadingFilter: boolean;
  filterButtonChipValue: string;
  distributor: any;
  brand: any;
  fleet: any;
  region: any;
  vechileAssignment: any;
  dealer: any;
  modelYear: any;
  series: any;
  earmarkTypes: any;
  isCascadingFilterSwitchChecked: boolean;
  expandedPanel: string | boolean;
  selectedPredefined: Array<string>;
  predefinedChipValue: string;
  selectedDistributors: Array<string>;
  distributorsChipValue: string;
  selectedBrands: Array<any>;
  brandsChipValue: string;
  selectedFleets: Array<string>;
  fleetsChipValue: string;
  selectedRegions: Array<string>;
  regionsChipValue: string;
  selectedVehicleAssignment: Array<string>;
  selectedSeries: Array<string>;
  vehicleAssignmentChipValue: string;
  modelYearChipValue: string;
  dealerChipValue: string;
  seriesChipValue: string;
  isDealerListOpen: boolean;
  isSeriesListOpen: boolean;
}

// Define the initial state using that type
const initialState: CascadingFilter = {
  auth: {},
  cfilter: {
    // distributorCode: { in: ['91041', '92092', '92121'] },
    // lifecycleSubstatusDescription: lifecycleStatusList,
    // modelYear: { gt: 2023 },
  },
  resetCascadingFilter: false,
  isCascadingFilterOpen: false,
  filterButtonChipValue: 'All',
  distributor: [],
  brand: [],
  fleet: [],
  region: [],
  vechileAssignment: [],
  dealer: [],
  modelYear: [],
  series: [],
  earmarkTypes: [],
  isCascadingFilterSwitchChecked: true,
  expandedPanel: false,
  selectedPredefined: predefinedFilters?.map((item) => item.label),
  predefinedChipValue: 'ALL',
  selectedDistributors: [],
  distributorsChipValue: 'ALL',
  selectedBrands: [],
  brandsChipValue: 'ALL',
  selectedFleets: [],
  fleetsChipValue: 'ALL',
  selectedRegions: [],
  regionsChipValue: 'ALL',
  selectedVehicleAssignment: [],
  vehicleAssignmentChipValue: 'ALL',
  modelYearChipValue: 'ALL',
  dealerChipValue: 'ALL',
  seriesChipValue: 'ALL',
  selectedSeries: [],
  isDealerListOpen: false,
  isSeriesListOpen: false,
};

// `createSlice` will infer the state type from the `initialState` argument
export const cascadingFilterSlice = createSlice({
  name: 'cascadingFilter',
  initialState,
  reducers: {
    setCascadingFilter: (state, action: PayloadAction<any>) => {
      state.cfilter = action.payload;
    },
    setIsCascadingFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isCascadingFilterOpen = action.payload;
    },
    setFilterButtonChipValue: (state, action: PayloadAction<string>) => {
      state.filterButtonChipValue = action.payload;
    },
    setResetCascadingFilter: (state, action: PayloadAction<boolean>) => {
      state.resetCascadingFilter = action.payload;
    },
    setAuthData: (state, action: PayloadAction<any>) => {
      state.auth = action.payload;
    },
    setPageFilterData: (state, action: PayloadAction<any>) => {
      let distributorArray: any = [];
      let brandsArray: any = [];
      let regionsArray: any = [];
      let dealersArray: any = [];
      let seriesArray: any = [];
      action?.payload?.pageFilter?.distributorCodes?.map((distributor: any) => {
        distributorArray.push({ ...distributor, isChecked: true });
        distributor?.brandCodes?.map((brands: any) => {
          brandsArray.push({
            isChecked: true,
            distributorCode: distributor.distributorCode,
            ...brands,
          });
          brands?.regions?.map((region: any) => {
            regionsArray.push({
              isChecked: true,
              distributorCode: distributor.distributorCode,
              brandCode: brands.brandCode,
              ...region,
            });
            region?.dealers?.map((dealer: any) => {
              dealersArray.push({
                isChecked: true,
                distributorCode: distributor.distributorCode,
                brandCode: brands.brandCode,
                regionCode: region.regionCode,
                ...dealer,
              });
            });
          });
          brands?.saleSeriesNames?.map((saleSeriesName: any) => {
            seriesArray.push({
              isChecked: true,
              distributorCode: distributor.distributorCode,
              brandCode: brands.brandCode,
              ...saleSeriesName,
            });
          });
        });
      });

      // Add user to the state array
      state.distributor = distributorArray;
      // localStorage.setItem('distributor', JSON.stringify(distributorArray));
      state.brand = brandsArray;
      let updatedBrandArr = brandsArray.reduce((acc, curr) => {
        acc[curr.brandName] = curr;
        return acc;
      }, {});
      const filteredData = Object.values(updatedBrandArr);
      // localStorage.setItem('brand', JSON.stringify(filteredData));
      // localStorage.setItem('brand', JSON.stringify(brandsArray));
      state.region = regionsArray;
      // localStorage.setItem('region', JSON.stringify(regionsArray));
      state.dealer = dealersArray;
      state.series = seriesArray;
      state.earmarkTypes = action?.payload?.pageFilter?.earmarkTypes;
      state.modelYear = action?.payload?.pageFilter?.modelYears?.map((item: any) => {
        return { ...item, isChecked: true };
      });
      state.vechileAssignment = action?.payload?.pageFilter?.vehicleAssignmentIndicators?.map(
        (item: any) => {
          return { ...item, isChecked: true };
        }
      );
      state.fleet = action?.payload?.pageFilter?.fleetIndicators?.map((item: any) => {
        return { ...item, isChecked: true };
      });

      //Initial Selected states
      state.selectedDistributors = state.distributor?.map((item: any) => item.distributorCode);
      state.selectedBrands = [...new Set(state.brand?.map((item: any) => item.brandCode))];
      state.selectedFleets = state.fleet?.map((item: any) => item.name);
      state.selectedRegions = state.region?.map((item: any) => item.regionCode);
      state.selectedVehicleAssignment = state.vechileAssignment?.map((item: any) => item.name);
      let unqseries = [...new Set(state.series.map((d: any) => d.saleSeriesName))];
      state.selectedSeries = unqseries.map((d: any) => d);
    },
    setisCascadingFilterSwitchChecked: (state, action: PayloadAction<boolean>) => {
      state.isCascadingFilterSwitchChecked = action.payload;
    },
    setexpandedPanel: (state, action: PayloadAction<string | boolean>) => {
      state.expandedPanel = action.payload;
    },
    setdistributor: (state, action: PayloadAction<Array<any>>) => {
      state.distributor = action.payload;
    },
    setbrand: (state, action: PayloadAction<Array<any>>) => {
      state.brand = action.payload;
    },
    setfleet: (state, action: PayloadAction<Array<any>>) => {
      state.fleet = action.payload;
    },
    setregion: (state, action: PayloadAction<Array<any>>) => {
      state.region = action.payload;
    },
    setvechileAssignment: (state, action: PayloadAction<Array<any>>) => {
      state.vechileAssignment = action.payload;
    },
    setmodelYear: (state, action: PayloadAction<Array<any>>) => {
      state.modelYear = action.payload;
    },
    setdealer: (state, action: PayloadAction<Array<any>>) => {
      state.dealer = action.payload;
    },
    setselectedPredefined: (state, action: PayloadAction<Array<string>>) => {
      state.selectedPredefined = action.payload;
      if (state.selectedPredefined.length == 1) {
        state.predefinedChipValue = state.selectedPredefined[0];
      } else if (state.selectedPredefined.length === predefinedFilters.length) {
        state.predefinedChipValue = `ALL`;
      } else {
        state.predefinedChipValue = `${state.selectedPredefined.length}`;
      }
      state.selectedDistributors = state.distributor?.map((item: any) => item.distributorCode);
    },
    setselectedDistributors: (state, action: PayloadAction<Array<string>>) => {
      state.selectedDistributors = action.payload;
    },
    setdistributorsChipValue: (state, action: PayloadAction<string>) => {
      state.distributorsChipValue = action.payload;
    },
    setselectedBrands: (state, action: PayloadAction<Array<any>>) => {
      state.selectedBrands = action.payload;
    },
    setselectedSeries: (state, action: PayloadAction<Array<any>>) => {
      state.selectedSeries = action.payload;
    },
    setbrandsChipValue: (state, action: PayloadAction<string>) => {
      state.brandsChipValue = action.payload;
    },
    setselectedFleets: (state, action: PayloadAction<Array<string>>) => {
      state.selectedFleets = action.payload;
    },
    setfleetsChipValue: (state, action: PayloadAction<string>) => {
      state.fleetsChipValue = action.payload;
    },
    setselectedRegions: (state, action: PayloadAction<Array<string>>) => {
      state.selectedRegions = action.payload;
    },
    setregionsChipValue: (state, action: PayloadAction<string>) => {
      state.regionsChipValue = action.payload;
    },
    setselectedVehicleAssignment: (state, action: PayloadAction<Array<string>>) => {
      state.selectedVehicleAssignment = action.payload;
    },
    setvehicleAssignmentChipValue: (state, action: PayloadAction<string>) => {
      state.vehicleAssignmentChipValue = action.payload;
    },
    setmodelYearChipValue: (state, action: PayloadAction<string>) => {
      state.modelYearChipValue = action.payload;
    },
    setdealerChipValue: (state, action: PayloadAction<string>) => {
      state.dealerChipValue = action.payload;
    },
    setseriesChipValue: (state, action: PayloadAction<string>) => {
      state.seriesChipValue = action.payload;
    },
    setisDealerListOpen: (state, action: PayloadAction<boolean>) => {
      state.isDealerListOpen = action.payload;
    },
    setisSeriesListOpen: (state, action: PayloadAction<boolean>) => {
      state.isSeriesListOpen = action.payload;
    },
  },
});

export const {
  setCascadingFilter,
  setIsCascadingFilterOpen,
  setFilterButtonChipValue,
  setResetCascadingFilter,
  setAuthData,
  setPageFilterData,
  setisCascadingFilterSwitchChecked,
  setexpandedPanel,
  setselectedPredefined,
  setdistributor,
  setbrand,
  setfleet,
  setregion,
  setvechileAssignment,
  setmodelYear,
  setdealer,
  setselectedDistributors,
  setdistributorsChipValue,
  setselectedBrands,
  setbrandsChipValue,
  setselectedFleets,
  setfleetsChipValue,
  setselectedRegions,
  setregionsChipValue,
  setselectedVehicleAssignment,
  setvehicleAssignmentChipValue,
  setselectedSeries,
  setmodelYearChipValue,
  setdealerChipValue,
  setseriesChipValue,
  setisDealerListOpen,
  setisSeriesListOpen,
} = cascadingFilterSlice.actions;

export default cascadingFilterSlice.reducer;
